import React from "react";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import SellLeads from "./SellLeads";
import { connect } from "react-redux";
import { deleteLead, getMarketplaceLeads } from "../../../store/marketplace";

const SellLeadsContainer = (props) => {
  return (
    <SellLeads
      companyType={props.companyType}
      leads={props.leads}
      getMarketplaceLeads={props.getMarketplaceLeads}
      deleteLead={props.deleteLead}
    />
  );
};

const mapStateToProps = (state) => ({
  companyType: state.auth.company_info.type,
  leads: state.marketplace.leads,
});

export default compose(
  connect(mapStateToProps, {
    getMarketplaceLeads,
    deleteLead,
  }),
  withAuthRedirect,
)(SellLeadsContainer);
