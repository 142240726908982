import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { getCasesList, updateTopCases } from "../../../store/cases";
import ProfileTopCasesEditing from "./ProfileTopCasesEditing";

const ProfileTopCasesEditingContainer = (props) => {
  return <ProfileTopCasesEditing {...props}/>;
};

const mapStateToProps = state => ({
  auth: state.auth,
  cases: state.cases,
});

const mapDispatchToProps = {
  getCasesList,
  updateTopCases
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthRedirect
)(ProfileTopCasesEditingContainer);
