import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import styles from "./Breadcrumbs.module.css";

const Breadcrumbs = (props) => {
  const { subPages, currentPage, className } = props;

  return (
    <ul className={cn(styles.breadcrumbs, className)}>
      <li>
        <Link to="/">Home</Link>
      </li>
      {subPages?.map(page => (
        <li key={page.link}>
          <Link to={page.link}>{page.label}</Link>
        </li>
      ))}
      <li>{currentPage}</li>
    </ul>
  );
};

export default Breadcrumbs;