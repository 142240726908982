import React from "react";
import { connect } from "react-redux";

import { recover, hideModal } from "../../store/auth";
import PasswordRecovery from "./PasswordRecovery";

const PasswordRecoveryContainer = (props) => {
  return (
    <PasswordRecovery
      is_fetching={props.auth.is_fetching}
      show_modal={props.auth.show_modal}
      recover={props.recover}
      hideModal={props.hideModal}
    />
  );
};

let mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { recover, hideModal })(PasswordRecoveryContainer);
