import { connect } from "react-redux";
import PriceIndicator from "./PriceIndicator";

const PriceIndicatorContainer = (props) => {
  return (
    <PriceIndicator
      rate_difference={props.rate_difference}
      rate={props.rate}
      position_level_id={props.position_level_id}
      position_levels={props.position_levels}
    />
  );
};

let mapStateToProps = (state) => ({
  position_levels: state.developer.position_levels,
});

export default connect(mapStateToProps, {})(PriceIndicatorContainer);
