import qs from "qs";
import { axiosFileInstances, axiosInstances, axiosJSONInstances } from "./api";

const axiosAuthInstance = axiosInstances.auth;

const axiosAccountInstance = axiosInstances.account;
const axiosAccountJSONInstance = axiosJSONInstances.account;
const axiosAccountFileInstance = axiosFileInstances.account;

export const authAPI = {
  getCompanyData(token) {
    return axiosAccountInstance
      .get("api/users/companies/", qs.stringify(token))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
  },

  acceptTerms() {
    return axiosAccountInstance
      .put("api/users/companies", qs.stringify({ terms_accepted: "1" }))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
  },

  getCompanyVerificationChecks() {
    return axiosAccountInstance
      .get("api/users/companies/vetted/check")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
  },

  getCompanyTop10Checks() {
    return axiosAccountInstance
      .get("api/users/companies/in-top-10/check")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
  },

  login(email, password) {
    const data = {
      email: email,
      password: password,
    };

    return axiosAuthInstance
      .post("api/auth/login", qs.stringify(data))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
  },

  register(data) {
    return axiosAuthInstance
      .post("api/auth/register", qs.stringify(data))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
  },

  confirm(data) {
    return axiosAuthInstance
      .post("api/auth/confirm", qs.stringify(data))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
  },

  confirmEmail(otp) {
    return axiosAuthInstance
      .post("api/auth/confirm-email", qs.stringify({ otp }))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
  },

  editProfile(data) {
    return axiosAccountJSONInstance
      .put("api/users/companies/", JSON.stringify(data))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
  },

  updateSkill(data) {
    return axiosAccountFileInstance.post("api/users/skills", data).then((response) => {
      return response.data;
    });
  },

  uploadPhoto(data) {
    return axiosAccountFileInstance.post("api/users/upload", data).then((response) => {
      return response.data;
    });
  },

  uploadAwardLogo(data) {
    return axiosAccountFileInstance.post("api/users/award/upload", data).then((response) => {
      return response.data;
    });
  },

  uploadTechnologiesLogo(data) {
    return axiosAccountFileInstance.post("api/users/technologies/upload", data).then((response) => {
      return response.data;
    });
  },

  uploadClientsLogo(data) {
    return axiosAccountFileInstance.post("api/users/clients/upload", data).then((response) => {
      return response.data;
    });
  },

  recover(email) {
    let data = {
      email,
    };
    return axiosAuthInstance
      .post("api/auth/recover", qs.stringify(data))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
  },

  payForChat(data) {
    return axiosAccountInstance
      .post("api/users/companies/points", qs.stringify(data))
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          return error.response;
        }
      });
  },
};
