import React, { useEffect } from "react";
import { connect } from "react-redux";

import RegistrationQuick from "./RegistrationQuick";
import { trialSearch, setSelectedTrialDeveloper } from "../../../store/search";
import { quickRegister, loginAndHire } from "../../../store/auth";
import { getCurrentDeveloper } from "../../../store/developer";

const RegistrationQuickContainer = (props) => {
  useEffect(() => {
    props.getCurrentDeveloper(props.match.params.developer_id);
  }, []);

  return (
    <RegistrationQuick
      {...props}
      is_fetching_auth={props.is_fetching_auth}
      developer={props.developer}
      trialSearch={props.trialSearch}
      quickRegister={props.quickRegister}
      loginAndHire={props.loginAndHire}
    />
  );
};

let mapStateToProps = (state) => {
  return {
    app: state.app,
    is_auth: state.auth.is_auth,
    is_fetching: state.search.is_fetching,
    is_fetching_auth: state.auth.is_fetching_auth,
    trial_search: state.search.trial_search,
    skillSponsor: state.search.skillSponsor,
    developer: state.developer?.current_developer,
  };
};

export default connect(mapStateToProps, {
  getCurrentDeveloper,
  trialSearch,
  setSelectedTrialDeveloper,
  quickRegister,
  loginAndHire,
})(RegistrationQuickContainer);
