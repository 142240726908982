import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  getIndustriesList,
  getIndustriesTopCompanies,
  getPopularServicesList,
  getPopularServicesTopCompanies,
  addViewBannerAnalytic,
  addClickBannerAnalytic,
  addChooseServiceAnalytic,
  addViewIndustryCompaniesAnalytic,
  addViewServiceCompaniesAnalytic,
  addClickIndustryCompanyAnalytic,
  addClickServiceCompanyAnalytic,
  addClickServiceCompanyTelegramAnalytic,
  addClickServiceCompanyWhatsappAnalytic,
  addClickServiceCompanyEmailAnalytic,
} from "../../store/app";
import TeamsPage from "./TeamsPage";

const defaultPopularServiceId = 5;

const numberOfCompaniesInTopIndustries = 10;

const TeamsPageContainer = (props) => {
  const { industries } = props;

  const [topIndustries, setTopIndustries] = useState([]);
  const [otherIndustries, setOtherIndustries] = useState([]);
  const [industriesWithCompanies, setIndustriesWithCompanies] = useState([]);

  useEffect(() => {
    props.getIndustriesList();
    props.getPopularServicesList();
  }, []);

  useEffect(() => {
    if (industries.length) {
      const topIndustries = [];
      const otherIndustries = [];
      industries.forEach(industry => {
        if (industry.number_of_companies >= numberOfCompaniesInTopIndustries) {
          topIndustries.push(industry);
        } else if (industry.number_of_companies) {
          otherIndustries.push(industry);
        }
      });

      const industriesWithCompanies = [...topIndustries, ...otherIndustries];
      setTopIndustries(topIndustries);
      setOtherIndustries(otherIndustries);
      setIndustriesWithCompanies(industriesWithCompanies);
    }
  }, [industries]);

  return (
    <TeamsPage
      {...props}
      industries={industriesWithCompanies}
      topIndustries={topIndustries}
      otherIndustries={otherIndustries}
      defaultPopularServiceId={defaultPopularServiceId}
    />
  );
};

let mapStateToProps = (state) => ({
  industries: state.app.industries_list,
  industriesTopCompanies: state.app.industries_top_companies,
  popularServices: state.app.popular_services_list,
  popularServicesTopCompanies: state.app.popular_services_top_companies,
  stats: state.app.stats,
});

const mapDispatchToProps = {
  getIndustriesList,
  getIndustriesTopCompanies,
  getPopularServicesList,
  getPopularServicesTopCompanies,
  addViewBannerAnalytic,
  addClickBannerAnalytic,
  addChooseServiceAnalytic,
  addViewIndustryCompaniesAnalytic,
  addViewServiceCompaniesAnalytic,
  addClickIndustryCompanyAnalytic,
  addClickServiceCompanyAnalytic,
  addClickServiceCompanyTelegramAnalytic,
  addClickServiceCompanyWhatsappAnalytic,
  addClickServiceCompanyEmailAnalytic,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsPageContainer);
