import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import { getCasesList, getCasesListById, deleteCase } from "../../store/cases";
import { startOutsourceChat } from "../../store/outsource";
import CompanyDetails from "./CompanyDetails";

const CompanyDetailsContainer = (props) => {
  useEffect(() => {
    props.getCasesListById(props.match.params.companyId);
  }, []);

  return (
    <CompanyDetails
      isOwnCases={props.companyType === 2}
      companyInfo={props.cases.companyInfo}
      markets_list={props.markets_list}
      isFetching={props.cases.isFetching}
      casesList={props.cases.casesList}
      startOutsourceChat={props.startOutsourceChat}
      deleteCase={props.deleteCase}
    />
  );
};

let mapStateToProps = (state) => ({
  companyType: state.auth?.company_info?.type,
  markets_list: state.app.markets_list,
  cases: state.cases,
});

export default compose(
  connect(mapStateToProps, {
    getCasesList,
    getCasesListById,
    startOutsourceChat,
    deleteCase,
  }),
  withAuthRedirect,
)(CompanyDetailsContainer);
