import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Table, Tooltip } from "antd";
import moment from "moment";
import cn from "classnames";

import { marketplaceChatAPI } from "../../api/marketplace";
import { siteNameForTitle } from "../../constants/global-constants";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import PriceIndicatorContainer from "../common/PriceIndicator/PriceIndicatorContainer";
import MarketplaceNavigation from "./MarketplaceNavigation";
import ProjectDescriptionModal from "./ProjectDescriptionModal";
import WalletModal from "../Payment/WalletModal/WalletModal";
import Modal from "../common/Modal/Modal";

import verifiedIcon from "../../assets/img/verified-2.png";
import nonVerifiedIcon from "../../assets/img/non_verified.png";
import styles from "./Marketplace.module.css";

const Marketplace = (props) => {
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [modalData, setModalData] = useState(false);

  const connectToChat = (message, chatId, searchId) => {
    return props.hire(
      message,
      chatId,
      true,
      () => marketplaceChatAPI.increaseCounter({ search_id: searchId, type: "dev" }),
      () => setShowWalletModal(true)
    );
  };

  const chatToClient = (record) => {
    const title = `${record.position_level_name} ${record.job_title_name}`;
    const skillsList = record.skills.map((skill) => skill.skill_name).join(", ");
    const dateString = moment(record.created).format("DD/MM/YYYY");

    const message = `I would like to provide developer for your public request: ${title}; ${skillsList}; ${dateString}`;

    // const chatId = `${props.company_id}:${record.companyId}`;
    const chatId = `${record.company_id}:${props.companyId}`;

    if (props.companyInfo.legal_documents && props.companyInfo.stripe_subscribed) {
      return connectToChat(message, chatId, record.search_id);
    }

    const payData = {
      search_id: record.search_id,
      for_outstaff: 1,
    };

    props.payForChat(
      payData,
      () => {
        connectToChat(message, chatId, record.search_id);
        // history.push(`/chat/${chatId}`);
      },
      () => setShowWalletModal(true)
    );
  };

  const columns = [
    {
      title: "Request",
      dataIndex: "created",
      key: "created",
      width: 110,
      render: (created, record) => (
        <div className={styles.requestInfo}>
          {record.is_deleted === 1 && <div className={styles.lock} />}
          <div>
            <div>{moment(created).format("DD/MM/YYYY")}</div>
            <div><strong>{record.position_level_name} {record.job_title_name}</strong></div>
            <div>
              {record.skills.map((skill) => skill.skill_name).join(", ")}{record.skills.length ? ', ' : ''}
              English:&nbsp;{record.cerf_level || '0'}
              {record.location ? `, Country: ${record.location}` : ''}<br />
              {/*Client country: {}*/}
            </div>
            <div>
              <img
                className={styles.verifiedButton}
                src={record.verified ? verifiedIcon : nonVerifiedIcon}
                alt={record.verified ? 'VERIFIED' : 'NON-VERIFIED'}
              />
            </div>
          </div>
        </div>
      ),
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
    },
    {
      title: "Rate, $/h",
      dataIndex: "rate_to",
      key: "rate_to",
      width: 150,
      render: (rate_to, record) => {
        return (
          <>
            <div className={styles.rate}>{rate_to}$/h</div>
            <PriceIndicatorContainer rate={rate_to} position_level_id={record.position_level_id}/>
          </>
        );
      },
      sorter: (a, b) => a.rate - b.rate,
    },
    {
      title: "Project description",
      dataIndex: "description",
      key: "description",
      render: (description) => (
        <div className={styles.projectDescription}>
          <div
            className={cn(styles.descriptionCell, styles.descriptionLink)}
            dangerouslySetInnerHTML={{ __html: description }}
            onClick={() => setModalData({ description })}
          />
          {/*<div className={styles.helpLink} onClick={() => setModalData({ description })}>*/}
            {/*?*/}
          {/*</div>*/}
        </div>
      ),
    },
    {
      title: "Verified / All",
      dataIndex: "vetted",
      key: "vetted",
      render: (vetted, record) => (
        <div>
          {vetted ? "Verified only" : "All companies"}
          {!!record.exclude_location && (
            <div className={styles.excludeCountries}>
              There are country restrictions: {record.exclude_location}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Total candidates",
      dataIndex: "found_last_run",
      key: "found_last_run",
      render: (found_last_run, record) =>
        `Search: ${found_last_run} ${!!record.total_requests ? `/ +${record.total_requests}` : ""}`,
    },
    {
      title: "Action",
      key: "operation",
      width: 190,
      align: "center",
      render: (record) => {
        if (record.is_deleted) {
          return (
            <div className={cn("btn", styles.chatBtn, styles.greyChatBtn)}>
              Expired
            </div>
          )
        }

        let canChatToClient = true;
        if (
          (record.vetted === 1 && props?.companyVerificationChecks?.length > 0)
          || record.exclude_location?.includes(props.companyCountry)
        ) {
          canChatToClient = false;
        }

        if (canChatToClient) {
          return (
            <div className={cn("btn", styles.chatBtn)} onClick={() => chatToClient(record)}>
              Chat with client
            </div>
          )
        }

        return (
          <Tooltip
            title="Please add verification information to be able to chat with clients"
            overlayInnerStyle={{ textAlign: "center", maxWidth: "240px" }}
          >
            <div className={cn("btn", styles.chatBtn, styles.chatBtnDisabled)}>
              Chat with client
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const { developers } = props.marketplace;

  const renderDescriptionModal = () => {
    return modalData && (
      <Modal
        showModal={setModalData}
        noBg={true}
        renderComponent={<ProjectDescriptionModal {...modalData} />}
      />
    )
  };

  const renderWalletModal = () => {
    if (showWalletModal) {
      return (
        <Modal
          showModal={setShowWalletModal}
          renderComponent={<WalletModal closeModal={() => setShowWalletModal(false)} />}
          noBg
        />
      )
    }

    return null;
  };

  return (
    <div className={cn(styles.wrapper, styles.companies)}>
      <div className={styles.modal}>
        {renderDescriptionModal()}
      </div>
      <div className={styles.walletModal}>
        {renderWalletModal()}
      </div>

      <Helmet>
        <title>Marketplace / Find outstaffing developers | {siteNameForTitle}</title>
      </Helmet>

      <DashboardMenuContainer/>

      <h1 className={styles.pageTitle}>
        Marketplace / Find outstaffing developers
      </h1>

      <MarketplaceNavigation pricePerChat={3} {...props} />

      <Table
        dataSource={[...developers]}
        columns={columns}
        pagination={false}
        className={styles.tableWrapper}
      />
    </div>
  );
};

export default Marketplace;
