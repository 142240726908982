import React from "react";
import { Helmet } from "react-helmet";

import { siteNameForTitle } from "../../../constants/global-constants";
import Preloader from "../../common/Preloader/Preloader";
import ProfileMenu from "../ProfileMenu";
import SocialForm from "./ClientsForm";
import CompanyMenu from "../CompanyMenu";

import styles from "./ProfileClientsEditing.module.css";

const ProfileClientsEditing = (props) => {

  const onSubmit = (formData) => {
    const userData = {
      clients: formData.clients,
      url_redirect: "/profile",
    };

    props.editClients(userData);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Profile editing | {siteNameForTitle}</title>
      </Helmet>

      {props.auth.is_fetching && <Preloader/>}

      <h2 className={`${styles.title} text_center`}>
        Company profile
      </h2>

      <ProfileMenu />

      <CompanyMenu companyInfo={props.auth.company_info} />

      <div className={styles.subtitle}>Edit profile / Clients</div>

      <SocialForm onSubmit={onSubmit} />
    </div>
  );
};

export default ProfileClientsEditing;
