import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { required, length } from "redux-form-validators";

import { Input, SelectInputKeyValue } from "../../common/FormsControls/FormControls";
import { countriesWithRequiredTelegram, siteNameForTitle } from "../../../constants/global-constants";
import Preloader from "../../common/Preloader/Preloader";
import Editor from "../../common/SimpleEditor/SimpleEditor";
import styles from "./RegistrationStep3.module.css";

let RegistrationStep3Form = (props) => {
  let isTelegramRequired = false;
  if (props.company_info.type === 2 && countriesWithRequiredTelegram.includes(props.company_info.country)) {
    isTelegramRequired = true;
  }

  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fieldsWrapper}>
        <Field
          component={Editor}
          name={"description"}
          label={"About company"}
          label_type={"label_above"}
          validate={[required()]}
        />
        <Field
          component={SelectInputKeyValue}
          name="market_id"
          label="Market"
          validate={[required()]}
          help_text="for example: Ecommerce"
          options={props.markets_list}
        />
        <Field
          component={Input}
          type={"text"}
          pattern="[0-9]*"
          inputMode="numeric"
          name={"telegram"}
          label="Telegram"
          help_text={"Telegram number: (country code) (operator code) number.\nFor example: 380931234567"}
          validate={isTelegramRequired && [required(), length({ minimum: 10 })]}
          normalize={(val) => (val || "").replace(/[^\d]/g, "")}
        />
        <Field component={Input} type={"text"} name={"linkedin"} label="Linkedin" />
        <Field component={Input} type={"text"} name={"twitter"} label="Twitter" />
        <Field component={Input} type={"text"} name={"facebook"} label="Facebook" />
      </div>

      <div className={styles.submitWrapper}>
        <button className={`btn ${styles.submit}`}>Submit</button>
      </div>
    </form>
  );
};

RegistrationStep3Form = reduxForm({ form: "registrationStep3" })(RegistrationStep3Form);

const RegistrationStep3 = (props) => {
  const onSubmit = (formData) => {
    formData = {
      ...formData,
      url_redirect: "/",
    };
    props.editProfile(formData);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Registration | {siteNameForTitle}</title>
      </Helmet>

      {props.auth.is_fetching && <Preloader />}

      <h2 className={styles.title}>Company profile</h2>
      <div className={styles.steps}>
        <ul className={styles.stepsMenu}>
          <li>
            <NavLink to="/registration" activeClassName={styles.active}>
              Base info
            </NavLink>
          </li>
          <li>
            <NavLink to="/registration-company-info" activeClassName={styles.active}>
              Company info
            </NavLink>
          </li>
          <li>
            <NavLink to="/registration-extra-info" activeClassName={styles.active}>
              Additional info
            </NavLink>
          </li>
        </ul>
      </div>

      <RegistrationStep3Form
        onSubmit={onSubmit}
        markets_list={props.markets_list}
        company_info={props.auth.company_info}
      />
    </div>
  );
};

export default RegistrationStep3;
