import React from "react";
import { Field, reduxForm } from "redux-form";
import { Checkbox } from "../../common/FormsControls/FormControls";
import Preloader from "../../common/Preloader/Preloader";
import { NavLink } from "react-router-dom";
import styles from "./BookmarksForm.module.css";

const Form = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      {props.currentDeveloperBookmarks.length ? (
        <>
          <div className={styles.bookmarks_wrapper}>
            {props.currentDeveloperBookmarks.map((bookmark) => (
              <Field
                key={bookmark.bookmark_id}
                name={`bookmark-${bookmark.bookmark_id}`}
                component={Checkbox}
                defaultChecked={bookmark.saved}
                label={bookmark.bookmark_name}
              />
            ))}
          </div>

          <div className={`d_flex ${styles.buttons}`}>
            <button className={`btn ${styles.submit}`}>Save</button>
          </div>
        </>
      ) : (
        <div className={styles.no_bookmarks_tip}>
          Please go to{" "}
          <NavLink to={"/bookmarks"} className={styles.no_bookmarks_tip_link}>
            bookmarks
          </NavLink>{" "}
          and add new bookmark lists
        </div>
      )}
    </form>
  );
};

let ReduxForm = reduxForm({ form: "bookmarks" })(Form);

const BookmarksForm = (props) => {
  const onSubmit = (data) => {
    let addArr = [];
    let deleteArr = [];

    for (const prop in data) {
      let parts = prop.toString().split("-");
      let id = parts.pop();
      if (data[prop] === true) addArr.push(id);
      else if (data[prop] === false) deleteArr.push(id);
    }

    addArr.forEach((bookmark_id) => {
      props.addDeveloperToBookmark(bookmark_id, props.currentDeveloperId);
    });
    deleteArr.forEach((bookmark_id) => {
      props.deleteDeveloperFromBookmark(bookmark_id, props.currentDeveloperId);
    });

    props.setAddingToBookmark(null);
  };

  return (
    <div className={styles.wrapper}>
      {props.isFetching && <Preloader />}

      <div className={styles.form_title}>Add to Bookmark</div>
      <ReduxForm
        onSubmit={onSubmit}
        currentDeveloperBookmarks={props.currentDeveloperBookmarks}
      />
    </div>
  );
};

export default BookmarksForm;
