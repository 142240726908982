import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import cn from "classnames";

import { getCompanyData } from "../../../store/auth";
import { Input } from "../../common/FormsControls/FormControls";
import UploadImageButton from "../../common/UploadImageButton/UploadImageButton";
import LogosList from "../LogosList/LogosList";

import styles from "./ProfileTechnologiesEditing.module.css";

const formName = "technologiesForm";

const TechnologiesForm = (props) => {
  const {
    handleSubmit,
    error,
    dispatch,
    technologyName,
    technologyLogo,
  } = props;

  const [technologies, setTechnologies] = useState([]);

  useEffect(() => {
    if (props.initialValues?.technologies?.length) {
      const initialTechnologies = [];

      props.initialValues.technologies.forEach((technology) => {
        initialTechnologies.push(technology);
      });

      setTechnologies(initialTechnologies);
    }
  }, []);

  const rebuildTechnologyFields = (newTechnologiesArray) => {
    setTechnologies(newTechnologiesArray);
    dispatch(change(formName, 'technologies', null));

    newTechnologiesArray.forEach((technology, index) => {
      const fieldName = `technologies[${index}]`;
      dispatch(change(formName, fieldName, technology));
    });
  };

  const addTechnology = () => {
    if (!technologyName || !technologyLogo) {
      return;
    }

    const technologyData = {
      name: technologyName,
      logo: technologyLogo,
    };

    const newTechnologiesArray = [technologyData, ...technologies];
    rebuildTechnologyFields(newTechnologiesArray);

    dispatch(change(formName, "technology_name", ""));
    dispatch(change(formName, "technology_logo", null));
  };

  const removeTechnology = (index) => {
    technologies.splice(index, 1);
    rebuildTechnologyFields([...technologies]);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.technologies_form}>
      <div className={styles.fields_wrapper}>
        <div className={cn(styles.technologies_section)}>
          <div className={cn(styles.left_column, styles.field_label)}>
            Technologies
          </div>
          <div className={cn(styles.fields_row, styles.technologies_wrapper)}>
            <div className={styles.technologies_fields_wrapper}>
              <Field
                className={styles.input}
                component={Input}
                name="technology_name"
                label="Name"
              />
              <Field
                component={UploadImageButton}
                name="technology_logo"
                label="Upload logo"
                mode="photo"
              />
              <button
                className={styles.grey_button}
                type="button"
                onClick={addTechnology}
              >
                Add new technology
              </button>
            </div>

            {!!technologies.length && (
              <>
                <div className={styles.technologies_items}>
                  {technologies.map((technology, index) => (
                    <div className={styles.industry_item_row} key={index}>
                      <div className={styles.industry_item}>
                        {technology.name}
                      </div>
                      <div
                        className={styles.delete_industry}
                        onClick={() => removeTechnology(index)}
                      >
                        delete
                      </div>
                    </div>
                  ))}
                </div>

                <div className={styles.technology_logos}>
                  <LogosList items={technologies}/>
                </div>
              </>
            )}
          </div>
        </div>

        {error && (
          <div className={`${styles.form_errors} text_center validation_message`}>
            {error}
          </div>
        )}

        <button className={`btn ${styles.submit}`}>Save</button>
      </div>
    </form>
  );
};


const selector = formValueSelector(formName);

let TechnologiesReduxForm = reduxForm({
  form: formName,
  enableReinitialize: true,
})(TechnologiesForm);

const mapStateToProps = (state) => ({
  initialValues: {
    ...state.auth.company_info,
  },
  technologyName: selector(state, 'technology_name'),
  technologyLogo: selector(state, 'technology_logo'),
});

const mapDispatchToProps = { load: getCompanyData }; // bind account loading action creator

export default connect(mapStateToProps, mapDispatchToProps)(TechnologiesReduxForm);