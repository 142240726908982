import React from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";

import NavigationContainer from "./Navigation/NavigationContainer";
import { siteNameForTitle } from "../../constants";

import logo from "../../assets/img/logo.png";
import styles from "./Header.module.css";

const Header = (props) => {
    return (
        <div className={styles.wrapper}>
            <header className={cn(styles.header, props.auth.is_auth ? "wide_container" : styles.header_short)}>
                <NavLink to="/">
                    <img
                      className={styles.logo}
                      src={logo}
                      alt={siteNameForTitle}
                    />
                </NavLink>

                <NavigationContainer />
            </header>
        </div>
    );
};

export default Header;
