import React from "react";
import cn from "classnames";

import { buildTelegramLink, buildWhatsappLink, normalizeURL } from "../../../utils/utils";

import styles from "./SocialLinks.module.css";

const SocialLinks = (props) => {
  let {
    telegram,
    linkedin,
    facebook,
    whatsapp,
    youtube,
    official_email,
    className,
    onTelegramClick,
    onWhatsappClick,
    onEmailClick,
  } = props;

  const linkedinUrl = normalizeURL(linkedin);
  const facebookUrl = normalizeURL(facebook);
  const youtubeUrl = normalizeURL(youtube);
  const telegramUrl = telegram && buildTelegramLink(telegram);
  const whatsappUrl = whatsapp && buildWhatsappLink(whatsapp);

  return (
    <div className={cn(styles.socialButtons, className)}>
      {linkedinUrl && (
        <a
          className={cn(styles.socialLink, styles.linkedin)}
          href={linkedinUrl}
          target="_blank"
          rel="nofollow"
        />
      )}
      {facebookUrl && (
        <a
          className={cn(styles.socialLink, styles.facebook)}
          href={facebookUrl}
          target="_blank"
          rel="nofollow"
        />
      )}
      {youtubeUrl && (
        <a
          className={cn(styles.socialLink, styles.youtube)}
          href={youtubeUrl}
          target="_blank"
          rel="nofollow"
        />
      )}
      {telegramUrl && (
        <a
          className={cn(styles.socialLink, styles.telegram)}
          href={telegramUrl}
          target="_blank"
          rel="nofollow"
          onClick={onTelegramClick}
        />
      )}
      {whatsappUrl && (
        <a
          className={cn(styles.socialLink, styles.whatsapp)}
          href={whatsappUrl}
          target="_blank"
          rel="nofollow"
          onClick={onWhatsappClick}
        />
      )}
      {official_email && (
        <a
          className={cn(styles.socialLink, styles.email)}
          href={`mailto:${official_email}`}
          target="_blank"
          rel="nofollow"
          onClick={onEmailClick}
        />
      )}
    </div>
  );
};

export default SocialLinks;