import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import styles from "./Profile.module.css";

const CompanyMenu = (props) => {

  const {
    developersCounter,
    casesCounter,
    companyInfo,
  } = props;

  if (!companyInfo.is_single_developer) {

    return (
      <ul className={styles.companyMenu}>
        <li>
          <NavLink to="/profile/edit" exact>
            Base info
          </NavLink>
        </li>
        <li>
          <NavLink to="/profile/edit/technologies">
            Technologies
          </NavLink>
        </li>
        <li>
          <NavLink to="/profile/edit/clients">
            Clients
          </NavLink>
        </li>
        <li>
          <NavLink to="/profile/edit/top-cases">
            Top cases
          </NavLink>
        </li>
        <li>
          <NavLink to="/company-bench">
            My developers
            {!!developersCounter && <span className={styles.counter}> ({developersCounter})</span>}
          </NavLink>
        </li>
        <li>
          <NavLink to="/cases">
            Cases
            {!!casesCounter && <span className={styles.counter}> ({casesCounter})</span>}
          </NavLink>
        </li>
        <li>
          <NavLink to="/profile/edit/social">
            Socials
          </NavLink>
        </li>
      </ul>
    );
  }

  return (
    <ul className={styles.companyMenu}>
      <li>
        <NavLink to="/profile/edit" exact>
          Base info
        </NavLink>
      </li>
      <li>
        <NavLink to="/profile/edit/skills">
          Skills&Rates
        </NavLink>
      </li>
      <li>
        <NavLink to="/profile/edit/relocation">
          Relocation
        </NavLink>
      </li>
      <li>
        <NavLink to="/profile/edit/social">
          Social
        </NavLink>
      </li>
      <li>
        <NavLink to="/profile/edit/alerts">
          Alerts
        </NavLink>
      </li>
    </ul>
  );
};

const mapStateToProps = (state) => ({
  developersCounter: state.developer.developers?.length,
  casesCounter: state.cases.casesList?.length,
});

export default connect(mapStateToProps)(CompanyMenu);
