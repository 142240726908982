import React from "react";
import cn from "classnames";
import { Modal, Popover, Table } from "antd";

import styles from "./CompanyVerification.module.css";

const verificationField = [
  { name: "Company name", key: "company_name" },
  { name: "Logo", key: "logo" },
  { name: "Website", key: "site", companyOnly: true },
  { name: "Company description", key: "description" },
  { name: "Name of CEO/Founder", key: "representative_name" },
  { name: "Email confirmation", key: "email" },
  { name: "Legal docs verification", key: "legal_documents" },
];

const columns = [
  {
    title: "Criterion",
    dataIndex: "name",
    key: "name",
    render: name => <div className={styles.name}>{name}</div>,
  },
  {
    title: "Status",
    dataIndex: "missed",
    key: "missed",
    align: "center",
    render: (missed) => (
      <Popover content={missed ? "Please add this information" : "Information is added"} trigger="hover">
        <div className={cn(styles.status, missed ? styles.warning : styles.success)}/>
      </Popover>
    ),
  },
];

const CompanyVerification = (props) => {
  const {
    companyVerificationChecks,
    name,
    company,
    setShowVerificationChecks,
    isSingleDeveloper,
  } = props;

  const dataSource = [];

  verificationField.forEach(fieldData => {
    if (!(fieldData.companyOnly && isSingleDeveloper) || (fieldData.freelancerOnly && !isSingleDeveloper)) {
      dataSource.push({
        name: fieldData.name,
        key: fieldData.key,
        missed: companyVerificationChecks.includes(fieldData.key),
      });
    }
  });

  const renderVerificationContent = () => {
    if (companyVerificationChecks.length) {
      return (
        <>
          {name}, unfortunately your {isSingleDeveloper ? '' : 'company'} profile is not complete enough to
          qualify for VERIFIED status. You need to complete the following:
          <Table dataSource={dataSource} columns={columns} pagination={false}/>
        </>
      );
    }

    return (
      <>
        Congratulations {name}, your company {company} has passed the iBench verification process and
        received VERIFIED status. This makes it possible to receive requests only for VERIFIED companies and
        priority service in iBench support.
      </>
    );
  };

  return (
    <Modal
      title={"Company verification"}
      visible={true}
      onOk={() => setShowVerificationChecks(false)}
      onCancel={() => setShowVerificationChecks(false)}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      {renderVerificationContent()}
    </Modal>
  );
};

export default CompanyVerification;
