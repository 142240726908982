import { useState, useEffect } from "react";
import { connect } from "react-redux";

import { marketsAPI } from "../../../api/markets";
import { editProfile } from "../../../store/auth";
import RegistrationStep3 from "./RegistrationStep3";

const RegistrationStep3Container = (props) => {
  const [markets_list, set_markets_list] = useState(null);

  useEffect(() => {
    marketsAPI.getMarketsList().then((result) => {
      set_markets_list(result);
    });
  }, []);

  return <RegistrationStep3 {...props} markets_list={markets_list} editProfile={props.editProfile} />;
};

let mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  editProfile,
})(RegistrationStep3Container);
