import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { developersAPI } from "../../../api/developers";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { addSearch } from "../../../store/search";
import india_countries_list from "../../../utils/india_countries_list";
import SearchAdding from "./SearchAdding";

const SearchAddingContainer = (props) => {
  const [jobTitlesList, setJobTitlesList] = useState(null);
  const [positionLevelsList, setPositionLevelsList] = useState(null);
  const [englishLevelsList, setEnglishLevelsList] = useState(null);

  useEffect(() => {
    developersAPI.getJobTitles().then((result) => setJobTitlesList(result));
    developersAPI.getPositionLevels().then((result) => setPositionLevelsList(result));
    developersAPI.getLanguageLevels().then((result) => setEnglishLevelsList(result));
  }, []);

  return (
    <SearchAdding
      {...props}
      slot_number={props.search.searches.length + 1}
      jobTitlesList={jobTitlesList}
      positionLevelsList={positionLevelsList}
      skillsList={props.skillsList}
      countriesList={india_countries_list.map((item) => item.label)}
      englishLevelsList={englishLevelsList}
      addSearch={props.addSearch}
      companyInfo={props.companyInfo}
    />
  );
};

const mapStateToProps = (state) => ({
  search: state.search,
  skillsList: state.app.skills_list,
  companyInfo: state.auth.company_info,
});

const mapDispatchToProps = { addSearch };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthRedirect
)(SearchAddingContainer);
