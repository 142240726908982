import React, { useEffect, useMemo, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "antd";
import moment from "moment";
import cn from "classnames";

import { buildImageLink, isAfterYesterday } from "../../utils/utils";
import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import { getMarketplaceCandidateSearches, applyToVacancy } from "../../store/candidates";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import ProjectDescriptionModal from "./ProjectDescriptionModal";
import Modal from "../common/Modal/Modal";

import styles from "./Marketplace.module.css";

const MarketplaceContainer = (props) => {
  const {
    getMarketplaceCandidateSearches,
    searches,
    applyToVacancy,
    companyId,
    companyCountry,
  } = props;

  useEffect(() => {
    getMarketplaceCandidateSearches();
  }, []);

  const newCandidateSearches = useMemo(() => {
    return searches.filter(search => isAfterYesterday(search.created_at));
  }, [searches]);

  const [modalData, setModalData] = useState(false);

  const applyHandler = (vacancyData) => {
    applyToVacancy(vacancyData, companyId);
  };

  const getLanguagesListString = (languages) => {
    return languages?.map(language => `${language.language}: ${language.cerf_level}`).join(", ");
  };

  const getCompanyLogo = (searchData) => {
    const link = `/company/${searchData.company_id}`;
    if (searchData.logo) {
      return (
        <Link to={link} target="_blank">
          <img
            src={buildImageLink(searchData.logo)}
            alt={searchData.company_name}
            className={styles.logo}
          />
        </Link>
      );
    }

    return (
      <Link to={link} className={styles.companyName} target="_blank">
        {searchData.company_name}
      </Link>
    )
  };

  const columns = [
    {
      title: "Request",
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt, record) => {
        return (
          <div className={styles.requestInfo}>
            {record.is_deleted === 1 && <div className={styles.lock} />}
            <div>
              <div className="bold">
                {moment(createdAt).format('DD/MM/YYYY')}
              </div>
              <div className="bold mt-5 fsz-16">
                {record.position_level_name} {record.job_title_name}
              </div>
              <div className="fsz-16">
                {getLanguagesListString(record.languages)}
              </div>
              <div className="fsz-16">
                Experience in: {record.project_area_name}
              </div>
              <div className="bold mt-25">
                Remote, start: {record.start_in}
              </div>
            </div>
          </div>
        );
      },
      sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
    {
      title: "Salary, $",
      dataIndex: "rate_to",
      key: "rate_to",
      render: (rateTo) => (
        <div className={styles.talentRate}>
          {rateTo} $
        </div>
      ),
      sorter: (a, b) => a.rate_to - b.rate_to,
    },
    {
      title: "Job Description",
      dataIndex: "description",
      key: "description",
      render: (description, record) => {
        return (
          <>
            <div>
              {getCompanyLogo(record)}
            </div>
            <div className={styles.vacancyDescriptionBlock}>
              <div className={styles.vacancyDescription} dangerouslySetInnerHTML={{ __html: description }} />
              <div className={styles.helpLink} onClick={() => setModalData({ description })}>
                ?
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Countries",
      dataIndex: "exclude_location",
      key: "exclude_location",
      render: (excludeLocation, { location }) => (
        <>
          <div>{location || 'All countries'}</div>
          {!!excludeLocation && (
            <div className="bold mt-10">
              There are country restrictions: {excludeLocation}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Total Candidates",
      dataIndex: "applicants",
      key: "applicants",
      render: (applicants) => (
        <>
          {applicants?.map((applicant) => (
            <div key={applicant.id} className={styles.candidate}>
              {applicant.country}, {applicant.position_level_name},
              ${applicant.monthly_rate || `${applicant.hourly_rate}/h`}
            </div>
          ))}
        </>
      ),
    },
    {
      title: "Action",
      key: "operation",
      render: (record) => {
        if (record.is_deleted) {
          return (
            <div className={cn("btn", styles.chatBtn, styles.greyChatBtn)}>
              Expired
            </div>
          );
        }

        let canChatToClient = true;

        if (record.exclude_location?.includes(companyCountry)) {
          canChatToClient = false;
        }

        return (
          <div
            className={cn(styles.applyButton, { [styles.chatBtnDisabled]: !canChatToClient }) }
            onClick={() => canChatToClient && applyHandler(record)}
          >
            Apply
          </div>
        );
      },
    },
  ];

  const renderDescriptionModal = () => {
    return modalData && (
      <Modal
        showModal={setModalData}
        noBg={true}
        renderComponent={<ProjectDescriptionModal {...modalData} />}
      />
    );
  };

  return (
    <div className={cn(styles.wrapper, styles.talents)}>
      <div className={styles.modal}>
        {renderDescriptionModal()}
      </div>

      <DashboardMenuContainer/>

      <h1 className={styles.pageTitle}>
        Open vacancies
      </h1>

      <div className={styles.totalVacancies}>
        <span>Total vacancies: {searches.length}</span>
        <span>New vacancies: {newCandidateSearches.length}</span>
      </div>

      <Table
        dataSource={searches}
        columns={columns}
        pagination={false}
        className={styles.tableWrapper}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.auth?.company_info?.id,
  companyCountry: state.auth?.company_info?.country,
  searches: state.candidates.searches,
});

const mapDispatchToProps = {
  getMarketplaceCandidateSearches,
  applyToVacancy,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthRedirect,
)(MarketplaceContainer);
