import React from "react";
import { Helmet } from "react-helmet";

import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import MarketplaceNavigation from "./MarketplaceNavigation";
import LeadsList from "../LeadsList/LeadsList";
import { siteNameForTitle } from "../../constants/global-constants";

import styles from "./Marketplace.module.css";

const MarketplaceLeads = (props) => {
  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Marketplace / Leads | {siteNameForTitle}</title>
      </Helmet>

      <DashboardMenuContainer/>

      <h1 className={styles.pageTitle}>Marketplace / Find Employee</h1>
      <MarketplaceNavigation {...props} />
      <LeadsList
        leads={props.marketplace.leads}
        deleteLead={props.deleteLead}
      />
    </div>
  );
};

export default MarketplaceLeads;
