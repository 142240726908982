import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { getCasesList, setCurrentCase, editCase } from "../../../store/cases";
import countries_list from "../../../utils/countries_list";
import CaseEditing from "./CaseEditing";

const CaseEditingContainer = (props) => {
    useEffect(() => {
        props.getCasesList();
    }, []);

    useEffect(() => {
        props.setCurrentCase(props.match.params.case_id);
    }, [props.cases.casesList, props.match.params.case_id]);

    return (
        <CaseEditing
            isFetching={props.cases.isFetching}
            countries_list={countries_list.map((item) => item.label)}
            markets_list={props.markets_list}
            skills_list={props.skills_list}
            currentCase={props.cases.currentCase}
            editCase={props.editCase}
            companyInfo={props.companyInfo}
        />
    );
};

let mapStateToProps = (state) => ({
    cases: state.cases,
    markets_list: state.app.markets_list,
    skills_list: state.app.skills_list,
    companyInfo: state.auth.company_info,
});

export default compose(
    connect(mapStateToProps, {
        getCasesList,
        setCurrentCase,
        editCase,
    }),
    withAuthRedirect
)(CaseEditingContainer);
