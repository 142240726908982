import cn from "classnames";

import styles from "./RadioButton.module.css";

const RadioButton = (props) => {
    const { input, meta, child, ...restProps } = props;

    return (
        <div className={cn(styles.wrapper, styles[props.designType], props.input_wrapper_class)}>
            <label className={cn("input_wrapper", styles.input_wrapper_radio)}>
                <input
                    {...input}
                    {...restProps}
                    className={cn("form_control", styles.form_control_radio, props.input_design, props.className)}
                    type="radio"
                />
                <span className={styles.radiomark}></span>
                <span className={styles.text}>{props.text}</span>
            </label>

            {props.help_text && (
                <div className={`${styles.input_help} ${styles.input_help_second} ${props.help_text_design}`}>
                    {props.help_text}
                </div>
            )}

            {meta.touched && meta.error && <div className="validation_message">{meta.error}</div>}
        </div>
    );
};

export default RadioButton;
