import { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withAuthRedirect } from "hoc/withAuthRedirect";
import { getOutsourceSearch, editSearch } from "store/outsource";
import { addProjectToMarketplace } from "store/marketplace";

import OutsourceEditing from "./OutsourceEditing";
import india_countries_list from "../../../utils/india_countries_list";

const OutsourceEditingContainer = (props) => {
    useEffect(() => {
        props.getOutsourceSearch(props.match.params.searchId);
    }, [props.match.params.searchId]);

    return (
        <OutsourceEditing
            isFetching={props.outsource.isFetching}
            currentSearch={props.outsource.currentSearch}
            markets_list={props.markets_list}
            editSearch={props.editSearch}
            addProjectToMarketplace={props.addProjectToMarketplace}
            countries_list={india_countries_list.map((item) => item.label)}
        />
    );
};

let mapStateToProps = (state) => ({
    outsource: state.outsource,
    markets_list: state.app.markets_list,
});

export default compose(
    connect(mapStateToProps, {
        getOutsourceSearch,
        editSearch,
        addProjectToMarketplace,
    }),
    withAuthRedirect
)(OutsourceEditingContainer);
