import React, { useEffect } from "react";
import { connect } from "react-redux";
import cn from "classnames";

import { history } from "../../../utils";
import { dataWasSaved } from "../../../utils/notifications";
import { updateSkills } from "../../../store/auth";
import { getJobTitlesList, getLanguageLevelsList, getProjectAreas } from "../../../store/app";
import Preloader from "../../common/Preloader/Preloader";
import ProfileMenu from "../ProfileMenu";
import CompanyMenu from "../CompanyMenu";
import SkillsForm from "./SkillsForm";
import FormIndicator from "../../registration/TeamSearch/FormIndicator/FormIndicator";

import styles from "./Skills.module.css";

const Skills = (props) => {
  const {
    getJobTitlesList,
    getLanguageLevelsList,
    getProjectAreas,
    auth,
    updateSkills,
  } = props;

  useEffect(() => {
    getJobTitlesList();
    getLanguageLevelsList();
    getProjectAreas();
  }, []);

  const onSubmit = (formData) => {
    if (
      !formData.skills?.length ||
      !formData.languages?.length ||
      !formData.project_areas?.length ||
      !formData.cv?.length
    ) {
      return;
    }

    const userData = {
      job_title_id: formData.job_title_id,
      position_level_id: formData.position_level_id,
      years_experience: formData.years_experience,
      rate_currency: formData.rate_currency,
      skills: formData.skills,
      languages: formData.languages,
      project_areas: formData.project_areas.map(area => area.id),
      cv: formData.cv[0],
    };

    if (formData.additional_job_title_id) {
      userData.additional_job_title_id = formData.additional_job_title_id;
    }

    if (auth.company_info.profile_fill_step < 2) {
      userData.profile_fill_step = 2;
    }

    if (formData.hourly_rate) {
      userData.hourly_rate = formData.hourly_rate;
    }

    if (formData.monthly_rate) {
      userData.monthly_rate = formData.monthly_rate;
    }

    updateSkills(userData, () => {
      if (formData.url_redirect) {
        return history.push(formData.url_redirect);
      }

      dataWasSaved();
    });
  };

  return (
    <div className={styles.wrapper}>
      {auth.is_fetching && <Preloader/>}

      <h2 className={cn(styles.title, "text_center")}>
        Developer profile
      </h2>

      <ProfileMenu />

      <CompanyMenu companyInfo={auth.company_info} />

      <div className={styles.subtitle}>Edit profile / Skills&Rates</div>

      {auth.company_info.profile_fill_step < 5 && <FormIndicator page={2} className={styles.indicator} />}

      <SkillsForm onSubmit={onSubmit} />
    </div>

  )
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  jobTitles: state.app.job_titles_list,
});

const mapDispatchToProps = {
  updateSkills,
  getJobTitlesList,
  getLanguageLevelsList,
  getProjectAreas,
};

export default connect(mapStateToProps, mapDispatchToProps)(Skills);
