import React, { useEffect, useState } from "react";
import { Modal, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import Preloader from "../../common/Preloader/Preloader";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import SearchEditingContainer from "../SearchEditing/SearchEditingContainer";
import SearchResultsItem from "../SearchResultsItem/SearchResultsItem";
import BookmarksFormContainer from "../BookmarksForm/BookmarksFormContainer";
import AdvCard from "../../common/AdvCard/AdvCard";

import styles from "./SearchResults.module.css";

const SearchResults = (props) => {
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [searchResultsUniqueCompanies, setSearchResultsUniqueCompanies] = useState(0);
  const [alternativeSearchResultsVerifiedCompanies, setAlternativeSearchResultsVerifiedCompanies] = useState(0);
  const [alternativeSearchResultsUnverifiedCompanies, setAlternativeSearchResultsUnverifiedCompanies] = useState(0);

  useEffect(() => {
    if (props.search.current_search_results) {
      let companiesIds = props.search.current_search_results.map((item) => item.company_id);
      setSearchResultsUniqueCompanies(Array.from(new Set(companiesIds)).length);
    }

    if (props.search.alternative_search_results?.verified) {
      const companiesIds = props.search.alternative_search_results.verified.map((item) => item.company_id);
      setAlternativeSearchResultsVerifiedCompanies(Array.from(new Set(companiesIds)).length);
    }

    if (props.search.alternative_search_results?.unverified) {
      const companiesIds = props.search.alternative_search_results.unverified.map((item) => item.company_id);
      setAlternativeSearchResultsUnverifiedCompanies(Array.from(new Set(companiesIds)).length);
    }

    if (
      !!props.search.is_fetching &&
      !!props.search.current_search_results &&
      props.search.current_search_results.length < 3 &&
      props.search.current_search?.for_marketplace === 0 &&
      props.search.edit_mode_disabled
    ) {
      setShowRequestModal(true);
    }
  }, [
    props.search.current_search?.for_marketplace,
    props.search.current_search_results,
    props.search.edit_mode_disabled,
    props.search.is_fetching,
  ]);

  const hideRequestAssistantMessage = () => {
    props.showAssistantRequestModal(null);
    props.showModal(false);
  };

  const hideBookmarksForm = () => {
    props.setResponseMessage(null);
    props.showModal(false);
  };

  const addSearchToMarketplace = () => {
    props.addDeveloperSearchToMarketplace({ search_id: props.search_id });
    setShowRequestModal(false);
  };

  let skillsList = [];

  if (props.search.current_search?.skills?.length) {
    skillsList = props.search.current_search.skills.map(skill => skill.skill_name);
  }

  const renderDeveloperCard = (developerData) => (
    <SearchResultsItem
      key={developerData.developer_id}
      search={developerData}
      company_id={props.search.current_search?.company_id}
      hire={props.hire}
      incrementCTR={props.incrementCTR}
      showModal={props.showModal}
      setAddingToBookmark={props.setAddingToBookmark}
      setCurrentDeveloperId={props.setCurrentDeveloperId}
      setCurrentDeveloperBookmarks={props.setCurrentDeveloperBookmarks}
    />
  );

  return (
    <div className={styles.wrapper}>
      {props.search && props.search.is_fetching && <Preloader/>}
      <Modal
        title="Amazing!"
        visible={props.search.show_assistant_request_modal}
        onOk={hideRequestAssistantMessage}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>We have received your request. We will contact you shortly.</p>
      </Modal>

      <Modal visible={props.bookmarks.isModal && props.bookmarks.isBookmarkAdding} footer={null}>
        <BookmarksFormContainer/>
      </Modal>

      <Modal
        title="Amazing!"
        visible={props.bookmarks.isModal && props.bookmarks.responseMessage}
        onOk={hideBookmarksForm}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>{props.bookmarks.responseMessage}</p>
      </Modal>

      <Tooltip title="Add search to the marketplace" placement="left">
        <PlusCircleOutlined className={styles.requestModalBtn} onClick={() => setShowRequestModal(true)}/>
      </Tooltip>

      <Modal
        title={"Request visibility!"}
        visible={showRequestModal}
        footer={null}
        onCancel={() => setShowRequestModal(false)}
      >
        <p className={styles.marketplaceText}>
          We suggest making your request visible to other companies. Perhaps they can help you. You will
          receive offers from them inside our chat.
        </p>
        <button className={styles.marketplaceBtn} onClick={addSearchToMarketplace}>
          Yes, share it!
        </button>
        <div className={styles.marketplaceBtnText} onClick={() => setShowRequestModal(false)}>
          no, thanks
        </div>
      </Modal>

      <DashboardMenuContainer/>

      <SearchEditingContainer setShowRequestModal={setShowRequestModal}/>

      <div className={styles.search_results_title}>Search result: {skillsList.join(', ')}</div>

      {props.search?.skillSponsor && <AdvCard skillSponsor={props.search.skillSponsor} />}

      {props.search?.skillSponsorDevelopers?.length > 0 && (
        <div className={styles.sponsorDevelopers}>
          {props.search.skillSponsorDevelopers.map(renderDeveloperCard)}
        </div>
      )}

      <div className={styles.search_results_wrapper}>
        {props.search?.current_search_results?.length > 0 && (
          <>
            <div className={styles.search_statistics}>
              <span className={styles.label}>Search result:</span>{" "}
              <span className={styles.result_counter}>{props.search.current_search_results.length}</span>{" "}
              developer
              {props.search.current_search_results.length > 1 && "s"} from
              <span className={styles.result_counter}> {searchResultsUniqueCompanies}</span>
              {searchResultsUniqueCompanies > 1 ? " companies" : " company"}
            </div>
            {props.search.current_search_results.map(renderDeveloperCard)}
          </>
        )}

        {props.search?.alternative_search_results?.verified?.length > 0 && (
          <>
            <div className={styles.search_statistics}>
              {props.search.alternative_search_results.verified.length} developer
              {props.search.alternative_search_results.verified.length > 1 && "s"} from{" "}
              {alternativeSearchResultsVerifiedCompanies} <span className={styles.label}>Verified</span>
              {alternativeSearchResultsVerifiedCompanies > 1 ? " companies" : " company"}
            </div>
            {props.search.alternative_search_results.verified.map(renderDeveloperCard)}
          </>
        )}

        {props.search?.alternative_search_results?.unverified?.length > 0 && (
          <>
            <div className={styles.search_statistics}>
              {props.search.alternative_search_results.unverified.length} developer
              {props.search.alternative_search_results.unverified.length > 1 && "s"} from{" "}
              {alternativeSearchResultsUnverifiedCompanies} <span className={styles.label}>Unverified</span>
              {alternativeSearchResultsUnverifiedCompanies > 1 ? " companies" : " company"}
            </div>
            {props.search.alternative_search_results.unverified.map((search) => (
              <SearchResultsItem
                key={search.developer_id}
                search={search}
                company_id={props.search.current_search?.company_id}
                search_id={props.search.current_search.search_id}
                hire={props.hire}
                incrementCTR={props.incrementCTR}
                showModal={props.showModal}
                setAddingToBookmark={props.setAddingToBookmark}
                setCurrentDeveloperId={props.setCurrentDeveloperId}
                setCurrentDeveloperBookmarks={props.setCurrentDeveloperBookmarks}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
