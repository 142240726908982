import React from "react";
import { NavLink } from "react-router-dom";

import { buildImageLink } from "../../../utils/utils";
import default_company_photo from "../../../assets/img/rowing.svg";
import verified_icon from "../../../assets/img/verified.png";
import styles from "./CompanyTop20Card.module.css";

const CompanyTop20Card = (props) => {
  const { company, onClick } = props;

  return (
    <div key={company.id} className={styles.companyTop20Card}>
      <div className={styles.leftColumn}>
        <NavLink
          to={`/company/${company.id}`}
          className={styles.logoLink}
          onClick={onClick}
        >
          <img
            className={styles.companyLogo}
            src={company.logo ? buildImageLink(company.logo) : default_company_photo}
            alt={company.company_name}
          />
        </NavLink>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.top20Header}>
          <div className={styles.top20InfoColumn}>
            <NavLink
              to={`/company/${company.id}`}
              className={styles.top20Link}
              onClick={onClick}
            >
              {company.company_name}
            </NavLink>
            {company.country}
          </div>
          {!!company.verified && <img src={verified_icon} className={styles.verified} alt="Verified" />}
        </div>
        <div className={styles.top20Description} dangerouslySetInnerHTML={{ __html: company.description }} />
      </div>
    </div>
  );
};

export default CompanyTop20Card;
