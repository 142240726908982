import { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { developersAPI } from "../../../api/developers";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import {
  showModal,
  switchEditMode,
  getCurrentSearch,
  getFreelancerSearches,
  editFreelancerSearch,
  deleteSearch
} from "../../../store/search";
import countries_list from "../../../utils/countries_list";
import FreelancerSearchEditing from "./FreelancerSearchEditing";

const FreelancerSearchEditingContainer = (props) => {
  const [jobTitlesList, setJobTitlesList] = useState(null);
  const [positionLevelsList, setPositionLevelsList] = useState(null);
  const [englishLevelsList, setEnglishLevelsList] = useState(null);

  useEffect(() => {
    developersAPI.getJobTitles().then((result) => setJobTitlesList(result));
    developersAPI.getPositionLevels().then((result) => setPositionLevelsList(result));
    developersAPI.getLanguageLevels().then((result) => setEnglishLevelsList(result));
  }, []);

  return (
    <FreelancerSearchEditing
      {...props}
      jobTitlesList={jobTitlesList}
      positionLevelsList={positionLevelsList}
      skills_list={props.skills_list}
      englishLevelsList={englishLevelsList}
      countries_list={countries_list.map((item) => item.label)}
      showModal={props.showModal}
      switchEditMode={props.switchEditMode}
      getSearches={props.getSearches}
      editSearch={props.editSearch}
      deleteSearch={props.deleteSearch}
    />
  );
};

let mapStateToProps = (state) => ({
  search: state.search,
  skills_list: state.app.skills_list,
});

export default compose(
  connect(mapStateToProps, {
    showModal,
    switchEditMode,
    getCurrentSearch,
    getFreelancerSearches,
    editSearch: editFreelancerSearch,
    deleteSearch,
  }),
  withAuthRedirect
)(FreelancerSearchEditingContainer);
