import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import {
  telegramSupport,
  linkedinSupport,
  facebookSupport,
  youtubeSupport,
  whatsappSupport,
  popularDeveloperTeams,
  trialSearchSkillsLinksArray,
} from "../../constants";
import ClientsStatsContainer from "./ClientsStats/ClientsStatsContainer";
import SocialLinks from "../common/SocialLinks/SocialLinks";

import logo from "../../assets/img/logo.png";
import styles from "./Footer.module.css";

const Footer = (props) => {
  const {
    auth,
  } = props;

  if (auth.is_auth) {
    return (
      <footer className={styles.auth}>
        <div className={styles.pageFooter}>
          <div className={styles.info}>
            <div className={styles.sitename}>iBench</div>
            <div className={styles.copyright}>
              Copyright © 2019-{new Date().getFullYear()}, iBench. All rights reserved.
              <br/>
              Email: <a href="mailto:hello@ibench.in">hello@ibench.in</a>
            </div>
          </div>

          <div className={styles.supportLinkWrapper}>
            <a
              href="mailto:hello@ibench.in"
              className={styles.support}
            >
              Support
            </a>
          </div>
          
          <ClientsStatsContainer/>
        </div>
      </footer>
    );
  }

  return (
    <footer className={styles.no_auth}>
      <div className={cn(styles.pageFooter, "mobile_hidden")}>
        <div className={styles.leftColumn}>
          <img className={styles.logo} src={logo} alt="iBench"/>
          <div className={styles.leftPadding}>
            <nav className={styles.menuWrapper}>
              <ul className={styles.menu}>
                <li>
                  <NavLink to="/team-search">Hire developer team</NavLink>
                </li>
                <li>
                  <NavLink to="/development-teams">Development teams</NavLink>
                </li>
                <li>
                  <NavLink to="/teams">Popular teams</NavLink>
                </li>
                <li>
                  <NavLink to="/verifiedteams">Verified teams</NavLink>
                </li>
                <li>
                  <NavLink to="/trial-search/react.js">Developers search</NavLink>
                </li>
                <li>
                  <NavLink to="/about">About</NavLink>
                </li>
                <li>
                  {/* It's link for WP (with reload of page) */}
                  <a href="/blog" rel="nofollow">Blog</a>
                </li>
              </ul>
            </nav>
            <SocialLinks
              className={styles.socialButtons}
              telegram={telegramSupport}
              linkedin={linkedinSupport}
              facebook={facebookSupport}
              whatsapp={whatsappSupport}
              youtube={youtubeSupport}
            />
            <div className={cn(styles.copyright, styles.desktopCopyright)}>
              <div>Copyright © 2019-{new Date().getFullYear()}, iBench. All rights reserved.</div>
              <div>Email: <a href="mailto:info@ibench.net">info@ibench.net</a></div>
            </div>
            <ul className={cn(styles.menu, styles.smallMenu)}>
              <li>
                <NavLink to="/privacy-policy">Privacy</NavLink>
              </li>
              <li>
                <NavLink to="/cookie-policy">Cookie</NavLink>
              </li>
              <li>
                <NavLink to="/terms-of-use">Terms</NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.popularDeveloperTeams}>
            <h3 className={styles.verifiedCompaniesTitle}>
              Popular developer teams
            </h3>
            <ul className={styles.popularDeveloperList}>
              {popularDeveloperTeams.map(linkData => (
                <li key={linkData.link}>
                  <NavLink to={linkData.link}>{linkData.label}</NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.popularTechnologies}>
            <h3 className={styles.verifiedCompaniesTitle}>
              Popular technologies
            </h3>
            <ul className={styles.popularTechnologiesList}>
              {trialSearchSkillsLinksArray.map(linkData => (
                <li key={linkData.link}>
                  <NavLink to={linkData.link}>{linkData.label}</NavLink>
                </li>
              ))}
            </ul>
            <ClientsStatsContainer className={styles.stat}/>
          </div>
        </div>
      </div>
      <div className={cn(styles.mobileFooter, "mobile_visible")}>
        <img className={styles.logo} src={logo} alt="iBench"/>

        <nav className={styles.topMenuWrapper}>
          <ul className={styles.menu}>
            <li>
              <NavLink to="/team-search">Hire developer team</NavLink>
            </li>
            <li>
              <NavLink to="/trial-search/react.js">Developers search</NavLink>
            </li>
            <li>
              <NavLink to="/development-teams">Development teams</NavLink>
            </li>
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
            <li>
              <NavLink to="/teams">Popular teams</NavLink>
            </li>
            <li>
              {/* It's link for WP (with reload of page) */}
              <a href="/blog" rel="nofollow">Blog</a>
            </li>
          </ul>
        </nav>

        <h3 className={styles.popularDeveloperTeams}>
          Popular developer teams
        </h3>

        <ul className={styles.popularDeveloperList}>
          {popularDeveloperTeams.map(linkData => (
            <li key={linkData.link}>
              <NavLink to={linkData.link}>{linkData.label}</NavLink>
            </li>
          ))}
        </ul>

        <SocialLinks
          className={styles.socialButtons}
          telegram={telegramSupport}
          linkedin={linkedinSupport}
          facebook={facebookSupport}
          whatsapp={whatsappSupport}
          youtube={youtubeSupport}
        />

        <div className={styles.copyright}>
          Copyright © 2019-{new Date().getFullYear()}, iBench. All rights reserved.
          <br/>
          Email: <a href="mailto:hello@ibench.in">hello@ibench.in</a>
        </div>

        <nav className={styles.menuWrapper}>
          <ul className={styles.menu}>
            <li>
              <NavLink to="/privacy-policy">Privacy</NavLink>
            </li>
            <li>
              <NavLink to="/cookie-policy">Cookie</NavLink>
            </li>
            <li>
              <NavLink to="/terms-of-use">Terms</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

let mapStateToProps = (state) => ({
  auth: state.auth,
});


export default connect(mapStateToProps)(Footer);
