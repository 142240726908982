import { useEffect } from "react";
import { connect } from "react-redux";

import { getProjectById } from "../../../store/cases";
import ProjectPage from "./ProjectPage";

const ProjectPageContainer = (props) => {
    useEffect(() => {
        props.getProjectById(props.match.params.projectId);
    }, []);

    return <ProjectPage isFetching={props.isFetching} currentProject={props.currentProject} />;
};

let mapStateToProps = (state) => {
    return {
        isFetching: state.cases.isFetching,
        currentProject: state.cases.currentProject,
    };
};

export default connect(mapStateToProps, { getProjectById })(ProjectPageContainer);
