import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import SearchResults from "./SearchResults";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import {
  deleteSearch,
  editSearch,
  getCurrentSearch,
  getSearchResults,
  switchEditMode,
  requestAssistant,
  showAssistantRequestModal,
  setSelectedTrialDeveloper,
} from "../../../store/search";
import {
  showModal,
  setResponseMessage,
  getBookmarksList,
  setAddingToBookmark,
  setCurrentDeveloperId,
  setCurrentDeveloperBookmarks,
} from "../../../store/bookmarks";
import { hire } from "../../../store/chat";
import { incrementCTR } from "../../../store/developer";
import { addDeveloperSearchToMarketplace } from "../../../store/marketplace";

let SearchResultsContainer = (props) => {
  useEffect(() => {
    props.getBookmarksList();
    props.getCurrentSearch(props.match.params.search_id);
    props.getSearchResults(props.match.params.search_id);
  }, [props.match.params.search_id]);

  return (
    <SearchResults
      companyInfo={props.companyInfo}
      search={props.search}
      bookmarks={props.bookmarks}
      search_id={props.match.params.search_id}
      switchEditMode={props.switchEditMode}
      editSearch={props.editSearch}
      deleteSearch={props.deleteSearch}
      requestAssistant={props.requestAssistant}
      hire={props.hire}
      incrementCTR={props.incrementCTR}
      showModal={props.showModal}
      showAssistantRequestModal={props.showAssistantRequestModal}
      setResponseMessage={props.setResponseMessage}
      setAddingToBookmark={props.setAddingToBookmark}
      setCurrentDeveloperId={props.setCurrentDeveloperId}
      setCurrentDeveloperBookmarks={props.setCurrentDeveloperBookmarks}
      addDeveloperSearchToMarketplace={props.addDeveloperSearchToMarketplace}
    />
  );
};

let mapStateToProps = (state) => ({
  search: state.search,
  bookmarks: state.bookmarks,
  companyInfo: state.auth?.company_info,
});

SearchResultsContainer = withRouter(SearchResultsContainer);

export default compose(
  connect(mapStateToProps, {
    switchEditMode,
    getSearchResults,
    getCurrentSearch,
    editSearch,
    deleteSearch,
    requestAssistant,
    hire,
    showModal,
    showAssistantRequestModal,
    setResponseMessage,
    getBookmarksList,
    setAddingToBookmark,
    setCurrentDeveloperId,
    setCurrentDeveloperBookmarks,
    incrementCTR,
    addDeveloperSearchToMarketplace,
    setSelectedTrialDeveloper,
  }),
  withAuthRedirect
)(SearchResultsContainer);
