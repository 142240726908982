import { notification } from 'antd';

import {
  marketplaceDevelopersAPI,
  marketplaceProjectsAPI,
  marketplaceLeadsAPI,
} from '../api/marketplace';

const IS_FETCHING = 'ibench/marketplace/IS_FETCHING';
const SET_DEVELOPERS = 'ibench/marketplace/SET_DEVELOPERS';
const SET_FREELANCERS = 'ibench/marketplace/SET_FREELANCERS';
const SET_PROJECTS = 'ibench/marketplace/SET_PROJECTS';
const SET_LEADS = 'ibench/marketplace/SET_LEADS';

let initialState = {
  isFetching: false,
  developers: [],
  freelancers: [],
  projects: [],
  leads: [],
};

const marketplace = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, isFetching: action.payload };

    case SET_DEVELOPERS:
      return { ...state, developers: action.payload };

    case SET_FREELANCERS:
      return { ...state, freelancers: action.payload };

    case SET_PROJECTS:
      return { ...state, projects: action.payload };

    case SET_LEADS:
      return { ...state, leads: action.payload };

    default:
      return state;
  }
};

const isFetching = (payload) => ({ type: IS_FETCHING, payload });
const setDevelopers = (payload) => ({ type: SET_DEVELOPERS, payload });
const setFreelancers = (payload) => ({ type: SET_FREELANCERS, payload });
const setProjects = (payload) => ({ type: SET_PROJECTS, payload });
const setLeads = (payload) => ({ type: SET_LEADS, payload });

export const getMarketplaceDevelopers = () => {
  return (dispatch) => {
    marketplaceDevelopersAPI.getDevelopers().then((data) => {
      const developers = data.marketData.sort((current, previous) => {
        if (previous.is_deleted !== current.is_deleted) {
          return current.is_deleted - previous.is_deleted;
        }

        return current.created > previous.created ? -1 : 1;
      });
      dispatch(setDevelopers(developers));
    });
  };
};

export const getMarketplaceFreelancers = () => {
  return (dispatch) => {
    marketplaceDevelopersAPI.getDevelopers(1).then((data) => {
      const freelancers = data.marketData.sort((current, previous) => {
        if (previous.is_deleted !== current.is_deleted) {
          return current.is_deleted - previous.is_deleted;
        }

        return current.created > previous.created ? -1 : 1;
      });
      dispatch(setFreelancers(freelancers));
    });
  };
};

export const getMarketplaceProjects = () => {
  return (dispatch) => {
    marketplaceProjectsAPI.getProjects().then((data) => {
      dispatch(setProjects(data.marketData));
    });
  };
};

export const getMarketplaceLeads = () => {
  return (dispatch) => {
    marketplaceLeadsAPI.getLeads().then((data) => {
      dispatch(setLeads(data.data));
    });
  };
};

export const deleteLead = (data, callback) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    return marketplaceLeadsAPI.deleteLead(data).then(() => {
      dispatch(isFetching(false));
      notification.success({
        message: 'Lead has been deleted',
        placement: 'bottomRight',
        duration: 5,
      });
      dispatch(getMarketplaceLeads());
      callback && callback();
    });
  };
};

export const addDeveloperSearchToMarketplace = (data) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    marketplaceDevelopersAPI.addDeveloper(data).then(() => {
      dispatch(isFetching(false));
      notification.success({
        message: 'Developers search has been added to the marketplace',
        placement: 'bottomRight',
        duration: 5,
      });
    });
  };
};

export const deleteDeveloperSearchFromMarketplace = (data) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    return marketplaceDevelopersAPI.deleteDeveloper(data).then(() => {
      dispatch(isFetching(false));
      notification.success({
        message: 'Developers search has been deleted from the marketplace',
        placement: 'bottomRight',
        duration: 5,
      });
    });
  };
};

export const addProjectToMarketplace = (data) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    marketplaceProjectsAPI.addProject(data).then(() => {
      dispatch(isFetching(false));
      notification.success({
        message: 'Project search has been added to the marketplace',
        placement: 'bottomRight',
        duration: 5,
      });
    });
  };
};

export const deleteProjectFromMarketplace = (data) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    return marketplaceProjectsAPI.deleteProject(data).then(() => {
      dispatch(isFetching(false));
      notification.success({
        message: 'Project search has been deleted from the marketplace',
        placement: 'bottomRight',
        duration: 5,
      });
    });
  };
};

export default marketplace;
