import React from "react";

import cashImage from "../../../assets/img/cash.png";
import styles from "./WelcomeModal.module.css";

const WelcomeModal = () => {
  return (
    <div className={styles.welcomeModal}>
      <div className={styles.modalHeader}>
        Welcome to iBench
      </div>
      <div className={styles.modalBody}>
        <div className={styles.bodyTop}>
          <div className={styles.messageBlock}>
            {/*<p>*/}
              {/*Welcome congrats 5 free pins have been added to your wallet! You can spend that to Chat with clients.*/}
            {/*</p>*/}
            <div className={styles.chatWrapper}>
              <div className={styles.buttonBlock}>
                <div className={styles.chatBtn}>
                  Chat with client
                </div>
              </div>
              <div>
                <p className={styles.whenYouClick}>
                  When you click on this button, connections are removed from your wallet.
                </p>
                <div className={styles.wallet} >
                  Wallet: 5 pins
                </div>
              </div>
            </div>
          </div>
          <div className={styles.imageBlock}>
            <img
              className={styles.image}
              src={cashImage}
              alt="Subscription is inactive"
            />
          </div>
        </div>
        <div className={styles.bodyBottom}>
          <div className={styles.bottomLeft}>
            <p>
              Outsourcing projects = 2 pins<br />
              Outstaffing projects = 2 pins
            </p>
          </div>
          {/*<div className={styles.bottomRight}>*/}
            {/*<p>*/}
              {/*If the request was from a verified client,<br />*/}
              {/*the system will remove 2 times more pins.*/}
            {/*</p>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
