import React from "react";
import { Multiselect, DropdownList } from "react-widgets";
import cn from "classnames";

import styles from "./FormControls.module.css";
import "react-widgets/dist/css/react-widgets.css";
import { Switch } from "antd";

/*
 * CUSTOM INPUT PARAMETERS
 *
 * label
 * label_type - label_above
 * input_design - input_grey,
 * help_text
 * help_text_design - black
 *
 */

const FormControl = ({ label, input, meta, children, help_text, ...props }) => {
  const labelUp = (e) => {
    e.target.parentNode.querySelector(".form_control").focus();
  };

  return (
    <div className={`form_group ${props.label_type} ${props.input_wrapper_class}`}>
      <div className={`input_wrapper`}>
        {label && (
          <label
            className={`label ${
              meta.active || meta.dirty || meta.initial || meta.initial === 0 ? "active" : ""
            }`}
            onClick={labelUp}
          >
            {label}
          </label>
        )}

        {children}
      </div>

      {help_text && <div className={`${styles.input_help} ${props.help_text_design}`}>{help_text}</div>}

      {meta.touched && meta.error && <div className="validation_message">{meta.error}</div>}
      {props.withErrorSign && meta.touched && meta.error && <span className="validation_status_error"/>}
      {meta.visited && meta.valid && <span className="validation_status_ok"/>}
    </div>
  );
};

export const Input = (props) => {
  const { input, meta, ...restProps } = props;

  const validationClass = meta.touched && meta.error ? "validation_error" : "validation_ok";

  return (
    <FormControl {...props}>
      <input
        {...input}
        {...restProps}
        className={`form_control ${props.input_design} ${props.className} ${validationClass}`}
      />
    </FormControl>
  );
};

export const SelectInput = (props) => {
  const { input, meta, placeholder, withoutEmpty, ...restProps } = props;

  return (
    <FormControl {...props}>
      <select
        {...input}
        {...restProps}
        className={`form_control ${props.input_design} ${
          meta.touched && meta.error ? "validation_error" : "validation_ok"
          }`}
      >
        {placeholder && <option value="" disabled selected hidden>{placeholder}</option>}
        {!withoutEmpty && <option />}
        {props.options && props.options.map((item, index) => (
          <option value={item} key={index}>
            {item}
          </option>
        ))}
      </select>
    </FormControl>
  );
};

export const SelectInputIdName = (props) => {
  const { input, meta, placeholder, withoutEmpty, ...restProps } = props;

  return (
    <FormControl {...props}>
      <select
        {...input}
        {...restProps}
        className={`form_control ${props.input_design} ${
          meta.touched && meta.error ? "validation_error" : "validation_ok"
          }`}
      >
        {placeholder && <option value="" disabled selected hidden>{placeholder}</option>}
        {!withoutEmpty && <option />}
        {props.options && props.options.map((item, index) => (
          <option value={item.id} key={index}>
            {item.name}
          </option>
        ))}
      </select>
    </FormControl>
  );
};

export const SelectInputKeyValue = (props) => {
  const { input, meta, withoutBlankValue, ...restProps } = props;

  const validationClass = meta.touched && meta.error ? "validation_error" : "validation_ok";

  return (
    <FormControl {...props}>
      <select
        {...input}
        {...restProps}
        className={`form_control ${props.input_design} ${validationClass}`}
      >
        {!withoutBlankValue && <option/>}
        {props.options && props.options.map((item) => (
          <option value={item[Object.keys(item)[0]]} key={item[Object.keys(item)[0]]}>
            {item[Object.keys(item)[1]]}
          </option>
        ))}
      </select>
    </FormControl>
  );
};

export const SelectInputEnglish = (props) => {
  const { input, meta, ...restProps } = props;

  return (
    <FormControl {...props}>
      <select
        {...input}
        {...restProps}
        className={`form_control ${props.input_design} ${
          meta.touched && meta.error ? "validation_error" : "validation_ok"
          }`}
      >
        <option/>
        {props.options &&
        props.options.map((level) => (
          <option value={level.english_level_id} key={level.english_level_id}>
            {level.cerf_level} ({level.english_level_name})
          </option>
        ))}
      </select>
    </FormControl>
  );
};

export const SelectInputSkill = (props) => {
  const { input, meta, ...restProps } = props;

  return (
    <FormControl {...props}>
      <select
        {...input}
        {...restProps}
        className={`form_control ${props.input_design} ${
          meta.touched && meta.error ? "validation_error" : "validation_ok"
          }`}
      >
        <option/>
        {props.skills_list &&
        props.skills_list.map((item) => (
          <option value={item.skill_id} key={item.skill_id}>
            {item.skill_name}
          </option>
        ))}
      </select>
    </FormControl>
  );
};

export const Textarea = (props) => {
  const { input, meta, showSymbolsNumber, ...restProps } = props;

  const withError = meta.touched && meta.error;

  return (
    <FormControl {...props}>
      <textarea
        {...input}
        {...restProps}
        className={cn(
          props.className || 'form_control',
          props.input_design,
          withError ? "validation_error" : "validation_ok",
        )}
      />
      {showSymbolsNumber && (
        <span className={cn(styles.symbols_number, { [styles.absolute]: withError })}>
          {input.value.length} symbols
        </span>
      )}
    </FormControl>
  );
};

export const Checkbox = (props) => {
  const { input, meta, ...restProps } = props;

  return (
    <div className={`form_group ${styles.checkbox_form_group} ${props.label_type} ${props.input_wrapper_class}`}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={cn("input_wrapper", styles.input_wrapper_checkbox, props.className, { active: meta.active || meta.dirty || meta.initial })}
        >
          <input
            {...input}
            {...restProps}
            type="checkbox"
            className={`form_control ${styles.form_control_checkbox} ${props.input_design} ${props.className}`}
          />
          <span className={styles.checkmark}></span>
          {props.label}
        </label>
      )}

      {props.help_text && (
        <div className={`${styles.input_help} ${props.help_text_design}`}>{props.help_text}</div>
      )}

      {meta.touched && meta.error && <div className="validation_message">{meta.error}</div>}
    </div>
  );
};

export const renderMultiselect = ({ input, label, data, valueField, textField, placeholder, disabled }) => {
  return (
    <div className={styles.multiselectWrapper}>
      {label && <label className={styles.label}>{label}</label>}
      <Multiselect
        {...input}
        onBlur={() => input.onBlur()}
        value={input.value || []}
        data={data}
        valueField={valueField}
        textField={textField}
        filter="contains"
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

export const renderDropdownList = ({ input, data, valueField, textField, placeholder }) => {
  return (
    <DropdownList
      {...input}
      onBlur={() => input.onBlur()}
      value={input.value || []}
      data={data}
      valueField={valueField}
      textField={textField}
      filter="contains"
      placeholder={placeholder}
    />
  );
};

export const renderRadioButton = ({ label, ...props }) => {
  const { input, ...restProps } = props;
  return (
    <FormControl {...props}>
      <label>
        <input
          {...input}
          type="radio"
          {...restProps}
        />
        <span>{label}</span>
      </label>
    </FormControl>
  )
};

export const DropdownIdName = ({ label, value, onChange, options, className }) => {
  const onChangeHandler = (event) => {
    onChange(+event.target.value);
  };

  return (
    <div className={cn("dropdown_id_name_wrapper", className)}>
      {label && <label>{label}</label>}
      <select
        value={value}
        onChange={onChangeHandler}
      >
        {options && options.map((item) => (
          <option value={item.id} key={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export const CheckboxSwitch = (props) => {
  const { input, defaultChecked, label, labelClassName, ...restProps } = props;

  return (
    <div className={styles.switch_wrapper}>
      <div>
        <div className={styles.switch_label}>
          <span
            className={cn(styles.switch_label_text, labelClassName)}
            dangerouslySetInnerHTML={{ __html: label}}
          />
          <input
            {...input}
            {...restProps}
            type="checkbox"
            className={`form_control ${styles.form_control_checkbox} ${props.input_design} ${props.className}`}
          />
          <Switch
            {...input}
            checkedChildren="On"
            unCheckedChildren="Off"
            defaultChecked={defaultChecked}
          />
        </div>
      </div>
    </div>
  );
};

export const RadioButton = (props) => {
  const { input, meta, ...restProps } = props;

  return (
    <div className={styles.radioButtonWrapper}>
      <label>
        <input
          {...input}
          {...restProps}
          type="radio"
          checked={input.checked}
          className={styles.invisible}
        />
        <span className={cn(styles.radioButton, { checked: input.checked })}>
          {props.text}
        </span>
      </label>
      {meta.touched && meta.error && <div className="validation_message">{meta.error}</div>}
    </div>
  );
};