import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import {
  addBookmark,
  getBookmarksList,
  showModal,
  setBookmarkFormStatus,
  setResponseMessage,
} from "../../../store/bookmarks";
import BookmarksList from "./BookmarksList";

const BookmarksListContainer = (props) => {
  useEffect(() => {
    props.getBookmarksList();
  }, []);

  return <BookmarksList {...props} />;
};

let mapStateToProps = (state) => {
  return {
    isFetching: state.bookmarks.isFetching,
    isModal: state.bookmarks.isModal,
    bookmarksList: state.bookmarks.bookmarksList,
    bookmarkFormStatus: state.bookmarks.bookmarkFormStatus,
    responseMessage: state.bookmarks.responseMessage,
  };
};

export default compose(
  connect(mapStateToProps, {
    getBookmarksList,
    addBookmark,
    showModal,
    setBookmarkFormStatus,
    setResponseMessage,
  }),
  withAuthRedirect
)(BookmarksListContainer);
