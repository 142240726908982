import qs from "qs";
import { axiosInstances } from "./api";

const axiosInstance = axiosInstances.account;

export const stripeAPI = {
  paymentSuccess(data) {
    return axiosInstance.post('api/users/stripe/points/success', qs.stringify(data)).then((response) => {
      return response.data;
    });
  },
  subscriptionSuccess(data) {
    return axiosInstance.post('api/users/stripe/subscription/success', qs.stringify(data)).then((response) => {
      return response.data;
    });
  },
};
