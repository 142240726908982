import React, { useState } from "react";
import cn from "classnames";

import PaymentButton from "../PaymentButton/PaymentButton";

import cashImage from "../../../assets/img/cash.png";
import cashFBInImage from "../../../assets/img/cash-fb-in.png";
import styles from "./WalletModal.module.css";

const WalletModal = ({ closeModal }) => {
  const [showShareModal, setShowShareModal] = useState(false);

  if (showShareModal) {
    return (
      <div className={styles.shareModal}>
        <div className={cn(styles.modalHeader, styles.blue)}>
          Get +5 free pins
        </div>
        <div className={styles.modalShareBody}>
          <div className={styles.shareDetails}>
            <div className={styles.shareDescription}>
              <p>
                Hey! We are glad that you want to get free pins.&nbsp;
                <strong>We give this opportunity once a week.</strong>
              </p>
              <p>
                <strong>To get free connections, you need:</strong>
              </p>
              <p>
                A) <strong>send a screenshot</strong> from your Facebook or LinkedIn, where you can see that you shared
                the message
              </p>
              <p>
                B) the message should be available to everyone, not just friends
              </p>
              <p>
                C) link to your Facebook or LinkedIn
              </p>
              <p>
                within 3 days we will check it, and if everything is ok, we will charge you pins
              </p>
              <p>
                <strong>Our email:</strong> <a href="mailto:hello@ibench.in" target="_blank">hello@ibench.in</a>
              </p>
            </div>
            <div className={styles.shareImageBlock}>
              <img className={styles.shareImage} src={cashFBInImage} alt="Cash"/>
            </div>
          </div>
          <button className={styles.gotItButton} onClick={closeModal}>
            Got it!
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.walletModal}>
      <div className={styles.modalHeader}>
        Wallet
      </div>
      <div className={styles.modalBody}>
        <div className={styles.messageBlock}>
          <p>
            You would like to add new pins? It's the right way. New pins are potential deals.
          </p>
        </div>
        <div className={styles.sharingBlock}>
          <img
            className={styles.image}
            src={cashImage}
            alt="Subscription is inactive"
          />
          <div className={styles.sharingMessage}>
            <p>
              Tell about us to your friends on Facebook or LinkedIn, and take 5 pins for free. Click to Share
              button.
            </p>
            <div className={styles.shareButtons}>
              <a
                className={styles.facebookButton}
                onClick={() => setShowShareModal(true)}
                href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fibench.in%2F"
                target="_blank"
                rel="nofollow"
              >
                Share
              </a>
              <a
                className={styles.linkedInButton}
                onClick={() => setShowShareModal(true)}
                href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fibench.in%2F"
                target="_blank"
                rel="nofollow"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.priceWrapper}>
        <PaymentButton />
      </div>
    </div>
  );
};

export default WalletModal;
