import React from "react";
import { connect } from "react-redux";

import ClientsStats from "./ClientsStats";

const ClientsStatsContainer = (props) => {
  return <ClientsStats stats={props.stats} className={props.className} />;
};

let mapStateToProps = (state) => ({
  stats: state.app.stats,
});

export default connect(mapStateToProps, {})(ClientsStatsContainer);
