import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import { Carousel } from "antd";
import cn from "classnames";

import { siteNameForTitle, defaultDescription } from "../../../constants";
import { setRegistrationType } from "../../../utils/utils";
import SearchResultsItem from "../../Startup/SearchResultsItem/SearchResultsItem";
import AdvCard from "../AdvCard/AdvCard";
import CompanyTop10Card from "../CompanyTop10Card/CompanyTop10Card";
import { DropdownIdName, renderMultiselect } from "../FormsControls/FormControls";
import Preloader from "../Preloader/Preloader";
import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs";

import styles from "./TrialSearch.module.css";

const SearchForm = (props) => {
  const addToSearch = (skill) => {
    let skills = props.skills ? [...props.skills] : [];
    // check if skill wasn't added, then add it
    let skillIndex = skills.findIndex((item) => item.skill_id === skill.skill_id);
    if (skillIndex === -1) {
      skills.push(skill);
      props.dispatch(change("trial-search", "skills", skills));
    }
  };

  useEffect(() => {
    if (props.default_skill && props.skills_list.length) {
      const skill = props.skills_list.find(skill => {
        return skill.skill_name.toLowerCase() === props.default_skill.toLowerCase();
      });

      if (skill) {
        addToSearch(skill);
        setTimeout(props.submit);
      }
    }

  }, [props.default_skill, props.skills_list]);

  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <Field
          name="skills"
          component={renderMultiselect}
          validate={[required()]}
          data={props.skills_list}
          valueField="skill_id"
          textField="skill_name"
          placeholder="Enter the technologies to find the best matching developers"
        />
      </div>

      <div className={styles.submit_wrapper}>
        <button className={`btn_2 ${styles.submit}`} disabled={!props.skills?.length}>
          Search
        </button>
      </div>

      {props.error && <div className={`${styles.form_erros} text_center validation_message`}>{props.error}</div>}

      <div className={styles.popular_searches}>
        <div className={styles.popular_searches_label}>Popular searches:</div>
        <div className={styles.popular_searches_list}>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 104, skill_name: "React.js" })}
          >
            React.js
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 249, skill_name: "Angular" })}
          >
            Angular
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 163, skill_name: "Node.js" })}
          >
            Node.js
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 204, skill_name: "Flutter" })}
          >
            Flutter
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 48, skill_name: "Golang" })}
          >
            Golang
          </div>
        </div>
      </div>
    </form>
  );
};

let SearchReduxForm = reduxForm({ form: "trial-search" })(SearchForm);

const formSelector = formValueSelector("trial-search");

SearchReduxForm = connect((state) => {
  const skills = formSelector(state, "skills");
  return { skills };
})(SearchReduxForm);

const TrialSearch = (props) => {
  const [searchedSkills, setSearchedSkills] = useState(null);

  const skillOptions = useMemo(() => {
    return props.skills_list?.map(skill => ({
      id: skill.skill_id,
      name: skill.skill_name,
    }));
  }, [props.skills_list]);

  const search_results_unique_companies = useMemo(() => {

    if (props.trial_search) {
      const all_ids = props.trial_search.map((item) => {
        return item.company_id;
      });

      return Array.from(new Set(all_ids)).length;
    }
  }, [props.trial_search]);

  const skillsList = useMemo(() => {
    if (searchedSkills?.length) {
      const skillsArray = searchedSkills.map(skill => skill.skill_name);
      return skillsArray.join(', ');
    }
    return '';
  }, [searchedSkills]);

  const onSubmit = (formData) => {
    const searchSkills = formData.skills.map(skill => skill.skill_id);

    setSearchedSkills(formData.skills);
    props.trialSearch(searchSkills, props.company_id);
  };

  const changeSkillHandler = (skillId) => {
    const skillData = props.skills_list.find(skill => skill.skill_id === skillId);
    const skills = [skillData];
    onSubmit({ skills });
  };

  return (
    <>
      <Helmet>
        <title>{siteNameForTitle} / Remote programmers from trusted European IT companies</title>
        <meta
          name="description"
          content={defaultDescription}
        />
        <meta
          name="keywords"
          content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
          data-react-helmet="true"
        />
      </Helmet>

      <Breadcrumbs className={styles.breadcrumbs} currentPage={skillsList || "Trial search"}/>
      <div className={styles.wrapper}>
        {props.is_fetching && <Preloader/>}

        <div className="mobile_hidden">
          {!props.company_id && (
            <>
              <h1 className={styles.title}>
                Remote <span className="purple">{skillsList}</span> programmers from trusted
                <span className="purple"> European</span> IT companies
              </h1>
              <div className={styles.subtitle}>
                We do not have freelancers. Only programmers working in IT companies.
              </div>
              <ul className={styles.advantages}>
                <li className={styles.advantagesItem}>Communication without intermediaries</li>
                <li className={styles.advantagesItem}>Assign a programmer to your project in 2-3 days</li>
                <li className={styles.advantagesItem}>You don't need to pay for our service</li>
              </ul>
            </>
          )}
          <SearchReduxForm
            default_skill={props?.match?.params?.skill}
            skills_list={props.skills_list}
            onSubmit={onSubmit}
          />

          {!props.is_auth && (
            <div className={styles.advancedSearchTip}>
              <Link
                className={styles.advancedSearchTipLink}
                to="/registration"
                onClick={() => setRegistrationType(1)}
              >
                Sign up
              </Link>{" "}
              for Free if you need an advanced search
            </div>
          )}

          {props.demo && props.app.stats && (
            <>
              <div className={styles.stats}>
                {props.app.stats.developersCount}+ Remote Developers, {props.app.stats.companiesCount}+
                Сompanies
              </div>
              <div className={styles.demoResultsTitle}>
                Our <span className={styles.demoResultsTitleBlue}>Developers</span>
              </div>
              <div className={styles.demoResultsSubtitle}>from Ukraine, Germany, Poland, Estonia, Israel…</div>
            </>
          )}

          {skillsList && (
            <div className={styles.trial_search_title}>
              Search result{" "}
              <span className={styles.trial_search_title_skills}>
                {skillsList}
              </span>
            </div>
          )}

          {!props.company_id && searchedSkills && props.skillSponsor && <AdvCard skillSponsor={props.skillSponsor}  />}
        </div>

        <div className="mobile_visible">
          <div className={styles.mobileTitle}>
            Search developers without registration
          </div>
          <div className={styles.mobileInfo}>
            All developers presented on the platform work in companies and are provided according to the outstaffing model
            for projects.
          </div>
          <div className={styles.dearClient}>
            Dear client! For a full search, use the desktop iBench version,
            and <Link to="/registration">register as a client</Link>.
          </div>
          <DropdownIdName
            className={styles.select}
            options={skillOptions}
            label="Select technology"
            value={searchedSkills?.[0]?.skill_id}
            onChange={changeSkillHandler}
          />
          {!props.company_id && searchedSkills && props.skillSponsor && (
            <div className={styles.advMobileCompany}>
              <CompanyTop10Card company={{ ...props.skillSponsor, id: props.skillSponsor?.company_id }} />
            </div>
          )}

          {skillsList && (
            <div className={styles.trial_search_title}>
              Search result{" "}
              <span className={styles.trial_search_title_skills}>
                {skillsList}
              </span>
            </div>
          )}
        </div>

        {props.skillSponsorDevelopers?.length > 0 && (
          <div className={styles.sponsorDevelopers}>
            {props.skillSponsorDevelopers?.map((search) => (
              <SearchResultsItem
                key={search.developer_id}
                is_auth={props.is_auth}
                search={search}
                setSelectedTrialDeveloper={props.setSelectedTrialDeveloper}
              />
            ))}
          </div>
        )}

        {props.trial_search?.length > 0 && (
          <>
            <div className={cn(styles.search_statistics, "mobile_hidden")}>
              <span className={styles.result_counter}>{props.trial_search.length}</span> developer
              {props.trial_search.length > 1 && "s"} from{" "}
              <span className={styles.result_counter}>{search_results_unique_companies}</span>
              {search_results_unique_companies > 1 ? " companies" : " company"}
            </div>
            {props.trial_search?.map((search) => (
              <SearchResultsItem
                key={search.developer_id}
                is_auth={props.is_auth}
                search={search}
                setSelectedTrialDeveloper={props.setSelectedTrialDeveloper}
              />
            ))}
          </>
        )}

        {props.trial_search?.length === 0 && (
          <div className={styles.no_results_message}>Currently, no developers are matching your search.</div>
        )}

        <Link to="/registration" className={cn(styles.registerButton, "mobile_visible")} />
      </div>
    </>
  );
};

export default TrialSearch;
