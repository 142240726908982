import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Field, FieldArray, reduxForm, reset } from "redux-form";
import { required, numericality } from "redux-form-validators";
import moment from "moment";

import { getCurrentDeveloper } from "../../../store/developer";
import { history } from "../../../utils";
import {
  Input,
  SelectInput,
  SelectInputEnglish,
  SelectInputKeyValue,
  SelectInputSkill,
  renderMultiselect,
} from "../../common/FormsControls/FormControls";
import Editor from "../../common/SimpleEditor/SimpleEditor";
import Preloader from "../../common/Preloader/Preloader";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import DeveloperProject from "../DeveloperProject/DeveloperProject";
import UploadImage from "../../common/UploadImage/UploadImage";
import DatePickerWrapper from "../../common/DatePickerWrapper/DatePickerWrapper";
import ProfileMenu from "../../Profile/ProfileMenu";
import CompanyMenu from "../../Profile/CompanyMenu";

import styles from "./DeveloperEditing.module.css";
import React from "react";

let DeveloperEditingForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <Field component={UploadImage} name="photo" label="Upload Photo" mode="photo"/>
        <Field
          component={Input}
          type={"text"}
          name={"name"}
          label="Name"
          validate={[required()]}
          help_text="for example: James"
        />
        <Field
          component={SelectInputKeyValue}
          name="job_title_id"
          label="Job title"
          validate={[required()]}
          help_text="for example: Front-end Developer"
          options={props.jobTitlesList}
        />
        <Field
          component={SelectInput}
          name={"country"}
          label="Developer's Location"
          options={props.countries_list}
          validate={[required()]}
        />
        <Field
          component={Editor}
          type={"text"}
          name={"general_info"}
          label="About developer"
          validate={[required()]}
          rows={4}
        />
      </div>

      <div className={styles.submit_wrapper}>
        <button className={`btn ${styles.submit}`}>Save</button>
      </div>
    </form>
  );
};

const renderSkills = ({ fields, meta: { error, submitFailed }, ...restProps }) => {
  return (
    <div>
      {fields.map((skill, index) => (
        <div
          key={index}
          className={`${styles.skill_wrapper} d_flex justify_content_between align_items_center`}
        >
          <Field
            name={`${skill}.skill_id`}
            component={SelectInputSkill}
            skills_list={restProps.skills_list}
            validate={[required()]}
            label="Skill"
            help_text="for example: JS"
          />
          <Field
            name={`${skill}.skill_experience`}
            component={SelectInput}
            label="Experience"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            validate={[required()]}
            help_text="for example: 3 years"
          />
          <button
            type="button"
            title="Remove Skill"
            onClick={() => fields.remove(index)}
            className={styles.remove_skill}
          />
        </div>
      ))}
      <div>
        <button type="button" onClick={() => fields.push({})}>
          Add Skill
        </button>
        {submitFailed && error && <span>{error}</span>}
      </div>
    </div>
  );
};

let DeveloperProjectForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <Field
          component={Input}
          type={"text"}
          name={"project_name"}
          label="Project name"
          validate={[required()]}
          help_text="for example: Smart solutions"
        />
        <Field
          component={Editor}
          type={"text"}
          name={"description"}
          label="Project description"
          validate={[required()]}
          rows={4}
        />
        <Field component={Input} type={"text"} name={"link"} label="Project link"/>
        <div className={styles.projectDuration}>
          <div className={styles.projectDurationItem}>
            <Field
              name={"from_year"}
              label="Project start"
              defaultValue={props.initialValues?.from_year && moment(props.initialValues.from_year)}
              component={DatePickerWrapper}
              help_text="yyyy/mm"
              validate={[required()]}
            />
          </div>
          <div className={styles.projectDurationItem}>
            <Field
              name={"to_year"}
              label="Project end"
              defaultValue={props.initialValues?.to_year && moment(props.initialValues.to_year)}
              component={DatePickerWrapper}
              help_text="yyyy/mm"
              validate={[required()]}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Field
              name={"main_skill_id"}
              component={SelectInputSkill}
              skills_list={props.skills_list}
              label="Main skill"
              validate={[required()]}
              help_text="for example: JS"
            />
          </div>
          <div className={styles.col}>
            <Field
              component={Input}
              type={"number"}
              name={"team_size"}
              label="Team size"
              validate={[required(), numericality({ int: true, ">": 1 })]}
              help_text="For example: 8"
            />
          </div>
        </div>
        <div className={styles.projectSkillsWrapper}>
          <Field
            name={"skills"}
            component={renderMultiselect}
            label="Technologies"
            validate={[required()]}
            data={props.skills_list}
            valueField="skill_id"
            textField="skill_name"
            placeholder="Enter the project technologies"
          />
        </div>
        <Field
          component={Editor}
          type={"text"}
          name={"responsibilities"}
          label="Responsibilities"
          validate={[required()]}
          rows={4}
        />
      </div>

      <div className={styles.submit_wrapper}>
        <button className={`btn ${styles.submit}`}>Save</button>
      </div>
    </form>
  );
};

let DeveloperEditingForm2 = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        {!props.isSingleDeveloper && (
          <Field
            component={Input}
            type={"number"}
            name={"rate"}
            label="Hourly Rate"
            validate={[required()]}
            help_text="For example: $35/h"
          />
        )}
        {!!props.isSingleDeveloper && (
          <Field
            component={Input}
            type={"number"}
            name={"rate_month"}
            label="Salary/month"
            validate={[required()]}
            help_text="For example: $3000"
          />
        )}
        <Field
          name={"main_skill_id"}
          component={SelectInputSkill}
          skills_list={props.skills_list}
          label="Main skill"
          validate={[required()]}
          help_text="for example: JS"
        />
        <FieldArray name="skills" component={renderSkills} skills_list={props.skills_list}/>
        <Field
          component={SelectInput}
          name={"experience"}
          label="Experience"
          options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          validate={[required()]}
          help_text="for example: 3 years"
        />
        <Field
          component={SelectInputEnglish}
          name="english_level_id"
          label="English level"
          validate={[required()]}
          help_text="for example: B1 (intermediate)"
          options={props.englishLevelsList}
        />
        <Field
          component={SelectInputKeyValue}
          name="education"
          label="Education"
          validate={[required()]}
          help_text="for example: PhD."
          options={props.degreesList}
        />
      </div>

      <div className={styles.submit_wrapper}>
        <button className={`btn ${styles.submit}`}>Save</button>
      </div>
    </form>
  );
};

DeveloperEditingForm = reduxForm({ form: "developerEditing" })(DeveloperEditingForm);
DeveloperEditingForm = connect(
  (state) => ({
    initialValues: state.developer.current_developer, // pull initial values
  }),
  { load: getCurrentDeveloper } // bind account loading action creator
)(DeveloperEditingForm);

DeveloperProjectForm = reduxForm({ form: "developerProject" })(DeveloperProjectForm);
let DeveloperProjectInitialReduxForm = connect((state, props) => ({
  initialValues: state.developer.current_developer.projects[props.editingProject], // pull initial values
}))(DeveloperProjectForm);

DeveloperEditingForm2 = reduxForm({ form: "developerEditing_2" })(DeveloperEditingForm2);
DeveloperEditingForm2 = connect(
  (state) => ({
    initialValues: state.developer.current_developer, // pull initial values
  }),
  { load: getCurrentDeveloper } // bind account loading action creator
)(DeveloperEditingForm2);

const DeveloperEditing = (props) => {
  const [isProjectFormOpen, setIsProjectFormOpen] = useState(false);
  const [editingProject, setEditingProject] = useState(null);

  const dispatch = useDispatch();

  const onSubmit = (formData) => {
    let developerData = {
      name: formData.name,
      job_title_id: formData.job_title_id,
      country: formData.country,
      general_info: formData.general_info,
      developer_id: props.developer.current_developer.developer_id,
    };

    if (typeof formData.photo?.[0] !== "string") developerData.photo = formData.photo;

    props.editDeveloper(developerData);
  };

  const onSubmitProject = (formData) => {
    props.addDeveloperProject(props.developer.current_developer.developer_id, formData);
    setIsProjectFormOpen(false);
    setEditingProject(null);
    dispatch(reset("developerProject"));
  };

  const onEditProject = (formData) => {
    let mappedFormData = {
      project_id: formData.project_id,
      project_name: formData.project_name,
      description: formData.description,
      link: formData.link,
      from_year: moment(formData.from_year).format("YYYY-MM-DD"),
      to_year: moment(formData.to_year).format("YYYY-MM-DD"),
      main_skill_id: formData.main_skill_id,
      team_size: formData.team_size,
      responsibilities: formData.responsibilities,
      addSkills: [],
      deleteSkills: [],
    };

    const projectIndex = props.developer.current_developer.projects.findIndex(
      (project) => project.project_id === formData.project_id
    );

    const containsSkills = (array, value) => array.some((item) => Number(value) === Number(item.skill_id));

    // skills for adding
    formData.skills.forEach((skill) => {
      let isSkillExists = containsSkills(
        props.developer.current_developer.projects[projectIndex].skills,
        skill.skill_id
      );
      if (!isSkillExists) mappedFormData.addSkills.push(skill);
    });

    // skills for deleting
    props.developer.current_developer.projects[projectIndex].skills.forEach((skill) => {
      let isSkillExists = containsSkills(formData.skills, skill.skill_id);
      if (!isSkillExists) mappedFormData.deleteSkills.push(skill);
    });

    props.editDeveloperProject(props.developer.current_developer.developer_id, mappedFormData);
    setIsProjectFormOpen(false);
    setEditingProject(null);
    dispatch(reset("developerProject"));
  };

  const onDeleteProject = (projectId) => {
    props.deleteDeveloperProject(props.developer.current_developer.developer_id, { project_id: projectId });
  };

  const editOnSubmit = (formData) => {
    let add_skills = [];
    let delete_skills = [];
    let edit_skills = [];

    let developerData = {
      main_skill_id: formData.main_skill_id,
      experience: formData.experience,
      english_level_id: formData.english_level_id,
      education: formData.education,
      developer_id: props.developer.current_developer.developer_id,
    };

    if (props.companyInfo?.is_single_developer) {
      developerData.rate_month = formData.rate_month;
    } else {
      developerData.rate = formData.rate;
    }

    // ADD SKILLS
    const containsSkills = (array, value) => {
      return array.some((item) => value === item.skill_id);
    };
    formData.skills.forEach((skill) => {
      let check_result = containsSkills(props.developer.current_developer.skills, skill.skill_id);
      if (!check_result) {
        add_skills = [...add_skills, skill];
      }
    });
    if (add_skills.length) {
      developerData = {
        ...developerData,
        add_skills,
      };
    }

    // EDIT SKILLS
    formData.skills.forEach((skill) => {
      props.developer.current_developer.skills.forEach((item) => {
        if (item.skill_id === skill.skill_id && item.skill_experience !== skill.skill_experience) {
          edit_skills = [...edit_skills, skill];
        }
      });
    });
    if (edit_skills.length) {
      developerData = {
        ...developerData,
        edit_skills,
      };
    }

    // DELETE SKILLS
    const containsOldSkills = (array, value) => {
      return array.some((item) => value === item.skill_id);
    };
    props.developer.current_developer.skills.forEach((skill) => {
      let check_result = containsOldSkills(formData.skills, skill.skill_id);
      if (!check_result) {
        delete_skills = [...delete_skills, skill];
      }
    });
    if (delete_skills.length) {
      developerData = {
        ...developerData,
        delete_skills,
      };
    }

    props.editDeveloper(developerData);
  };

  return (
    <div className={styles.wrapper}>
      {props.developer.is_fetching && <Preloader/>}

      <div className={styles.slot_number}>Slot Editing</div>

      <h2 className={`${styles.title} text_center`}>
        Company profile
      </h2>

      <ProfileMenu />

      <CompanyMenu companyInfo={props.companyInfo} />

      <div className={styles.form_wrapper}>
        <Tabs>
          <TabList className={`${styles.adding_menu} d_flex justify_content_center`}>
            {props.developer && props.developer.current_developer && (
              <NavLink
                to={`/developer/${props.developer.current_developer.developer_id}`}
                className={styles.adding_menu_item}
              >
                View developer
              </NavLink>
            )}
            <Tab className={styles.adding_menu_item}>Base info</Tab>
            <Tab className={styles.adding_menu_item}>Projects</Tab>
            <Tab className={styles.adding_menu_item}>Skills and Rate</Tab>
          </TabList>
          <TabPanel>
            <DeveloperEditingForm
              onSubmit={onSubmit}
              jobTitlesList={props.jobTitlesList}
              countries_list={props.countries_list}
            />
          </TabPanel>
          <TabPanel>
            {isProjectFormOpen ? (
              <DeveloperProjectForm onSubmit={onSubmitProject} skills_list={props.skills_list}/>
            ) : (
              <div className={styles.submit_wrapper}>
                <button className={`btn ${styles.submit}`} onClick={() => setIsProjectFormOpen(true)}>
                  Add new project
                </button>
              </div>
            )}

            {props.developer.current_developer?.projects.map((project, index) => (
              <>
                {index === editingProject ? (
                  <DeveloperProjectInitialReduxForm
                    editingProject={editingProject}
                    skills_list={props.skills_list}
                    onSubmit={onEditProject}
                  />
                ) : (
                  <div className={styles.developerProjectWrapper}>
                    <DeveloperProject key={project.project_id} number={index + 1} {...project} />
                    <div className={styles.developerProjectActions}>
                      <button
                        className={`btn btn_pink_2 ${styles.submit}`}
                        onClick={() => setEditingProject(index)}
                      >
                        Edit project
                      </button>
                      <button
                        className={`btn btn_red ${styles.submit}`}
                        onClick={() => onDeleteProject(project.project_id)}
                      >
                        Delete project
                      </button>
                    </div>
                  </div>
                )}
              </>
            ))}

            <div className={styles.submit_wrapper}>
              <button
                className={`btn ${styles.submit}`}
                onClick={() =>
                  history.push(`/developer/${props.developer.current_developer.developer_id}`)
                }
                disabled={!props.developer?.current_developer?.projects.length}
              >
                Save
              </button>
            </div>
          </TabPanel>
          <TabPanel>
            <DeveloperEditingForm2
              onSubmit={editOnSubmit}
              skills_list={props.skills_list}
              englishLevelsList={props.englishLevelsList}
              degreesList={props.degreesList}
              isSingleDeveloper={props.companyInfo?.is_single_developer}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default DeveloperEditing;
