import React from "react";
import { connect } from "react-redux";

import Header from "./Header";

const HeaderContainer = (props) => {
  return <Header auth={props.auth} />;
};

let mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(HeaderContainer);
