import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  deleteDeveloper,
  editBenchStatus,
  getCurrentDeveloper,
  hideModal,
  showBenchModal,
  showModal,
} from "../../store/developer";
import { setSelectedTrialDeveloper } from "../../store/search";
import { hire } from "../../store/chat";
import Developer from "./Developer";

const DeveloperContainer = (props) => {
  useEffect(() => {
    props.getCurrentDeveloper(props.match.params.developer_id);
  }, []);

  return (
    <Developer
      is_auth={props.auth.is_auth}
      developer={props.developer}
      position_levels={props.position_levels}
      company_id={props.auth.company_info && props.auth.company_info.id}
      company_type={props.auth.company_info ? props.auth.company_info.type : 1}
      is_pro_account={props.auth.company_info && props.auth.company_info.is_pro_account}
      verified={props.auth.company_info && props.auth.company_info.verified}
      company_logo={props.auth.company_info && props.auth.company_info.logo}
      companyInfo={props.auth.company_info}
      setMeta={props.setMeta}
      showModal={props.showModal}
      showBenchModal={props.showBenchModal}
      hideModal={props.hideModal}
      deleteDeveloper={props.deleteDeveloper}
      editBenchStatus={props.editBenchStatus}
      setSelectedTrialDeveloper={!props.auth.is_auth && props.setSelectedTrialDeveloper}
      hire={props.hire}
      is_single_developer={props.company_info?.is_single_developer}
    />
  );
};

const mapStateToProps = (state) => ({
  company_info: state.auth?.company_info,
  position_levels: state.app.position_levels,
  auth: state.auth,
  developer: state.developer,
});

const mapDispatchToProps = {
  getCurrentDeveloper,
  showModal,
  showBenchModal,
  hideModal,
  deleteDeveloper,
  editBenchStatus,
  setSelectedTrialDeveloper,
  hire,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperContainer);
