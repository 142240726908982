import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required, numericality } from "redux-form-validators";
import moment from "moment";

import { siteNameForTitle } from "../../../constants/global-constants";
import { Input, SelectInput, renderMultiselect } from "../../common/FormsControls/FormControls";
import Preloader from "../../common/Preloader/Preloader";
import ProfileMenu from "../../Profile/ProfileMenu";
import CompanyMenu from "../../Profile/CompanyMenu";
import UploadImage from "../../common/UploadImage/UploadImage";
import RadioButton from "../../common/RadioButton/RadioButton";
import UploadFile from "../../common/UploadFile/UploadFile";
import Editor from "../../common/Editor/Editor";
import DatePickerWrapper from "../../common/DatePickerWrapper/DatePickerWrapper";

import styles from "./CaseEditing.module.css";

let CaseEditingForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <Field component={UploadImage} name="case_logo" label="Upload Photo" mode="photo"/>
        <Field
          component={Input}
          type={"text"}
          name={"name"}
          label="Project name"
          validate={[required()]}
          help_text="for example: My first project"
        />
        <Field
          component={Editor}
          name={"description"}
          label="Project description"
          validate={[required()]}
          placeholder={"Write project description..."}
        />
        <div className={styles.projectDuration}>
          <div className={styles.projectDurationItem}>
            <Field
              name={"from_date"}
              label="Project start"
              defaultValue={props.initialValues?.from_date && moment(props.initialValues.from_date)}
              component={DatePickerWrapper}
              help_text="For example: June 2020"
              validate={[required()]}
            />
          </div>
          <div className={styles.projectDurationItem}>
            <Field
              name={"to_date"}
              label="Project end"
              defaultValue={props.initialValues?.to_date && moment(props.initialValues.to_date)}
              component={DatePickerWrapper}
              help_text="For example: June 2021"
              validate={[required()]}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Field
              component={Input}
              type={"number"}
              name={"team_size"}
              label="Team size"
              validate={[required(), numericality({ int: true, ">": 1 })]}
              help_text="For example: 8"
            />
          </div>
          <div className={styles.col}>
            <Field
              component={SelectInput}
              name={"country"}
              label="Client's Country"
              options={props.countries_list}
              validate={[required()]}
              help_text="for example: USA"
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Field
              className={styles.hoursSpentInput}
              component={Input}
              type={"number"}
              name={"hours_spent"}
              label="Total hours spent"
              validate={[required(), numericality({ int: true, ">": 0 })]}
              help_text="For example: 200"
            />
          </div>
        </div>
        <Field
          component={renderMultiselect}
          name={"markets"}
          label="What are you building?"
          validate={[required()]}
          data={props.markets_list}
          valueField="market_id"
          textField="market_name"
          placeholder="What are you building?"
        />

        <div className={styles.projectSkillsWrapper}>
          <Field
            name={"skills"}
            component={renderMultiselect}
            label="Technologies"
            validate={[required()]}
            data={props.skills_list}
            valueField="skill_id"
            textField="skill_name"
            placeholder="Enter the project technologies"
          />
        </div>

        <div className={styles.monthBudgetWrapper}>
          <div className={styles.monthBudgetTitle}>Project budget</div>
          <div className={styles.monthBudget}>
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="5000"
              text="Less then $5,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="15000"
              text="Up to $15,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="35000"
              text="Up to $35,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="75000"
              text="Up to $75,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="100000"
              text="Up to $100,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="200000"
              text="Up to $200,000"
              validate={[required()]}
              designType={"greenButton"}
            />
          </div>
        </div>

        <Field component={Input} type={"url"} name={"links"} label="Additional link"/>
        <Field component={UploadImage} name="covers" label="Upload Photo" title="Add covers" multiple={true}/>
        <Field
          component={UploadFile}
          name="files"
          label="upload file"
          title="Upload PDF presentation"
          multiple={true}
          maxSize={15}
          accept={[".pdf"]}
        />
      </div>

      <div>
        <button className={`btn ${styles.submit}`}>SAVE CASE</button>
      </div>
    </form>
  );
};

CaseEditingForm = reduxForm({ form: "caseEditing", enableReinitialize: true })(CaseEditingForm);
CaseEditingForm = connect((state) => ({
  initialValues: state.cases.currentCase, // pull initial values
}))(CaseEditingForm);

const CaseEditing = (props) => {
  const onSubmit = (formData) => {
    let mappedFormData = {
      case_id: formData.case_id,
      budget: formData.budget,
      description: formData.description,
      country: formData.country,
      team_size: formData.team_size,
      hours_spent: formData.hours_spent,
      covers: formData.covers.filter((cover) => typeof cover !== "string"),
      coversDelete: formData.coversDelete,
      files: formData.files.filter((file) => typeof file !== "string"),
      filesDelete: formData.filesDelete,
      from_date: moment(formData.from_date).format("YYYY-MM-DD"),
      to_date: moment(formData.to_date).format("YYYY-MM-DD"),
      links: formData.links,
      addMarkets: [],
      deleteMarkets: [],
      addSkills: [],
      deleteSkills: [],
    };

    if (typeof formData.case_logo?.[0] !== "string") mappedFormData.case_logo = formData.case_logo;

    const containsMarkets = (array, value) => array.some((item) => Number(value) === Number(item.market_id));

    // markets for adding
    formData.markets.forEach((market) => {
      let isMarketExists = containsMarkets(props.currentCase.markets, market.market_id);
      if (!isMarketExists) mappedFormData.addMarkets.push(market);
    });

    // markets for deleting
    props.currentCase.markets.forEach((market) => {
      let isMarketExists = containsMarkets(formData.markets, market.market_id);
      if (!isMarketExists) mappedFormData.deleteMarkets.push(market);
    });

    const containsSkills = (array, value) => array.some((item) => Number(value) === Number(item.skill_id));

    // skills for adding
    formData.skills.forEach((skill) => {
      let isSkillExists = containsSkills(props.currentCase.skills, skill.skill_id);
      if (!isSkillExists) mappedFormData.addSkills.push(skill);
    });

    // skills for deleting
    props.currentCase.skills.forEach((skill) => {
      let isSkillExists = containsSkills(formData.skills, skill.skill_id);
      if (!isSkillExists) mappedFormData.deleteSkills.push(skill);
    });

    props.editCase(mappedFormData);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Edit case | {siteNameForTitle}</title>
      </Helmet>

      {props.isFetching && <Preloader/>}

      <h2 className={`${styles.profileTitle} text_center`}>
        Company profile
      </h2>

      <ProfileMenu />

      <CompanyMenu companyInfo={props.companyInfo} />

      <div className={styles.title}>Edit case</div>

      <div className={styles.formWrapper}>
        <CaseEditingForm
          countries_list={props.countries_list}
          markets_list={props.markets_list}
          skills_list={props.skills_list}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};

export default CaseEditing;
