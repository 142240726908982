import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { getVerifiedCompanies, getVerifiedCompaniesByService } from "../../store/company";
import { siteNameForTitle, popularDeveloperTeams, defaultDescription } from "../../constants";
import CompanyTop10Card from "../common/CompanyTop10Card/CompanyTop10Card";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import styles from "./VerifiedCompanies.module.css";

const VerifiedCompanies = (props) => {
  const {
    getVerifiedCompanies,
    getVerifiedCompaniesByService,
    companies,
    match,
  } = props;

  const [ title, setTitle ] = useState('All verified teams');

  useEffect(() => {
    const serviceData = popularDeveloperTeams.find(service => service.link === match.url);

    if (serviceData?.id) {
      setTitle(serviceData.label);
      return getVerifiedCompaniesByService(serviceData.id);
    }

    getVerifiedCompanies();
  }, [match.url]);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{siteNameForTitle} / {title}</title>
        <meta
          name="description"
          content={defaultDescription}
        />
        <meta
          name="keywords"
          content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
          data-react-helmet="true"
        />
      </Helmet>
      <Breadcrumbs className={styles.breadcrumbs} currentPage={title}/>
      <h1 className={styles.pageTitle}>
        {title}
      </h1>
      <div className={styles.verifiedCompanies}>
        {companies.map(company => <CompanyTop10Card company={company} />)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  companies: state.company.verifiedCompanies,
});

const mapDispatchToProps = {
  getVerifiedCompanies,
  getVerifiedCompaniesByService,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifiedCompanies);
