import React from "react";
import { Helmet } from "react-helmet";

import { siteNameForTitle } from "../../constants";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <>
      <Breadcrumbs className={styles.breadcrumbs} currentPage="Privacy notice"/>
      <div className={styles.wrapper}>
        <Helmet>
          <title>{siteNameForTitle} / Privacy Notice</title>
          <meta
            name="description"
            content="Highly skilled software developers, verified by real companies. Realtime search. No freelancers here."
            data-react-helmet="true"
          />
          <meta
            name="keywords"
            content="software developers, ux/ui designers, bench, developers on demand, realtime developers search, e-commerce developers, fin-tech developers, healthcare developers"
            data-react-helmet="true"
          />
        </Helmet>

        <h1 className={styles.title}>Privacy Notice</h1>

        <p>
          <b>Last updated July 14, 2021</b>
        </p>
        <br />

        <p>
          Thank you for choosing to be part of our community at Linenberger Group LTD., doing business as Linenberger Group (<b>"Linenberger Group"</b>
          , <b>"we"</b>,<b>"us"</b>, <b>"our"</b>). We are committed to protecting your personal information and your
          right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards
          to your personal information, please contact us at info@linenberger.uk.
        </p>
        <p>
          When you visit our website <a href="https://ibench.in/">https://ibench.in</a> (the <b>"Website"</b>), and more
          generally, use any of our services (the <b>"Services"</b>, which include the Website), we appreciate that you
          are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we
          seek to explain to you in the clearest way possible what information we collect, how we use it and what rights
          you have in relation to it. We hope you take some time to read through it carefully, as it is important. If
          there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services
          immediately.
        </p>
        <p>
          This privacy notice applies to all information collected through our Services (which, as described above,
          includes our Website), as well as, any related services, sales, marketing or events.
        </p>
        <p>
          <b>
            Please read this privacy notice carefully as it will help you understand what we do with the information that
            we collect.
          </b>
        </p>
        <br />

        <p>
          <b>TABLE OF CONTENTS</b>
        </p>
        <ol>
          <li>
            <a href="#1">WHAT INFORMATION DO WE COLLECT?</a>
          </li>
          <li>
            <a href="#2">HOW DO WE USE YOUR INFORMATION?</a>
          </li>
          <li>
            <a href="#3">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
          </li>
          <li>
            <a href="#4">WHO WILL YOUR INFORMATION BE SHARED WITH?</a>
          </li>
          <li>
            <a href="#5">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
          </li>
          <li>
            <a href="#6">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
          </li>
          <li>
            <a href="#7">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
          </li>
          <li>
            <a href="#8">WHAT ARE YOUR PRIVACY RIGHTS?</a>
          </li>
          <li>
            <a href="#9">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
          </li>
          <li>
            <a href="#10">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
          </li>
          <li>
            <a href="#11">DO WE MAKE UPDATES TO THIS NOTICE?</a>
          </li>
          <li>
            <a href="#12">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
          </li>
          <li>
            <a href="#13">HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</a>
          </li>
        </ol>
        <br />

        <h2 id="1">1. WHAT INFORMATION DO WE COLLECT?</h2>
        <p>
          <h4>Personal information you disclose to us</h4>
        </p>
        <p>
          <i>
            <b>In Short:</b> We collect personal information that you provide to us.
          </i>
        </p>
        <p>
          We collect personal information that you voluntarily provide to us when you register on the Website, express an
          interest in obtaining information about us or our products and Services, when you participate in activities on
          the Website (such as by posting messages in our online forums or entering competitions, contests or giveaways)
          or otherwise when you contact us.
        </p>
        <p>
          The personal information that we collect depends on the context of your interactions with us and the Website,
          the choices you make and the products and features you use. The personal information we collect may include the
          following:
        </p>
        <p>
          <b>Personal Information Provided by You</b>. We collect names; phone numbers; email addresses; mailing
          addresses; job titles; usernames; contact preferences; billing addresses; telegram; twitter; linkedin; and other
          similar information.
        </p>
        <p>
          Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment
          instrument number (such as a credit card number), and the security code associated with your payment instrument.
          All payment data is stored by Stripe. You may find their privacy notice link(s) here:{" "}
          <a href="https://stripe.com" target="_blank" rel="nofollow">
            https://stripe.com
          </a>
          .
        </p>
        <p>
          All personal information that you provide to us must be true, complete and accurate, and you must notify us of
          any changes to such personal information
        </p>
        <br />
        <h4>Information automatically collected</h4>
        <p>
          <i>
            <b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or browser and device
            characteristics — is collected automatically when you visit our Website
          </i>
        </p>
        <p>
          We automatically collect certain information when you visit, use or navigate the Website. This information does
          not reveal your specific identity (like your name or contact information) but may include device and usage
          information, such as your IP address, browser and device characteristics, operating system, language
          preferences, referring URLs, device name, country, location, information about how and when you use our Website
          and other technical information. This information is primarily needed to maintain the security and operation of
          our Website, and for our internal analytics and reporting purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and similar technologies. You can find out
          more about this in our Cookie Notice:{" "}
          <a href="https://ibench.in/cookie-policy">https://ibench.net/cookie-policy</a>.
        </p>
        <p>The information we collect includes:</p>
        <ul>
          <li>
            Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance information our
            servers automatically collect when you access or use our Website and which we record in log files. Depending
            on how you interact with us, this log data may include your IP address, device information, browser type and
            settings and information about your activity in the Website (such as the date/time stamps associated with your
            usage, pages and files viewed, searches and other actions you take such as which features you use), device
            event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware
            settings).
          </li>
          <li>
            Device Data. We collect device data such as information about your computer, phone, tablet or other device you
            use to access the Website. Depending on the device used, this device data may include information such as your
            IP address (or proxy server), device and application identification numbers, location, browser type, hardware
            model Internet service provider and/or mobile carrier, operating system and system configuration information.
          </li>
          <li>
            Location Data. We collect location data such as information about your device's location, which can be either
            precise or imprecise. How much information we collect depends on the type and settings of the device you use
            to access the Website. For example, we may use GPS and other technologies to collect geolocation data that
            tells us your current location (based on your IP address). You can opt out of allowing us to collect this
            information either by refusing access to the information or by disabling your Location setting on your device.
            Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.
          </li>
        </ul>
        <br />
        <br />

        <h2 id="2">2. HOW DO WE USE YOUR INFORMATION?</h2>
        <p>
          <i>
            <b>In Short:</b> We process your information for purposes based on legitimate business interests, the
            fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
          </i>
        </p>
        <p>
          We use personal information collected via our Website for a variety of business purposes described below. We
          process your personal information for these purposes in reliance on our legitimate business interests, in order
          to enter into or perform a contract with you, with your consent, and/or for compliance with our legal
          obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
        </p>
        <p>We use the information we collect or receive:</p>
        <ul>
          <li>
            <b>To facilitate account creation and logon process.</b> If you choose to link your account with us to a
            third-party account (such as your Google or Facebook account), we use the information you allowed us to
            collect from those third parties to facilitate account creation and logon process for the performance of the
            contract.
          </li>
          <li>
            <b>To post testimonials.</b> We post testimonials on our Website that may contain personal information. Prior
            to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If
            you wish to update, or delete your testimonial, please contact us at hello@ibench.in and be sure to include
            your name, testimonial location, and contact information.
          </li>
          <li>
            <b>Request feedback.</b> We may use your information to request feedback and to contact you about your use of
            our Website
          </li>
          <li>
            <b>To enable user-to-user communications.</b> We may use your information in order to enable user-to-user
            communications with each user's consent.
          </li>
          <li>
            <b>To manage user accounts.</b> We may use your information for the purposes of managing our account and
            keeping it in working order.
          </li>
          <li>
            <b>To send administrative information to you.</b> We may use your personal information to send you product,
            service and new feature information and/or information about changes to our terms, conditions, and policies.
          </li>
          <li>
            <b>To protect our Services.</b> We may use your information as part of our efforts to keep our Website safe
            and secure (for example, for fraud monitoring and prevention).
          </li>
          <li>
            <b>
              To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory
              requirements or in connection with our contract.
            </b>
          </li>
          <li>
            <b>To respond to legal requests and prevent harm.</b> If we receive a subpoena or other legal request, we may
            need to inspect the data we hold to determine how to respond.
          </li>
          <li>
            <b>Fulfill and manage your orders.</b> We may use your information to fulfill and manage your orders,
            payments, returns, and exchanges made through the Website.
          </li>
          <li>
            <b>Administer prize draws and competitions.</b> We may use your information to administer prize draws and
            competitions when you elect to participate in our competitions.
          </li>
          <li>
            <b>To deliver and facilitate delivery of services to the user.</b> We may use your information to provide you
            with the requested service.
          </li>
          <li>
            <b>To respond to user inquiries/offer support to users.</b> We may use your information to respond to your
            inquiries and solve any potential issues you might have with the use of our Services.
          </li>
          <li>
            <b>To send you marketing and promotional communications.</b> We and/ or our third-party marketing partners may
            use the personal information you send to us for our marketing purposes, if this is in accordance with your
            marketing preferences. For example, when expressing an interest in obtaining information about us or our
            Website, subscribing to marketing or otherwise contacting us, we will collect personal information from you.
            You can opt-out of our marketing emails at any time (see the "<u>WHAT ARE YOUR PRIVACY RIGHTS?</u>" below).
          </li>
          <li>
            <b>Deliver targeted advertising to you.</b> We may use your information to develop and display personalized
            content and advertising (and work with third parties who do so) tailored to your interests and/or location and
            to measure its effectiveness. For more information see our Cookie Notice:{" "}
            <a href="https://ibench.in/cookie-policy">https://ibench.in/cookie-policy</a>.
          </li>
        </ul>
        <br />
        <br />

        <h2 id="3">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
        <p>
          <i>
            <b>In Short:</b> We only share information with your consent, to comply with laws, to provide you with
            services, to protect your rights, or to fulfill business obligations.
          </i>
        </p>
        <p>We may process or share your data that we hold based on the following legal basis:</p>
        <ul>
          <li>
            <b>Consent:</b> We may process your data if you have given us specific consent to use your personal
            information for a specific purpose.
          </li>
          <li>
            <b>Legitimate Interests:</b> We may process your data when it is reasonably necessary to achieve our
            legitimate business interests.
          </li>
          <li>
            <b>Performance of a Contract:</b> Where we have entered into a contract with you, we may process your personal
            information to fulfill the terms of our contract.
          </li>
          <li>
            <b>Legal Obligations:</b> We may disclose your information where we are legally required to do so in order to
            comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such
            as in response to a court order or a subpoena (including in response to public authorities to meet national
            security or law enforcement requirements).
          </li>
          <li>
            <b>Vital Interests:</b> We may disclose your information where we believe it is necessary to investigate,
            prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving
            potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we
            are involved.
          </li>
        </ul>
        <p>
          More specifically, we may need to process your data or share your personal information in the following
          situations:
        </p>
        <ul>
          <li>
            <b>Business Transfers.</b> We may share or transfer your information in connection with, or during
            negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </li>
          <li>
            <b>Vendors, Consultants and Other Third-Party Service Providers.</b> We may share your data with third-party
            vendors, service providers, contractors or agents who perform services for us or on our behalf and require
            access to such information to do that work. Examples include: payment processing, data analysis, email
            delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use
            tracking technology on the Website, which will enable them to collect data on our behalf about how you
            interact with our Website over time. This information may be used to, among other things, analyze and track
            data, determine the popularity of certain content, pages or features, and better understand online activity.
            Unless described in this notice, we do not share, sell, rent or trade any of your information with third
            parties for their promotional purposes. We have contracts in place with our data processors, which are
            designed to help safeguard your personal information. This means that they cannot do anything with your
            personal information unless we have instructed them to do it. They will also not share your personal
            information with any organization apart from us. They also commit to protect the data they hold on our behalf
            and to retain it for the period we instruct.
          </li>
          <li>
            <b>Affiliates.</b> We may share your information with our affiliates, in which case we will require those
            affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint
            venture partners or other companies that we control or that are under common control with us.
          </li>
          <li>
            <b>Other Users.</b> When you share personal information (for example, by posting comments, contributions or
            other content to the Website) or otherwise interact with public areas of the Website, such personal
            information may be viewed by all users and may be publicly made available outside the Website in perpetuity.
            Similarly, other users will be able to view descriptions of your activity, communicate with you within our
            Website, and view your profile.
          </li>
        </ul>
        <br />
        <br />

        <h2 id="4">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h2>
        <p>
          <i>
            <b>In Short:</b> We only share information with the following categories of third parties.
          </i>
        </p>
        <p>
          We only share and disclose your information with the following categories of third parties. If we have processed
          your data based on your consent and you wish to revoke your consent, please contact us using the contact details
          provided in the section below titled "<u>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</u>".
        </p>
        <ul>
          <li>Ad Networks</li>
          <li>Payment Processors</li>
          <li>Performance Monitoring Tools</li>
          <li>Social Networks</li>
        </ul>
        <br />
        <br />

        <h2 id="5">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
        <p>
          <i>
            <b>In Short:</b> We may use cookies and other tracking technologies to collect and store your information.
          </i>
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
          information. Specific information about how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice: <a href="https://ibench.in/cookie-policy">https://ibench.in/cookie-policy</a>.
        </p>
        <br />
        <br />

        <h2 id="6">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
        <p>
          <i>
            <b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this
            privacy notice unless otherwise required by law.
          </i>
        </p>
        <p>
          We will only keep your personal information for as long as it is necessary for the purposes set out in this
          privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or
          other legal requirements). No purpose in this notice will require us keeping your personal information for
          longer than the period of time in which users have an account with us.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your personal information, we will either delete or
          anonymize such information, or, if this is not possible (for example, because your personal information has been
          stored in backup archives), then we will securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <br />
        <br />

        <h2 id="7">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
        <p>
          <i>
            <b>In Short:</b> We aim to protect your personal information through a system of organizational and technical
            security measures.
          </i>
        </p>
        <p>
          We have implemented appropriate technical and organizational security measures designed to protect the security
          of any personal information we process. However, despite our safeguards and efforts to secure your information,
          no electronic transmission over the Internet or information storage technology can be guaranteed to be 100%
          secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will
          not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although
          we will do our best to protect your personal information, transmission of personal information to and from our
          Website is at your own risk. You should only access the Website within a secure environment.
        </p>
        <br />
        <br />

        <h2 id="8">8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
        <p>
          <i>
            <b>In Short:</b> In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have
            rights that allow you greater access to and control over your personal information. You may review, change, or
            terminate your account at any time.
          </i>
        </p>
        <p>
          In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may
          include the right (i) to request access and obtain a copy of your personal information, (ii) to request
          rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable,
          to data portability. In certain circumstances, you may also have the right to object to the processing of your
          personal information. To make such a request, please use the <u>contact details</u> provided below. We will
          consider and act upon any request in accordance with applicable data protection laws.
        </p>
        <p>
          If we are relying on your consent to process your personal information, you have the right to withdraw your
          consent at any time. Please note however that this will not affect the lawfulness of the processing before its
          withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful
          processing grounds other than consent.
        </p>
        <p>
          If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information,
          you also have the right to complain to your local data protection supervisory authority. You can find their
          contact details here:{" "}
          <a href="http://ec.europa.eu/justice/data-protection/bodies/ authorities/index_en.htm" rel="nofollow">
            http://ec.europa.eu/justice/data-protection/bodies/ authorities/index_en.htm
          </a>
          .
        </p>
        <p>
          If you are a resident in Switzerland, the contact details for the data protection authorities are available
          here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" rel="nofollow">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
        </p>
        <p>If you have questions or comments about your privacy rights, you may email us at hello@ibench.in.</p>
        <br />

        <h4>Account Information</h4>
        <p>
          If you would at any time like to review or change the information in your account or terminate your account, you
          can:
        </p>
        <ul>
          <li>Contact us using the contact information provided.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or delete your account and information from our
          active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems,
          assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
        </p>
        <p>
          <b>
            <u>Cookies and similar technologies:</u>
          </b>{" "}
          Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your
          browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could
          affect certain features or services of our Website. To opt-out of interest-based advertising by advertisers on
          our Website visit <a href="http://www.aboutads.info/choices/" rel="nofollow">http://www.aboutads.info/choices/</a>. For further
          information, please see our Cookie Notice:{" "}
          <a href="https://ibench.in/cookie-policy">https://ibench.in/cookie-policy</a>.
        </p>
        <p>
          <b>
            <u>Opting out of email marketing:</u>
          </b>{" "}
          You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails
          that we send or by contacting us using the details provided below. You will then be removed from the marketing
          email list — however, we may still communicate with you, for example to send you service-related emails that are
          necessary for the administration and use of your account, to respond to service requests, or for other
          non-marketing purposes. To otherwise opt-out, you may:
          <ul>
            <li>Contact us using the contact information provided.</li>
          </ul>
        </p>
        <br />
        <br />

        <h2 id="9">9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
        <p>
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT")
          feature or setting you can activate to signal your privacy preference not to have data about your online
          browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and
          implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any
          other mechanism that automatically communicates your choice not to be tracked online. If a standard for online
          tracking is adopted that we must follow in the future, we will inform you about that practice in a revised
          version of this privacy notice.
        </p>
        <br />
        <br />

        <h2 id="10">10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
        <p>
          <i>
            <b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding access to
            your personal information.
          </i>
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are
          California residents to request and obtain from us, once a year and free of charge, information about categories
          of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
          addresses of all third parties with which we shared personal information in the immediately preceding calendar
          year. If you are a California resident and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a registered account with the Website, you have
          the right to request removal of unwanted data that you publicly post on the Website. To request removal of such
          data, please contact us using the contact information provided below, and include the email address associated
          with your account and a statement that you reside in California. We will make sure the data is not publicly
          displayed on the Website, but please be aware that the data may not be completely or comprehensively removed
          from all our systems (e.g. backups, etc.).
        </p>
        <br />

        <h4>CCPA Privacy Notice</h4>
        <p>The California Code of Regulations defines a "resident" as:</p>
        <p>(1) every individual who is in the State of California for other than a temporary or transitory purpose and</p>
        <p>
          (2) every individual who is domiciled in the State of California who is outside the State of California for a
          temporary or transitory purpose
        </p>
        <p>All other individuals are defined as "non-residents."</p>
        <p>
          If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your
          personal information.
        </p>
        <h6>What categories of personal information do we collect?</h6>
        <p>We have collected the following categories of personal information in the past twelve (12) months:</p>
        <table className={`table ${styles.table}`}>
          <thead>
            <tr>
              <td>Category</td>
              <td>Examples</td>
              <td>Collected</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A. Identifiers</td>
              <td>
                Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique
                personal identifier, online identifier, Internet Protocol address, email address and account name
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>B. Personal information categories listed in the California Customer Records statute</td>
              <td>Name, contact information, education, employment, employment history and financial information</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>C. Protected classification characteristics under California or federal law</td>
              <td>Gender and date of birth</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>D. Commercial information</td>
              <td>Transaction information, purchase history, financial details and payment information</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>E. Biometric information</td>
              <td>Fingerprints and voiceprints</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>F. Internet or other similar network activity</td>
              <td>
                Browsing history, search history, online behavior, interest data, and interactions with our and other
                websites, applications, systems and advertisements
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>G. Geolocation data</td>
              <td>Device location</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
              <td>Images and audio, video or call recordings created in connection with our business activities</td>
              <td>YES</td>
            </tr>
            <tr>
              <td>I. Professional or employment-related information</td>
              <td>
                Business contact details in order to provide you our services at a business level, job title as well as
                work history and professional qualifications if you apply for a job with us
              </td>
              <td>NO</td>
            </tr>
            <tr>
              <td>J. Education Information</td>
              <td>Student records and directory information</td>
              <td></td>
            </tr>
            <tr>
              <td>K. Inferences drawn from other personal information</td>
              <td>
                Inferences drawn from any of the collected personal information listed above to create a profile or
                summary about, for example, an individual’s preferences and characteristics
              </td>
              <td>YES</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          We may also collect other personal information outside of these categories instances where you interact with us
          in-person, online, or by phone or mail in the context of:
        </p>
        <ul>
          <li>Receiving help through our customer support channels;</li>
          <li>Participation in customer surveys or contests; and</li>
          <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
        </ul>
        <br />

        <h4>How do we use and share your personal information?</h4>
        <p>Linenberger Group LTD. collects and shares your personal information through:</p>
        <ul>
          <li>Targeting cookies/Marketing cookies</li>
          <li>Social media cookies</li>
          <li>Beacons/Pixels/Tags</li>
        </ul>
        <p>
          More information about our data collection and sharing practices can be found in this privacy notice and our
          Cookie Notice: <a href="https://ibench.in/cookie-policy">https://ibench.in/cookie-policy</a>.
        </p>
        <p>
          You may contact us by email at <a href="mailto:hello@ibench.in">hello@ibench.in</a>, or by referring to
          the contact details at the bottom of this document.
        </p>
        <p>
          If you are using an authorized agent to exercise your right to opt-out we may deny a request if the authorized
          agent does not submit proof that they have been validly authorized to act on your behalf.
        </p>
        <br />

        <h4>Will your information be shared with anyone else?</h4>
        <p>
          We may disclose your personal information with our service providers pursuant to a written contract between us
          and each service provider. Each service provider is a for-profit entity that processes the information on our
          behalf.
        </p>
        <p>
          We may use your personal information for our own business purposes, such as for undertaking internal research
          for technological development and demonstration. This is not considered to be "selling" of your personal data.
        </p>
        <p>
          Linenberger Group LTD. has disclosed the following categories of personal information to third parties for a business
          or commercial purpose in the preceding twelve (12) months:
        </p>
        <ul>
          <li>
            Category B. Personal information, as defined in the California Customer Records law, such as your name,
            contact information, education, employment, employment history and financial information.
          </li>
          <li>Category G. Geolocation data, such as device location.</li>
          <li>
            Category H. Audio, electronic, visual and similar information, such as images and audio, video or call
            recordings created in connection with our business activities.
          </li>
          <li>Category J. Education information, such as student records and directory information.</li>
        </ul>
        <p>
          The categories of third parties to whom we disclosed personal information for a business or commercial purpose
          can be found under "<u>WHO WILL YOUR INFORMATION BE SHARED WITH?</u>".
        </p>
        <p>
          Linenberger Group LTD. has not sold any personal information to third parties for a business or commercial purpose in
          the preceding twelve (12) months. Linenberger Group LTD. will not sell personal information in the future belonging to
          website visitors, users and other consumers.
        </p>
        <br />

        <h4>Your rights with respect to your personal data</h4>
        <p>
          <u>Right to request deletion of the data - Request to delete</u>
        </p>
        <p>
          You can ask for the deletion of your personal information. If you ask us to delete your personal information, we
          will respect your request and delete your personal information, subject to certain exceptions provided by law,
          such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation or any processing that may be required to protect against illegal
          activities.
        </p>
        <p>
          <u>Right to be informed - Request to know</u>
        </p>
        <p>Depending on the circumstances, you have a right to know:</p>
        <ul>
          <li>whether we collect and use your personal information;</li>
          <li>the categories of personal information that we collect;</li>
          <li>the purposes for which the collected personal information is used;</li>
          <li>whether we sell your personal information to third parties;</li>
          <li>the categories of personal information that we sold or disclosed for a business purpose;</li>
          <li>
            the categories of third parties to whom the personal information was sold or disclosed for a business purpose;
            and
          </li>
          <li>the business or commercial purpose for collecting or selling personal information</li>
        </ul>
        <p>
          In accordance with applicable law, we are not obligated to provide or delete consumer information that is
          de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
        </p>
        <p>
          <u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</u>
        </p>
        <p>We will not discriminate against you if you exercise your privacy rights.</p>
        <p>
          <u>Verification process</u>
        </p>
        <p>
          Upon receiving your request, we will need to verify your identity to determine you are the same person about
          whom we have the information in our system. These verification efforts require us to ask you to provide
          information so that we can match it with information you have previously provided us. For instance, depending on
          the type of request you submit, we may ask you to provide certain information so that we can match the
          information you provide with the information we already have on file, or we may contact you through a
          communication method (e.g. phone or email) that you have previously provided to us. We may also use other
          verification methods as the circumstances dictate.
        </p>
        <p>
          We will only use personal information provided in your request to verify your identity or authority to make the
          request. To the extent possible, we will avoid requesting additional information from you for the purposes of
          verification. If, however, we cannot verify your identity from the information already maintained by us, we may
          request that you provide additional information for the purposes of verifying your identity, and for security or
          fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying
          you.
        </p>
        <p>
          <u>Other privacy rights</u>
        </p>
        <ul>
          <li>you may object to the processing of your personal data</li>
          <li>
            you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict
            the processing of the data
          </li>
          <li>
            you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request
            from an authorized agent that does not submit proof that they have been validly authorized to act on your
            behalf in accordance with the CCPA
          </li>
          <li>
            you may request to opt-out from future selling of your personal information to third parties. Upon receiving a
            request to opt-out, we will act upon the request as soon as feasibly possible, but no later than 15 days from
            the date of the request submission
          </li>
        </ul>
        <p>
          To exercise these rights, you can contact us by email at{" "}
          <a href="mailto:hello@ibench.in">hello@ibench.in</a>, or by referring to the contact details at the bottom
          of this document. If you have a complaint about how we handle your data, we would like to hear from you.
        </p>
        <br />
        <br />

        <h2 id="11">11. DO WE MAKE UPDATES TO THIS NOTICE? </h2>
        <p>
          <i>
            <b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.
          </i>
        </p>
        <p>
          We may update this privacy notice from time to time. The updated version will be indicated by an updated
          "Revised" date and the updated version will be effective as soon as it is accessible. If we make material
          changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by
          directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of
          how we are protecting your information.
        </p>
        <br />
        <br />

        <h2 id="12">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
        <p>
          If you have questions or comments about this notice, you may email us at{" "}
          <a href="mailto:hello@ibench.in">hello@ibench.in</a> or by post to:
        </p>
        <p>
          LINENBERGER GROUP LTD
          <br />
          4-8 Ludgate Circus, London, England, EC4M 7LF
        </p>
        <br />
        <br />

        <h2 id="13">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
        <p>
          Based on the applicable laws of your country, you may have the right to request access to the personal
          information we collect from you, change that information, or delete it in some circumstances. To request to
          review, update, or delete your personal information, please visit:{" "}
          <a href="https://ibench.in">https://ibench.in</a>.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
