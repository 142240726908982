import React from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import { acceptance, length } from "redux-form-validators";
import cn from "classnames";

import { Checkbox, CheckboxSwitch, Textarea } from "../../common/FormsControls/FormControls";

import styles from "./Relocation.module.css";
import { Link } from "react-router-dom";

const formName = "relocationForm";

const RelocationForm = (props) => {
  const {
    handleSubmit,
    initialValues,
    dispatch,
  } = props;

  const saveHandler = () => {
    dispatch(change(formName, "url_redirect"));
  };

  const nextPageHandler = () => {
    dispatch(change(formName, "url_redirect", "/profile/edit/social"));
  };

  const previousPageHandler = () => {
    dispatch(change(formName, "url_redirect", "/profile/edit/skills"));
  };

  return (
    <form onSubmit={handleSubmit} className={styles.relocation_form}>
      <div className={styles.fields_wrapper}>
        <Field
          className={styles.textarea}
          component={Textarea}
          name="degree"
          label="Do you have a Ph.D. degree, or any other degree? What degree do you hold?"
          validate={[length({ maximum: 512 })]}
        />
        <Field
          className={styles.textarea}
          component={Textarea}
          name="prize_winner"
          label="Are you a prize-winner in international olympiads?"
          validate={[length({ maximum: 512 })]}
        />
        <Field
          className={styles.textarea}
          component={Textarea}
          name="tried_relocating"
          label="Have you already tried relocating?"
          validate={[length({ maximum: 512 })]}
        />

        <div className={styles.checkbox_wrapper}>
          <Field
            component={Checkbox}
            name="relocate_to_usa"
            label="USA"
            className={styles.checkbox}
            defaultChecked={initialValues.relocate_to_usa}
          />
        </div>

        <div className={styles.checkbox_wrapper}>
          <Field
            component={Checkbox}
            name="relocate_to_sweden"
            label="Sweden"
            className={styles.checkbox}
            defaultChecked={initialValues.relocate_to_sweden}
          />
        </div>

        <div className={styles.checkbox_wrapper}>
          <Field
            component={Checkbox}
            name="relocate_to_britain"
            label="Great Britain"
            className={styles.checkbox}
            defaultChecked={initialValues.relocate_to_britain}
          />
        </div>

        <div className={styles.switchWrapper}>
          <Field
            component={CheckboxSwitch}
            name="ready_to_relocate"
            label="Are you ready<br /> to relocate?"
            defaultChecked={initialValues.ready_to_relocate}
            labelClassName={styles.switchLabel}
          />
        </div>
        <div className={styles.switchWrapper}>
          <Field
            component={CheckboxSwitch}
            name="send_data_to_partners"
            label="Send your details to<br /> our partners?"
            defaultChecked={initialValues.send_data_to_partners}
            labelClassName={styles.switchLabel}
          />
        </div>

        <div className={styles.submit_wrapper}>
          <button className={cn("btn", styles.submit)} onClick={saveHandler}>
            Save
          </button>
        </div>

        <div className={cn(styles.submit_wrapper)}>
          <button className={cn("btn", styles.submit, styles.yellow)} onClick={previousPageHandler}>
            Previous
          </button>
          <button className={cn("btn", styles.submit, styles.yellow)} onClick={nextPageHandler}>
            Next
          </button>
        </div>
      </div>

    </form>
  );
};

let RelocationReduxForm = reduxForm({
  form: formName,
  enableReinitialize: true,
})(RelocationForm);

const mapStateToProps = (state) => ({
  initialValues: state.auth.company_info,
});

export default connect(mapStateToProps)(RelocationReduxForm);