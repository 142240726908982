import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import { getProjectsList } from "../../store/cases";
import Projects from "./Projects";

const ProjectsContainer = (props) => {
    useEffect(() => {
        props.getProjectsList();
    }, []);

    return (
        <Projects
            companyId={props.companyId}
            isFetching={props.cases.isFetching}
            projectsList={props.cases.projectsList}
        />
    );
};

let mapStateToProps = (state) => ({
    companyId: state.auth.company_info.id,
    cases: state.cases,
});

export default compose(
    connect(mapStateToProps, {
        getProjectsList,
    }),
    withAuthRedirect,
)(ProjectsContainer);
