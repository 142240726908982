import React from "react";

const LandingTwoCalendly = () => {
  setTimeout(() => {
    window.location.href = "https://calendly.com/ibenchdenis/one2one";
  }, 1000);
  return <div />;
};

export default LandingTwoCalendly;
