import React from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

import { siteNameForTitle, defaultDescription } from "../../constants";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import landing3 from "../../assets/content_img/landing/landing-3.png";
import landing4 from "../../assets/content_img/landing/landing-4.png";
import landingTwo1 from "../../assets/content_img/landing/landing-two-1.png";
import landingTwo2 from "../../assets/content_img/landing/landing-two-2.png";
import landingTwo3 from "../../assets/content_img/landing/landing-two-3.png";
import polygonRegularPink from "../../assets/img/polygon-regular-pink.svg";
import polygonRegularGreen from "../../assets/img/polygon-regular-green.svg";
import polygonRegularPurple from "../../assets/img/polygon-regular-purple.svg";
import polygonRegularYellow from "../../assets/img/polygon-regular-yellow.svg";
import polygonSolidPink from "../../assets/img/polygon-solid-pink.svg";
import polygonSolidGreen from "../../assets/img/polygon-solid-green.svg";
import polygonSolidPurple from "../../assets/img/polygon-solid-purple.svg";
import polygonSolidYellow from "../../assets/img/polygon-solid-yellow.svg";
import restaurantsAndCafesIcon from "../../assets/img/restaurants-and-cafes.svg";
import servicesIcon from "../../assets/img/services.svg";
import automotiveBusinessIcon from "../../assets/img/automotive-business.svg";
import beauryIndustryIcon from "../../assets/img/beauty-industry.svg";
import deliveryServicesIcon from "../../assets/img/delivery-services.svg";
import fitnessIcon from "../../assets/img/fitness.svg";
import leisureIcon from "../../assets/img/leisure.svg";
import medicineIcon from "../../assets/img/medicine.svg";
import shopsIcon from "../../assets/img/shops.svg";
import tourismIcon from "../../assets/img/tourism.svg";

import styles from "./LandingTwo.module.css";

const LandingTwo = () => {
  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{siteNameForTitle} / Have an idea - iBench - is the best way to turn it into reality!</title>
        <meta
          name="description"
          content={defaultDescription}
        />
        <meta
          name="keywords"
          content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
          data-react-helmet="true"
        />
      </Helmet>
      <Breadcrumbs
        className={styles.breadcrumbs}
        currentPage="Have an idea - iBench - is the best way to turn it into reality!"
      />

      <section className={styles.section}>
        <div className={styles.sectionBlock}>
          <h1>
            <span className={styles.yellowText}>Have an idea</span>
            &nbsp;- iBench - is the best way to&nbsp;
            <span className={styles.purpleText}>turn it into reality!</span>
          </h1>
          <div className={styles.buttonsBlock}>
            <NavLink to="/registration" className={cn(styles.btn, "btn_2", "btn_purple")}>
              Sign up
            </NavLink>
            <a
              href="/website-and-mobile-app-development/calendly"
              className={cn(styles.btn, "btn_2", "btn_pink")}
              target="_blank"
              rel="nofollow"
            >
              Demo day
            </a>
          </div>
        </div>
        <div className={cn(styles.sectionBlock, styles.mobileHidden)}>
          <img
            src={landingTwo1}
            className={styles.buildWebsiteImg}
            alt="Have an idea"
          />
        </div>
      </section>
      <section className={styles.section}>
        <div className={cn(styles.sectionBlock, styles.mt50)}>
          <p className={styles.middleText}>
            <span className={styles.sectionTitle}>Your plate is full – you do have an acute issue</span>&nbsp;
            of time, budget, team, as well as many tasks and processes?
          </p>
        </div>
        <div className={styles.sectionBlock}>
          <p className={styles.polygonText}>
            <img src={polygonRegularPurple} className={styles.polygonIcon} alt="1"/>
            <span className={styles.bold}>iBench is an effective tool</span> for solving your business problems.
          </p>
          <p className={styles.polygonText}>
            <img src={polygonRegularYellow} className={styles.polygonIcon} alt="2"/>
            <span className={styles.bold}>Do you want to sell or provide services?</span>&nbsp;
            Website, catalog, landing page or online store.
          </p>
          <p className={styles.polygonText}>
            <img src={polygonRegularGreen} className={styles.polygonIcon} alt="3"/>
            <span className={styles.bold}>DIs the site going to inform your customers?</span>&nbsp;
            Corporate website or portal - your choice. You can add a blog or forum.
          </p>
          <p className={styles.polygonText}>
            <img src={polygonRegularPink} className={styles.polygonIcon} alt="4"/>
            <span className={styles.bold}>Are you going to provide a specific service via the Internet.</span>&nbsp;
            It will be a great solution to combine a landing page and a website - conveniently and efficiently solution.
          </p>
        </div>
      </section>

      <section className={styles.section}>
        <div className={cn(styles.sectionBlock, styles.mt35)}>
          <p className={styles.middleBoldText}>
            One of the salient features of mobile apps for business is
          </p>
          <p>
            that it offers awareness and recognition of your brand to the customers for an enhanced communication.
          </p>
          <p>
            It is this regular interaction with your target market,&nbsp;
            <span className={styles.bold}>
              which sows the seeds to grow trust and belief between you and your customers.
            </span>
          </p>
          <p>
            With this tool <span className={styles.bold}>is possible to increase sales in:</span>
          </p>
          <div className={styles.items}>
            <div className={styles.smallText}>
              <img src={restaurantsAndCafesIcon} className={styles.icon} />
              restaurants and cafes
            </div>
            <div className={styles.smallText}>
              <img src={deliveryServicesIcon} className={styles.icon} />
              delivery services
            </div>
            <div className={styles.smallText}>
              <img src={tourismIcon} className={styles.icon} />
              tourism
            </div>
            <div className={styles.smallText}>
              <img src={shopsIcon} className={styles.icon} />
              shops
            </div>
            <div className={styles.smallText}>
              <img src={medicineIcon} className={styles.icon} />
              medicine
            </div>
            <div className={styles.smallText}>
              <img src={leisureIcon} className={styles.icon} />
              leisure
            </div>
            <div className={styles.smallText}>
              <img src={automotiveBusinessIcon} className={styles.icon} />
              automotive business
            </div>
            <div className={styles.smallText}>
              <img src={fitnessIcon} className={styles.icon} />
              fitness
            </div>
            <div className={styles.smallText}>
              <img src={beauryIndustryIcon} className={styles.icon} />
              beauty industry
            </div>
            <div className={styles.smallText}>
              <img src={servicesIcon} className={styles.icon} />
              services
            </div>
          </div>
        </div>
        <div className={cn(styles.sectionBlock, styles.mobileHidden)}>
          <img
            src={landing3}
            alt="Vacant"
          />
        </div>
      </section>
      <section className={cn(styles.section, styles.youCanTry)}>
        <div className={styles.sectionBlock}>
          <img src={landingTwo2} alt="Mobile app" />
        </div>
        <div className={cn(styles.sectionBlock, styles.mt70)}>
          <p className={styles.middleBoldText}>
            You can try to dive into all the stages of creating a project by yourself -
          </p>
          <p>
            if you’re managing a team or program, you likely have multiple projects in progress at the same time.
          </p>
          <p className={styles.polygonText}>
            <img src={polygonRegularPurple} className={styles.polygonIcon} alt="1"/>
            But keeping track of all the moving pieces can get complicated, and fast. Without a good process for
            managing multiple projects, it’s hard to know what work to prioritize.

          </p>
          <p className={styles.polygonText}>
            <img src={polygonRegularYellow} className={styles.polygonIcon} alt="2"/>
            But is it much easier and more efficient to entrust this work to specialists with many years of experience?
          </p>
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.sectionBlock}>
          <p className={styles.middleBoldText}>
            iBench is a platform that brings together the best development companies in the world
          </p>
          <p>
            with experience in implementing most of the existing tasks related to the development of websites and
            iOS/Android mobile applications.
          </p>
        </div>
        <div className={cn(styles.sectionBlock, styles.ibenchPlatformImage)}>
          <img src={landing4} alt="Platform" />
        </div>
      </section>

      <section className={styles.whyYouNeed}>
        <p className={cn(styles.middleBoldText, styles.mt25)}>
          Why do you need iBench?
        </p>
        <div className={styles.needItems}>
          <div className={styles.needItem}>
            <div className={styles.polygonSolid}>
              <img src={polygonSolidPurple} className={styles.polygonIcon} alt="1"/>
              <span>1</span>
            </div>
            <p>
              <span className={styles.bold}>You can place your project on our platform free</span> of charge and it
              will be available according to your choice - either to selected categories or to all participants of the
              platform.
            </p>
          </div>
          <div className={styles.needItem}>
            <div className={styles.polygonSolid}>
              <img src={polygonSolidPink} className={styles.polygonIcon} alt="2"/>
              <span>2</span>
            </div>
            <p>
              <span className={styles.bold}>Development companies by their own offer their services.</span>&nbsp;
              It is convenient and fast, since everything is placed in one spot, and it takes less time to find what you
              need. You comfortably choose the artist according to your own criteria.
            </p>
          </div>
          <div className={styles.needItem}>
            <div className={styles.polygonSolid}>
              <img src={polygonSolidYellow} className={styles.polygonIcon} alt="3"/>
              <span>3</span>
            </div>
            <p>
              <span className={styles.bold}>The number of our contractors constantly growing</span>, so it is possible
              to solve a problem of any complexity: from a simple website to a multi-level corporate portal / mobile
              application.
            </p>
          </div>
          <div className={styles.needItem}>
            <div className={styles.polygonSolid}>
              <img src={polygonSolidGreen} className={styles.polygonIcon} alt="4"/>
              <span>4</span>
            </div>
            <p>
              <span className={styles.bold}>With iBench, you can solve the problem</span> of creating a website
              or mobile application with minimal efforts and maximum time savings.
            </p>
          </div>
        </div>
      </section>
      <section className={styles.section}>
        <div className={cn(styles.sectionBlock, styles.mtm50)}>
          <img src={landingTwo3} alt="iBench" />
        </div>
        <div className={cn(styles.sectionBlock, styles.whyWeTrusted)}>
          <p className={styles.middleBoldText}>
            Why we are trusted?
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.yellowIcon)} icon={faCheck} />
            <span className={styles.bold}>Now there are over 350 companies</span> from all over the world on the
            platform and their number is increasing every day;
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.yellowIcon)} icon={faCheck} />
            <span className={styles.bold}>You have an opportunity to choose a verified company;</span>
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.yellowIcon)} icon={faCheck} />
            <span className={styles.bold}>You work with the contractor directly</span>, without intermediaries
            and additional commissions;
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.yellowIcon)} icon={faCheck} />
            <span className={styles.bold}>Already, our clients are respected companies</span> from Germany, Sweden,
            Poland, Belgium, Estonia, Israel, the United States of America, Canada.
          </p>
        </div>
      </section>

      <section className={styles.section}>
        <div className={cn(styles.sectionBlock)}>
          <p className={styles.middleText}>
            <span className={styles.sectionTitle}>
              Are you ready to get your best development service with iBench?
            </span>&nbsp;
            Explore maximum satisfaction with the result!
          </p>
        </div>
        <div className={styles.sectionBlock}>
          <p className={styles.middleText}>
            Make your choice right now:
          </p>
          <div className={styles.buttonsBlock}>
            <NavLink to="/registration" className={cn(styles.btn, "btn_2", "btn_purple")}>
              Sign up
            </NavLink>
            <a
              href="https://calendly.com/ibenchdenis/one2one"
              className={cn(styles.btn, "btn_2", "btn_pink")}
              target="_blank"
              rel="nofollow"
            >
              Demo day
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingTwo;
