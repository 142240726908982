import React, { useEffect } from "react";

import { dataWasSaved } from "../../../utils/notifications";
import TalentBaseInfoForm from "./TalentBaseInfoForm";

const TalentProfileEditing = (props) => {
  const {
    getCountriesList,
    editProfile,
    auth: { company_info }
  } = props;

  useEffect(() => {
    getCountriesList();
  }, []);

  const onSubmit = (formData) => {

    const userData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      open_to_work: formData.open_to_work !== "false" && !!formData.open_to_work,
      start_in: formData.start_in,
      description: formData.description,
      official_email: formData.official_email,
      business_phone: formData.business_phone,
      country: formData.country,
      city: formData.city,
      url_redirect: formData.url_redirect,
    };

    if (formData.representative_photo?.length && typeof formData.representative_photo?.[0] !== "string") {
      userData.representative_photo = formData.representative_photo;
    }

    if (company_info.profile_fill_step < 1) {
      userData.profile_fill_step = 1;
    }

    editProfile(userData, () => {
      if (!formData.url_redirect) {
        dataWasSaved();
      }
    });
  };

  return (
    <TalentBaseInfoForm onSubmit={onSubmit} />
  );
};

export default TalentProfileEditing;
