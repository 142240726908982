import qs from "qs";
import { axiosInstances } from "./api";

const axiosInstance = axiosInstances.account;

export const outsourceAPI = {
  getOutsourceSearchList() {
    return axiosInstance.get(`api/users/search/outsource`).then((response) => {
      return response.data;
    });
  },

  getSearch(searchId) {
    return axiosInstance.get(`api/users/search/outsource?search_id=${searchId}`)
      .then(({ data }) => {
        if (data?.searches?.[0]?.location) {
          data.searches[0].location = data.searches[0].location.split(", ");
        }
        return data;
      });
  },

  getSearchResults(searchId) {
    return axiosInstance.get(`api/users/search/outsource/results?search_id=${searchId}`).then((response) => {
      return response.data;
    });
  },

  addSearch(data) {
    return axiosInstance.post(`api/users/search/outsource`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  editSearch(data) {
    return axiosInstance.put(`api/users/search/outsource`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  deleteSearch(data) {
    return axiosInstance.delete(`api/users/search/outsource`, { data: qs.stringify(data) }).then((response) => {
      return response.data;
    });
  },

  startChat(data) {
    return axiosInstance.post(`api/users/companies/outsource/projects`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },
};

export const outsourceMarketsAPI = {
  addMarket(data) {
    return axiosInstance.post(`api/users/search/outsource/markets`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  deleteMarket(data) {
    return axiosInstance
      .delete(`api/users/search/outsource/markets`, { data: qs.stringify(data) })
      .then((response) => {
        return response.data;
      });
  },
};
