import React from "react";
import cn from "classnames";

import { DropdownIdName } from "../../common/FormsControls/FormControls";

import styles from "./TopCompaniesSelects.module.css";

const TopCompaniesSelects = (props) => {
  const {
    onChangeIndustry,
    onChangePopularService,
    industries,
    popularServices,
    selectedIndustryId,
    selectedPopularServiceId,
    hideIndustries = false,
    className,
  } = props;
  return (
    <div className={cn(styles.topCompaniesSelects, className)}>
      {!hideIndustries && (
        <div className={styles.selectWrapper}>
          <DropdownIdName
            className={styles.select}
            options={industries}
            label="Industry"
            value={selectedIndustryId}
            onChange={onChangeIndustry}
          />
        </div>
      )}
      <div className={styles.selectWrapper}>
        <DropdownIdName
          className={styles.select}
          options={popularServices}
          label="Popular services"
          value={selectedPopularServiceId}
          onChange={onChangePopularService}
        />
      </div>
    </div>
  );
};

export default TopCompaniesSelects;
