import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { editTechnologies } from "../../../store/auth";
import ProfileTechnologiesEditing from "./ProfileTechnologiesEditing";

const ProfileTechnologiesEditingContainer = (props) => {
  return <ProfileTechnologiesEditing {...props} editTechnologies={props.editTechnologies} />;
};

let mapStateToProps = state => ({
  auth: state.auth,
});

export default compose(
  connect(mapStateToProps, {
    editTechnologies,
  }),
  withAuthRedirect
)(ProfileTechnologiesEditingContainer);
