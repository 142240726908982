import React from "react";
import moment from "moment";

import styles from "./NewsModal.module.css";

const NewsModal = ({ gotItNews, news }) => (
  <div className={styles.newsModal}>
    <div className={styles.modalHeader}>
      iBench Update
    </div>
    <div className={styles.modalBody}>
      {news.map((newsData) => (
        <div className={styles.newsWrapper} key={newsData.news_id}>
          <div className={styles.newsTitle}>
            {newsData.subject}
          </div>
          <div className={styles.newsPublishDate}>
            {moment(newsData.publish_date).format("DD MMM YYYY")}
          </div>
          <div
            className={styles.newsContent}
            dangerouslySetInnerHTML={{ __html: newsData.message}}
          />
        </div>
      ))}
    </div>
    <div className={styles.termsWrapper}>
      <button className={`btn ${styles.submit}`} onClick={gotItNews}>Got it!</button>
    </div>
  </div>
);

export default NewsModal;
