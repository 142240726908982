import React from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm, formValueSelector } from "redux-form";
import cn from "classnames";

import { CheckboxSwitch, SelectInput, SelectInputKeyValue } from "../../common/FormsControls/FormControls";
import TelegramBot from "../ProfileSocialEditing/TelegramBot";

import styles from "./TalentSettingsForm.module.css";
import { required } from "redux-form-validators";

const formName = "settingsForm";

const TalentSettingsForm = (props) => {
  let {
    initialValues,
    handleSubmit,
    positionLevels,
    profile,
    dispatch,
  } = props;

  const saveHandler = () => {
    dispatch(change(formName, "url_redirect"));
  };

  const previousPageHandler = () => {
    dispatch(change(formName, "url_redirect", "/profile/edit/social"));
  };

  const renderButtons = () => {
    if (profile) {
      return (
        <>
          <div className={styles.submit_wrapper}>
            <button className={cn("btn", styles.submit)} onClick={saveHandler}>
              Save
            </button>
          </div>

          <div className={cn(styles.submit_wrapper)}>
            <button className={cn("btn", styles.submit, styles.yellow)} onClick={previousPageHandler}>
              Previous
            </button>
          </div>
        </>
      );
    }

    return <button className={`btn ${styles.submit}`}>Save</button>;
  };

  return (
    <form onSubmit={handleSubmit} className={styles.skills_form}>
      <div className={styles.fields_wrapper}>
        <div className={cn(styles.field_label)}>
          Your position from Skills&Rates
        </div>
        <div className={styles.tob_title}>
          <div>
            <strong>Main</strong>: Fullstack developer
          </div>
          <div>
            <strong>Additional</strong>: Frontend developer
          </div>
        </div>
        <div className={cn(styles.field_label)}>
          Seniority level
        </div>

        <div className={styles.fields_row}>
          <Field
            input_wrapper_class={styles.select_wrapper}
            component={SelectInputKeyValue}
            name="alert_position_level_id"
            label="Seniority level*"
            validate={[required()]}
            options={positionLevels}
            help_text="for example: Senior"
            help_text_design={styles.help_text}
          />
        </div>
        <TelegramBot className={styles.telegramBot} />
        <div className={styles.switchWrapper}>
          <Field
            component={CheckboxSwitch}
            name="receive_emails"
            label="Daily email updates"
            defaultChecked={initialValues.receive_emails}
            labelClassName={styles.switchLabel}
          />
        </div>
        <div className={styles.switchWrapper}>
          <Field
            component={CheckboxSwitch}
            name="receive_telegram"
            label="Daily Telegram updates"
            defaultChecked={initialValues.receive_telegram}
            labelClassName={styles.switchLabel}
          />
        </div>

        {renderButtons()}
      </div>
    </form>
  );
};

const selector = formValueSelector(formName);

const TalentSettingsReduxForm = reduxForm({
  form: formName,
  enableReinitialize: true,
})(TalentSettingsForm);

const mapStateToProps = (state) => ({
  initialValues: {
    ...state.auth.company_info,
    receive_emails: !!state.auth.company_info.receive_emails,
    receive_telegram: !!state.auth.company_info.receive_telegram,
  },
  skillsList: state.app.skills_list,
  skillId: selector(state, 'skill'),
  alerts: selector(state, 'alerts'),
  positionLevels: state.developer.position_levels,
});

export default connect(mapStateToProps)(TalentSettingsReduxForm);