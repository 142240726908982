import React from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import cn from "classnames";

import { renderMultiselect } from "../../common/FormsControls/FormControls";
import FormIndicator from "./FormIndicator/FormIndicator";

import styles from "./TeamSearch.module.css";

const SearchForm = (props) => {
  const addToSearch = (market) => {
    let markets = props.markets ? [...props.markets] : [];
    // check if market wasn't added, then add it
    let marketIndex = markets.findIndex((item) => item.market_id === market.market_id);
    if (marketIndex === -1) {
      markets.push(market);
      props.dispatch(change("team-search", "markets", markets));
    }
  };

  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <FormIndicator page={props.page} />

      <div className={styles.multiselect_wrapper}>
        <Field
          name="markets"
          component={renderMultiselect}
          validate={[required()]}
          data={props.markets_list}
          valueField="market_id"
          textField="market_name"
          placeholder="Add a product type"
        />
      </div>
      {props.error && <div className={`${styles.form_erros} text_center validation_message`}>{props.error}</div>}
      <div className={styles.popular_searches}>
        <div className={styles.popular_searches_label}>Popular searches:</div>
        <div className={styles.popular_searches_list}>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ market_id: 9, market_name: "Blockchains" })}
          >
            Blockchains
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ market_id: 37, market_name: "Mobile application" })}
          >
            Mobile application
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ market_id: 47, market_name: "Web Development" })}
          >
            Web Development
          </div>
        </div>
      </div>
      <div className={`${styles.submit_wrapper} text_center`}>
        <button className={cn(styles.submit, "btn_2", "btn_purple", "mobile_hidden")}>Next step</button>
        <button className={cn(styles.submit, "btn_2", "btn_purple", "mobile_visible")}>Next</button>
      </div>
    </form>
  );
};

let ReduxForm = reduxForm({
  form: "team-search",
  destroyOnUnmount: false,
})(SearchForm);

const formSelector = formValueSelector("team-search");
ReduxForm = connect((state) => {
  const markets = formSelector(state, "markets");
  return { markets };
})(ReduxForm);

export default ReduxForm;
