import qs from "qs";
import { axiosFileInstances, axiosInstances, axiosJSONInstances } from "./api";

const axiosInstance = axiosInstances.account;
const axiosJSONInstance = axiosJSONInstances.account;
const axiosFileInstance = axiosFileInstances.account;

export const casesAPI = {
  getCasesList() {
    return axiosInstance.get(`api/users/companies/cases`).then((response) => {
      return response.data;
    });
  },

  getCasesListById(companyId) {
    return axiosInstance.get(`api/users/companies/cases/information?company_id=${companyId}`).then((response) => {
      return response.data;
    });
  },

  addCase(data) {
    return axiosInstance.post(`api/users/companies/cases`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  editCase(data) {
    return axiosInstance.put(`api/users/companies/cases`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  updateTopCases(topCases) {
    return axiosJSONInstance.put(`api/users/companies/cases/update-top`, JSON.stringify({ top_cases: topCases }))
      .then((response) => {
        return response.data;
      });
  },

  deleteCase(data) {
    return axiosInstance.delete(`api/users/companies/cases`, { data: qs.stringify(data) }).then((response) => {
      return response.data;
    });
  },

  uploadFile(data) {
    return axiosFileInstance.post(`api/users/companies/cases/upload`, data).then((response) => {
      return response.data;
    });
  },

  deleteFile(data) {
    return axiosFileInstance
      .delete(`api/users/companies/cases/files`, { data: qs.stringify(data) })
      .then((response) => {
        return response.data;
      });
  },

  getProjectsList() {
    return axiosInstance.get(`api/users/companies/outsource/projects`).then((response) => {
      return response.data;
    });
  },

  getProjectById(projectId) {
    return axiosInstance.get(`api/users/companies/outsource/projects?project_id=${projectId}`).then((response) => {
      return response.data;
    });
  },
};

export const casesMarketsAPI = {
  addMarket(data) {
    return axiosInstance.post(`api/users/companies/cases/markets`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  deleteMarket(data) {
    return axiosInstance
      .delete(`api/users/companies/cases/markets`, { data: qs.stringify(data) })
      .then((response) => {
        return response.data;
      });
  },
};

export const casesSkillsAPI = {
  addSkill(data) {
    return axiosInstance.post(`api/users/companies/cases/skills`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  deleteSkill(data) {
    return axiosInstance
      .delete(`api/users/companies/cases/skills`, { data: qs.stringify(data) })
      .then((response) => {
        return response.data;
      });
  },
};
