import React from "react";
import { NavLink } from "react-router-dom";


import styles from "./SellLeadButton.module.css";

const SellLeadButton = () => {
  return (
    <>
      <NavLink
        to="/add-lead"
        className={styles.sellLeadsButton}
      />
      <div className={styles.arrowMessage}>
        New opportunity to earn money! Sell non-targeted leads!
      </div>
    </>
  );
};

export default SellLeadButton;
