import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { confirmEmail } from "../../store/auth";
import { siteNameForTitle } from "../../constants/global-constants";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import Preloader from "../common/Preloader/Preloader";

import confirmImage from "../../assets/img/confirm-email.png";
import canceledImage from "../../assets/img/canceled.png";
import styles from "./ConfirmationEmail.module.css";

const ConfirmationEmail = (props) => {
  const { auth, confirmEmail } = props;

  const [success, setSuccess] = useState(null);

  useEffect(() => {
    const otp = new URLSearchParams(window.location.search).get("otp");
    confirmEmail(otp, () => setSuccess(true), () => setSuccess(false))
  }, []);

  const renderSuccess = () => (
    <div className={styles.content}>
      <div className={styles.pageTitle}>
        Thank you for confirming your email.
      </div>
      <img className={styles.successImage} src={confirmImage} alt="Success"/>
      <div className={styles.recommendation}>
        We recommend adding a backup email to your company's profile
      </div>
      {auth.is_auth && (
        <Link to="/profile/edit" className={styles.button}>
          Add backup email
        </Link>
      )}
    </div>
  );

  const renderCanceled = () => (
    <div className={styles.content}>
      <div className={styles.errorTitle}>
        It seems that your link is broken, please contact our <a href="mailto:hello@ibench.in">support</a>
      </div>
      <img className={styles.successImage} src={canceledImage} alt="Canceled"/>
    </div>
  );

  const renderContent = () => {
    if (success === null) {
      return false;
    }

    return success ? renderSuccess() : renderCanceled();
  };

  return (
    <div className={styles.wrapper}>
      {auth.is_fetching && <Preloader />}
      <Helmet>
        <title>Confirmation email | {siteNameForTitle}</title>
      </Helmet>

      {auth.is_auth && <DashboardMenuContainer/>}

      <h1 className={styles.title}>Email confirmation</h1>

      {renderContent()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  confirmEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationEmail);
