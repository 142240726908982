import React from "react";
import cn from "classnames";

import styles from "./FormIndicator.module.css";

const FormIndicator = (props) => {
  const { page, className } = props;
  let price_class;

  switch (page) {
    case 1:
      price_class = "first";
      break;
    case 2:
      price_class = "second";
      break;
    case 3:
      price_class = "third";
      break;
    case 4:
      price_class = "fourth";
      break;
    case 5:
      price_class = "fifth";
      break;

    default:
      price_class = "first";
  }

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={styles.title}>Step {page} of 5</div>
      <div className={`${styles.indicator} ${styles[price_class]}`}>
        <div className={styles.indicator_item} />
        <div className={styles.indicator_item} />
        <div className={styles.indicator_item} />
        <div className={styles.indicator_item} />
        <div className={styles.indicator_item} />
      </div>
    </div>
  );
};

export default FormIndicator;
