import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie-player";

import { siteNameForTitle, defaultDescription } from "../../../constants";
import ResponseMessage from "./ResponseMessage";
import TeamSearchForm1 from "./TeamSearchForm1";
import TeamSearchForm2 from "./TeamSearchForm2";
import TeamSearchForm3 from "./TeamSearchForm3";
import TeamSearchForm4 from "./TeamSearchForm4";
import TeamSearchForm5 from "./TeamSearchForm5";
import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs";

import * as popular_team_animation from "../../../assets/animation/popular-team";
import styles from "./TeamSearch.module.css";

const TeamSearch = (props) => {
  const [page, setPage] = useState(1);

  const nextPage = () => setPage(page + 1);

  const onSubmit = (formData) => {
    let markets = "";
    formData.markets.forEach((market) => {
      markets += `${market.market_id},`;
    });
    markets = markets.slice(0, -1); // remove last comma

    let skills = "";
    formData.skills.forEach((skill) => {
      skills += `${skill.skill_id},`;
    });
    skills = skills.slice(0, -1); // remove last comma

    let newData = {
      ...formData,
      markets,
      skills,
      rentDuration: formData.rentDuration[0], // TODO fix it to string in input
    };

    props.sendTeamRequest(newData);
    setPage(6);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{siteNameForTitle} / Development team request form</title>
        <meta
          name="description"
          content={defaultDescription}
        />
        <meta
          name="keywords"
          content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
          data-react-helmet="true"
        />
      </Helmet>

      <Breadcrumbs className={styles.breadcrumbs} currentPage="Hire team" />

      <div className={styles.animationSection}>
        <div className={styles.leftColumn}>
          <h1 className={styles.desktopTitle}>
            <div className={styles.yellowFont}>Development team</div>
            request form
          </h1>
          <div className={styles.info}>
            We'll help you select a team that can complete your project on time, meet standards, and adhere to
            programming norms, from our pool of trusted, proven teams.
          </div>
        </div>
        <div className={styles.animationWrapper}>
          <Lottie
            loop
            animationData={popular_team_animation}
            play
          />
        </div>
      </div>

      <div className="mobile_visible">
        <h1 className={styles.title}>
          Development team request form
        </h1>
        <div className={styles.subtitle}>
          We'll help you select a team that can complete your project on time, meet standards, and adhere to programming
          norms, from our pool of trusted, proven teams.
        </div>
      </div>

      {page === 1 && <TeamSearchForm1 page={page} onSubmit={nextPage} />}
      {page === 2 && <TeamSearchForm2 page={page} markets_list={props.app.markets_list} onSubmit={nextPage} />}
      {page === 3 && <TeamSearchForm3 page={page} skills_list={props.app.skills_list} onSubmit={nextPage} />}
      {page === 4 && <TeamSearchForm4 page={page} onSubmit={nextPage} />}
      {page === 5 && <TeamSearchForm5 page={page} onSubmit={onSubmit} />}
      {page === 6 && <ResponseMessage page={page} message={props.app.team_request_message} />}
    </div>
  );
};

export default TeamSearch;
