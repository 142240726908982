import cn from "classnames";

import styles from "./Verified.module.css";

const Verified = ({ text, className, isDisabled, onClick }) => {
    return (
        <div className={cn(styles.verified, isDisabled && styles.disabled, className)} onClick={onClick}>
            {text ? text : "VERIFIED"}
        </div>
    );
};

export default Verified;
