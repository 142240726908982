import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { format, length, required, email } from "redux-form-validators";
import cn from "classnames";

import { startInList } from "../../../constants/global-constants";
import { getCitiesByCountryId } from "../../../store/countries";
import { Input, SelectInput, Textarea, CheckboxSwitch } from "../../common/FormsControls/FormControls";
import FormIndicator from "../../registration/TeamSearch/FormIndicator/FormIndicator";
import UploadImage from "../../common/UploadImage/UploadImage";
import CopyLink from "../../common/CopyLink/CopyLink";

import styles from "./ProfileEditing.module.css";

const formName = "baseInfoForm";

const TalentBaseInfoForm = (props) => {
  const {
    handleSubmit,
    error,
    initialValues,
    countries,
    cities,
    getCitiesByCountryId,
    dispatch,
    openToWork,
  } = props;

  useEffect(() => {
    if (countries.length && initialValues.country) {
      getCities(initialValues.country);
    }
  }, [initialValues, countries]);

  const countryList = useMemo(() => countries.map(item => item.name), [countries]);
  const citiesList = useMemo(() => cities.map(item => item.name), [cities]);

  const getCities = (country) => {
    const currentCountry = countries.find(countryData => countryData.name === country);
    getCitiesByCountryId(currentCountry?.id);
  };

  const changeCountryHandler = (event) => {
    getCities(event.target.value);
    dispatch(change(formName, "city", ""));
  };

  const saveHandler = () => {
    dispatch(change(formName, "url_redirect"));
  };

  const nextPageHandler = () => {
    dispatch(change(formName, "url_redirect", "/profile/edit/skills"));
  };

  const companyLink = `/talent/${initialValues.id}`;

  return (
    <>
      {initialValues.profile_fill_step < 5 && <FormIndicator page={1} className={styles.indicator} />}
      <form onSubmit={handleSubmit} className={cn(styles.base_info_form, styles.developer_base_info)}>
        <div className={styles.fields_wrapper}>
          <div className={styles.field_label}>
            <div className={styles.copy_link_wrapper}>
              <CopyLink
                link={companyLink}
                message="Company link has been copied to clipboard"
              />
            </div>
          </div>

          <div className={styles.developer_form_main_part}>
            <div className={cn(styles.upload_photo_wrapper, styles.developer_photo)}>
              <Field
                component={UploadImage}
                name="representative_photo"
                label="Upload Photo"
                mode="photo"
              />
            </div>
            <div className={cn(styles.fields_row, styles.open_to_work)}>
              <Field
                component={CheckboxSwitch}
                name="open_to_work"
                label="Open to work"
                defaultChecked={initialValues.open_to_work}
              />
              <Field
                input_wrapper_class={cn(styles.select_wrapper, styles.start_in_select)}
                component={SelectInput}
                name="start_in"
                label="Start in"
                options={startInList}
                withoutEmpty
                disabled={!openToWork || openToWork === 'false'}
              />
            </div>

            <div className={cn(styles.field_label, styles.company_info)}>Base info</div>

            <div className={cn(styles.fields_section, styles.representative_section)}>
              <div className={styles.fields_row}>
                <Field
                  className={styles.input}
                  component={Input}
                  type="text"
                  name="first_name"
                  label="First Name*"
                  validate={[required(), format({ with: /^[a-zA-Z\s]+$/i, message: 'Only Latin letters' })]}
                  help_text="for example: John"
                  help_text_design={styles.help_text}
                />
                <Field
                  className={styles.input}
                  component={Input}
                  type="text"
                  name="last_name"
                  label="Last Name*"
                  validate={[required(), format({ with: /^[a-zA-Z\s]+$/i, message: 'Only Latin letters' })]}
                  help_text="for example: Gold"
                  help_text_design={styles.help_text}
                />
              </div>
            </div>

            <div className={cn(styles.about_you, styles.full_width)}>
              <Field
                className={styles.textarea}
                component={Textarea}
                type="text"
                name="description"
                label="About you*"
                validate={[required(), length({ maximum: 512 })]}
                showSymbolsNumber
              />
            </div>

            <div className={styles.fields_row}>
              <Field
                input_wrapper_class={styles.full_width}
                className={styles.input}
                component={Input}
                type="text"
                name="official_email"
                label="Contact email*"
                help_text="for example: name@gmail.com"
                help_text_design={styles.help_text}
                validate={[required(), email()]}
              />
            </div>
            <div className={styles.fields_row}>
              <Field
                input_wrapper_class={cn(styles.full_width, styles.phone_wrapper)}
                className={styles.input}
                component={Input}
                type="phone"
                name="business_phone"
                label="Contact phone*"
                help_text="for example: +380631112233"
                help_text_design={styles.help_text}
                validate={[
                  required(),
                  format({
                    with: /^[0-9]{9,14}$/i,
                    message: 'Incorrect phone number',
                  }),
                ]}
              />
            </div>

            <div className={cn(styles.field_label, styles.location_label)}>Location</div>
            <div className={styles.fields_row}>
              <Field
                input_wrapper_class={cn(styles.select_wrapper, styles.full_width)}
                component={SelectInput}
                name="country"
                label="Country*"
                options={countryList}
                validate={[required()]}
                onChange={changeCountryHandler}
              />
            </div>
            <div className={styles.fields_row}>
              <Field
                input_wrapper_class={cn(styles.select_wrapper, styles.full_width)}
                component={SelectInput}
                name="city"
                label="City*"
                options={citiesList}
                validate={[required()]}
              />
            </div>

            {error && (
              <div className={cn(styles.form_errors, "text_center", "validation_message")}>
                {error}
              </div>
            )}

            <div className={styles.submit_wrapper}>
              <button className={cn("btn", styles.submit)} onClick={saveHandler}>
                Save
              </button>
            </div>

            <div className={cn(styles.submit_wrapper)}>
              <button className={cn("btn", styles.submit, styles.yellow)} onClick={nextPageHandler}>
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

const selector = formValueSelector(formName);

const BaseInfoFormReduxForm = reduxForm({
  form: formName,
  enableReinitialize: true,
})(TalentBaseInfoForm);

const mapStateToProps = (state) => ({
  initialValues: {
    ...state.auth.company_info,
    open_to_work: !!state.auth.company_info.open_to_work,
    start_in: state.auth.company_info.start_in || '2 weeks',
  },
  countries: state.countries.countryList,
  cities: state.countries.cityList,
  openToWork: selector(state, 'open_to_work'),
});

const mapDispatchToProps = {
  getCitiesByCountryId,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseInfoFormReduxForm);