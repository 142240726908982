import qs from "qs";
import { axiosInstances } from "./api";

const axiosAuthInstance = axiosInstances.auth;
const axiosAccountInstance = axiosInstances.account;

export const marketsAPI = {
  getPublicMarketsList() {
    return axiosAuthInstance.get(`api/public/market/list`).then((response) => {
      return response;
    });
  },

  getMarketsList() {
    return axiosAccountInstance.get(`api/users/companies/market/list`).then((response) => {
      return response.data.result;
    });
  },

  addMarket(data) {
    return axiosAccountInstance.post(`api/users/companies/market`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  deleteMarket(data) {
    return axiosAccountInstance.delete(`api/users/companies/market`, { data: qs.stringify(data) }).then((response) => {
      return response.data;
    });
  },
};
