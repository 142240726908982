import React from "react";
import { connect } from "react-redux";

import { logout } from "../../../store/store";
import Navigation from "./Navigation";

const NavigationContainer = (props) => {
  return (
    <Navigation
      auth={props.auth}
      news={props.news}
      logout={props.logout}
    />
  );
};

let mapStateToProps = (state) => ({
  auth: state.auth,
  news: state.news.newsList,
});

export default connect(mapStateToProps, { logout })(NavigationContainer);
