import qs from 'qs';
import { axiosInstances } from "./api";

const axiosInstance = axiosInstances.account;

export const marketplaceDevelopersAPI = {
  getDevelopers(is_single_developer) {
    return axiosInstance.get(`api/users/marketplace/developers?is_single_developer=${is_single_developer}`)
    .then((response) => {
      return response.data;
    });
  },

  addDeveloper(data) {
    return axiosInstance.post('api/users/marketplace/request/developers', qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  deleteDeveloper(data) {
    return axiosInstance
    .delete('api/users/marketplace/request/developers', { data: qs.stringify(data) })
    .then((response) => {
      return response.data;
    });
  },
};

export const marketplaceProjectsAPI = {
  getProjects() {
    return axiosInstance.get('api/users/marketplace/projects').then((response) => {
      return response.data;
    });
  },

  addProject(data) {
    return axiosInstance.post('api/users/marketplace/request/projects', qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  deleteProject(data) {
    return axiosInstance
    .delete('api/users/marketplace/request/projects', { data: qs.stringify(data) })
    .then((response) => {
      return response.data;
    });
  },
};

export const marketplaceLeadsAPI = {
  getLeads() {
    return axiosInstance.get('api/users/marketplace/leads').then((response) => {
      return response.data;
    });
  },

  deleteLead(data) {
    return axiosInstance
    .delete('api/users/marketplace/leads', { data: qs.stringify(data) })
    .then((response) => {
      return response.data;
    });
  },
};


export const marketplaceChatAPI = {
  increaseCounter(data) {
    return axiosInstance.post('api/users/marketplace/request/chat', qs.stringify(data)).then((response) => {
      return response.data;
    });
  },
};
