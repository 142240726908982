import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";

import { Input, SelectInput } from "../../common/FormsControls/FormControls";
import { siteNameForTitle } from "../../../constants/global-constants";
import Preloader from "../../common/Preloader/Preloader";
import UploadImage from "../../common/UploadImage/UploadImage";
import styles from "./RegistrationStep2.module.css";

let RegistrationStep2Form = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <Field component={UploadImage} name="logo" label="Upload Logo" mode="photo" />
        <Field component={Input} type={"text"} name={"company_name"} label="Company name" validate={[required()]} />
        <Field component={Input} type={"text"} name={"site"} label="Website" help_text="for example: www.site.com" />
        <Field
          component={Input}
          type={"text"}
          name={"business_phone"}
          label="Cell"
          help_text="for example: +1 (code) phone"
        />
        <Field
          component={SelectInput}
          name={"country"}
          label="Country"
          options={props.countries_list}
          validate={[required()]}
        />
        <Field component={Input} type={"text"} name={"city"} label="City" validate={[required()]} />
        <Field component={Input} type={"text"} name={"address"} label="Address" />
      </div>
      <div className={styles.submit_wrapper}>
        <button className={`btn ${styles.submit}`}>Next</button>
      </div>
    </form>
  );
};

RegistrationStep2Form = reduxForm({ form: "registrationStep2" })(RegistrationStep2Form);

const RegistrationStep2 = (props) => {
  const onSubmit = (formData) => {
    formData = {
      ...formData,
      url_redirect: "/registration-extra-info",
    };
    props.editProfile(formData);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Registration | {siteNameForTitle}</title>
      </Helmet>

      {props.auth.is_fetching && <Preloader />}

      <h2 className={styles.title}>Company profile</h2>
      <div className={styles.steps}>
        <ul className={styles.stepsMenu}>
          <li>
            <NavLink to="/registration" activeClassName={styles.active}>
              Base info
            </NavLink>
          </li>
          <li>
            <NavLink to="/registration-company-info" activeClassName={styles.active}>
              Company info
            </NavLink>
          </li>
          <li>
            <NavLink to="/registration-extra-info" activeClassName={styles.active}>
              Additional info
            </NavLink>
          </li>
        </ul>
      </div>

      <RegistrationStep2Form onSubmit={onSubmit} countries_list={props.countries_list} />
    </div>
  );
};

export default RegistrationStep2;
