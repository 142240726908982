import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { getLeadById, updateLead } from "../../../store/lead";
import LeadEditing from "./LeadEditing";

let LeadEditingContainer = (props) => {

  useEffect(() => {
    props.getLeadById(props.match.params.lead_id);
  }, []);

  return (
    <LeadEditing
      search={props.search}
      leadData={props.leadData}
      updateLead={props.updateLead}
      companyType={props.companyType}
    />
  );
};

LeadEditingContainer = withRouter(LeadEditingContainer);

let mapStateToProps = (state) => ({
  search: state.search,
  leadData: state.lead.current_lead,
  companyType: state.auth.company_info.type,
});

export default compose(
  connect(mapStateToProps, {
    getLeadById,
    updateLead,
  }),
  withAuthRedirect
)(LeadEditingContainer);
