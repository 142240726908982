import { Helmet } from "react-helmet";
import React from "react";

import config from "../../../config";

import { siteNameForTitle } from "../../../constants/global-constants";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import styles from "./SubscriptionCanceled.module.css";

const SubscriptionCanceled = () => {
  const payConnects = () => {
    const userToken = JSON.parse(localStorage.getItem("user")).token;

    const stripe = window.Stripe(config.stripe.key); // real key
    // const stripe = window.Stripe("pk_test_vfyCrZ4dWUImEL7nBS4Yl0aA"); // test key
    stripe.redirectToCheckout({
      lineItems: [{ price: config.stripe.subscription, quantity: 1 }], // real payment - 99 EURO for 10 connects
      mode: "subscription",
      successUrl: `https://ibench.in/subscription/success?token=${userToken}`,
      cancelUrl: "https://ibench.in/subscription/canceled",
    }).then(function (result) {
      if (result.error) {
        const displayError = document.getElementById("error-message");
        displayError.textContent = result.error.message;
      }
    });
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Payment Canceled | {siteNameForTitle}</title>
      </Helmet>
      <DashboardMenuContainer/>
      <div className={styles.content}>
        <h1 className={styles.pageTitle}>Payment Canceled</h1>
        <div className={styles.info}>
          <div className={styles.priceWrapper}>
            started at
            <div className={styles.price}>10 EURO</div>
            /Month
          </div>
        </div>
        <div className={styles.status}>
          Your iBench Subscription will be deactivated
        </div>
        <div className={styles.paymentService}/>
        <div className={styles.stripeError}>
          <div id="error-message" />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCanceled;
