import React from "react";
import { Helmet } from "react-helmet";

import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import { siteNameForTitle } from "../../constants/global-constants";

import styles from "./News.module.css";

const News = ({ news }) => {

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>News | {siteNameForTitle}</title>
      </Helmet>

      <DashboardMenuContainer/>

      <h1 className={styles.title}>News</h1>

      <div className={styles.news}>
        {Object.keys(news).map((date) => {
          const dateNews = news[date];

          return (
            <div className={styles.news_item} key={date}>
              <div className={styles.day}>{date}</div>
              <div>
                {dateNews.map(newsData => (
                  <div className={styles.news_wrapper}>
                    <div className={styles.subject}>{newsData.subject}</div>
                    <div className={styles.news_message} dangerouslySetInnerHTML={{ __html: newsData.message}} />
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default News;
