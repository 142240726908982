import React from "react";

import { dataWasSaved } from "../../../utils/notifications";
import Preloader from "../../common/Preloader/Preloader";
import ProfileMenu from "../ProfileMenu";
import SocialForm from "./SocialForm";
import CompanyMenu from "../CompanyMenu";
import FormIndicator from "../../registration/TeamSearch/FormIndicator/FormIndicator";

import styles from "./ProfileSocialEditing.module.css";

const ProfileSocialEditing = (props) => {
  const { auth, editProfile } = props;

  const onSubmit = (formData) => {
    const userData = {
      facebook: formData.facebook,
      linkedin: formData.linkedin,
      telegram: formData.telegram,
      github: formData.github,
      whatsapp: formData.whatsapp,
      youtube: formData.youtube,
      behance: formData.behance,
      url_redirect: auth.user.is_single_developer ? formData.url_redirect : '/profile',
    };

    if (auth.user.is_single_developer && auth.company_info.profile_fill_step < 4) {
      userData.profile_fill_step = 4;
    }

    editProfile(userData, () => {
      if (!formData.url_redirect) {
        dataWasSaved();
      }
    });
  };

  return (
    <div className={styles.wrapper}>
      {auth.is_fetching && <Preloader/>}

      <h2 className={`${styles.title} text_center`}>
        {auth.user.is_single_developer ? "Developer" : "Company"} profile
      </h2>

      <ProfileMenu />

      <CompanyMenu companyInfo={auth.company_info} />

      <div className={styles.subtitle}>Edit profile / Social</div>

      {!!auth.company_info.is_single_developer && auth.company_info.profile_fill_step < 5 && (
        <FormIndicator page={4} className={styles.indicator} />
      )}

      <SocialForm onSubmit={onSubmit} />
    </div>
  );
};

export default ProfileSocialEditing;
