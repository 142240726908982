import qs from "qs";
import { axiosInstances } from "./api";

const axiosInstance = axiosInstances.account;

export const developerProjectSkillsAPI = {
  addSkill(developerId, data) {
    return axiosInstance
      .post(`api/users/developers/${developerId}/projects/skills`, qs.stringify(data))
      .then((response) => {
        return response.data;
      });
  },
  
  deleteSkill(developerId, data) {
    return axiosInstance
      .delete(`api/users/developers/${developerId}/projects/skills`, { data: qs.stringify(data) })
      .then((response) => {
        return response.data;
      });
  },
};
