import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getCompany } from "../../store/company";
import {
  addClickServiceCompanyTelegramAnalytic,
  addClickServiceCompanyWhatsappAnalytic,
  addClickServiceCompanyEmailAnalytic,
} from "../../store/app";
import { setSelectedTrialDeveloper } from "../../store/search";
import { hire } from "../../store/chat";
import { incrementCTR } from "../../store/developer";
import CompanyDetails from "../Profile/CompanyDetails";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import styles from "./Company.module.css";

const CompanyDeveloperContainer = (props) => {
  const { companyData, getCompany, match } = props;

  useEffect(() => {
    getCompany(match.params.company_id);
  }, []);

  return (
    <div className={styles.company_details}>
      <Breadcrumbs className={styles.breadcrumbs} currentPage={companyData?.company_name}/>
      {!!companyData && <CompanyDetails {...props} withTitle />}
    </div>
  )
};

const mapStateToProps = (state) => ({
  companyData: state.company.currentCompany,
  companyInfo: state.auth?.company_info,
});

const mapDispatchToProps = {
  getCompany,
  addClickServiceCompanyTelegramAnalytic,
  addClickServiceCompanyWhatsappAnalytic,
  addClickServiceCompanyEmailAnalytic,
  hire,
  incrementCTR,
  setSelectedTrialDeveloper,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDeveloperContainer);
