import React from "react";
import { NavLink } from "react-router-dom";

import { buildImageLink, buildTelegramLink, buildWhatsappLink } from "../../../utils/utils";

import default_company_photo from "../../../assets/img/rowing.svg";
import verified_icon from "../../../assets/img/verified.png";
import top10_icon from "../../../assets/img/top-10.png";
import styles from "./CompanyTop10Card.module.css";

const CompanyTop10Card = (props) => {
  const {
    company,
    onClick,
    telegramOnClick,
    whatsappOnClick,
    emailOnClick,
  } = props;

  const renderTelegramButton = () => {
    if (company.telegram) {
      return (
        <a
          href={buildTelegramLink(company.telegram)}
          className={styles.telegramLogo}
          onClick={telegramOnClick}
          target="_blank"
          rel="nofollow"
        />
      )
    }

    return <div className={styles.telegramLogo} />;
  };

  const renderWhatsappButton = () => {
    if (company.whatsapp) {
      return (
        <a
          href={buildWhatsappLink(company.whatsapp)}
          className={styles.whatsappLogo}
          onClick={whatsappOnClick}
          target="_blank"
          rel="nofollow"
        />
      )
    }

    return <div className={styles.whatsappLogo} />;
  };

  const renderMailButton = () => {
    if (company.official_email) {
      return (
        <a
          href={`mailto:${company.official_email}`}
          className={styles.mailLogo}
          onClick={emailOnClick}
          target="_blank"
          rel="nofollow"
        />
      )
    }

    return <div className={styles.mailLogo} />;
  };

  return (
    <div key={company.id} className={styles.companyTop10Card}>
      <div className={styles.leftColumn}>
        <NavLink
          to={`/company/${company.id}`}
          className={styles.logoLink}
          onClick={onClick}
        >
          <img
            className={styles.companyLogo}
            src={company.logo ? buildImageLink(company.logo) : default_company_photo}
            alt={company.company_name}
          />
        </NavLink>
        <div className={styles.verifiedButtons}>
          {!!company.in_top_10 && <img src={top10_icon} className={styles.top10Logo} alt="Top 10" />}
          {!!company.verified && <img src={verified_icon} className={styles.verified} alt="Verified" />}
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.top10Header}>
          <div className={styles.top10InfoColumn}>
            <NavLink
              to={`/company/${company.id}`}
              className={styles.top10Link}
              onClick={onClick}
            >
              {company.company_name}
            </NavLink>
            {company.country}
          </div>
          <div className={styles.socials}>
            {renderTelegramButton()}
            {renderWhatsappButton()}
            {renderMailButton()}
          </div>
        </div>
        <div
          className={styles.top10Description}
          dangerouslySetInnerHTML={{ __html: company.description }}
        />
      </div>
    </div>
  );
};

export default CompanyTop10Card;
