import React from "react";
import cn from "classnames";

import styles from "./ProfileSocialEditing.module.css";

const TelegramBot = ({ className }) => (
  <div className={cn(styles.fields_row, styles.telegramButtonRow, className)}>
    <div className={cn(styles.field_wrapper, styles.telegramButtonInfo)}>
      Click to receive notification of new messages immediately
    </div>
    <div className={cn(styles.field_wrapper, styles.telegramButtonWrapper)}>
      <a
        href="https://t.me/iBenchBot"
        target="_blank"
        rel="nofollow"
        className={styles.telegramButton}
      />
    </div>
  </div>
);

export default TelegramBot;