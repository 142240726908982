import axios from "axios";

import { serviceApiUrls } from "../constants/global-constants";

const authHeader = (json = false) => {
  let user = JSON.parse(localStorage.getItem("user"));
  const contentType = json ? "application/json" : "application/x-www-form-urlencoded";
  if (user && user.token) {
    return {
      "content-type": contentType,
      "x-solt": user.token,
    };
  } else {
    return {
      "content-type": contentType,
    };
  }
};

const addInterceptors = (instance, json) => {
  instance.interceptors.request.use(
    (config) => {
      if (!config.headers["x-solt"]) {
        const new_header = authHeader(json);
        if (new_header) config.headers = new_header;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

const initAxiosInstance = (serviceUrl, json) => {
  const serviceInstance = axios.create({
    async: true,
    crossDomain: true,
    baseURL: serviceUrl,
    headers: authHeader(json),
  });

  addInterceptors(serviceInstance, json);
  return serviceInstance;
};

const initFileAxiosInstance = (serviceUrl) => {
  const serviceInstance = axios.create({
    async: true,
    crossDomain: true,
    baseURL: serviceUrl,
    headers: authHeader(),
    processData: false,
    contentType: false,
    mimeType: "multipart/form-data",
  });

  addInterceptors(serviceInstance);
  return serviceInstance;
};

const axiosInstances = {};
const axiosJSONInstances = {};
const axiosFileInstances = {};

for (let service in serviceApiUrls) {
  const serviceUrl = serviceApiUrls[service];
  axiosInstances[service] = initAxiosInstance(serviceUrl);
  axiosJSONInstances[service] = initAxiosInstance(serviceUrl, true);
  axiosFileInstances[service] = initFileAxiosInstance(serviceUrl);
}

export { axiosInstances, axiosJSONInstances, axiosFileInstances };
