import React from "react";

import styles from "./InfoModal.module.css";

const InfoModal = (props) => {
  const {
    onClose,
    title,
    message,
  } = props;

  return (
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        {title}
      </div>
      <div className={styles.modalBody}>
        {message}
      </div>
      <div className={styles.buttonWrapper}>
        <button className={`btn ${styles.submit}`} onClick={onClose}>OK</button>
      </div>
    </div>
  );
};

export default InfoModal;
