import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import { siteNameForTitle } from "../../constants";
import CompanyTop10Card from "../common/CompanyTop10Card/CompanyTop10Card";
import CompanyTop20Card from "../common/CompanyTop20Card/CompanyTop20Card";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";
import TopCompaniesSelects from "../FrontPage/TopCompaniesSelects/TopCompaniesSelects";

import banner_image from "../../assets/img/banner.png";
import styles from "./TeamsPage.module.css";
import { DropdownIdName } from "../common/FormsControls/FormControls";

const top20CompaniesPerPage = 10;

const TeamsPage = (props) => {
  const {
    industries,
    topIndustries,
    otherIndustries,
    popularServices,
    industriesTopCompanies,
    getIndustriesTopCompanies,
    popularServicesTopCompanies,
    getPopularServicesTopCompanies,
    stats,
    defaultPopularServiceId,
    addViewBannerAnalytic,
    addClickBannerAnalytic,
    addChooseServiceAnalytic,
    addViewIndustryCompaniesAnalytic,
    addViewServiceCompaniesAnalytic,
    addClickIndustryCompanyAnalytic,
    addClickServiceCompanyAnalytic,
    addClickServiceCompanyTelegramAnalytic,
    addClickServiceCompanyWhatsappAnalytic,
    addClickServiceCompanyEmailAnalytic,
  } = props;

  const [selectedPopularService, setSelectedPopularService] = useState({});
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [top20Page, setTop20Page] = useState(0);
  const [top20PageCompanies, setTop20PageCompanies] = useState([]);

  useEffect(() => {
    addViewBannerAnalytic();
  }, []);

  useEffect(() => {
    if (popularServices.length) {
      changePopularServiceHandler(defaultPopularServiceId, false);
    }
  }, [popularServices]);

  useEffect(() => {
    if (industries.length) {
      changeIndustryHandler(industries[0].id);
    }
  }, [industries]);

  useEffect(() => {
    if (industriesTopCompanies) {
      changeTop20PageHandler(0);
    }
  }, [industriesTopCompanies]);

  useEffect(() => {
    if (popularServicesTopCompanies?.length) {
      const companiesList = popularServicesTopCompanies.map(company => company.id);
      addViewServiceCompaniesAnalytic(selectedPopularService.id, companiesList);
    }
  }, [popularServicesTopCompanies]);

  const clickBannerHandler = () => {
    addClickBannerAnalytic();
  };

  const clickIndustryCompanyHandler = (companyId) => {
    addClickIndustryCompanyAnalytic(selectedIndustry.id, companyId);
  };

  const clickServiceCompanyHandler = (companyId) => {
    addClickServiceCompanyAnalytic(selectedPopularService.id, companyId);
  };

  const clickTelegramServiceCompanyHandler = (companyId) => {
    addClickServiceCompanyTelegramAnalytic(companyId, selectedPopularService.id);
  };

  const clickWhatsappServiceCompanyHandler = (companyId) => {
    addClickServiceCompanyWhatsappAnalytic(companyId, selectedPopularService.id);
  };

  const clickEmailServiceCompanyHandler = (companyId) => {
    addClickServiceCompanyEmailAnalytic(companyId, selectedPopularService.id);
  };

  const changePopularServiceHandler = (popularServiceId, addAnalytic = true) => {
    popularServiceId = +popularServiceId;
    const popularServiceData = popularServices.find(popularService => popularService.id === popularServiceId);
    setSelectedPopularService(popularServiceData);
    getPopularServicesTopCompanies(popularServiceId);
    addAnalytic && addChooseServiceAnalytic(popularServiceId);
  };

  const changeIndustryHandler = (industryId) => {
    industryId = +industryId;
    const industryData = industries.find(industry => industry.id === industryId);
    setSelectedIndustry(industryData);
    getIndustriesTopCompanies(industryId);
    changeTop20PageCompanies(top20Page);
  };

  const changeTop20PageHandler = (page) => {
    setTop20Page(page);
    changeTop20PageCompanies(page);
  };

  const changeTop20PageCompanies = (page) => {
    const start = page * top20CompaniesPerPage;
    const end = page * top20CompaniesPerPage + top20CompaniesPerPage;
    const currentPageCompanies = industriesTopCompanies.slice(start, end);
    setTop20PageCompanies(currentPageCompanies);

    if (currentPageCompanies.length) {
      const companiesList = currentPageCompanies.map(company => company.id);
      addViewIndustryCompaniesAnalytic(selectedIndustry.id, companiesList);
    }
  };

  const renderPaginator = () => {
    if (industriesTopCompanies.length <= top20CompaniesPerPage) {
      return null;
    }

    return (
      <div className={styles.paginator}>
        <div
          className={cn(styles.paginatorItem, {[styles.active]: top20Page === 0})}
          onClick={() => changeTop20PageHandler(0)}
        >
          1
        </div>
        <div
          className={cn(styles.paginatorItem, {[styles.active]: top20Page === 1})}
          onClick={() => changeTop20PageHandler(1)}
        >
          2
        </div>
      </div>
    );
  };

  const renderIndustryButton = (industry) => (
    <h3
      key={industry.id}
      className={cn(styles.industryButton, { [styles.active]: selectedIndustry.id === industry.id})}
      onClick={() => changeIndustryHandler(industry.id)}
    >
      {industry.name}
    </h3>
  );

  return (
    <div className={styles.wrapper}>
      <Breadcrumbs className={styles.breadcrumbs} currentPage="Verified developer teams" />
      <Helmet>
        <title>{siteNameForTitle} / Verified developer teams</title>
        <meta
          name="description"
          content="IBench is a marketplace filled with highly skilled developers from Eastern Europe. Our developers can tackle web projects, mobile apps or MVPs for start-ups."
        />
        <meta
          name="keywords"
          content="software developers, ux/ui designers, bench, developers on demand, realtime developers search, e-commerce developers, fin-tech developers, healthcare developers"
          data-react-helmet="true"
        />
      </Helmet>
      <h1 className={styles.title}>
        Verified developer teams
      </h1>

      <form className={styles.topForm}>
        <div className={styles.title}>
          Teams: {stats?.companiesCount}
        </div>
        <div className={styles.topFormFieldsRow}>
          <div className={cn(styles.recommendation, "mobile_hidden")}>
            We recommend you some of <NavLink to="/verifiedteams">the teams that we have verified</NavLink>,
            who have expertise.
          </div>
          <div className={styles.topCompaniesSelectsWrapper}>
            <TopCompaniesSelects
              onChangeIndustry={changeIndustryHandler}
              onChangePopularService={changePopularServiceHandler}
              industries={industries}
              popularServices={popularServices}
              selectedIndustryId={selectedIndustry.id}
              selectedPopularServiceId={selectedPopularService.id}
              hideIndustries
            />
          </div>
        </div>
      </form>
      <div className={styles.list_title}>
        <h2>
          List of the <span className={styles.purple}>Top</span>,
          <span className={styles.purple}> {selectedPopularService.name}</span> development teams
        </h2>
        <a
          href="https://t.me/Gendolf77"
          target="_blank"
          rel="nofollow"
          className={styles.advertising}
        >
          Advertising
        </a>
      </div>
      <div className={styles.top10Companies}>
        {popularServicesTopCompanies.map(company => (
          <CompanyTop10Card
            key={company.id}
            company={company}
            onClick={() => clickServiceCompanyHandler(company.id)}
            telegramOnClick={() => clickTelegramServiceCompanyHandler(company.id)}
            whatsappOnClick={() => clickWhatsappServiceCompanyHandler(company.id)}
            emailOnClick={() => clickEmailServiceCompanyHandler(company.id)}
          />
        ))}
      </div>
      <div className={styles.list_title}>
        <h2>
          List of the <span className={styles.purple}>Verified</span> Teams in
          <span className={styles.purple}> {selectedIndustry.name}</span> industry
        </h2>
        <a
          href="https://t.me/Gendolf77"
          target="_blank"
          rel="nofollow"
          className={styles.advertising}
        >
          Advertising
        </a>
      </div>
      <div className={styles.top20Companies}>
        <div className={styles.leftColumn}>
          <div className={cn(styles.industriesListWrapper, "mobile_hidden")}>
            <div className={styles.industriesList}>
              <div className={styles.industriesListLabel}>TOP Industries</div>
              {topIndustries.map(renderIndustryButton)}
              <div className={styles.industriesListLabel}>Other Industries</div>
              {otherIndustries.map(renderIndustryButton)}
            </div>
          </div>
          <div className="mobile_visible">
            <DropdownIdName
              className={styles.select}
              options={industries}
              label="Industry"
              value={selectedIndustry.id}
              onChange={changeIndustryHandler}
            />
          </div>
          <div className={styles.bannersSection}>
            <a
              onClick={clickBannerHandler}
              className={cn(styles.bannerLink, styles.sterlingBanner)}
              href="https://sterling-law.co.uk/"
              target="_blank"
              rel="nofollow"
            >
              <img src={banner_image} alt="Sterling law"/>
            </a>
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.top20CompaniesList}>
            {top20PageCompanies.map(company => (
              <CompanyTop20Card
                key={company.id}
                company={company}
                onClick={() => clickIndustryCompanyHandler(company.id)}
              />)
            )}
          </div>
          {renderPaginator()}
        </div>
      </div>
    </div>
  );
};

export default TeamsPage;
