import { useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { Row, Col } from "antd";
import moment from "moment";

import { siteNameForTitle } from "../../constants/global-constants";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import MarketplaceNavigation from "./MarketplaceNavigation";
import CloseRequestModal from "./CloseRequestModal";
import styles from "./Marketplace.module.css";

const MarketplaceFreelancers = (props) => {
  const [showCloseRequestModal, setShowCloseRequestModal] = useState(false);
  const [searchId, setSearchId] = useState();

  const onCloseRequestConfirm = (search_id) => {
    setShowCloseRequestModal(true);
    setSearchId(search_id);
  };

  const onCloseRequest = async () => {
    await props.deleteDeveloperSearchFromMarketplace({
      search_id: searchId,
      comment:
        props.closeMarketplaceRequestForm.values.reason !== "Other"
          ? props.closeMarketplaceRequestForm.values.reason
          : props.closeMarketplaceRequestForm.values.comment,
    });
    await props.getMarketplaceDevelopers();
    setShowCloseRequestModal(false);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Marketplace / Developers | {siteNameForTitle}</title>
      </Helmet>

      <CloseRequestModal
        visible={showCloseRequestModal}
        onSubmit={onCloseRequest}
        onCancel={() => setShowCloseRequestModal(false)}
      />

      <DashboardMenuContainer/>

      <h1 className={styles.pageTitle}>Marketplace / Find Employee</h1>
      <MarketplaceNavigation {...props} />

      <div className={styles.cardsList}>
        {props.marketplace.freelancers.map((developer) => (
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <div className={styles.cardDate}>
                date: {moment(developer.created).format("DD/MM/YYYY")}
              </div>
              <NavLink to={`/employee-search/${developer.search_id}`} className={styles.searchLink}>
                {developer.position_level_name}, {developer.job_title_name}
              </NavLink>
            </div>
            <div className={styles.cardContent}>
              <Row className={styles.cardRow}>
                <Col>
                  <span className={styles.cardLabel}>Skills: </span>
                  {developer.skills.map((skill) => skill.skill_name).join(", ")}
                </Col>
              </Row>
              <Row gutter={16} className={styles.cardRow}>
                <Col xs={24} sm={12}>
                  <span className={styles.cardLabel}>English: </span>
                  {developer.cerf_level}
                </Col>
                <Col xs={24} sm={12}>
                  <span className={styles.cardLabel}>Salary/month: </span>${developer.rate_month}
                </Col>
              </Row>
              <Row gutter={16} className={styles.cardRow}>
                <Col xs={24} sm={12}>
                  <span className={styles.cardLabel}>Companies: </span>
                  {developer.vetted === 1 ? "Only verified" : "All"}
                </Col>
                <Col xs={24} sm={12}>
                  <span className={styles.cardLabel}>Search results: </span>
                  {developer.found_last_run}
                </Col>
              </Row>
            </div>
            <div className={styles.cardFooter}>
              <NavLink to={`/chat`} className={styles.cardBtn}>
                View proposals
              </NavLink>
              <span
                className={styles.closeRequest}
                onClick={() => onCloseRequestConfirm(developer.search_id)}
              >
                  close request
                </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarketplaceFreelancers;
