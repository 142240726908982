import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { initializeApp, setTheme } from "../../store/app";
import Preloader from "../common/Preloader/Preloader";
import App from "./App";

class AppContainer extends React.Component {
  setTheme(pathname) {
    if (pathname !== "/company-bench" && pathname.includes("/company")) {
      pathname = "/company"; // handler for company page
    }

    if (pathname.indexOf('/registration-quick') === 0) {
      pathname = '/registration-quick';
    }

    if (pathname.indexOf('/trial-search') === 0) {
      pathname = '/trial-search';
    }

    const white_theme_URLs = [
      "/",
      "/teams",
      "/development-teams",
      "/intro",
      "/welcome",
      "/company",
      "/verifiedteams",
      "/trial-search",
      "/registration-quick",
      "/team-search",
      "/newtalents",
      "/about",
      "/benefits",
      "/price-for-it-companies",
      "/faq",
      "/privacy-policy",
      "/cookie-policy",
      "/terms-of-use",
      "/website-and-mobile-app-development",
      "/mobile-and-web-development",
      "/thankyou",
    ];

    if (white_theme_URLs.includes(pathname)) {
      this.props.setTheme("white_theme");
    } else {
      this.props.setTheme("default_theme");
    }

    if (pathname === "/" && this.props.auth.is_auth) {
      this.props.setTheme("default_theme"); // fix for history.listen when front page redirect after login
    }
  }

  componentDidMount() {
    this.setTheme(this.props.location.pathname); // initial theme setting

    // history listener for theme switching
    this.props.history.listen((location, action) => {
      this.setTheme(location.pathname);
    });

    this.props.initializeApp();
  }

  render() {
    if (!this.props.initialized) {
      return <Preloader />;
    }

    return <App theme={this.props.theme} auth={this.props.auth} />;
  }
}

let mapStateToProps = (state) => ({
  theme: state.app.theme,
  initialized: state.app.initialized,
  auth: state.auth,
});

export default compose(connect(mapStateToProps, { setTheme, initializeApp }), withRouter)(AppContainer);
