import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { addSearch } from "../../../store/outsource";
import OutsourceAdding from "./OutsourceAdding";
import india_countries_list from "../../../utils/india_countries_list";

const countryList = '';

const OutsourceAddingContainer = (props) => {
  return (
    <OutsourceAdding
      isFetching={props.outsource.isFetching}
      markets_list={props.markets_list}
      addSearch={props.addSearch}
      countries_list={india_countries_list.map((item) => item.label)}
    />
  );
};

let mapStateToProps = (state) => ({
  outsource: state.outsource,
  markets_list: state.app.markets_list,
});

export default compose(
  connect(mapStateToProps, {
    addSearch,
  }),
  withAuthRedirect
)(OutsourceAddingContainer);
