import React, { useState } from "react";
import { Helmet } from "react-helmet";

import LeadForm from "../LeadForm/LeadForm";
import CongratsModal from "../CongratsModal/CongratsModal";
import Modal from "../../common/Modal/Modal";
import {leadSalesTermsOptions, siteNameForTitle} from "../../../constants";
import { history } from "../../../utils";

import styles from "./LeadAdding.module.css";

const LeadAdding = (props) => {
  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const initialValues = {
    type: 'qualified',
    qualification_level: 'cold',
    white_label: 'white label',
    lead_sales_terms: leadSalesTermsOptions[0].key,
    vetted: "0",
  };



  const submitHandler = (formData) => {
    props.addLead(formData, () => setShowCongratsModal(true));
  };

  const onGotItHandler = () => {
    history.push(props.companyType === 1 ? "/marketplace/leads" : "/sell-leads");
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Sell leads | {siteNameForTitle}</title>
      </Helmet>
      {showCongratsModal && (
        <div className={styles.congratsModal}>
          <Modal
            showModal={setShowCongratsModal}
            renderComponent={<CongratsModal onGotIt={onGotItHandler} />}
            noBg
          />
        </div>
      )}

      <LeadForm
        onSubmit={submitHandler}
        search={props.search}
        initialValues={initialValues}
      />
    </div>
  );
};

export default LeadAdding;
