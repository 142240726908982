import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Editor.css";

const Editor = ({ label, input, placeholder, meta }) => {
    return (
        <div className={`form_group label_above`}>
            <div className="input_wrapper">
                {label && <label className={"label"}>{label}</label>}

                <ReactQuill
                    {...input}
                    onChange={(newValue, delta, source) => {
                        if (source === "user") {
                            input.onChange(newValue);
                        }
                    }}
                    onBlur={(range, source, quill) => {
                        input.onBlur(quill.getHTML());
                    }}
                    theme="snow"
                    modules={Editor.modules}
                    formats={Editor.formats}
                    bounds={".app"}
                    placeholder={placeholder}
                />
            </div>

            {meta.touched && meta.error && <div className="validation_message">{meta.error}</div>}
        </div>
    );
};

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
    toolbar: [
        [{ header: "2" }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link"],
        ["clean"],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
];

export default Editor;
