import { useState, useEffect, useRef } from "react";
import styles from "./PriceIndicator.module.css";

const PriceIndicator = (props) => {
  const node = useRef();

  const [is_open, setOpen] = useState(false);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  let rate_difference, average_rate, price_class, description;

  console.log('===props', props);

  if (props.rate_difference !== undefined) {
    rate_difference = props.rate_difference;
  } else {
    let obj_index;
    if (props.position_levels) {
      obj_index = props.position_levels.findIndex((obj) => obj.position_level_id === props.position_level_id);
      average_rate = props.position_levels[obj_index] ? props.position_levels[obj_index].average_rate : 0; // fix it
      let rd = ((props.rate - average_rate) / ((props.rate + average_rate) / 2)) * 100;
      rate_difference = rd > 20 ? 3 : rd > -20 ? 2 : rd > -50 ? 1 : 0;
    }
  }

  switch (rate_difference) {
    case 0:
      price_class = "very_low_price";
      description = "suspiciously low price";
      break;
    case 1:
      price_class = "low_price";
      description = "low price";
      break;
    case 2:
      price_class = "good_price";
      description = "good price";
      break;
    case 3:
      price_class = "unicorn_price";
      description = "unicorn price";
      break;

    default:
      price_class = "very_low_price";
      description = "suspiciously low price";
  }

  return (
    <div ref={node} className={styles.wrapper}>
      <div className={styles.indicator_wrapper}>
        <div className={`${styles.indicator} ${styles[price_class]}`}>
          <div className={`${styles.indicator_item}`}></div>
          <div className={`${styles.indicator_item}`}></div>
          <div className={`${styles.indicator_item}`}></div>
          <div className={`${styles.indicator_item}`}></div>
        </div>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.help_link} onClick={(e) => setOpen(!is_open)}>
        ?
      </div>
      {is_open && (
        <div className={styles.pricing}>
          <div className={styles.pricing_header}>
            <div className={styles.pricing_header_item}>Percentage difference</div>
            <div className={styles.pricing_header_item}>Average Prices</div>
          </div>
          <div className={styles.pricing_body}>
            <div className={styles.pricing_colors}>
              <div className={`${styles.indicator} ${styles.unicorn_price}`}>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
              </div>
              <div className={styles.description}>unicorn price</div>
              <div className={`${styles.indicator} ${styles.good_price}`}>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
              </div>
              <div className={styles.description}>good price</div>
              <div className={`${styles.indicator} ${styles.low_price}`}>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
              </div>
              <div className={styles.description}>low price</div>
              <div className={`${styles.indicator} ${styles.very_low_price}`}>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
              </div>
              <div className={styles.description}>suspiciously low price</div>
            </div>

            <div className={styles.pricing_percents}>
              <div className={styles.pricing_percents_item}>>20%</div>
              <div className={styles.pricing_percents_item}>[-20%…+20%]</div>
              <div className={styles.pricing_percents_item}>[-50%…- 20%]</div>
              <div className={styles.pricing_percents_item}>&lt;-50%</div>
            </div>

            <div className={styles.pricing_positions}>
              <div className={`${styles.indicator} ${styles.average_price}`}>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
                <div className={`${styles.indicator_item}`}></div>
              </div>
              <div className={styles.description}>Average Price</div>
              <div className={styles.pricing_positions_item}>
                Junior = $5 - $15/h
              </div>
              <div className={styles.pricing_positions_item}>
                Middle = $15 - $30/h
              </div>
              <div className={styles.pricing_positions_item}>
                Senior = $30 - $50/h
              </div>
              <div className={styles.pricing_positions_item}>
                TL = $40 - $70/h
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PriceIndicator;
