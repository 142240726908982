import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import Verified from "../common/Verified/Verified";
import Modal from "../common/Modal/Modal";
import { buildImageLink } from "../../utils/utils";

import defaultProfilePhoto from "../../assets/img/boy.svg";
import styles from "./Chat.module.css";

const ChatList = (props) => {
  const [deleteChatId, setDeleteChatId] = useState(null);

  const hideModal = () => setDeleteChatId(null);

  const renderOnlineStatus = (companyId) => {
    if (props.online_users?.includes(companyId.toString())) {
      return <div className={styles.online}>Online</div>;
    }

    return <div className={styles.offline}>Offline</div>
  };

  const renderUserInfo = (user) => {
    if (user.is_single_developer) {
      return (
        <div className={styles.name}>
          {user.first_name} {user.last_name[0].toUpperCase()}.{' '}
          {user.position_level_name}{' '}
          {user.job_title_name && `${user.job_title_name}, `}
          {user.additional_job_title_name && `${user.additional_job_title_name}, `}
          {user.country}
          <div>Salary: {user.monthly_rate ? `$${user.monthly_rate}` : 'contract'}</div>
        </div>
      );
    }

    if (user.type === 1) {
      return (
        <>
          <div className={styles.name}>
            {user.first_name} {user.last_name}
          </div>
          {user.position && <div className={styles.position}>{user.position}</div>}
          {user.city && <span className={styles.city}>{user.city}, </span>}
          {user.country && <span className={styles.country}>{user.country}</span>}
        </>
      );
    }

    return (
      <>
        <div className={styles.name}>
          {user.company_name}
        </div>
        <div className={styles.name}>
          {user.representative_name}
        </div>
        {user.position && <div className={styles.position}>{user.position}</div>}
        {user.city && <span className={styles.city}>{user.city}, </span>}
        {user.country && <span className={styles.country}>{user.country}</span>}
        &nbsp;&nbsp;
        {user.verified === 1 && <Verified className={styles.chatListVerified} />}
      </>
    );
  };

  const renderChatCard = (item) => {
    let photo = item.user.representative_photo ? buildImageLink(item.user.representative_photo) : defaultProfilePhoto;
    
    return (
      <div
        key={item.chat_id}
        className={cn(styles.chat_item_wrapper, {[styles.lead_chat]: item.lead })}
      >
        <div className={styles.delete} onClick={() => setDeleteChatId(item.chat_id)}>×</div>
        <NavLink
          to={`/chat/${item.chat_id}`}
          className={cn(
            styles.chatPreview,
            item.status === 0 && item.author_id !== props.user.id && styles.unread
          )}
          activeClassName={styles.active}
        >
          <div>
            <img
              src={photo}
              alt={item.user.representative_name}
              className={styles.photo}
            />
            {renderOnlineStatus(item.user.company_id)}
            {item.user.logo && (
              <img
                src={buildImageLink(item.user.logo)}
                alt={item.user.company_name}
                className={styles.logo}
              />
            )}
          </div>
          <div className={styles.userInfo}>
            {renderUserInfo(item.user)}
          </div>
        </NavLink>
      </div>
    );
  };

  return (
    <div className={styles.chatListWrapper}>
      {deleteChatId && (
        <Modal
          title={"Attention!"}
          message={"You are ready to delete this chat?"}
          buttons={[
            {
              btn_text: "Yes, delete",
              btn_handler: () => props.deleteChat(deleteChatId, hideModal),
            },
            {
              btn_text: "No",
              btn_handler: hideModal,
            },
          ]}
        />
      )}
      {props.chat_list.map(renderChatCard)}
    </div>
  );
};

export default ChatList;
