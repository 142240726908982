import React from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { faChild, faChartPie, faRocket, faUsers, faCheck } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb, faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";

import { siteNameForTitle, defaultDescription } from "../../constants";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import landing1 from "../../assets/content_img/landing/landing-1.png";
import landing2 from "../../assets/content_img/landing/landing-2.png";
import landing3 from "../../assets/content_img/landing/landing-3.png";
import landing4 from "../../assets/content_img/landing/landing-4.png";
import landing5 from "../../assets/content_img/landing/landing-5.png";
import landing6 from "../../assets/content_img/landing/landing-6.png";
import landing7 from "../../assets/content_img/landing/landing-7.png";
import polygonBlue from "../../assets/img/polygon-blue.svg";
import polygonGreen from "../../assets/img/polygon-green.svg";
import polygonPurple from "../../assets/img/polygon-purple.svg";
import polygonRed from "../../assets/img/polygon-red.svg";
import polygonYellow from "../../assets/img/polygon-yellow.svg";
import styles from "./Landing.module.css";

const Landing = () => {
  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{siteNameForTitle} / Build a Website or Mobile App with iBench</title>
        <meta
          name="description"
          content={defaultDescription}
        />
        <meta
          name="keywords"
          content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
          data-react-helmet="true"
        />
      </Helmet>
      <Breadcrumbs className={styles.breadcrumbs} currentPage="Build a Website or Mobile App with iBench" />

      <section className={styles.section}>
        <div className={styles.sectionBlock}>
          <h1>
            Build a Website or Mobile App with iBench
          </h1>
          <p>
            Have you been dreaming of building your own website or mobile app?
          </p>
          <p>
            Find out about the challenges and how you can tackle all of them with the help of iBench.
          </p>
          <div className={styles.buttonsBlock}>
            <a
              href="/mobile-and-web-development/calendly"
              className={styles.btn}
              target="_blank"
              rel="nofollow"
            >
              Check out a demo
            </a>
            <NavLink to="/registration" className={styles.btn}>
              Start building my project
            </NavLink>
          </div>
        </div>
        <div className={cn(styles.sectionBlock, styles.mobileHidden)}>
          <img
            src={landing1}
            className={styles.buildWebsiteImg}
            alt="Build a Website or Mobile App with iBench"
          />
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.sectionBlock}>
          <div className={styles.sectionTitle}>
            How to build a website or mobile app
          </div>
          <p>
            Like any IT process, building a website or mobile app takes careful planning and execution.<br />
            <span className={styles.purpleText}>There are at least three major stages involved:</span>
          </p>
          <div className={styles.sectionSubTitle}>
            <FontAwesomeIcon className={cn(styles.icon, styles.iconReflection, styles.yellowIcon)} icon={faLightbulb} />
            Idea
          </div>
          <p>
            The first step is coming up with an idea for your website or app. Then you can move to the planning stage.
          </p>
          <div className={styles.sectionSubTitle}>
            <FontAwesomeIcon className={cn(styles.icon, styles.greenIcon)} icon={faChartPie} />
            Planning
          </div>
          <p>
            Once you have a concept, you need to formulate a solid plan for the appearance and functionality of your
            site or app.
          </p>
          <div className={styles.sectionSubTitle}>
            <FontAwesomeIcon className={cn(styles.icon, styles.greenIcon)} icon={faChild} />
            Execution
          </div>
          <p>
            Now that your concept and plan are in place, you need to put them into action with a well-executed strategy.
          </p>
        </div>
        <div className={cn(styles.sectionBlock, styles.mobileHidden)}>
          <img
            src={landing2}
            className={styles.mt35}
            alt="Outsource"
          />
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.sectionBlock}>
          <img src={landing3} alt="Outsource" />

        </div>
        <div className={styles.sectionBlock}>
          <div className={cn(styles.sectionTitle, styles.mt35)}>
            Challenges of building a website or mobile app
          </div>
          <p>
            Building your own website or app involves several requirements and challenges along the way.&nbsp;
            <span className={styles.purpleText}>Here are three of the major ones:</span>
          </p>
          <div className={styles.challenges}>
            <div>
              <div className={styles.sectionSubTitle}>
                <FontAwesomeIcon className={cn(styles.icon, styles.blueIcon)} icon={faRocket} />
                Skills
              </div>
              <p>
                It’s essential to have a knowledge of modern technologies and the skills to implement them.
              </p>
            </div>
            <div>
              <div className={styles.sectionSubTitle}>
                <FontAwesomeIcon className={cn(styles.icon, styles.redIcon)} icon={faClock} />
                Time
              </div>
              <p>
                Properly designing and building a website or app can take a lot of planning and development time.
              </p>
            </div>
            <div>
              <div className={styles.sectionSubTitle}>
                <FontAwesomeIcon className={cn(styles.icon, styles.greenIcon)} icon={faUsers} />
                Team
              </div>
              <p>
                Building a site or app singehandedly just isn’t practical anymore. You need to find a team to help you.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.sectionBlock}>
          <div className={styles.sectionTitle}>
            The DIY way
          </div>
          <p>
            Let's take a look at how it usually works if you decide to tackle the project yourself:
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.greenIcon)} icon={faCheck} />
            <span className={styles.bold}>You have an idea and a budget.</span>
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.greenIcon)} icon={faCheck}/>
            <span className={styles.bold}>You look for a company</span> to help you implement the project, which may
            take a week or two, or even longer
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.greenIcon)} icon={faCheck} />
            <span className={styles.bold}>You can use Upwork</span>, but they charge extra and you won't be able to
            communicate with the contractor at your convenience, because you can only communicate on their platform.
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.greenIcon)} icon={faCheck} />
            <span className={styles.bold}>If you manage to find a contractor</span>, but they charge extra and you won't
            be able to communicate with the contractor at your convenience, because you can only communicate on their
            platform.
          </p>
          <p className={styles.bold}>
            Does that process look appealing to you?&nbsp;
            <span className={styles.purpleText}>
              Let's see how you can make it a lot easier, saving you time and nerves.
            </span>
          </p>
        </div>
        <div className={styles.sectionBlock}>
          <img src={landing4} alt="The DIY way" />
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.sectionBlock}>
          <img
            src={landing5}
            className={styles.marketplaceImage}
            alt="Marketplace"
          />
        </div>
        <div className={styles.sectionBlock}>
          <div className={styles.sectionTitle}>
            How can iBench help?
          </div>
          <p>
            If you want to build your own website or mobile app fast, and you don’t have enough time or skills to do it
            yourself, turn to iBench and hire a professional software company to take care of the whole project from
            soup to nuts. Get the job done with iBench in just five simple steps:
          </p>
          <p className={styles.bold}>
            Get the job done with iBench in just&nbsp;
            <span className={styles.purpleText}>
              five simple steps:
            </span>
          </p>
          <p className={styles.polygonText}>
            <img src={polygonPurple} className={styles.polygonIcon} alt="1"/>
            Create a project with a description.
          </p>
          <p className={styles.polygonText}>
            <img src={polygonYellow} className={styles.polygonIcon} alt="2"/>
            Choose whether to receive offers from all companies or only verified ones.
          </p>
          <p className={styles.polygonText}>
            <img src={polygonGreen} className={styles.polygonIcon} alt="3"/>
            Publish your project on the platform.
          </p>
          <p className={styles.polygonText}>
            <img src={polygonRed} className={styles.polygonIcon} alt="4"/>
            Receive offers from companies and communicate with them directly in the internal chat.
          </p>
          <p className={styles.polygonText}>
            <img src={polygonBlue} className={styles.polygonIcon} alt="5"/>
            Choose the best company to execute your project.
          </p>
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.sectionBlock}>
          <div className={cn(styles.sectionTitle, styles.mt25)}>
            Why iBench?
          </div>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.yellowIcon)} icon={faCheck} />
            We work with more than <span className={styles.bold}>300 companies</span> from around the world
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.yellowIcon)} icon={faCheck} />
            You choose a <span className={styles.bold}>100% verified company.</span>
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.yellowIcon)} icon={faCheck} />
            You work directly with a representative of the company,&nbsp;
            <span className={styles.bold}>without intermediaries or additional commissions</span>
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.yellowIcon)} icon={faCheck} />
            Hire remote experts with <span className={styles.bold}>200+ skills available</span>
          </p>
          <p className={styles.checkText}>
            <FontAwesomeIcon className={cn(styles.icon, styles.yellowIcon)} icon={faCheck} />
            The iBench team's hiring and selection services are available for&nbsp;
            <span className={styles.bold}>verified clients</span>
          </p>
        </div>
        <div className={cn(styles.sectionBlock, styles.mobileHidden)}>
          <img src={landing6} alt="My Developers" />
        </div>
      </section>
      <section className={styles.section}>
        <div className={cn(styles.sectionBlock, styles.mobileHidden)}>
          <img src={landing7} alt="Marketplace" />
        </div>
        <div className={cn(styles.sectionBlock, styles.marketplaceBlock)}>
          <div className={styles.sectionTitle}>
            Get Started!
          </div>
          <p>
            Are you excited to start building your website or app with iBench?&nbsp;
            <span className={styles.bold}>Sign up for a demo, or register</span> on the site and get started right away!
          </p>
          <div className={styles.buttonsBlock}>
            <a
              href="https://calendly.com/ibenchdenis/one2one"
              className={styles.btn}
              target="_blank"
              rel="nofollow"
            >
              Check out a demo
            </a>
            <NavLink to="/registration" className={styles.btn}>
              Start building my project
            </NavLink>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;
