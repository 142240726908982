import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import cn from "classnames";

import { siteNameForTitle, defaultDescription } from "../../constants";
import { normalizeURL, buildImageLink, buildWhatsappLink } from "../../utils/utils";
import LogosList from "./LogosList/LogosList";
import ClientLogos from "./ClientLogos/ClientLogos";
import SearchResultsItem from "../Startup/SearchResultsItem/SearchResultsItem";
import SocialLinks from "../common/SocialLinks/SocialLinks";

import default_company_photo from "../../assets/img/rowing.svg";
import verified_icon from "../../assets/img/verified.png";
import top10_icon from "../../assets/img/top-10.png";
import styles from "./CompanyDetails.module.css";

const CompanyDetails = (props) => {
  let {
    id,
    site,
    company_name,
    logo,
    country,
    telegram,
    linkedin,
    facebook,
    whatsapp,
    youtube,
    description,
    verified,
    team_size,
    founded,
    rates,
    awards,
    technologies,
    marketList,
    clients,
    cases,
    developers,
    in_top_10,
    official_email,
    popular_service_name,
  } = props?.companyData;

  const titlePartString = useMemo(() => {
    const titleParts = [popular_service_name || 'Web app development', company_name];

    if (country) {
      titleParts.push(`from ${country}`);
    }

    return titleParts.join(' ');

  }, [props.companyData]);

  const h1String = useMemo(() => {
    let title = titlePartString;

    if (site) {
      const normalizedLink = normalizeURL(site);
      title += ` - <a href="${normalizedLink}" target="_blank" rel="nofollow">${normalizedLink}</a>`;
    }

    return title;

  }, [props.companyData]);

  const titleString = useMemo(() => {
    let title = titlePartString;

    if (site) {
      const normalizedLink = normalizeURL(site);
      title += ` - ${normalizedLink}`;
    }

    return title;

  }, [props.companyData]);

  const {
    companyInfo,
    hire,
    incrementCTR,
    setSelectedTrialDeveloper,
    addClickServiceCompanyTelegramAnalytic,
    addClickServiceCompanyWhatsappAnalytic,
    addClickServiceCompanyEmailAnalytic,
  } = props;

  const siteUrl = normalizeURL(site);

  const clickTelegramServiceCompanyHandler = () => {
    // This function doesn't exist on profile page
    addClickServiceCompanyTelegramAnalytic?.(id);
  };

  const clickWhatsappServiceCompanyHandler = () => {
    // This function doesn't exist on profile page
    addClickServiceCompanyWhatsappAnalytic?.(id);
  };

  const clickEmailServiceCompanyHandler = () => {
    // This function doesn't exist on profile page
    addClickServiceCompanyEmailAnalytic?.(id);
  };

  const renderDeveloperCard = (developerData) => (
    <SearchResultsItem
      key={developerData.developer_id}
      search={developerData}
      company_id={companyInfo?.id}
      incrementCTR={incrementCTR}
      setSelectedTrialDeveloper={companyInfo ? null : setSelectedTrialDeveloper}
      hire={companyInfo?.type === 1 ? hire : null}
    />
  );

  const renderChatWithClientButton = () => {
    if (whatsapp) {
      return (
        <a
          href={buildWhatsappLink(whatsapp)}
          className={styles.chatWithClient}
          onClick={clickWhatsappServiceCompanyHandler}
          target="_blank"
          rel="nofollow"
        />
      )
    }

    return <div className={styles.chatWithClient} />;
  };

  return (
    <>
      <Helmet>
        <title>{siteNameForTitle} / {titleString}</title>
        <meta
          name="description"
          content={defaultDescription}
        />
        <meta
          name="keywords"
          content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
          data-react-helmet="true"
        />
      </Helmet>
      {props.withTitle && <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: h1String }} />}
      <div className={styles.contentWrapper}>
        <div className={styles.topSection}>
          <div className={styles.leftColumn}>
            <img
              className={styles.companyLogo}
              src={logo ? buildImageLink(logo) : default_company_photo}
              alt={company_name}
            />
            <div className={styles.top10Verified}>
              {!!in_top_10 && <img src={top10_icon} className={styles.verified} alt="In Top 10" />}
              {!!verified && <img src={verified_icon} className={styles.verified} alt="Verified" />}
            </div>
            {renderChatWithClientButton()}
            <div className={styles.awardsLogos}>
              {awards.map((award) => (
                <img
                  key={award.id}
                  className={styles.awardLogo}
                  src={buildImageLink(award.logo)}
                  alt={`${company_name} - ${award.name}`}
                />
              ))}
            </div>
          </div>
          <div className={styles.rightColumn}>
            <div className={cn(styles.companyInfoRow, styles.flex, styles.mainInfo)}>
              <div className={styles.leftColumn}>
                <div className={cn(styles.companyInfoColumn, styles.companyNameColumn)}>
                  <div className={styles.companyInfoItem}>
                    <span className={styles.bold}>Company: </span>
                    {company_name}
                  </div>
                  <div className={styles.companyInfoItem}>
                    {!!founded && (
                      <>
                        <span className={styles.bold}>Founded: </span>
                        {founded}
                      </>
                    )}
                  </div>
                </div>


                <div className={styles.companyInfoColumn}>
                  <div className={styles.companyInfoItem}>
                    <span className={styles.bold}>Location: </span>
                    {country}
                  </div>
                  <div className={styles.companyInfoItem}>
                    {siteUrl && (
                      <a className={styles.siteLink} href={siteUrl} target="_blank" rel="nofollow">
                        Company Website
                      </a>
                    )}
                  </div>
                </div>

              </div>

              <div className={styles.rightColumn}>
                <div className={styles.companyInfoColumn}>
                  <div className={styles.companyInfoItem}>
                    {!!team_size && (
                      <>
                        <span className={styles.bold}>Team: </span>
                        {team_size}
                      </>
                    )}
                  </div>
                  <div className={styles.companyInfoItem}>
                    {!!rates && (
                      <>
                        <span className={styles.bold}>Rates: </span>
                        ${rates} / hr
                      </>
                    )}
                  </div>
                </div>
                <div className={cn(styles.companyInfoRow, styles.flex, styles.locationRow)}>
                  <SocialLinks
                    className={styles.companyInfoColumn}
                    telegram={telegram}
                    linkedin={linkedin}
                    facebook={facebook}
                    whatsapp={whatsapp}
                    youtube={youtube}
                    official_email={official_email}
                    onTelegramClick={clickTelegramServiceCompanyHandler}
                    onWhatsappClick={clickWhatsappServiceCompanyHandler}
                    onEmailClick={clickEmailServiceCompanyHandler}
                  />
                </div>
              </div>
            </div>

            {!!description && (
              <div
                className={cn(styles.companyInfoRow, styles.description, "ql-editor")}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            {!!technologies.length && (
              <div className={styles.companyInfoRow}>
                <div className={styles.sectionTitle}>
                  Technologies we specialize in
                </div>
                <div className={styles.technologyItems}>
                  <LogosList items={technologies} />
                </div>
              </div>
            )}
            {!!marketList.length && (
              <div className={styles.companyInfoRow}>
                <div className={styles.sectionTitle}>
                  Areas of experience
                </div>
                <div className={styles.sectionDescription}>
                  Our knowledgeable team has worked in several industries, such as:
                </div>
                <div className={styles.marketItems}>
                  {marketList.map(market => (
                    <div key={market.market_id} className={styles.marketItem}>
                      {market.market_name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

        </div>
        {!!clients.length && (
          <div className={styles.bottomRow}>
            <div className={styles.sectionTitle}>
              Our clients
            </div>
            <div className={styles.clientItems}>
              <ClientLogos clients={clients} />
            </div>
          </div>
        )}
        {!!cases.length && (
          <div className={cn(styles.bottomRow, styles.casesSection)}>
            <div className={styles.sectionTitle}>
              Cases
            </div>
            <div className={styles.caseItems}>
              {cases.map((caseData) => (
                <div key={caseData.case_id} className={styles.caseBlock}>
                  <div className={styles.caseLeftColumn}>
                    <h2 className={styles.caseName}>
                      {caseData.name}
                    </h2>
                    <div className={styles.caseInfoRow}>
                      <span className={styles.bold}>Engineers involved: </span> {caseData.team_size}
                    </div>
                    {caseData.hours_spent && (
                      <div className={styles.caseInfoRow}>
                        <span className={styles.bold}>Total hours spent: </span> {caseData.hours_spent}
                      </div>
                    )}
                    <div className={styles.caseInfoRow}>
                      <span className={styles.bold}>Budget: </span> ${caseData.budget}
                    </div>
                    <div
                      className={cn(styles.caseInfoRow, styles.caseDescription, "ql-editor")}
                      dangerouslySetInnerHTML={{ __html: caseData.description}}
                    />
                    {caseData.links && (
                      <a
                        className={styles.caseLink}
                        href={normalizeURL(caseData.links)}
                        target="_blank"
                        rel="nofollow"
                      >
                        Visit website
                      </a>
                    )}
                  </div>
                  {caseData.covers?.[0] && (
                    <div className={styles.caseRightColumn}>
                      <img
                        className={styles.caseCover}
                        src={buildImageLink(caseData.covers[0])}
                        alt={`${company_name} - ${caseData.name}`}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

      </div>
      {developers.length > 0 && (
        <>
          <div className={styles.companyDevelopersTitle}>
            Company Developers
          </div>
          {developers.map(renderDeveloperCard)}
        </>
      )}
    </>
  );
};

export default CompanyDetails;
