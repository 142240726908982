import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { getSearches } from "../../../store/search";
import StartupSlots from "./StartupSlots";

const StartupSlotsContainer = (props) => {
  useEffect(() => {
    props.getSearches();
  }, []);

  return <StartupSlots {...props} />;
};

let mapStateToProps = (state) => ({
  search: state.search,
});

export default compose(connect(mapStateToProps, {
  getSearches,
}), withAuthRedirect)(StartupSlotsContainer);
