import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";

import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import FirstPartForm from "./FirstPartForm";
import SecondPartForm from "./SecondPartForm";
import Preloader from "../../common/Preloader/Preloader";

import styles from "./LeadForm.module.css";

let LeadForm = (props) => {
  // const [firstPart, setFirstPart] = useState(false);
  const [firstPart, setFirstPart] = useState(true);
  const [leadData, setLeadData] = useState({});

  useEffect(() => {
    const initLeadData = preparedLeadPriceData(props.initialValues);
    setLeadData(initLeadData);
  }, [props.initialValues]);

  const preparedLeadPriceData = (initLeadData) => {

    if (initLeadData.fixed_price && initLeadData.percentage) {

      initLeadData.money_fixed_price = initLeadData.fixed_price;
      initLeadData.money_percentage = initLeadData.percentage;
      delete initLeadData.fixed_price;
      delete initLeadData.percentage;
    }

    return initLeadData;
  };

  const saveFirstPart = (firstPartData) => {
    if (firstPartData.money_fixed_price) {
      firstPartData.fixed_price = firstPartData.money_fixed_price;
    }

    if (firstPartData.money_percentage) {
      firstPartData.percentage = firstPartData.money_percentage;
    }

    setLeadData(firstPartData);
    setFirstPart(false);
    window.scrollTo(0, 0);
  };

  const saveSecondPart = () => {
    props.onSubmit(props.values);
  };

  const previousHandler = () => {
    const leadData = preparedLeadPriceData(props.values);
    setLeadData(leadData);
    setFirstPart(true);
  };

  const renderFormPart = () => {
    if (firstPart) {
      return (
        <FirstPartForm
          onSubmit={saveFirstPart}
          initialValues={leadData}
        />
      );
    }

    return (
      <SecondPartForm
        onSubmit={saveSecondPart}
        onPrevious={previousHandler}
        initialValues={leadData}
      />
    )
  };

  return (
    <>
      {props.search.is_fetching && <Preloader/>}

      <DashboardMenuContainer/>

      <h1 className={styles.title}>
        <span>Sell leads</span>
      </h1>

      <div className={styles.form_title}>
        Please fill out this form as completely as possible
      </div>

      {renderFormPart()}
    </>
  );
};

LeadForm = connect(state => ({
  values: getFormValues("leadSecondPartForm")(state),
}))(LeadForm);

export default LeadForm;
