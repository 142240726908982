import { analyticsAPI } from "../api/analytics";

const SET_COMPANY_ANALYTICS = "ibench/developer/SET_COMPANY_ANALYTICS";

let initialState = {
  companyAnalytics: [],
};

const analytics = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_ANALYTICS:
      return { ...state, companyAnalytics: action.payload };
    default:
      return state;
  }
};

const setCompanyAnalytics = payload => ({ type: SET_COMPANY_ANALYTICS, payload });

export const getCompanyAnalytics = (searchParams) => {
  return (dispatch) => {
    analyticsAPI.getCompanyAnalytics(searchParams).then((response) => {
      dispatch(setCompanyAnalytics(response));
    });
  };
};

export default analytics;
