import React from "react";

import ClientBaseInfoForm from "./ClientBaseInfoForm";

const ClientProfileEditing = (props) => {
  const isCompany = !props.auth.user.is_single_developer;

  const onSubmit = (formData) => {
    const userData = {
      representative_name: formData.representative_name,
      position: formData.position,
      country: formData.country,
      city: formData.city,
      address: formData.address,
      description: formData.description,
      url_redirect: "/profile",
    };

    if (formData.rate_from && formData.rate_to) {
      userData.rates = `${formData.rate_from}-${formData.rate_to}`;
    } else {
      userData.rates = '';
    }

    if (formData.representative_photo?.length && typeof formData.representative_photo?.[0] !== "string") {
      userData.representative_photo = formData.representative_photo;
    }

    if (formData.logo?.length && typeof formData.logo?.[0] !== "string") {
      userData.logo = formData.logo;
    }

    props.editProfile(userData);
  };

  return (
    <ClientBaseInfoForm
      onSubmit={onSubmit}
      marketsList={props.markets_list}
      isCompany={isCompany}
    />
  );
};

export default ClientProfileEditing;
