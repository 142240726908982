import React from "react";
import { Helmet } from "react-helmet";

import LeadForm from "../LeadForm/LeadForm";

import { siteNameForTitle } from "../../../constants";
import styles from "./LeadEditing.module.css";
import { history } from "../../../utils";


const LeadEditing = (props) => {

  const onSubmit = (formData) => {
    props.updateLead(formData, () => {
      history.push(props.companyType === 1 ? "/marketplace/leads" : "/sell-leads");
    });
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Edit lead | {siteNameForTitle}</title>
      </Helmet>

      <LeadForm
        onSubmit={onSubmit}
        search={props.search}
        initialValues={props.leadData}
        leadData={props.leadData}
      />
    </div>
  );
};

export default LeadEditing;
