import { candidateSearchAPI } from "../api/candidates";
import { publicAPI } from "../api/public";
import { hire } from "./chat";
import { history } from "../utils";
import { notification } from "antd";

const IS_FETCHING = "ibench/candidates/IS_FETCHING";
const SET_CANDIDATE_SEARCHES = "ibench/candidates/SET_CANDIDATE_SEARCHES";
const SET_CANDIDATE_CURRENT_SEARCHES = "ibench/candidates/SET_CANDIDATE_CURRENT_SEARCHES";
const SET_CURRENT_TALENT = "ibench/candidates/SET_CURRENT_TALENT";
const SET_CANDIDATE_SEARCHE_RESULTS = "ibench/candidates/SET_CANDIDATE_SEARCHE_RESULTS";
const SET_NEW_TALENTS = "ibench/candidates/SET_NEW_TALENTS";

let initialState = {
  isFetching: false,
  searches: [],
  currentSearch: null,
  searchResults: [],
  additionalResults: [],
  currentTalent: null,
  newTalents: null,
};

const candidates = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, isFetching: action.payload };

    case SET_CANDIDATE_SEARCHES:
      return { ...state, searches: action.payload };

    case SET_CANDIDATE_CURRENT_SEARCHES:
      return { ...state, currentSearch: action.payload };

    case SET_CURRENT_TALENT:
      return { ...state, currentTalent: action.payload };

    case SET_NEW_TALENTS:
      return { ...state, newTalents: action.payload };

    case SET_CANDIDATE_SEARCHE_RESULTS:
      return {
        ...state,
        searchResults: action.payload.results,
        additionalResults: action.payload.additional,
      };

    default:
      return state;
  }
};

export const isFetching = (payload) => ({ type: IS_FETCHING, payload });

// GET OUTSOURCE SEARCH LIST
const setCandidateSearches = (payload) => ({ type: SET_CANDIDATE_SEARCHES, payload });
const setCandidateCurrentSearches = (payload) => ({ type: SET_CANDIDATE_CURRENT_SEARCHES, payload });
const setCandidateSearcheResults = (payload) => ({ type: SET_CANDIDATE_SEARCHE_RESULTS, payload });

export const getCandidateSearches = () => (dispatch) => {
  dispatch(isFetching(true));
  candidateSearchAPI.get().then((data) => {
    dispatch(setCandidateSearches(data.searches));
    dispatch(isFetching(false));
  });
};

export const getMarketplaceCandidateSearches = () => (dispatch) => {
  dispatch(isFetching(true));
  candidateSearchAPI.forMarketplace().then((data) => {
    dispatch(setCandidateSearches(data.searches));
    dispatch(isFetching(false));
  });
};

export const applyToVacancy = (data, companyId) => (dispatch) => {
  dispatch(isFetching(true));
  candidateSearchAPI.apply({ id: data.id }).then(() => {
    const message = `Hey! I would like to Apply on position: ${data.position_level_name} ${data.job_title_name}`;
    const chatId = `${data.company_id}:${companyId}`;
    dispatch(hire(message, chatId));
    dispatch(isFetching(false));
  });
};

export const addCandidateSearch = (data) => (dispatch) => {
  dispatch(isFetching(true));
  candidateSearchAPI.add(data).then((response) => {
    dispatch(isFetching(false));
    history.push(`/candidate-search/${response.id}`);
  });
};

export const updateCandidateSearches = (data, callback) => (dispatch) => {
  dispatch(isFetching(true));
  candidateSearchAPI.update(data).then(() => {
    dispatch(isFetching(false));
    callback?.();
  });
};

export const getCandidateSearchById = (id) => (dispatch) => {
  dispatch(isFetching(true));
  candidateSearchAPI.getById(id).then((data) => {
    dispatch(setCandidateCurrentSearches(data.search));
    dispatch(isFetching(false));
  });
};

export const deleteCandidateSearch = (id) => (dispatch) => {
  dispatch(isFetching(true));
  candidateSearchAPI.delete(id).then(() => {
    history.push("/search-candidates");
    dispatch(isFetching(false));
  });
};

export const getCandidateSearchResults = (id) => (dispatch) => {
  dispatch(isFetching(true));
  candidateSearchAPI.getResults(id).then((data) => {
    dispatch(setCandidateSearcheResults(data));
    dispatch(isFetching(false));
  });
};

const setCurrentTalent = (payload) => ({ type: SET_CURRENT_TALENT, payload });
export const getTalent = (id) => {
  return (dispatch) => {
    publicAPI.getTalent(id).then((data) => {
      dispatch(setCurrentTalent(data?.talent));
    });
  };
};

export const addCandidateSearchToMarketplace = (id) => (dispatch) => {
  dispatch(isFetching(true));
  candidateSearchAPI.addToMarketplace({ id }).then(() => {
    dispatch(isFetching(false));
    notification.success({
      message: 'Candidate search has been added to the marketplace',
      placement: 'bottomRight',
      duration: 5,
    });
  });
};

const setNewTalents = (payload) => ({ type: SET_NEW_TALENTS, payload });
export const getPublicTalents = () => (dispatch) => {
  publicAPI.getNewTalents().then((data) => {
    dispatch(setNewTalents(data?.talents));
  });
};

export default candidates;
