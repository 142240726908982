import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import styles from "./Profile.module.css";

const ProfileMenu = ({ userData }) => {
    return (
      <>
        <ul className={styles.subMenu}>
          <li>
            <NavLink to="/profile/edit">Edit profile</NavLink>
          </li>
          <li>
            <NavLink to="/password-edit">Edit password</NavLink>
          </li>
          <li>
            <NavLink to="/settings">Settings</NavLink>
          </li>
          {!userData.is_single_developer && userData.type === 2 && (
            <li>
              <NavLink to="/analytics">
                Analytics
              </NavLink>
            </li>
          )}
        </ul>
      </>
    );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth.user,
  };
};

export default connect(mapStateToProps)(ProfileMenu);
