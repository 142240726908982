import React, { useEffect, useMemo } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { history } from "../../../utils";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { getCandidateSearchById, updateCandidateSearches } from "../../../store/candidates";
import Preloader from "../../common/Preloader/Preloader";
import CandidateSearchForm from "../CandidateSearchForm/CandidateSearchForm";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";

import styles from "../CandidateSearchAdding/CandidateSearchAdding.module.css";

const CandidateSearchEditing = (props) => {
  const {
    getCandidateSearchById,
    updateCandidateSearches,
    match: { params: { search_id } },
    search,
  } = props;

  useEffect(() => {
    getCandidateSearchById(search_id);
  }, []);

  const onSubmit = (formData) => {
    updateCandidateSearches(formData, () => history.push(`/candidate-search/${search.id}`));
  };

  const initialValues = useMemo(() => {
    if (!search) {
      return {};
    }

    return {
      ...search,
      location: search?.location?.split(', '),
      exclude_location: search?.exclude_location?.split(', ') ,
    };
  }, [search]);

  return (
    <div className={styles.wrapper}>
      {props.auth.is_fetching && <Preloader/>}

      <DashboardMenuContainer/>

      <div className={styles.subtitle}>
        Find talents / Search talents
      </div>

      <CandidateSearchForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        edit
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  search: state.candidates.currentSearch,
});

const mapDispatchToProps = {
  updateCandidateSearches,
  getCandidateSearchById,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthRedirect
)(withRouter(CandidateSearchEditing));
