import { axiosInstances, axiosJSONInstances } from "./api";

const axiosInstance = axiosInstances.account;
const axiosJSONInstance = axiosJSONInstances.account;

export const candidateSearchAPI = {
  get() {
    return axiosInstance.get('api/users/candidate-search').then((response) => {
      return response.data;
    });
  },

  forMarketplace() {
    return axiosInstance.get('api/users/candidate-search/for-marketplace').then((response) => {
      return response.data;
    });
  },

  getById(id) {
    return axiosInstance.get(`api/users/candidate-search/${id}`).then((response) => {
      return response.data;
    });
  },

  add(data) {
    return axiosJSONInstance.post('api/users/candidate-search', data).then((response) => {
      return response.data;
    });
  },

  update(data) {
    return axiosJSONInstance.put('api/users/candidate-search', data).then((response) => {
      return response.data;
    });
  },

  getResults(id) {
    return axiosInstance.get(`api/users/candidate-search/results/${id}`).then((response) => {
      return response.data;
    });
  },

  delete(id) {
    return axiosInstance.delete(`api/users/candidate-search/${id}`).then((response) => {
      return response.data;
    });
  },

  addToMarketplace(data) {
    return axiosJSONInstance.post('api/users/candidate-search/add-to-marketplace', data).then((response) => {
      return response.data;
    });
  },

  apply(data) {
    return axiosJSONInstance.post('api/users/candidate-search/apply', data).then((response) => {
      return response.data;
    });
  }
};
