import { bookmarksAPI } from "api/bookmarks";
import { getSearchResults } from "./search";
import store from "./store";

const IS_FETCHING = "ibench/bookmarks/IS_FETCHING";
const IS_MODAL = "ibench/bookmarks/IS_MODAL";
const SET_BOOKMARK_FORM_STATUS = "ibench/bookmarks/SET_BOOKMARK_FORM_STATUS";
const SET_BOOKMARKS_LIST = "ibench/bookmarks/SET_BOOKMARKS_LIST";
const SET_RESPONSE_MESSAGE = "ibench/bookmarks/SET_RESPONSE_MESSAGE";
const SET_BOOKMARK_DATA = "ibench/bookmarks/SET_BOOKMARK_DATA";
const SET_BOOKMARK_NAME = "ibench/bookmarks/SET_BOOKMARK_NAME";
const IS_ADDING_TO_BOOKMARK = "ibench/bookmarks/IS_ADDING_TO_BOOKMARK";
const SET_CURRENT_DEVELOPER_ID = "ibench/bookmarks/SET_CURRENT_DEVELOPER_ID";
const SET_CURRENT_DEVELOPER_BOOKMARKS =
  "ibench/bookmarks/SET_CURRENT_DEVELOPER_BOOKMARKS";
const SET_CURRENT_BOOKMARK_ID = "ibench/bookmarks/SET_CURRENT_BOOKMARK_ID";
const IS_BOOKMARK_DELETE = "ibench/bookmarks/IS_BOOKMARK_DELETE";

let initialState = {
  isFetching: false,
  isModal: false,
  bookmarkFormStatus: null,
  bookmarksList: [],
  responseMessage: null,
  bookmarkData: null,
  bookmarkName: null,
  isBookmarkAdding: null,
  currentDeveloperId: null,
  currentDeveloperBookmarks: [],
  currentBookmarkId: null,
  isBookmarkDelete: false,
};

const bookmarks = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case IS_MODAL:
      return {
        ...state,
        isModal: action.payload,
      };

    case SET_BOOKMARK_FORM_STATUS:
      return {
        ...state,
        bookmarkFormStatus: action.payload,
      };

    case SET_BOOKMARKS_LIST:
      return {
        ...state,
        bookmarksList: action.payload,
      };

    case SET_RESPONSE_MESSAGE:
      return {
        ...state,
        responseMessage: action.payload,
      };

    case SET_BOOKMARK_DATA:
      return {
        ...state,
        bookmarkData: action.payload,
      };
    case SET_BOOKMARK_NAME:
      let currentBookmark = state.bookmarksList.filter(
        (item) => item.bookmark_id === action.payload
      );
      return {
        ...state,
        bookmarkName:
          currentBookmark.length && currentBookmark[0].bookmark_name,
      };

    case IS_ADDING_TO_BOOKMARK:
      return {
        ...state,
        isBookmarkAdding: action.payload,
      };

    case SET_CURRENT_DEVELOPER_ID:
      return {
        ...state,
        currentDeveloperId: action.payload,
      };

    case SET_CURRENT_DEVELOPER_BOOKMARKS:
      let currentDeveloperBookmarks = state.bookmarksList.map((bookmark) => {
        let savedBookmarks = action.payload.filter((item) => {
          return Number(item.bookmark_id) === bookmark.bookmark_id;
        });

        if (savedBookmarks.length) return { ...bookmark, saved: true };
        else return { ...bookmark, saved: false };
      });

      return {
        ...state,
        currentDeveloperBookmarks,
      };

    case SET_CURRENT_BOOKMARK_ID:
      return {
        ...state,
        currentBookmarkId: action.payload,
      };

    case IS_BOOKMARK_DELETE:
      return {
        ...state,
        isBookmarkDelete: action.payload,
      };

    default:
      return state;
  }
};

export const isFetching = (payload) => ({
  type: IS_FETCHING,
  payload,
});

export const showModal = (payload) => ({
  type: IS_MODAL,
  payload,
});

export const setBookmarkFormStatus = (payload) => ({
  type: SET_BOOKMARK_FORM_STATUS,
  payload,
});

export const setResponseMessage = (payload) => ({
  type: SET_RESPONSE_MESSAGE,
  payload,
});

// SET CURRENT DEVELOPER ID
export const setCurrentDeveloperId = (payload) => ({
  type: SET_CURRENT_DEVELOPER_ID,
  payload,
});

// SET CURRENT DEVELOPER BOOKMARKS
export const setCurrentDeveloperBookmarks = (payload) => ({
  type: SET_CURRENT_DEVELOPER_BOOKMARKS,
  payload,
});

// SET CURRENT BOOKMARK ID
export const setCurrentBookmarkId = (payload) => ({
  type: SET_CURRENT_BOOKMARK_ID,
  payload,
});

// GET BOOKMARKS LIST
export const setBookmarksList = (payload) => ({
  type: SET_BOOKMARKS_LIST,
  payload,
});
export const getBookmarksList = () => {
  return (dispatch) => {
    bookmarksAPI.getBookmarksList().then((data) => {
      dispatch(setBookmarksList(data.bookmarksList));

      let currentBookmarkId = store.getState().bookmarks.currentBookmarkId;
      if (currentBookmarkId) dispatch(setBookmarkName(currentBookmarkId));
    });
  };
};

// ADD BOOKMARK
export const addBookmark = (data) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    bookmarksAPI.addBookmark(data).then(() => {
      dispatch(getBookmarksList());
      dispatch(setResponseMessage("Bookmark has been added."));
      dispatch(isFetching(false));
    });
  };
};

// EDIT BOOKMARK
export const editBookmark = (bookmark_id, bookmark_name) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    bookmarksAPI.editBookmark(bookmark_id, bookmark_name).then(() => {
      dispatch(getBookmarksList());
      dispatch(setResponseMessage("Bookmark has been edited."));
      dispatch(isFetching(false));
    });
  };
};

export const setBookmarkDelete = (payload) => ({
  type: IS_BOOKMARK_DELETE,
  payload,
});

// DELETE BOOKMARK
export const deleteBookmark = (bookmark_id) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    bookmarksAPI.deleteBookmark(bookmark_id).then(() => {
      dispatch(getBookmarksList());
      dispatch(setResponseMessage("Bookmark has been deleted."));
      dispatch(isFetching(false));
    });
  };
};

// GET DEVELOPERS FROM BOOKMARK
export const setBookmarkData = (payload) => ({
  type: SET_BOOKMARK_DATA,
  payload,
});
export const setBookmarkName = (payload) => ({
  type: SET_BOOKMARK_NAME,
  payload,
});
export const getBookmarksDevelopers = (developer_id) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    bookmarksAPI.getBookmarksDevelopers(developer_id).then((data) => {
      dispatch(setBookmarkData(data.bookmarkData));
      dispatch(setBookmarkName(developer_id));
      dispatch(isFetching(false));
    });
  };
};

export const setAddingToBookmark = (payload) => ({
  type: IS_ADDING_TO_BOOKMARK,
  payload,
});

// ADD DEVELOPER TO BOOKMARK
export const addDeveloperToBookmark = (bookmark_id, developer_id) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    let search = store.getState().search;
    let search_id = search.current_search && search.current_search.search_id;

    let bookmarks = store.getState().bookmarks;
    let currentBookmarkId =
      bookmarks.currentBookmarkId && bookmarks.currentBookmarkId;

    bookmarksAPI
      .addDeveloperToBookmark(bookmark_id, developer_id)
      .then(() => {
        dispatch(setResponseMessage("Bookmarks saved!"));
        if (currentBookmarkId)
          dispatch(getBookmarksDevelopers(currentBookmarkId));
        if (search_id) dispatch(getSearchResults(search_id));
        dispatch(isFetching(false));
      })
      .catch((e) => {
        dispatch(isFetching(false));
      });
  };
};

// REMOVE DEVELOPER FROM BOOKMARK
export const deleteDeveloperFromBookmark = (bookmark_id, developer_id) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    let search = store.getState().search;
    let search_id = search.current_search && search.current_search.search_id;

    let bookmarks = store.getState().bookmarks;
    let currentBookmarkId =
      bookmarks.currentBookmarkId && bookmarks.currentBookmarkId;

    bookmarksAPI
      .deleteDeveloperFromBookmark(bookmark_id, developer_id)
      .then(() => {
        dispatch(setResponseMessage("Bookmarks saved!"));
        if (currentBookmarkId)
          dispatch(getBookmarksDevelopers(currentBookmarkId));
        if (search_id) dispatch(getSearchResults(search_id));
        dispatch(isFetching(false));
      })
      .catch((e) => {
        dispatch(isFetching(false));
      });
  };
};

export default bookmarks;
