import { useState } from "react";
import { Helmet } from "react-helmet";
import { Field, FieldArray, reduxForm, reset } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { required, numericality } from "redux-form-validators";
import moment from "moment";

import {
  Input,
  SelectInput,
  SelectInputKeyValue,
  SelectInputEnglish,
  SelectInputSkill,
  renderMultiselect,
} from "../../common/FormsControls/FormControls";
import Preloader from "../../common/Preloader/Preloader";
import ProfileMenu from "../../Profile/ProfileMenu";
import CompanyMenu from "../../Profile/CompanyMenu";
import { siteNameForTitle } from "../../../constants/global-constants";
import DeveloperProject from "../DeveloperProject/DeveloperProject";
import UploadImage from "../../common/UploadImage/UploadImage";
import Editor from "../../common/SimpleEditor/SimpleEditor";
import DatePickerWrapper from "../../common/DatePickerWrapper/DatePickerWrapper";

import styles from "./DeveloperAdding.module.css";
import React from "react";

let DeveloperAddingForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <Field component={UploadImage} name="photo" label="Upload Photo" mode="photo"/>
        <Field
          component={Input}
          type={"text"}
          name={"name"}
          label="Name"
          validate={[required()]}
          help_text="for example: James"
        />
        <Field
          component={SelectInputKeyValue}
          name="job_title_id"
          label="Job title"
          validate={[required()]}
          help_text="for example: Front-end Developer"
          options={props.jobTitlesList}
        />
        <Field
          component={SelectInput}
          name={"country"}
          label="Developer's Location"
          options={props.countries_list}
          validate={[required()]}
          help_text="for example: Ukraine"
        />
        <Field
          component={Editor}
          type={"text"}
          name={"general_info"}
          label="About developer"
          validate={[required()]}
          rows={4}
        />
      </div>

      <div className={styles.submit_wrapper}>
        <button className={`btn ${styles.submit}`}>Save</button>
      </div>
    </form>
  );
};

const renderSkills = ({ fields, meta: { error, submitFailed }, ...restProps }) => {
  return (
    <div>
      {fields.map((skill, index) => (
        <div key={index} className={styles.skill_wrapper}>
          <Field
            name={`${skill}.skill_id`}
            component={SelectInputSkill}
            skills_list={restProps.skills_list}
            label="Skill"
            validate={[required()]}
            help_text="for example: JS"
          />
          <Field
            name={`${skill}.skill_experience`}
            component={SelectInput}
            label="Experience"
            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            validate={[required()]}
            help_text="for example: 3 years"
          />
          <button
            type="button"
            title="Remove Skill"
            onClick={() => fields.remove(index)}
            className={styles.removeSkill}
          />
        </div>
      ))}
      <div>
        <button type="button" onClick={() => fields.push({})}>
          Add Skill
        </button>
        {submitFailed && error && <span>{error}</span>}
      </div>
    </div>
  );
};

let DeveloperProjectForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <Field
          component={Input}
          type={"text"}
          name={"project_name"}
          label="Project name"
          validate={[required()]}
          help_text="for example: Smart solutions"
        />
        <Field
          component={Editor}
          type={"text"}
          name={"description"}
          label="Project description"
          validate={[required()]}
          rows={4}
        />
        <Field component={Input} type={"text"} name={"link"} label="Project link"/>
        <div className={styles.projectDuration}>
          <div className={styles.projectDurationItem}>
            <Field
              name={"from_year"}
              label="Project start"
              defaultValue={props.initialValues?.from_year && moment(props.initialValues.from_year)}
              component={DatePickerWrapper}
              help_text="yyyy/mm"
              validate={[required()]}
            />
          </div>
          <div className={styles.projectDurationItem}>
            <Field
              name={"to_year"}
              label="Project end"
              defaultValue={props.initialValues?.to_year && moment(props.initialValues.to_year)}
              component={DatePickerWrapper}
              help_text="yyyy/mm"
              validate={[required()]}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Field
              name={"main_skill_id"}
              component={SelectInputSkill}
              skills_list={props.skills_list}
              label="Main skill"
              validate={[required()]}
              help_text="for example: JS"
            />
          </div>
          <div className={styles.col}>
            <Field
              component={Input}
              type={"number"}
              name={"team_size"}
              label="Team size"
              validate={[required(), numericality({ int: true, ">": 1 })]}
              help_text="For example: 8"
            />
          </div>
        </div>
        <div className={styles.projectSkillsWrapper}>
          <Field
            name={"skills"}
            component={renderMultiselect}
            label="Technologies"
            validate={[required()]}
            data={props.skills_list}
            valueField="skill_id"
            textField="skill_name"
            placeholder="Enter the project technologies"
          />
        </div>
        <Field
          component={Editor}
          type={"text"}
          name={"responsibilities"}
          label="Responsibilities"
          validate={[required()]}
          rows={4}
        />
      </div>

      <div className={styles.submit_wrapper}>
        <button className={`btn ${styles.submit}`}>Save</button>
      </div>
    </form>
  );
};

let DeveloperEditingForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        {!props.isSingleDeveloper && (
          <Field
            component={Input}
            type={"number"}
            name={"rate"}
            label="Hourly Rate"
            validate={[required()]}
            help_text="For example: $35/h"
          />
        )}
        {!!props.isSingleDeveloper && (
          <Field
            component={Input}
            type={"number"}
            name={"rate_month"}
            label="Salary/month"
            validate={[required()]}
            help_text="For example: $3000"
          />
        )}
        <Field
          name={"main_skill_id"}
          component={SelectInputSkill}
          skills_list={props.skills_list}
          label="Main skill"
          validate={[required()]}
          help_text="for example: JS"
        />
        <FieldArray name="skills" component={renderSkills} skills_list={props.skills_list}/>
        <Field
          component={SelectInput}
          name={"experience"}
          label="Experience"
          options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          validate={[required()]}
          help_text="for example: 3 years"
        />
        <Field
          component={SelectInputEnglish}
          name="english_level_id"
          label="English level"
          validate={[required()]}
          help_text="for example: B1 (intermediate)"
          options={props.englishLevelsList}
        />
        <Field
          component={SelectInputKeyValue}
          name="education"
          label="Education"
          validate={[required()]}
          help_text="for example: PhD."
          options={props.degreesList}
        />
      </div>

      <div className={styles.submit_wrapper}>
        <button className={`btn ${styles.submit}`}>Save</button>
      </div>
    </form>
  );
};

DeveloperAddingForm = reduxForm({ form: "developerAdding" })(DeveloperAddingForm);
DeveloperProjectForm = reduxForm({ form: "developerProject" })(DeveloperProjectForm);
let DeveloperProjectInitialReduxForm = connect((state, props) => ({
  initialValues: state.developer.current_developer.projects[props.editingProject], // pull initial values
}))(DeveloperProjectForm);
DeveloperEditingForm = reduxForm({ form: "developerEditing" })(DeveloperEditingForm);

const DeveloperAdding = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isSecondTabDisabled, setIsSecondTabDisabled] = useState(false);
  const [isThirdTabDisabled, setIsThirdTabDisabled] = useState(true);
  const [isProjectFormOpen, setIsProjectFormOpen] = useState(true);
  const [editingProject, setEditingProject] = useState(null);

  const dispatch = useDispatch();

  const handleSelect = (index) => setSelectedIndex(index);

  const handleTabSwitch = (index) => {
    setSelectedIndex(index);
    if (index === 1) setIsSecondTabDisabled(false);
    if (index === 2) setIsThirdTabDisabled(false);
  };

  const onSubmit = (formData) => {
    props.addDeveloper(formData, props.companyInfo.is_single_developer);
    handleTabSwitch(1);
  };

  const onSubmitProject = (formData) => {
    props.addDeveloperProject(props.developer.developer_id, formData);
    setIsProjectFormOpen(false);
    setEditingProject(null);
    dispatch(reset("developerProject"));
  };

  const onEditProject = (formData) => {
    props.editDeveloperProject(props.developer.developer_id, formData);
    setIsProjectFormOpen(false);
    setEditingProject(null);
    dispatch(reset("developerProject"));
  };

  const onDeleteProject = (projectId) => {
    props.deleteDeveloperProject(props.developer.developer_id, { project_id: projectId });
  };

  const editOnSubmit = (formData) => {
    formData = {
      ...formData,
      developer_id: props.developer.developer_id,
    };
    if (formData.skills) {
      formData = {
        ...formData,
        add_skills: [...formData.skills],
      };
    }
    props.editDeveloper(formData);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Add developer | {siteNameForTitle}</title>
      </Helmet>

      {props.developer.is_fetching && <Preloader/>}
      {/*<DashboardMenuContainer/>*/}

      <h2 className={`${styles.title} text_center`}>
        Company profile
      </h2>

      <ProfileMenu />

      <CompanyMenu companyInfo={props.companyInfo} />

      <div className={styles.slotNumber}>
        {props.companyInfo.is_single_developer ? 'Add my profile' : `Add Developer #${props.slot_number}`}
      </div>

      <div className={styles.formWrapper}>
        <Tabs selectedIndex={selectedIndex} onSelect={handleSelect} forceRenderTabPanel={true}>
          <TabList className={styles.addingMenu}>
            <Tab className={styles.addingMenuItem}>Base info</Tab>
            <Tab className={styles.addingMenuItem} disabled={isSecondTabDisabled}>
              Projects
            </Tab>
            <Tab className={styles.addingMenuItem} disabled={isThirdTabDisabled}>
              Skills and Rate
            </Tab>
          </TabList>
          <TabPanel>
            <DeveloperAddingForm
              onSubmit={onSubmit}
              jobTitlesList={props.jobTitlesList}
              countries_list={props.countries_list}
            />
          </TabPanel>
          <TabPanel>
            {isProjectFormOpen ? (
              <DeveloperProjectForm onSubmit={onSubmitProject} skills_list={props.skills_list}/>
            ) : (
              <div className={styles.submit_wrapper}>
                <button className={`btn ${styles.submit}`} onClick={() => setIsProjectFormOpen(true)}>
                  Add new project
                </button>
              </div>
            )}

            {props.developer.current_developer?.projects?.map((project, index) => (
              <>
                {index === editingProject ? (
                  <DeveloperProjectInitialReduxForm
                    editingProject={editingProject}
                    skills_list={props.skills_list}
                    onSubmit={onEditProject}
                  />
                ) : (
                  <div className={styles.developerProjectWrapper}>
                    <DeveloperProject key={project.project_id} number={index + 1} {...project} />
                    <div className={styles.developerProjectActions}>
                      <button
                        className={`btn btn_pink_2 ${styles.submit}`}
                        onClick={() => setEditingProject(index)}
                      >
                        Edit project
                      </button>
                      <button
                        className={`btn btn_red ${styles.submit}`}
                        onClick={() => onDeleteProject(project.project_id)}
                      >
                        Delete project
                      </button>
                    </div>
                  </div>
                )}
              </>
            ))}

            <div className={styles.submit_wrapper}>
              <button
                className={`btn ${styles.submit}`}
                onClick={() => handleTabSwitch(2)}
                disabled={!props.developer?.current_developer?.projects?.length}
              >
                NEXT
              </button>
            </div>
          </TabPanel>
          <TabPanel>
            <DeveloperEditingForm
              onSubmit={editOnSubmit}
              skills_list={props.skills_list}
              englishLevelsList={props.englishLevelsList}
              degreesList={props.degreesList}
              isSingleDeveloper={props.isSingleDeveloper}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default DeveloperAdding;
