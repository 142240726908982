import qs from "qs";
import { axiosInstances, axiosJSONInstances } from "./api";

const axiosInstance = axiosInstances.auth;
const axiosJSONInstance = axiosJSONInstances.auth;

export const publicAPI = {
  getStats() {
    return axiosInstance.get('api/public/stats').then((response) => {
      return response;
    });
  },

  getNewCompanies() {
    return axiosInstance.get('api/public/stats/companies').then((response) => {
      return response;
    });
  },

  getNewStartups() {
    return axiosInstance.get('api/public/stats/startups').then((response) => {
      return response;
    });
  },

  requestTeam(data) {
    return axiosInstance.post('api/public/team/request', qs.stringify(data)).then((response) => {
      return response;
    });
  },

  getPositions() {
    return axiosInstance.get('api/public/list/positions').then((response) => {
      return response.data.position_level_list;
    });
  },

  getDeveloper(developer_id) {
    return axiosInstance.get(`api/public/developer/${developer_id}`).then((response) => {
      return response;
    });
  },

  getLead(lead_id) {
    return axiosInstance.get(`api/public/lead/${lead_id}`).then((response) => {
      return response;
    });
  },

  getCompany(company_id) {
    return axiosInstance.get(`api/public/company/${company_id}`).then((response) => {
      return response.data;
    });
  },

  getTalent(id) {
    return axiosInstance.get(`api/public/talent/${id}`).then((response) => {
      return response.data;
    });
  },

  getNewTalents() {
    return axiosJSONInstance.get('api/public/new-talents').then((response) => {
      return response.data;
    });
  },

  getVerifiedCompanies() {
    return axiosInstance.get('api/public/verified-companies').then((response) => {
      return response.data;
    });
  },

  getVerifiedCompaniesByService(serviceId) {
    return axiosInstance.get(`api/public/verified-companies-by-service/${serviceId}`).then((response) => {
      return response.data;
    });
  },

  getIndustriesList() {
    return axiosInstance.get('api/public/industries').then((response) => {
      return response.data;
    });
  },

  getIndustriesTopCompanies(industryId) {
    return axiosInstance.get(`api/public/industries/top-companies/${industryId}`).then((response) => {
      return response.data;
    });
  },

  getPopularServicesList() {
    return axiosInstance.get('api/public/popular-services').then((response) => {
      return response.data;
    });
  },

  getPopularServicesTopCompanies(popularServiceId) {
    return axiosInstance.get(`api/public/popular-services/top-companies/${popularServiceId}`).then((response) => {
      return response.data;
    });
  },

  getProjectAreas() {
    return axiosInstance.get('api/public/project-areas').then((response) => {
      return response.data;
    });
  },

  addAnalytic(data) {
    return axiosJSONInstance.post('api/public/add-analytic', JSON.stringify(data)).then((response) => {
      return response;
    });
  },
};
