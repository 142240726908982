import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { siteNameForTitle } from "../../constants/global-constants";
import { history } from "../../utils";

import thankYouImage from "../../assets/img/thankyou.png";
import styles from "./ThankYou.module.css";

const ThankYou = (props) => {
  const { isSingleDeveloper } = props;

  useEffect(() => {
    setTimeout(() => {
      const redirectURL = isSingleDeveloper ? "/profile/edit" : "/stats";
      history.push(redirectURL);
    }, 3000);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Thank You | {siteNameForTitle}</title>
      </Helmet>
      <div className={styles.successWrapper}>
        <div className={styles.success} />
        <img
          src={thankYouImage}
          className={styles.thankYouImage}
          alt="Thank you"
        />
        <div className={styles.messageWrapper}>
          <p className={styles.message}>
            Thank you for the successful registration.
          </p>
          <p className={styles.message}>
            Don't forget to invite one of your friends to register.
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isSingleDeveloper: state.auth.user.is_single_developer,
});

export default connect(mapStateToProps)(ThankYou);
