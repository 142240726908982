import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Lottie from "react-lottie-player";
import cn from "classnames";

import { siteNameForTitle, youtubeFrontLinks } from "../../constants";
import { getRandomArrayItem } from "../../utils/utils";
import CompanyTop10Card from "../common/CompanyTop10Card/CompanyTop10Card";
import { DropdownIdName } from "../common/FormsControls/FormControls";
import Video from "../common/Video/Video";


import banner_image from "../../assets/img/banner.png";
import need_new_team_image from "../../assets/img/need_new_team.png";
import sacha_avatar from "../../assets/img/sacha.jpg";
import sasha_avatar from "../../assets/img/sasha.jpg";
import robert_avatar from "../../assets/img/robert.jpg";
import download_pdf_image from "../../assets/img/download-pdf.jpg";
import * as team_animation from "../../assets/animation/desktop-and-smartphone-app-development";
import * as popular_team_animation from "../../assets/animation/popular-team";
import styles from "./FrontPage.module.css";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

const clientsData = [{
  avatar: sacha_avatar,
  name: 'Sacha Nitsetska',
  jobTitle: 'Founder&CEO at Mavenli, London, UK',
  info: 'Having worked with iBench on a long-term app development project, I can say with certainty that this is a fantastic team with a wide breadth of capabilities that I can rely on to solve any problem that may arise. It is a breath of fresh air to work with a team that is so deeply positive, committed, honest, enthusiastic and helpful in every respect.',
}, {
  avatar: robert_avatar,
  name: 'Robert Schmidt',
  jobTitle: 'Founder of DevSpire, Germany',
  info: 'Denis iBench worked exceptionally well with us to find our ideal candidate, he communicated well and was very responsive throughout the whole process. He only forwarded us interesting, high-profile candidates and it only took us a couple of interviews to find a match. We can highly recommend working with him.'
}, {
  avatar: sasha_avatar,
  name: 'Sascha Schwabbauer',
  jobTitle: 'VP of Engineering, SDKs of IMG.LY, Germany',
  info: 'Denis iBench worked exceptionally well with us to find our ideal candidate, he communicated well and was very responsive throughout the whole process. He only forwarded us interesting, high-profile candidates and it only took us a couple of interviews to find a match. We can highly recommend working with him.'
}];

const FrontPage = (props) => {
  const {
    popularServices,
    popularServicesTopCompanies,
    getPopularServicesTopCompanies,
    defaultPopularServiceId,
    addViewBannerAnalytic,
    addClickBannerAnalytic,
    addChooseServiceAnalytic,
    addViewServiceCompaniesAnalytic,
    addClickServiceCompanyAnalytic,
    addClickServiceCompanyTelegramAnalytic,
    addClickServiceCompanyWhatsappAnalytic,
    addClickServiceCompanyEmailAnalytic,
  } = props;

  const [selectedPopularService, setSelectedPopularService] = useState({});
  const [videoLink, setVideoLink] = useState(0);

  useEffect(() => {
    addViewBannerAnalytic();
    const randomLink = getRandomArrayItem(youtubeFrontLinks);
    setVideoLink(randomLink);
  }, []);

  useEffect(() => {
    if (popularServices.length) {
      changePopularServiceHandler(defaultPopularServiceId, false);
    }
  }, [popularServices]);

  useEffect(() => {
    if (popularServicesTopCompanies?.length) {
      const companiesList = popularServicesTopCompanies.map(company => company.id);
      addViewServiceCompaniesAnalytic(selectedPopularService.id, companiesList);
    }
  }, [popularServicesTopCompanies]);

  const clickBannerHandler = () => {
    addClickBannerAnalytic();
  };

  const clickServiceCompanyHandler = (companyId) => {
    addClickServiceCompanyAnalytic(selectedPopularService.id, companyId);
  };

  const clickTelegramServiceCompanyHandler = (companyId) => {
    addClickServiceCompanyTelegramAnalytic(companyId, selectedPopularService.id);
  };

  const clickWhatsappServiceCompanyHandler = (companyId) => {
    addClickServiceCompanyWhatsappAnalytic(companyId, selectedPopularService.id);
  };

  const clickEmailServiceCompanyHandler = (companyId) => {
    addClickServiceCompanyEmailAnalytic(companyId, selectedPopularService.id);
  };

  const changePopularServiceHandler = (popularServiceId, addAnalytic = true) => {
    popularServiceId = +popularServiceId;
    const popularServiceData = popularServices.find(popularService => popularService.id === popularServiceId);
    setSelectedPopularService(popularServiceData);
    getPopularServicesTopCompanies(popularServiceId);
    addAnalytic && addChooseServiceAnalytic(popularServiceId);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{siteNameForTitle}</title>
        <meta
          name="description"
          content="iBench India. Find indian development teams and developers here"
        />
        <meta
          name="keywords"
          content="Indian developers, Indian development teams, Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
          data-react-helmet="true"
        />
      </Helmet>

      {/* <Breadcrumbs currentPage="Development teams" /> */}

      <div className="mobile_hidden">
        <div className={styles.calculatorSection}>
          <div className={styles.leftColumn}>
            <h1 className={styles.title}>
              Select a
              <span className={styles.yellowFont}> development team </span>
              for your project
            </h1>
            <div className={styles.calculatorInfoRow}>
              Here, you can choose from teams specializing in Web and Mobile application development, as well as
              e-commerce solutions for Shopify, Websites development and landing pages development.
              {/*Here, you can choose from teams specializing in*/}
              {/*<strong> <Link to="/verifiedteams/web-app-development-teams">Web</Link> </strong>*/}
              {/*and{" "}*/}
              {/*<strong><Link to="/verifiedteams/mobile-app-development">Mobile application development</Link> </strong>,*/}
              {/*as well as{" "}*/}
              {/*<strong>*/}
                {/*<Link to="/verifiedteams/shopify-development-teams">e-commerce solutions for Shopify</Link>*/}
              {/*</strong>,*/}
              {/*<strong> <Link to="/verifiedteams/company-websites">Websites development</Link> </strong>*/}
              {/*and{" "}*/}
              {/*<strong>*/}
                {/*<Link to="/verifiedteams/landing-pages-development-teams">landing pages development</Link>*/}
              {/*</strong>.*/}
            </div>
          </div>
          <div className={styles.calculatorWrapper}>
            <div className={styles.animationWrapper}>
              <Lottie
                loop
                animationData={team_animation}
                play
              />
            </div>
          </div>
        </div>

        <div className={styles.topForm}>
          <div className={styles.mobileButtons}>
            <Link to="/team-search">
              <img src={need_new_team_image} alt="I need a new team"/>
            </Link>
          </div>
          <div className={styles.describeProject}>
            If you don't have time, describe your project to us, and we will select several teams suitable for your
            project.
          </div>
        </div>

        <div className={cn(styles.calculatorSection, styles.popularTeams)}>
          <div className={styles.leftColumn}>
            <h2 className={styles.title}>
              Popular<br />
              <span className={styles.yellowFont}>development teams</span>
            </h2>
            <div className={styles.calculatorInfoRow}>
              We recommend you some of the teams that we have verified, who have expertise.
            </div>
            <DropdownIdName
              className={styles.select}
              options={popularServices}
              label="Project type"
              value={selectedPopularService.id}
              onChange={changePopularServiceHandler}
            />
          </div>
          <div className={styles.calculatorWrapper}>
            <div className={styles.animationWrapper}>
              <Lottie
                loop
                animationData={popular_team_animation}
                play
              />
            </div>
          </div>
        </div>

        <div className={styles.list_title}>
          <h3>
            List of the <span className={styles.blueFont}>Popular, {selectedPopularService.name} </span>
            development teams
          </h3>
          <a
            href="https://t.me/Gendolf77"
            target="_blank"
            rel="nofollow"
            className={styles.advertising}
          >
            Advertising
          </a>
        </div>
      </div>

      <div className="mobile_visible">
        <div className={styles.blueSection}>
          <h1 className={styles.mobileTitle}>
            We will help you select a development team for your project
          </h1>
        </div>
        <div className={styles.animationWrapper}>
          <Lottie
            loop
            animationData={team_animation}
            play
          />
        </div>
        <div className={styles.animationText}>
          We'll help you select a team that can complete your project on time, meet standards, and adhere to programming
          norms, from our pool of trusted, proven teams.
        </div>

        <div className={styles.mobileButtons}>
          <Link to="/team-search">
            <img src={need_new_team_image} alt="I need a new team"/>
          </Link>
        </div>
        <div className={styles.yellowSection}>
          <h2>
            Popular Development Teams
          </h2>
        </div>
        <div className={styles.secondAnimationWrapper}>
          <Lottie
            loop
            animationData={popular_team_animation}
            play
          />
        </div>
        <DropdownIdName
          className={styles.select}
          options={popularServices}
          label="Project type"
          value={selectedPopularService.id}
          onChange={changePopularServiceHandler}
        />
      </div>

      <div className={styles.top10Companies}>
        {popularServicesTopCompanies.map(company => (
          <CompanyTop10Card
            key={company.id}
            company={company}
            onClick={() => clickServiceCompanyHandler(company.id)}
            telegramOnClick={() => clickTelegramServiceCompanyHandler(company.id)}
            whatsappOnClick={() => clickWhatsappServiceCompanyHandler(company.id)}
            emailOnClick={() => clickEmailServiceCompanyHandler(company.id)}
          />
        ))}
      </div>

      <div className="mobile_hidden">
        <div className={cn(styles.topForm, styles.secondForm)}>
          <div className={styles.mobileButtons}>
            <Link to="/team-search">
              <img src={need_new_team_image} alt="I need a new team"/>
            </Link>
          </div>
          <div className={styles.describeProject}>
            If you don't have time, describe your project to us, and we will select several teams suitable for your
            project.
          </div>
        </div>

        <h2 className={cn(styles.title, styles.understand)}>
          <span className={styles.yellowFont}>We understand</span><br />
          how to select a development team
        </h2>

        <div className={cn(styles.calculatorInfoRow, styles.understandInfo)}>
          We have been working in the IT sector for over 17 years, and we select programmers for product companies,
          startups, and IT companies.
        </div>

        <div className={styles.video}>
          <Video src={`${videoLink}`} />
        </div>

        <div className={cn(styles.topForm, styles.secondForm)}>
          <div className={styles.mobileButtons}>
            <Link to="/team-search">
              <img src={need_new_team_image} alt="I need a new team"/>
            </Link>
          </div>
          <div className={styles.describeProject}>
            If you don't have time, describe your project to us, and we will select several teams suitable for your
            project.
          </div>
        </div>

        <div className={styles.bannersBlock}>
          <a
            onClick={clickBannerHandler}
            className={cn(styles.bannerLink, styles.sterlingBanner)}
            href="https://sterling-law.co.uk/"
            target="_blank"
            rel="nofollow"
          >
            <img src={banner_image} alt="Sterling law"/>
          </a>
          <a
            onClick={clickBannerHandler}
            className={styles.downloadPDFLink}
            href="/ibench_teams_checklist.pdf"
            target="_blank"
            rel="nofollow"
          >
            <img src={download_pdf_image} alt="Download PDF"/>
          </a>
        </div>


      </div>

      <div className="mobile_visible">
        <div className={styles.mobileButtons}>
          <Link to="/team-search">
            <img src={need_new_team_image} alt="I need a new team"/>
          </Link>
        </div>

        <div className={styles.graySection}>
          Our clients
        </div>

        <div>
          {clientsData.map(clientData => (
            <div key={clientData.name} className={styles.clientWrapper}>
              <img className={styles.clientAvatar} src={clientData.avatar} alt="Robert Schmidt"/>
              <div className={styles.clientName}>
                {clientData.name}
              </div>
              <div className={styles.clientJobTitle}>
                {clientData.jobTitle}
              </div>
              <div className={styles.clientInfo}>
                {clientData.info}
              </div>
            </div>
          ))}
        </div>

        <div className={styles.mobileButtons}>
          <Link to="/team-search">
            <img src={need_new_team_image} alt="I need a new team"/>
          </Link>
        </div>

        <div className={styles.graySection}>
          We recommend
        </div>

        <div className={styles.bannersSection}>
          <a
            onClick={clickBannerHandler}
            className={cn(styles.bannerLink, styles.sterlingBanner)}
            href="https://sterling-law.co.uk/"
            target="_blank"
            rel="nofollow"
          >
            <img src={banner_image} alt="Sterling law"/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FrontPage;
