import moment from "moment";

import { developersAPI } from "api/developers";
import { skillsAPI } from "api/skills";
import { developerProjectsAPI } from "api/developerProjects";
import { developerProjectSkillsAPI } from "api/developerProjectSkills";
import { publicAPI } from "api/public";
import { englishLevelsDescription } from "constants/global-constants";
import { getCompanyVerificationChecks } from "./auth";
import { history } from "utils";
import store from "./store";

const IS_FETCHING = "ibench/developer/IS_FETCHING";
const SHOW_MODAL = "ibench/developer/SHOW_MODAL";
const SHOW_BENCH_MODAL = "ibench/developer/SHOW_BENCH_MODAL";
const HIDE_MODAL = "ibench/developer/HIDE_MODAL";
const SET_DEVELOPERS = "ibench/developer/SET_DEVELOPERS";
const SET_DEVELOPER_ID = "ibench/developer/DEVELOPER_ID";
const SET_CURRENT_DEVELOPER = "ibench/developer/SET_CURRENT_DEVELOPER";
const SET_BENCH_STATUS = "ibench/developer/SET_BENCH_STATUS";
const SET_ENGLISH_LEVEL_DESCRIPTION = "ibench/developer/SET_ENGLISH_LEVEL_DESCRIPTION";
const SET_POSITION_LEVELS = "ibench/app/SET_POSITION_LEVELS";

let initialState = {
  is_fetching: false,
  show_modal: false,
  show_bench_modal: false,
  developers: [],
  current_developer: null,
  english_level_description: null,
  position_levels: null,
};

const developer = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, is_fetching: action.is_fetching };
    case SHOW_MODAL:
      return { ...state, show_modal: true };
    case SHOW_BENCH_MODAL:
      return { ...state, show_bench_modal: true };
    case HIDE_MODAL:
      return { ...state, show_modal: false, show_bench_modal: false };
    case SET_DEVELOPERS:
      return { ...state, developers: action.developers };
    case SET_DEVELOPER_ID:
      return { ...state, developer_id: action.developer_id };
    case SET_CURRENT_DEVELOPER:
      return { ...state, current_developer: action.payload };
    case SET_BENCH_STATUS:
      return {
        ...state,
        current_developer: {
          ...state.current_developer,
          on_bench: action.payload,
        },
      };
    case SET_ENGLISH_LEVEL_DESCRIPTION:
      return { ...state, english_level_description: action.payload };
    case SET_POSITION_LEVELS:
      return { ...state, position_levels: action.payload };
    default:
      return state;
  }
};

export const isFetching = (is_fetching) => ({ type: IS_FETCHING, is_fetching });
export const showModal = () => ({ type: SHOW_MODAL });
export const showBenchModal = () => ({ type: SHOW_BENCH_MODAL });
export const hideModal = () => ({ type: HIDE_MODAL });
const setDevelopers = (developers) => ({ type: SET_DEVELOPERS, developers });
export const setDeveloperId = (developer_id) => ({ type: SET_DEVELOPER_ID, developer_id });
export const setCurrentDeveloper = (payload) => ({ type: SET_CURRENT_DEVELOPER, payload });
export const setEnglishLevelDescription = (payload) => ({ type: SET_ENGLISH_LEVEL_DESCRIPTION, payload });
export const setBenchStatus = (payload) => ({ type: SET_BENCH_STATUS, payload });
const setPositionLevels = (payload) => ({ type: SET_POSITION_LEVELS, payload });

export const getDevelopers = () => {
  return (dispatch) => {
    developersAPI.getDevelopers().then((data) => {
      const developers = data.companyDevelopers || [];
      dispatch(setDevelopers(developers));
      return developers;
    });
  };
};

const prepareEnglishLevelDescription = (developerData) => {
  return (dispatch) => {
    if (!developerData.cerf_level) {
      return;
    }

    const current_english_level = englishLevelsDescription.filter(
      (item) => item.level === developerData.cerf_level
    );

    dispatch(setEnglishLevelDescription(current_english_level[0].description));
  }
};

export const getCurrentDeveloper = (current_developer_id) => {
  return (dispatch) => {
    let company_info = store.getState().auth.company_info;

    if (!company_info) {
      publicAPI.getDeveloper(current_developer_id).then((response) => {
        const developerData = response.data;
        dispatch(setCurrentDeveloper(developerData));
        dispatch(prepareEnglishLevelDescription(developerData));
      });
      return;
    }

    developersAPI.getDeveloper(current_developer_id).then((data) => {
      const developerData = data.companyDevelopers[0];
      if (developerData.company_id === company_info.id) {
        developerData.is_own = true;
      }
      dispatch(setCurrentDeveloper(developerData));
      dispatch(prepareEnglishLevelDescription(developerData));
    });
  };
};

export const addDeveloper = (formData, isSingleDeveloper = 0) => {
  const baseInfo = {
    name: formData.name,
    job_title_id: formData.job_title_id,
    country: formData.country,
    general_info: formData.general_info,
  };

  return (dispatch) => {
    dispatch(isFetching(true));

    developersAPI.addDeveloper(baseInfo).then((data) => {
      dispatch(setDeveloperId(data.developer.developer_id));
      if (formData.photo) {
        let photoData = new FormData();
        photoData.append("file", formData.photo[0]);
        photoData.append("developer_id", data.developer.developer_id);
        developersAPI.uploadPhoto(photoData);
      }
      dispatch(getCompanyVerificationChecks(isSingleDeveloper));
      dispatch(isFetching(false));
    });
  };
};

export const editDeveloper = (formData) => {
  let editingData = { ...formData };

  delete editingData.add_skills;
  delete editingData.edit_skills;
  delete editingData.delete_skills;

  return (dispatch) => {
    dispatch(isFetching(true));

    const promises = [];

    if (Object.keys(editingData).length > 1) {
      promises.push(developersAPI.editDeveloper(formData));
    }

    if (editingData.photo) {
      let photoData = new FormData();
      photoData.append("file", editingData.photo[0]);
      photoData.append("developer_id", editingData.developer_id);
      promises.push(developersAPI.uploadPhoto(photoData));
    }

    // ADD SKILLS
    if (formData.add_skills) {
      formData.add_skills.forEach((skill) => {
        promises.push(
          skillsAPI.addSkill({
            developer_id: formData.developer_id,
            skill_id: skill.skill_id,
            experience: skill.skill_experience,
          })
        );
      });
    }

    // EDIT SKILLS
    if (formData.edit_skills) {
      formData.edit_skills.forEach((skill) => {
        promises.push(
          skillsAPI.editSkill({
            developer_id: formData.developer_id,
            skill_id: skill.skill_id,
            experience: skill.skill_experience,
          })
        );
      });
    }

    // DELETE SKILLS
    if (formData.delete_skills) {
      formData.delete_skills.forEach((skill) => {
        promises.push(
          skillsAPI.deleteSkill({
            developer_id: formData.developer_id,
            skill_id: skill.skill_id,
          })
        );
      });
    }

    Promise.all(promises).then(() => {
      dispatch(isFetching(false));
      return history.push(`/developer/${formData.developer_id}`);
    });
  };
};

export const deleteDeveloper = (formData, isSingleDeveloper = 0) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    developersAPI.deleteDeveloper(formData).then(() => {
      dispatch(isFetching(false));
      dispatch(getCompanyVerificationChecks(isSingleDeveloper));
      return history.push(`/company-bench`);
    });
  };
};

export const addDeveloperProject = (developerId, formData) => {
  const mappedFormData = {
    ...formData,
    from_year: moment(formData.from_year).format("YYYY-MM-DD"),
    to_year: moment(formData.to_year).format("YYYY-MM-DD"),
  };

  return (dispatch) => {
    dispatch(isFetching(true));

    developerProjectsAPI
    .addDeveloperProject(developerId, mappedFormData)
    .then((data) => {
      const promises = [];

      if (formData.skills) {
        formData.skills.forEach((skill) => {
          promises.push(
            developerProjectSkillsAPI.addSkill(developerId, {
              project_id: data.project_id,
              skill_id: skill.skill_id,
            })
          );
        });
      }

      return Promise.all(promises);
    })
    .then(() => {
      dispatch(getCurrentDeveloper(developerId));
      dispatch(isFetching(false));
    });
  };
};

export const editDeveloperProject = (developerId, formData) => {
  let { project_id } = formData;

  return (dispatch) => {
    dispatch(isFetching(true));

    developerProjectsAPI
    .editDeveloperProject(developerId, formData)
    .then(() => {
      const promises = [];

      formData.addSkills.forEach(({ skill_id }) => {
        promises.push(
          developerProjectSkillsAPI.addSkill(developerId, {
            project_id,
            skill_id,
          })
        );
      });

      formData.deleteSkills.forEach(({ skill_id }) => {
        promises.push(
          developerProjectSkillsAPI.deleteSkill(developerId, {
            project_id,
            skill_id,
          })
        );
      });

      return Promise.all(promises);
    })
    .then(() => {
      dispatch(getCurrentDeveloper(developerId));
      dispatch(isFetching(false));
    });
  };
};

export const deleteDeveloperProject = (developerId, formData) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    developerProjectsAPI.deleteDeveloperProject(developerId, formData).then(() => {
      dispatch(getCurrentDeveloper(developerId));
      dispatch(isFetching(false));
    });
  };
};

export const editBenchStatus = (formData) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    developersAPI.editBenchStatus(formData).then((data) => {
      dispatch(isFetching(false));
      dispatch(setBenchStatus(formData.on_bench));
    });
  };
};

export const incrementCTR = (developerId) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    developersAPI.incrementCTR(developerId).then(() => {
      dispatch(isFetching(false));
    });
  };
};

// GET POSITION LEVELS
export const getPositionLevels = () => {
  return (dispatch) => {
    publicAPI.getPositions().then((result) => {
      dispatch(setPositionLevels(result));
    });
  };
};

export default developer;
