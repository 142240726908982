import qs from "qs";
import { axiosInstances } from "./api";

const axiosInstance = axiosInstances.account;

export const developerProjectsAPI = {
  getDeveloperProjects(developerId) {
    return axiosInstance.get(`api/users/developers/${developerId}/projects`).then((response) => {
      return response;
    });
  },

  addDeveloperProject(developerId, data) {
    return axiosInstance.post(`api/users/developers/${developerId}/projects`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  editDeveloperProject(developerId, data) {
    return axiosInstance.put(`api/users/developers/${developerId}/projects`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },
  
  deleteDeveloperProject(developerId, data) {
    return axiosInstance
      .delete(`api/users/developers/${developerId}/projects`, { data: qs.stringify(data) })
      .then((response) => {
        return response.data;
      });
  },
};
