import React from "react";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { email, required } from "redux-form-validators";
import { Helmet } from "react-helmet";
import cn from "classnames";

import { siteNameForTitle } from "../../../../constants";
import { Checkbox, Input, SelectInput } from "../../../common/FormsControls/FormControls";
import Preloader from "../../../common/Preloader/Preloader";
import DeveloperCard from "../DeveloperCard/DeveloperCard";

import styles from "./RegistrationQuickForm.module.css";

const AgreementLabel = () => {
  return (
    <span className={styles.privacy_policy}>
      I agree to the{" "}
      <NavLink to="/privacy-policy" target="_blank" rel="nofollow">
        Privacy Policy
      </NavLink>
    </span>
  );
};

const LoginForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.login_form}>
      <div className={styles.login_fields}>
        <Field
          component={Input}
          name="email"
          input_wrapper_class="form_control_2_wrapper"
          className={cn("form_control_2", styles.input)}
          validate={[required(), email()]}
          placeholder="Email"
        />
        <Field
          component={Input}
          name={"password"}
          type={"password"}
          input_wrapper_class="form_control_2_wrapper"
          className={cn("form_control_2", styles.input)}
          validate={[required()]}
          placeholder="Password"
        />
        <button className={styles.submit}>Log in</button>
      </div>
      {props.error && <div className={`${styles.form_erros} validation_message`}>{props.error}</div>}
    </form>
  );
};

const RegistrationForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <div className={styles.registration_fields}>
          <Field
            component={Input}
            name="representative_name"
            input_wrapper_class="form_control_2_wrapper"
            className={cn("form_control_2", styles.input)}
            validate={[required()]}
            placeholder="Name"
          />
          <Field
            component={Input}
            name="email"
            input_wrapper_class="form_control_2_wrapper"
            className={cn("form_control_2", styles.input)}
            validate={[required(), email()]}
            placeholder="Email"
          />
          <div className={styles.countries_select}>
            <Field
              component={SelectInput}
              name={"country"}
              options={props.countries_list}
              validate={[required()]}
              placeholder="Country"
              required
            />
          </div>
          <Field
            component={Input}
            name={"password"}
            type={"password"}
            input_wrapper_class="form_control_2_wrapper"
            className={cn("form_control_2", styles.input)}
            validate={[required()]}
            placeholder="Password"
          />
          <div className={styles.privacy_policy_wrapper}>
            <Field
              component={Checkbox}
              name="privacy-agreement"
              id="privacy-agreement"
              label={AgreementLabel()}
              validate={[required()]}
            />
          </div>
          <button className={styles.submit}>Register</button>
        </div>
      </div>

      {props.error && <div className={`${styles.form_erros} text_center validation_message`}>{props.error}</div>}
    </form>
  );
};

let RegistrationQuickReduxForm = reduxForm({
  form: "quick-registration",
})(RegistrationForm);

let LoginQuickReduxForm = reduxForm({
  form: "login",
})(LoginForm);

const RegistrationQuickForm = (props) => {
  const onSubmit = (formData) => {
    let newFormData = {
      ...formData,
      developer: props.developer,
    };

    props.quickRegister(newFormData);
  };

  const onLogin = (formData) => {
    let newFormData = {
      ...formData,
      developer: props.developer,
    };

    props.loginAndHire(newFormData);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{siteNameForTitle} / I want to hire him</title>
      </Helmet>
      {props.is_fetching && <Preloader />}
      <h1 className={styles.title}>I want to hire him</h1>

      <DeveloperCard developer={props.developer} />

      <LoginQuickReduxForm onSubmit={onLogin} />

      <div className={styles.hire_title}>
        To hire a developer,<br />
        create a free account for yourself
      </div>
      <div className={styles.subtitle}>
        Tired of waiting? In 1 min you can access to over 400+ top developers from real companies. No freelancers, No
        waiting.
      </div>

      <RegistrationQuickReduxForm
        onSubmit={onSubmit}
        countries_list={props.countries_list}
      />
    </div>
  );
};

export default RegistrationQuickForm;
