import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";

import Terms from '../../Terms/Terms'
import { Checkbox } from "../../common/FormsControls/FormControls";

import styles from "./TermsModal.module.css";

let TermsForm = (props) => {
  const [termsChecked, setTermsChecked] = useState(false);
  const onToggleTerms = () => {
    setTermsChecked(!termsChecked);
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <Field
        component={Checkbox}
        name="terms"
        label={(
          <div>
            I read and accept <u>the iBench Terms</u>
          </div>
        )}
        className="justify_content_center"
        onChange={onToggleTerms}
      />
      <div>
        <button className={`btn ${styles.submit}`} disabled={!termsChecked}>Submit</button>
      </div>
    </form>
  );
};

TermsForm = reduxForm({ form: "terms" })(TermsForm);

const TermsModal = ({ onSubmit }) => (
  <div className={styles.termsModal}>
    <div className={styles.modalHeader}>
      iBench Terms
    </div>
    <div className={styles.modalBody}>
      <Terms />
    </div>
    <div className={styles.termsWrapper}>
      <TermsForm onSubmit={onSubmit} />
    </div>
  </div>
);

export default TermsModal;
