import { countriesAPI } from "../api/countries";

const IS_FETCHING = "ibench/countries/IS_FETCHING";
const SET_COUNTRIES_LIST = "ibench/countries/SET_COUNTRIES_LIST";
const SET_CITIES_LIST = "ibench/countries/SET_CITIES_LIST";

let initialState = {
  isFetching: false,
  countryList: [],
  cityList: [],
};

const countries = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, isFetching: action.payload };

    case SET_COUNTRIES_LIST:
      return { ...state, countryList: action.payload };

    case SET_CITIES_LIST:
      return { ...state, cityList: action.payload };

    default:
      return state;
  }
};

export const isFetching = (payload) => ({ type: IS_FETCHING, payload });

// GET OUTSOURCE SEARCH LIST
const setCountriesList = (payload) => ({ type: SET_COUNTRIES_LIST, payload });
const setCitiesList = (payload) => ({ type: SET_CITIES_LIST, payload });

export const getCountriesList = () => {
  return (dispatch) => {
    countriesAPI.getCountries().then((response) => {
      dispatch(setCountriesList(response.data));
    });
  };
};

export const getCitiesByCountryId = (countryId) => {
  return (dispatch) => {
    countriesAPI.getCitiesByCountryId(countryId).then((response) => {
      dispatch(setCitiesList(response.data));
    });
  }
};

export default countries;
