import moment from "moment";
import { notification } from "antd";

import { history } from "utils";
import { casesAPI, casesMarketsAPI, casesSkillsAPI } from "api/cases";
import { getCompanyVerificationChecks } from "./auth";

const IS_FETCHING = "ibench/cases/IS_FETCHING";
const SET_CASES_LIST = "ibench/cases/SET_CASES_LIST";
const SET_CURRENT_CASE = "ibench/cases/SET_CURRENT_CASE";
const COMPANY_INFO = "ibench/cases/COMPANY_INFO";
const SET_PROJECTS_LIST = "ibench/cases/SET_PROJECTS_LIST";
const SET_CURRENT_PROJECT = "ibench/cases/SET_CURRENT_PROJET";

let initialState = {
  isFetching: false,
  casesList: [],
  currentCase: null,
  companyInfo: null,
  projectsList: [],
  currentProject: null,
};

const cases = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, isFetching: action.payload };

    case SET_CASES_LIST:
      return { ...state, casesList: action.payload };

    case SET_CURRENT_CASE:
      let currentCase = state.casesList.filter((c) => c.case_id === Number(action.payload))[0];
      currentCase = { ...currentCase, budget: currentCase?.budget.toString() };
      return { ...state, currentCase };

    case COMPANY_INFO:
      return { ...state, companyInfo: action.payload };

    case SET_PROJECTS_LIST:
      return { ...state, projectsList: action.payload };

    case SET_CURRENT_PROJECT:
      return { ...state, currentProject: action.payload };

    default:
      return state;
  }
};

export const isFetching = (payload) => ({ type: IS_FETCHING, payload });

export const setCurrentCase = (payload) => ({ type: SET_CURRENT_CASE, payload });

// GET CASES LIST
export const setCasesList = (payload) => ({ type: SET_CASES_LIST, payload });

export const getCasesList = () => {
  return (dispatch) => {
    casesAPI.getCasesList().then((data) => {
      dispatch(setCasesList(data.cases));
    });
  };
};

export const setCompanyInfo = (payload) => ({ type: COMPANY_INFO, payload });

// GET CASES LIST BY ID
export const getCasesListById = (companyId) => {
  return (dispatch) => {
    casesAPI.getCasesListById(companyId).then((data) => {
      let { cases, ...companyInformation } = data.companyInformation;
      dispatch(setCasesList(cases));
      dispatch(setCompanyInfo(companyInformation));
    });
  };
};

// ADD CASE
export const addCase = (formData) => {
  const mappedFormData = {
    ...formData,
    from_date: moment(formData.from_date).format("YYYY-MM-DD"),
    to_date: moment(formData.to_date).format("YYYY-MM-DD"),
  };

  return (dispatch) => {
    dispatch(isFetching(true));
    casesAPI
      .addCase(mappedFormData)
      .then((data) => {
        const promises = [];

        if (formData.logo.length) {
          formData.logo.forEach((logo) => {
            let photoData = new FormData();
            photoData.append("file", logo);
            photoData.append("case_id", data.case_id);
            photoData.append("type", "logo");
            promises.push(casesAPI.uploadFile(photoData));
          });
        }

        if (formData.covers.length) {
          Array.from(formData.covers).forEach((cover) => {
            let coverData = new FormData();
            coverData.append("file", cover);
            coverData.append("case_id", data.case_id);
            coverData.append("type", "cover");
            promises.push(casesAPI.uploadFile(coverData));
          });
        }

        if (formData.files.length) {
          Array.from(formData.files).forEach((file) => {
            let fileData = new FormData();
            fileData.append("file", file);
            fileData.append("case_id", data.case_id);
            fileData.append("type", "file");
            promises.push(casesAPI.uploadFile(fileData));
          });
        }

        formData.markets.forEach((market) => {
          promises.push(
            casesMarketsAPI.addMarket({
              case_id: data.case_id,
              market_id: market.market_id,
            })
          );
        });

        formData.skills.forEach((skill) => {
          promises.push(
            casesSkillsAPI.addSkill({
              case_id: data.case_id,
              skill_id: skill.skill_id,
            })
          );
        });

        return Promise.all(promises);
      })
      .then(() => {
        dispatch(getCompanyVerificationChecks());
        dispatch(isFetching(false));
        notification.success({
          message: "Case has been added",
          placement: "bottomRight",
          duration: 5,
        });
        return history.push("/cases");
      });
  };
};

// EDIT CASE
export const editCase = (formData) => {
  let { case_id } = formData;

  return (dispatch) => {
    dispatch(isFetching(true));
    casesAPI
      .editCase(formData)
      .then(() => {
        const promises = [];

        formData?.case_logo?.forEach((logo) => {
          let photoData = new FormData();
          photoData.append("file", logo);
          photoData.append("case_id", case_id);
          photoData.append("type", "logo");
          promises.push(casesAPI.uploadFile(photoData));
        });

        if (formData.covers.length) {
          Array.from(formData.covers).forEach((cover) => {
            let coverData = new FormData();
            coverData.append("file", cover);
            coverData.append("case_id", case_id);
            coverData.append("type", "cover");
            promises.push(casesAPI.uploadFile(coverData));
          });
        }

        if (formData.coversDelete?.length) {
          formData.coversDelete.forEach((file_name) => {
            promises.push(casesAPI.deleteFile({ case_id, file_name }));
          });
        }

        if (formData.files.length) {
          Array.from(formData.files).forEach((file) => {
            let fileData = new FormData();
            fileData.append("file", file);
            fileData.append("case_id", case_id);
            fileData.append("type", "file");
            promises.push(casesAPI.uploadFile(fileData));
          });
        }

        if (formData.filesDelete?.length) {
          formData.filesDelete.forEach((file_name) => {
            promises.push(casesAPI.deleteFile({ case_id, file_name }));
          });
        }

        formData.addMarkets.forEach(({ market_id }) =>
          promises.push(casesMarketsAPI.addMarket({ case_id, market_id }))
        );

        formData.deleteMarkets.forEach(({ market_id }) => {
          promises.push(casesMarketsAPI.deleteMarket({ case_id, market_id }));
        });

        formData.addSkills.forEach(({ skill_id }) => {
          promises.push(casesSkillsAPI.addSkill({ case_id, skill_id }));
        });

        formData.deleteSkills.forEach(({ skill_id }) => {
          promises.push(casesSkillsAPI.deleteSkill({ case_id, skill_id }));
        });

        return Promise.all(promises);
      })
      .then(() => {
        dispatch(isFetching(false));
        notification.success({
          message: "Case has been edited",
          placement: "bottomRight",
          duration: 5,
        });
        return history.push("/cases");
      })
      .catch(() => {
        dispatch(isFetching(false));
        notification.error({
          message: "Case editing error",
          placement: "bottomRight",
          duration: 5,
        });
      });
  };
};

// DELETE CASE
export const deleteCase = (data) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    casesAPI.deleteCase(data).then(() => {
      dispatch(getCasesList());
      notification.success({
        message: "Case has been deleted",
        placement: "bottomRight",
        duration: 5,
      });
      dispatch(getCompanyVerificationChecks());
      dispatch(isFetching(false));
    });
  };
};

export const updateTopCases = (topCases) => {
  return (dispatch) => {
    dispatch(isFetching(true));

    casesAPI.updateTopCases(topCases).then(() => {
      dispatch(isFetching(false));
      history.push('/profile');
    });
  };
};

// GET CASES LIST
export const setProjectsList = (payload) => ({ type: SET_PROJECTS_LIST, payload });

export const getProjectsList = () => {
  return (dispatch) => {
    casesAPI.getProjectsList().then((data) => {
      dispatch(setProjectsList(data.projects));
    });
  };
};

const setCurrentProject = (payload) => ({ type: SET_CURRENT_PROJECT, payload });

export const getProjectById = (projectId) => {
  return (dispatch, getState) => {
    dispatch(isFetching(true));
    casesAPI.getProjectById(projectId).then((data) => {
      const companyType = getState().auth.company_info.type;
      if (companyType === 1) return history.push(`/outsource-search/${data.search_id}`); // redirect startup to own outsource search page

      dispatch(setCurrentProject(data.projects[0]));
      dispatch(isFetching(false));
    });
  };
};

export default cases;
