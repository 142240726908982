import { applyMiddleware, combineReducers, createStore } from "redux";
import { reducer as formReducer } from "redux-form";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { history } from "utils";

import analytics from "./analytics";
import app from "./app";
import auth from "./auth";
import banners from "./banners";
import bookmarks from "./bookmarks";
import cases from "./cases";
import candidates from "./candidates";
import chat from "./chat";
import company from "./company";
import countries from "./countries";
import developer from "./developer";
import lead from "./lead";
import marketplace from "./marketplace";
import modal from "./modal";
import news from "./news";
import outsource from "./outsource";
import subscription from "./subscription";
import search from "./search";

const USER_LOGOUT = "ibench/store/USER_LOGOUT";

const allReducers = combineReducers({
  analytics,
  app,
  auth,
  banners,
  bookmarks,
  cases,
  candidates,
  chat,
  company,
  countries,
  developer,
  lead,
  marketplace,
  modal,
  news,
  outsource,
  search,
  subscription,
  form: formReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
    localStorage.removeItem("companyInfo");
    state = undefined;
  }

  return allReducers(state, action);
};

export const clearStore = () => ({
  type: USER_LOGOUT,
});

export const logout = () => {
  return (dispatch) => {
    dispatch(clearStore());
    history.push("/");
    history.go(0);
  };
};

let middleware = [thunk];

if (process.env.NODE_ENV !== "production") {
  middleware = [...middleware, logger];
}

export default createStore(rootReducer, applyMiddleware(...middleware));
