import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { getDevelopers, editBenchStatus } from "../../../store/developer";
import CompanyBench from "./CompanyBench";

const CompanyBenchContainer = (props) => {
  useEffect(() => {
    props.getDevelopers();
  }, []);

  return (
    <CompanyBench
      is_fetching={props.is_fetching}
      // stripe_subscribed={props.stripe_subscribed}
      developers={props.developers}
      getDevelopers={props.getDevelopers}
      editBenchStatus={props.editBenchStatus}
      isSingleDeveloper={props.auth?.company_info?.is_single_developer}
      companyInfo={props.auth?.company_info}
    />
  );
};

const mapStateToProps = (state) => ({
  is_fetching: state.developer.is_fetching,
  // stripe_subscribed: state.auth?.company_info?.stripe_subscribed,
  developers: state.developer.developers,
  auth: state.auth,
});

const mapDispatchToProps = {
  getDevelopers,
  editBenchStatus,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthRedirect,
)(CompanyBenchContainer);
