import React from "react";
import { connect } from "react-redux";

import { addCandidateSearch } from "../../../store/candidates";
import Preloader from "../../common/Preloader/Preloader";
import CandidateSearchForm from "../CandidateSearchForm/CandidateSearchForm";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";

import styles from "./CandidateSearchAdding.module.css";

const CandidateSearchAdding = (props) => {
  const { addCandidateSearch } = props;

  const onSubmit = (formData) => {
    addCandidateSearch(formData);
  };

  return (
    <div className={styles.wrapper}>
      {props.auth.is_fetching && <Preloader/>}

      <DashboardMenuContainer/>

      <div className={styles.subtitle}>
        Find talents / Search talent
      </div>

      <CandidateSearchForm onSubmit={onSubmit} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  addCandidateSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSearchAdding);
