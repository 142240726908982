import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";

import CloseModal from "./CloseModal";
import CopyLink from "../common/CopyLink/CopyLink";

import styles from "./LeadsList.module.css";

const LeadsList = (props) => {
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [leadId, setLeadId] = useState();

  const closeHandler = (leadId) => {
    setShowCloseModal(true);
    setLeadId(leadId);
  };

  const onClose = async (data) => {
    await props.deleteLead({
      id: leadId,
      comment: data.reason,
    }, () => setShowCloseModal(false));
  };

  const getLeadPriceText = (leadData) => {
    let leadPriceText = '';
    if (leadData.fixed_price) {
      leadPriceText = `Lead Price: $${leadData.fixed_price}`;

      if (leadData.percentage) {
        leadPriceText += ` + ${leadData.percentage}%`;
      }
      return leadPriceText;
    }

    return `Percentage: ${leadData.percentage}%`;
  };

  const renderLeadCard = (lead) => {
    return (
      <div key={lead.id} className={styles.leadCard}>
        <div className={styles.leadHeader}>
          <div className={styles.headerRow}>
            <div>
              Project type: {lead.project_type}
            </div>
            <div className={styles.smallFont}>
              Publish date:&nbsp;
              <span className={styles.normalFont}>{moment(lead.created).format("DD MMM YYYY")}</span>
            </div>
          </div>
          <div className={styles.headerRow}>
            <div>
              Budget: ${lead.budget}
            </div>
            <div>
              {getLeadPriceText(lead)}
            </div>
            <div>
              Start project: {moment(lead.start).format("DD MMM YYYY")}
            </div>
          </div>
        </div>
        <div className={styles.leadContent}>
          <div className={styles.leadTitle}>
            Title: {lead.project_name}
          </div>
          <div className={styles.leadDescription}>
            {lead.project_description}
          </div>
          <div className={styles.buttonWrapper}>
            <div className={styles.leadBuyers}>Buyers: {lead.buyers}</div>
            <NavLink to="/chat" className={styles.leadViewProposal}>
              View proposals
            </NavLink>
            <div className={styles.copyLink}>
              <CopyLink
                link={`/lead/${lead.id}`}
                message="Lead link has been copied to clipboard"
              />
            </div>
          </div>
          <div className={styles.leadBottomLinks}>
            <span onClick={() => closeHandler(lead.id)}>Close</span>
            <NavLink to={`/edit-lead/${lead.id}`}>
              Edit
            </NavLink>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {showCloseModal && (
        <CloseModal
          onShowModal={setShowCloseModal}
          onSubmit={onClose}
        />
      )}

      {props.leads.map(renderLeadCard)}
    </>
  );
};

export default LeadsList;
