import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import LeadsList from "../../LeadsList/LeadsList";
import SellLeadButton from "../SellLeadButton/SellLeadButton";
import { siteNameForTitle } from "../../../constants";

import styles from "./SellLeads.module.css";

const SellLeads = (props) => {
  useEffect(() => {
    if (props.companyType === 2) {
      props.getMarketplaceLeads();
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Developers | {siteNameForTitle}</title>
      </Helmet>

      <DashboardMenuContainer />

      <h1 className={`text_center ${styles.pageTitle}`}>Sell leads</h1>
      <div className={styles.subtitle}>
        For example, a client comes to you who wants a project with technologies that you don't specialize in. Now you
        have the opportunity to sell such a lead to another company for a fixed price or percentage.
      </div>
      <div className={styles.sellLeadButton}>
        <SellLeadButton />
      </div>
      {props.companyType === 2 && (
        <div className={styles.leadsList}>
          <LeadsList
            leads={props.leads}
            deleteLead={props.deleteLead}
            closeLeadForm={props.closeLeadForm}
          />
        </div>
      )}
      {/*<YoutubeButton src="https://www.youtube.com/embed/_r1JyEKL4fw"/>*/}
    </div>
  );
};

export default SellLeads;
