import React, { useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
// import { notification } from "antd";
import { email, required, length, acceptance, confirmation, format } from "redux-form-validators";
import cn from "classnames";

import countries_list from "../../../utils/countries_list";
import india_countries_list from "../../../utils/india_countries_list";
import { siteNameForTitle, defaultDescription } from "../../../constants/global-constants";
import {Input, SelectInput, Checkbox} from "../../common/FormsControls/FormControls";
import Preloader from "../../common/Preloader/Preloader";
import Modal from "../../common/Modal/Modal";
import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs";
import TermsModal from "../TermsModal/TermsModal";
import { checkPasswordStrength } from "../../../utils/validation";

import styles from "./RegistrationStep1.module.css";

const RadioButton = (props) => {
  const { input, meta, ...restProps } = props;
  return (
    <div className={styles.radioButtonWrapper}>
      <label>
        <input
          {...input}
          {...restProps}
          type="radio"
          checked={input.checked}
          className={styles.invisible}
        />
        <span className={cn(styles.radioButton, { checked: input.checked })}>{props.text}</span>
      </label>
      {meta.touched && meta.error && <div className="validation_message">{meta.error}</div>}
    </div>
  );
};

const registrationFormName = "registration";

let RegistrationForm = (props) => {
  const [userType, setUserType] = useState(props.initialValues.user_type);
  const [showModal, setShowModal] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  const countriesList = useMemo(() => {
    if (userType === "2") {
      return countries_list.map(country => country.label);
    }

    return india_countries_list.map(country => country.label);
  }, [userType]);

  console.log('===countriesList', countriesList);

  const changeCompanyType = (event) => {
    // this.setState({ userType: event.target.value });
    setUserType(event.target.value);
  };

  const toggleTerms = () => {
    setTermsChecked(!termsChecked);
  };

  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.modal}>
        {showModal && (
          <Modal
            showModal={setShowModal}
            noBg={true}
            renderComponent={<TermsModal />}
          />
        )}
      </div>
      <div className={styles.fields_wrapper}>
        <h1 className={styles.title}>Create your iBench account</h1>
        <div className={styles.radio}>
          <Field
            component={RadioButton}
            type="radio"
            name="user_type"
            value="0"
            text="IT company"
            validate={[required()]}
            onClick={changeCompanyType}
          />
          <Field
            component={RadioButton}
            type="radio"
            name="user_type"
            value="2"
            text="Client"
            validate={[required()]}
            onClick={changeCompanyType}
          />
          <Field
            component={RadioButton}
            type="radio"
            name="user_type"
            value="1"
            text="Talent"
            validate={[required()]}
            onChange={changeCompanyType}
          />
        </div>
        <Field
          component={Input}
          type={"email"}
          name={"email"}
          label="Email"
          validate={[required(), email()]}
          help_text="for example: user@company.com"
        />
        {userType !== "0" && (
          <div className={styles.passwordFields}>
            <Field
              component={Input}
              type={"text"}
              name={"first_name"}
              label="First Name"
              validate={[required(), format({ with: /^[a-zA-Z\s]+$/i, message: 'Only Latin letters' })]}
              help_text="for example: John"
            />
            <Field
              component={Input}
              type={"text"}
              name={"last_name"}
              label="Last Name"
              validate={[required(), format({ with: /^[a-zA-Z\s]+$/i, message: 'Only Latin letters' })]}
              help_text="for example: Doe"
            />
          </div>
        )}
        {userType === "0" && (
          <Field
            component={Input}
            type={"text"}
            label={'Company Name'}
            name={'company_name'}
            validate={[required()]}
            help_text="for example: John Gold"
          />
        )}
        <div className={styles.passwordFields}>
          <Field
            component={Input}
            type={"password"}
            name={"password"}
            label="Password"
            validate={[required(), length({ min: 6 }), checkPasswordStrength]}
            help_text="Minimum 6 symbols"
          />
          <Field
            component={Input}
            type={"password"}
            name={"password_copy"}
            label="Password copy"
            validate={[required(), confirmation({ field: 'password', fieldLabel: 'Password' })]}
            help_text="Minimum 6 symbols"
          />
        </div>
        <Field
          component={SelectInput}
          name={"country"}
          label="Country"
          options={countriesList}
          validate={[required()]}
        />
        <div className={styles.termsWrapper}>
          <Field
            component={props => <Checkbox {...props}/>}
            name="terms_accepted"
            label={(
              <span>
                I read and accept&nbsp;
                <span className={styles.termsButton} onClick={() => setShowModal(true)}>the iBench Terms</span>
              </span>
            )}
            validate={[acceptance()]}
            onChange={toggleTerms}
            checked={termsChecked}
          />
        </div>

        {props.error && <div className={`${styles.form_erros} text_center validation_message`}>{props.error}</div>}

        <div>
          <button className={`btn ${styles.submit}`}>Try iBench</button>
        </div>
      </div>
    </form>
  );
};

RegistrationForm = reduxForm({ form: registrationFormName })(RegistrationForm);

RegistrationForm = connect(() => {
  // const userType = localStorage.getItem("isSingleDeveloper") ? "1" : "0";
  const userType = "1";

  return {
    initialValues: {
      user_type: userType,
    }
  }
})(RegistrationForm);

const RegistrationStep1 = (props) => {
  const { referral, register } = props;

  const [ tries, setTries ] = useState(0);

  const onSubmit = (form_data) => {
    if (referral) {
      form_data = {
        ...form_data,
        referral,
      };
    }

    register(form_data, () => {
      // notification.success({
      //   message: 'Your application for registration has been submitted, expect an e-mail in the coming days.',
      //   placement: 'topRight',
      //   duration: 5,
      // });
      setTries(tries + 1);
    });
  };

  return (
    <>
      <Helmet>
        <title>{siteNameForTitle} / Registration</title>
        <meta
          name="description"
          content={defaultDescription}
        />
        <meta
          name="keywords"
          content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
          data-react-helmet="true"
        />
      </Helmet>
      <Breadcrumbs className={styles.breadcrumbs} currentPage="Registration" />

      <div className={styles.wrapper}>

        {props.auth.is_fetching && <Preloader />}

        <RegistrationForm
          key={tries}
          onSubmit={onSubmit}
        />
      </div>
    </>
  );
};

export default RegistrationStep1;
