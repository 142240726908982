import React from "react";

import TalentCard from "./TalentCard";
import styles from "./TalentDetails.module.css";

const TalentDetails = (props) => {
  let {
    talentData,
    talentData: {
      languages,
      skills,
    },
  } = props;

  const renderStars = (years) => {
    const stars = [];

    for (let i = 0; i < years; i++) {
      stars.push(<span key={i} className={styles.star}/>);
    }

    return stars;
  };

  return (
    <div className={styles.contentWrapper}>
      <TalentCard talentData={talentData} profile />
      <div className={styles.flex}>
        <div className={styles.leftPart} />
        <div className={styles.rightPart}>
          <div className={styles.skillsTitle}>
            Skills
          </div>
          <div className={styles.skillsList}>
            {skills.map((skill) => (
              <div key={skill.skill_id} className={styles.skill}>
                <div className={styles.skillName}>{skill.skill_name}</div>
                <div className={styles.skillExperience}>{skill.years}</div>
                <div className={styles.starsWrapper}>
                  {renderStars(skill.years)}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.skillsTitle}>
            Languages
          </div>
          <div className={styles.languagesList}>
            {languages.map((languageData, index) => (
              <div className={styles.languagesRow} key={index}>
                <div className={styles.languageItem}>
                  {languageData.language}, {languageData.cerf_level}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalentDetails;
