import cn from "classnames";
import Linkify from "react-linkify";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { Image, Modal, Button } from "antd";
import DOMPurify from "isomorphic-dompurify";

import { publicFilesUrl } from "../../../constants/global-constants";
import linkifyDecorator from "../../../utils/linkifyDecorator";
import { formatBudget } from "../../../utils/utils";
import styles from "./CaseItem.module.css";

const CaseItem = (props) => {
  const renderFileTypeImage = (name) => {
    const ext = name.split(".").pop();
    return <span className={styles[ext]}/>;
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this case?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        props.deleteCase({ case_id: props.caseId });
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      {props.caseLogo && (
        <div className={styles.logoWrapper}>
          <img src={`${publicFilesUrl}public/img/${props.caseLogo}`} alt={props.name} className={styles.logo}/>
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.contentTop}>
          <div>
            <div className={`${styles.row} ${styles.name}`}>Project name: {props.name}</div>
            <div className={styles.links}>
              <Linkify componentDecorator={() => linkifyDecorator(props.links, props.links)}>
                {props.links}
              </Linkify>
            </div>
          </div>
          <div className={styles.budget}>{formatBudget(props.budget)}</div>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>Project duration:</span>
          {`${moment(props.fromDate).format("MMMM YYYY")} - ${moment(props.toDate).format("MMMM YYYY")}`}
        </div>
        <div
          className={`${styles.row} ${styles.description} ql-editor`}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(props.description, {
              ADD_TAGS: ["iframe"],
              ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
            }),
          }}
        />
        <div className={styles.row}>
          <span className={styles.label}>Team:</span>
          {props.teamSize} developers
        </div>
        <div className={styles.row}>
          <span className={styles.label}>Technology stack:</span>
          {props.skills.map((skill) => skill.skill_name).join(", ")}
        </div>
        <div className={styles.row}>
          <span className={styles.label}>Client’s country:</span>
          {props.country}
        </div>

        {props.covers.length > 0 && (
          <div className={styles.coversList}>
            <Image.PreviewGroup>
              {props.covers?.map((cover) => (
                <div key={cover} className={styles.coverWrapper}>
                  <Image
                    src={`${publicFilesUrl}public/img/${cover}`}
                    alt={props.name}
                    className={styles.cover}
                  />
                </div>
              ))}
            </Image.PreviewGroup>
          </div>
        )}

        {props.files.length > 0 && (
          <>
            <div className={styles.label}>Files</div>
            {props.files?.map((file, i) => (
              <a
                key={file}
                href={`${publicFilesUrl}public/img/${file}`}
                target="_blank"
                rel="nofollow"
                className={styles.file}
              >
                {renderFileTypeImage(file)}
                File {i + 1}
              </a>
            ))}
          </>
        )}

        {props.isOwnCases && (
          <div className={styles.caseActions}>
            <NavLink
              to={`case/${props.caseId}/edit`}
              className={cn("btn", "btn_pink_2", styles.caseActionsBtn)}
            >
              Edit case
            </NavLink>
            <Button onClick={showDeleteConfirm} className={`btn btn_red ${styles.caseActionsBtn}`}>
              Delete case
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseItem;
