import React, { useMemo } from "react";
import {Helmet} from "react-helmet";
import {NavLink} from "react-router-dom";
import {Popover} from "antd";
import cn from "classnames";

import Modal from "../common/Modal/Modal";
import Preloader from "../common/Preloader/Preloader";
import PriceIndicatorContainer from "../common/PriceIndicator/PriceIndicatorContainer";
import Verified from "../common/Verified/Verified";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import DeveloperProject from "./DeveloperProject/DeveloperProject";
import ProfileMenu from "../Profile/ProfileMenu";
import CompanyMenu from "../Profile/CompanyMenu";
import { apiUrl, publicFilesUrl, siteNameForTitle } from "../../constants/global-constants";
import {history} from "../../utils";

import default_developer_photo from "../../assets/img/man.svg";
import default_company_photo from "../../assets/img/rowing.svg";
import styles from "./Developer.module.css";

const  Developer = (props) => {

  const developer = props.developer.current_developer;

  const titleString = useMemo(() => {
    if (!developer) {
      return "";
    }

    const titleParts = [developer.name];

    if (developer?.job_title_name) {
      titleParts.push(developer.job_title_name);
    }

    if (developer?.rate) {
      titleParts.push(`Rate $${developer.rate}`);
    }

    return titleParts.join(', ');
  }, [developer]);

  const breadcrumbsLabel = useMemo(() => {
    if (!developer) {
      return "";
    }

    const labelParts = [];

    if (developer?.job_title_name) {
      labelParts.push(developer.job_title_name);
    }

    labelParts.push(developer.name);

    return labelParts.join(' ');
  }, [developer]);

  const deleteDeveloper = () => {
    props.hideModal();
    props.deleteDeveloper({
      developer_id: developer?.developer_id,
    }, props.is_single_developer);
  };

  const deleteDeveloperConfirmation = () => {
    props.showModal();
  };

  const addToBench = () => {
    props.editBenchStatus({
      on_bench: 1,
      developer_id: developer?.developer_id,
    });
  };

  const deleteFromBench = () => {
    props.editBenchStatus({
      on_bench: 0,
      developer_id: developer?.developer_id,
    });
    props.hideModal();
  };

  const deleteFromBenchConfirmation = () => {
    props.showBenchModal();
  };

  let logo;

  if (developer?.is_own && props.company_logo) {
    logo = `${publicFilesUrl}public/img/${props.company_logo}`;
  } else if (developer?.company_logo) {
    logo = `${publicFilesUrl}public/img/${developer?.company_logo}`;
  } else {
    logo = default_company_photo;
  }

  let isVerified;

  if (developer?.is_own) {
    isVerified = props.verified === 1;
  } else {
    isVerified = developer?.company_verified === 1;
  }

  let keywords = "";
  if (developer) {
    keywords = `${developer?.job_title_name}, ${developer?.position_level_name}`;

    if (developer?.skills?.length) {
      for (let i = 0; i < 3; i++) {
        if (
          developer?.skills[i] &&
          developer?.skills[i].skill_name === "Git" &&
          developer?.skills.length > 3
        ) {
          keywords += `, ${developer?.skills[3].skill_name}`;
        } else if (developer?.skills[i]) {
          keywords += `, ${developer?.skills[i].skill_name}`;
        }
      }
    }

    if (developer.cerf_level) {
      keywords += `, English ${developer.cerf_level}`;
    }
  }

  const hire = () => {
    if (props.setSelectedTrialDeveloper) {
      props.setSelectedTrialDeveloper({
        developer_id: developer?.developer_id,
        company_id: developer?.company_id,
      });
      history.push(`/registration-quick/${developer?.developer_id}`);
    } else if (props.hire && developer?.developer_id) {
      const developerLink = `${apiUrl}developer/${developer.developer_id}`;
      props.hire(
        `Hi, we would like to chat about your Developer: <a href="${developerLink}">${developerLink}</a>`,
        `${props.company_id}:${developer.company_id}`
      );
    }
  };

  const renderMenu = () => {
    if (!props.is_auth) {
      return null;
    }

    if (developer?.is_own) {
      return (
        <>
          <h2 className={`${styles.profileTitle} text_center`}>
            Company profile
          </h2>
          <ProfileMenu />
          <CompanyMenu companyInfo={props.companyInfo} />
        </>
      );
    }

    return <DashboardMenuContainer/>;
  };

  return (
    <div className={cn(styles.wrapper, {[styles.is_auth]: props.is_auth})}>
      <Helmet>
        <title>{siteNameForTitle} / {titleString}</title>
        <meta name="description" content={titleString}/>
        <meta name="keywords" content={keywords}/>
        <meta property="og:title" content={titleString}/>
        <meta property="og:description" content={titleString}/>
        <meta property="og:image" content="https://ibench.in/ogImage.png"/>
        <meta property="og:image:width" content="628"/>
        <meta property="og:image:height" content="425"/>
      </Helmet>
      {props.developer.is_fetching && <Preloader/>}
      {props.developer.show_modal && (
        <Modal
          title={"Attention!"}
          message={
            "You are ready to delete developer? All requests for this developer will be automatically declined. The action is irreversible!"
          }
          buttons={[
            {btn_text: "Yes, delete", btn_handler: deleteDeveloper},
            {btn_text: "No", btn_handler: props.hideModal},
          ]}
        />
      )}
      {props.developer.show_bench_modal && developer?.on_bench === 1 && (
        <Modal
          title={"Attention!"}
          message={
            "You are ready to delete developer from bench? All requests for this developer will be automatically declined."
          }
          buttons={[
            {btn_text: "Yes, delete", btn_handler: deleteFromBench},
            {btn_text: "No", btn_handler: props.hideModal},
          ]}
        />
      )}

      {renderMenu()}

      {!props.is_auth && (
        <Breadcrumbs
          subPages={[{ label: 'Trial Search', link: '/trial-search' }]}
          currentPage={breadcrumbsLabel}
        />
      )}
      <div className={`d_flex justify_content_center align_items_center ${styles.top}`}>
        <h1 className={styles.title}>{titleString}</h1>
        {developer?.is_own && (
          <NavLink
            to={`/developer/${developer?.developer_id}/edit`}
            className={styles.edit_developer_link}
          >
            Edit
          </NavLink>
        )}
      </div>
      <div className={styles.developer_info}>
        <div className={styles.left_col}>
          <img
            src={
              developer?.photo
                ? `${publicFilesUrl}public/img/${developer?.photo}`
                : default_developer_photo
            }
            alt={developer?.name}
            className={styles.developer_photo}
          />
          {props.company_type === 1 && developer?.is_pro_account === 1 && (
            <div className={`pro_icon ${styles.pro_icon}`}>Pro</div>
          )}
          {props.company_type === 2 && props.is_pro_account === 1 && (
            <div className={`pro_icon ${styles.pro_icon}`}>Pro</div>
          )}
          {developer?.is_own &&
          (developer?.on_bench === 0 ? (
            <div className={styles.on_project}>ON PROJECT</div>
          ) : (
            <div className={styles.on_bench}>BENCH</div>
          ))}

          <NavLink to={`/company/${developer?.company_id}`}>
            <img
              src={logo}
              alt={`${developer?.name} company`}
              className={styles.company_logo}
            />
          </NavLink>
        </div>
        <div className={styles.right_col}>
          <div className={`d_flex justify_content_between align_items_center ${styles.top_info}`}>
            <div className={styles.name_job_title_wrapper}>
              <div className="d_flex align_items_center">
                <h4 className={styles.developer_name}>{developer?.name}</h4>

                {isVerified && (
                  <Verified className={styles.verified}/>
                )}
              </div>
              <div className={styles.job_title}>{developer?.job_title_name}</div>
            </div>

            <div>
              <div className={styles.rate}>${developer?.rate}/h</div>
              <PriceIndicatorContainer
                rate={developer?.rate}
                position_level_id={developer?.position_level_id}
              />
            </div>
          </div>
          <div className={styles.seniority}>
            {developer?.position_level_name},
            <span className={styles.experience}>{developer?.experience} years exp.</span>
            {developer?.position_calculated === 1 && (
              <div className={styles.confirmed}>Confirmed the seniority level</div>
            )}
          </div>
          <div
            className={styles.general_info}
            dangerouslySetInnerHTML={{__html: developer?.general_info}}
          />

          {props.company_type !== 2 && (
            <div className={styles.hire_btn_wrapepr}>
              <button className={`${styles.hire_btn} btn_2 btn_orange_light`} onClick={hire}>
                Hire now!
              </button>
            </div>
          )}

          {developer?.main_skill_name && (
            <div className={styles.mainSkill}>
              <span className={styles.skills_label}>Main skill: </span>
              {developer?.main_skill_name}
            </div>
          )}

          {developer?.skills.length > 0 && (
            <div className={styles.skills_wrapper}>
              <div className={styles.skills_label}>
                Skills <span className={styles.skills_tip}>(one star - one year of experience)</span>
              </div>
              {developer?.skills.map((skill) => (
                <div key={skill.skill_id} className={`${styles.skill} d_flex align_items_center`}>
                  <div className={styles.skill_name}>{skill.skill_name}</div>
                  <div className={styles.skill_experience}>{skill.skill_experience}</div>
                  <div className={`${styles.stars_wrapper} d_flex`}>
                    {[...Array(parseInt(skill.skill_experience, 10))].map((x, i) => (
                      <span key={i} className={styles.star}/>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {developer?.cerf_level && (
            <div className={styles.english_level_description_wrapper}>
              <div className={styles.english_level_type}>
                English - {developer?.cerf_level}
              </div>
              <div className={styles.english_level_description}>
                {developer?.cerf_level} - {props.developer.english_level_description}
              </div>
            </div>
          )}

          {developer?.education && (
            <div className={styles.education}>Education: {developer?.education}</div>
          )}

          {developer?.country && (
            <div className={styles.country}>Developer's Location - {developer?.country}</div>
          )}

          {developer?.description && (
            <div className={styles.english_level_description_wrapper}>
              <NavLink className={styles.english_level_type} to={`/company/${developer?.company_id}`}>
                {developer?.company_name || 'Company info'}
              </NavLink>
              <div
                className={styles.english_level_description}
                dangerouslySetInnerHTML={{__html: developer?.description}}
              />
            </div>
          )}

          {developer?.projects?.length > 0 && (
            <>
              <div className={styles.projectsBlockTitle}>Projects</div>
              {developer?.projects.map((project, index) => (
                <DeveloperProject key={project.project_id} number={index + 1} {...project} />
              ))}
            </>
          )}
        </div>
      </div>
      {developer?.is_own && (
        <div className={styles.extraLinks}>
          <span className={styles.delete_developer} onClick={deleteDeveloperConfirmation}>
            Delete developer (completely)
          </span>
          {developer?.on_bench ? (
            <span className={styles.delete_developer} onClick={deleteFromBenchConfirmation}>
              Delete from bench
            </span>
          ) : developer?.projects.length ? (
            <span className={styles.delete_from_bench} onClick={addToBench}>
              Add to bench
            </span>
          ) : (
            <Popover
              content={
                "Developers without any projects can't be showed. Please open the edit mode, and add projects."
              }
              trigger="hover"
            >
              <span className={`${styles.delete_from_bench} ${styles.delete_from_bench_disabled}`}>
                Add to bench
              </span>
            </Popover>
          )}
        </div>
      )}
    </div>
  );
};

export default Developer;
