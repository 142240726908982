import React, { useState, useEffect } from "react";
import { change } from "redux-form";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { publicFilesUrl } from "../../../constants/global-constants";
import styles from "./UploadImage.module.css";

const UploadImage = (props) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.meta.initial) {
      if (typeof props.meta.initial === "string") {
        setSelectedImages([`${publicFilesUrl}public/img/${props.meta.initial}`]); // set one image in array
      } else {
        setSelectedImages(props.meta.initial.map((imageURL) => `${publicFilesUrl}public/img/${imageURL}`)); // set array of images
      }
    }
  }, [props.meta.initial]);

  useEffect(() => {
    dispatch(change(props.meta.form, props.input.name, selectedImages));

    selectedImages.forEach((image, index) => {
      if (typeof image !== "string") {
        let reader = new FileReader();
        reader.onload = function () {
          let output = document.getElementById(`${props.input.name}-preview-${index}`);
          if (output) {
            output.src = reader.result;
          }
        };
        reader.readAsDataURL(image);
      }
    });
  }, [dispatch, props.meta.form, props.input.name, selectedImages, props.meta.initial]);

  const onChange = (e) => {
    props.input.onChange(e.target.files);
    if (e.target.files.length) setSelectedImages([...selectedImages, ...Array.from(e.target.files)]);
  };

  const removeImage = (index) => {
    setSelectedImages(selectedImages.filter((img, i) => i !== index));
    if (typeof selectedImages[index] === "string") {
      setDeletedImages([...deletedImages, selectedImages[index].split(`${publicFilesUrl}public/img/`).pop()]);
    }
  };

  useEffect(() => {
    dispatch(change(props.meta.form, `${props.input.name}Delete`, deletedImages));
  }, [dispatch, props.meta.form, props.input.name, deletedImages]);

  return (
    <div className={cn(styles.wrapper, props.multiple && styles.multiple)}>
      <div className={styles.title}>{props.title}</div>
      <div className={cn(styles.imagesList, "upload_wrapper")}>
        {selectedImages?.map((image, index) => (
          <div key={`uploadImage-${index}`} className={cn(styles.previewWrapper, "preview_wrapper")}>
            <img
              id={`${props.input.name}-preview-${index}`}
              className={props.mode === "photo" ? styles.photoPreview : styles.imagePreview}
              src={typeof image === "string" && image}
              alt="Preview"
            />
            <span className={styles.removeImage} onClick={() => removeImage(index)}/>
          </div>
        ))}

        {(!selectedImages.length || props.multiple) && (
          <div className={styles.inputWrapper}>
            <label
              htmlFor={props.input.name}
              className={cn(styles.imageLabel, props.mode === "photo" && styles.photoLabel)}
            >
              {props.label}
            </label>
            <input
              id={props.input.name}
              type="file"
              accept=".jpg, .png, .jpeg"
              className={styles.input}
              onChange={(e) => onChange(e)}
              multiple={props.multiple}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadImage;
