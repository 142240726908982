import { newsAPI } from "api/news";

const IS_FETCHING = "ibench/news/IS_FETCHING";
const SET_NEWS_LIST = "ibench/news/SET_NEWS_LIST";
const SET_FULL_NEWS_LIST = "ibench/news/SET_FULL_NEWS_LIST";

let initialState = {
  isFetching: false,
  newsList: [],
  fullNewsList: [],
};

const news = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, isFetching: action.payload };

    case SET_NEWS_LIST:
      return { ...state, newsList: action.payload };

    case SET_FULL_NEWS_LIST:
      return { ...state, fullNewsList: action.payload };

    default:
      return state;
  }
};

export const isFetching = (payload) => ({ type: IS_FETCHING, payload });

// GET OUTSOURCE SEARCH LIST
const setNewsList = (payload) => ({ type: SET_NEWS_LIST, payload });
const setFullNewsList = (payload) => ({ type: SET_FULL_NEWS_LIST, payload });

export const getNewsList = () => {
  return (dispatch) => {
    dispatch(isFetching(true));
    newsAPI.getNews().then((data) => {
      dispatch(setNewsList(data.news));
      dispatch(isFetching(false));
    });
  };
};

export const markAsRead = (newsIds, callback) => {
  return (dispatch) => {
    newsAPI.markAsRead(newsIds);
    dispatch(setNewsList([]));
    callback();
  }
};

export const getAllNews = () => {
  return (dispatch) => {
    dispatch(isFetching(true));
    newsAPI.getAllNews().then((data) => {
      dispatch(setFullNewsList(data.news));
      dispatch(isFetching(false));
    });
  };
};

export default news;
