import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

import defaultProfilePhoto from "assets/img/boy.svg";
import { publicFilesUrl } from "constants/global-constants";
import { history } from "../../utils";
import styles from "./Chat.module.css";

const ChatListDropdown = (props) => {

    const userOptions = props.chat_list.map((item) => {
        return {
            key: item.chat_id,
            text: item.user.representative_name || item.user.company_name,
            value: item.chat_id,
            image: {
                avatar: true,
                src: item.user.representative_photo
                  ? `${publicFilesUrl}public/img/${item.user.representative_photo}`
                  : defaultProfilePhoto
            },
        };
    });

    return (
      <div className={styles.chat_dropdown}>
        <Dropdown
          fluid
          selection
          options={userOptions}
          value={props.activeChatId}
          onChange={(event, data) => history.push(`/chat/${data.value}`)}
        />
      </div>
    );
};

export default ChatListDropdown;
