import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Helmet } from "react-helmet";
import cn from "classnames";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { editProfile } from "../../../store/auth";
import { getCountriesList } from "../../../store/countries";
import { siteNameForTitle } from "../../../constants";
import Preloader from "../../common/Preloader/Preloader";
import ProfileMenu from "../ProfileMenu";
import CompanyMenu from "../CompanyMenu";
import ProfileEditing from "./ProfileEditing";
import TalentProfileEditing from "./TalentProfileEditing";
import ClientProfileEditing from "./ClientProfileEditing";

import styles from "./ProfileEditing.module.css";

const ProfileEditingContainer = (props) => {
  const { user, company_info, is_fetching } = props.auth;

  const renderForm = () => {
    if (user.is_single_developer) {
      return <TalentProfileEditing {...props} editProfile={props.editProfile} />;
    }

    if (user.type === 1) {
      return <ClientProfileEditing {...props} editProfile={props.editProfile} />;
    }

    return <ProfileEditing {...props} editProfile={props.editProfile} />;
  };

  let userTypeName;

  if (user.is_single_developer) {
    userTypeName = "Developer";
  } else if (user.type === 1) {
    userTypeName = "Client";
  } else {
    userTypeName = "Company";
  }

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Profile editing | {siteNameForTitle}</title>
      </Helmet>

      {is_fetching && <Preloader/>}

      <h2 className={cn(styles.title, "text_center")}>
        {userTypeName} profile
      </h2>

      <ProfileMenu />

      {company_info.type === 2 && <CompanyMenu companyInfo={company_info} />}

      <div className={styles.subtitle}>
        Edit profile / Base info
      </div>

      {renderForm()}
    </div>

  )
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    markets_list: state.app.markets_list,
  };
};

const mapDispatchToProps = {
  editProfile,
  getCountriesList,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthRedirect
)(ProfileEditingContainer);
