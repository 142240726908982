import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withAuthRedirect } from "hoc/withAuthRedirect";
import {
  getMarketplaceDevelopers,
  getMarketplaceFreelancers,
  deleteDeveloperSearchFromMarketplace,
  getMarketplaceProjects,
  getMarketplaceLeads,
} from "../../store/marketplace";
import { hire } from "../../store/chat";
import MarketplaceFreelancers from "./MarketplaceFreelancers";

const MarketplaceFreelancerContainer = (props) => {
  useEffect(() => {
    if (props.companyType === 1) {
      props.getMarketplaceFreelancers();
      props.getMarketplaceLeads();
    }

    if (props.isSingleDeveloper) {
      props.getMarketplaceFreelancers()
    } else {
      props.getMarketplaceDevelopers();
      props.getMarketplaceProjects();
    }
  }, []);

  return (
    <MarketplaceFreelancers
      companyId={props.companyId}
      companyType={props.companyType}
      companyVerificationChecks={props.companyVerificationChecks}
      isSingleDeveloper={props.isSingleDeveloper}
      marketplace={props.marketplace}
      closeMarketplaceRequestForm={props.closeMarketplaceRequestForm}
      getMarketplaceDevelopers={props.getMarketplaceDevelopers}
      deleteDeveloperSearchFromMarketplace={props.deleteDeveloperSearchFromMarketplace}
      hire={props.hire}
    />
  );
};

let mapStateToProps = (state) => ({
  companyId: state.auth.company_info.id,
  companyType: state.auth.company_info.type,
  isSingleDeveloper: state.auth.company_info.is_single_developer,
  companyVerificationChecks: state.auth.companyVerificationChecks,
  marketplace: state.marketplace,
  closeMarketplaceRequestForm: state.form.closeMarketplaceRequest,
});

export default compose(
  connect(mapStateToProps, {
    getMarketplaceDevelopers,
    getMarketplaceFreelancers,
    deleteDeveloperSearchFromMarketplace,
    getMarketplaceProjects,
    getMarketplaceLeads,
    hire,
  }),
  withAuthRedirect,
)(MarketplaceFreelancerContainer);
