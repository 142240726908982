import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { required, numericality } from "redux-form-validators";
import moment from "moment";

import { Input, SelectInput, renderMultiselect } from "../../common/FormsControls/FormControls";
import Preloader from "../../common/Preloader/Preloader";
import ProfileMenu from "../../Profile/ProfileMenu";
import CompanyMenu from "../../Profile/CompanyMenu";
import { siteNameForTitle } from "../../../constants/global-constants";
import UploadImage from "../../common/UploadImage/UploadImage";
import RadioButton from "../../common/RadioButton/RadioButton";
import UploadFile from "../../common/UploadFile/UploadFile";
import Editor from "../../common/Editor/Editor";
import DatePickerWrapper from "../../common/DatePickerWrapper/DatePickerWrapper";

import styles from "./CaseAdding.module.css";

let CaseAddingForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <Field component={UploadImage} name="logo" label="Upload Photo" mode="photo"/>
        <Field
          component={Input}
          type={"text"}
          name={"name"}
          label="Project name"
          validate={[required()]}
          help_text="for example: My first project"
        />
        <Field
          component={Editor}
          name={"description"}
          label="Project description"
          validate={[required()]}
          placeholder={"Write project description..."}
        />
        <div className={styles.projectDuration}>
          <div className={styles.projectDurationItem}>
            <Field
              name={"from_date"}
              label="Project start"
              defaultValue={props.initialValues?.from_year && moment(props.initialValues.from_year)}
              component={DatePickerWrapper}
              help_text="For example: June 2020"
              validate={[required()]}
            />
          </div>
          <div className={styles.projectDurationItem}>
            <Field
              name={"to_date"}
              label="Project end"
              defaultValue={props.initialValues?.to_year && moment(props.initialValues.to_year)}
              component={DatePickerWrapper}
              help_text="For example: June 2021"
              validate={[required()]}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Field
              component={Input}
              type={"number"}
              name={"team_size"}
              label="Team size"
              validate={[required(), numericality({ int: true, ">": 1 })]}
              help_text="For example: 8"
            />
          </div>
          <div className={styles.col}>
            <Field
              component={SelectInput}
              name={"country"}
              label="Client's Country"
              options={props.countries_list}
              validate={[required()]}
              help_text="for example: USA"
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <Field
              className={styles.hoursSpentInput}
              component={Input}
              type={"number"}
              name={"hours_spent"}
              label="Total hours spent"
              validate={[required(), numericality({ int: true, ">": 0 })]}
              help_text="For example: 200"
            />
          </div>
        </div>
        <Field
          component={renderMultiselect}
          name={"markets"}
          label="What are you building?"
          validate={[required()]}
          data={props.markets_list}
          valueField="market_id"
          textField="market_name"
          placeholder="What are you building?"
        />

        <div className={styles.projectSkillsWrapper}>
          <Field
            name={"skills"}
            component={renderMultiselect}
            label="Technologies"
            validate={[required()]}
            data={props.skills_list}
            valueField="skill_id"
            textField="skill_name"
            placeholder="Enter the project technologies"
          />
        </div>

        <div className={styles.monthBudgetWrapper}>
          <div className={styles.monthBudgetTitle}>Project budget</div>
          <div className={styles.monthBudget}>
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="5000"
              text="Less then $5,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="15000"
              text="Up to $15,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="35000"
              text="Up to $35,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="75000"
              text="Up to $75,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="100000"
              text="Up to $100,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="200000"
              text="Up to $200,000"
              validate={[required()]}
              designType={"greenButton"}
            />
          </div>
        </div>

        <Field component={Input} type={"url"} name={"links"} label="Additional link"/>
        <Field component={UploadImage} name="covers" label="Upload Photo" title="Add covers" multiple={true}/>
        <Field
          component={UploadFile}
          name="files"
          label="upload file"
          title="Upload PDF presentation"
          multiple={true}
          maxSize={15}
          accept={[".pdf"]}
        />
      </div>

      <div>
        <button className={`btn ${styles.submit}`}>SAVE CASE</button>
      </div>
    </form>
  );
};

CaseAddingForm = reduxForm({ form: "caseAdding" })(CaseAddingForm);

const CaseAdding = (props) => {
  const onSubmit = (formData) => {
    props.addCase(formData);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Add new case | {siteNameForTitle}</title>
      </Helmet>

      {props.cases.isFetching && <Preloader/>}

      <h2 className={`${styles.profileTitle} text_center`}>
        Company profile
      </h2>

      <ProfileMenu />

      <CompanyMenu companyInfo={props.companyInfo} />

      <div className={styles.title}>Add new case</div>

      <div className={styles.formWrapper}>
        <CaseAddingForm
          onSubmit={onSubmit}
          countries_list={props.countries_list}
          markets_list={props.markets_list}
          skills_list={props.skills_list}
        />
      </div>
    </div>
  );
};

export default CaseAdding;
