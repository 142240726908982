import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm, formValueSelector } from "redux-form";
import { required, numericality } from "redux-form-validators";
import cn from "classnames";

import { yearsExperience, languagesList } from "../../../constants/global-constants";
import UploadFile from "../../common/UploadFile/UploadFile";
import {
  Input,
  SelectInput,
  SelectInputKeyValue,
  SelectInputEnglish,
  SelectInputIdName,
} from "../../common/FormsControls/FormControls";

import styles from "./Skills.module.css";

const formName = "skillsForm";

const SkillsForm = (props) => {
  const {
    handleSubmit,
    jobTitles,
    positionLevels,
    skillsList,
    languageLevels,
    projectAreas,
    initialValues,
    dispatch,
    skillId,
    skillYears,
    language,
    languageLevel,
    projectArea,
    cv,
  } = props;

  const [yearsExperienceList, setYearsExperienceList] = useState([]);
  let [skills, setSkills] = useState([]);
  let [languages, setLanguages] = useState([]);
  let [areas, setAreas] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (initialValues.position_level_id) {
      getYearsExperienceList(initialValues.position_level_id);
    }

    if (initialValues.skills?.length) {
      setSkillsField(initialValues.skills);
    }

    if (initialValues.languages?.length) {
      setLanguagesField(initialValues.languages);
    }

    if (initialValues.project_areas?.length) {
      setAreasField(initialValues.project_areas);
    }
  }, []);

  const submitHandler = (event) => {
    const errors = {};
    if (!Object.keys(skills).length) {
      errors.skills = true;
    }

    if (!Object.keys(languages).length) {
      errors.languages = true;
    }

    if (!Object.keys(areas).length) {
      errors.project_areas = true;
    }

    if (!cv.length) {
      errors.cv = true;
    }

    setErrors(errors);

    handleSubmit(event);
  };

  const getYearsExperienceList = (levelId) => {
    const yearsExperienceList = yearsExperience[levelId];
    setYearsExperienceList(yearsExperienceList);
  };

  const changeSeniorityHandler = (event) => {
    getYearsExperienceList(event.target.value);
    dispatch(change(formName, "position_level", ""));
    dispatch(change(formName, " ", ""));
  };

  const setSkillsField = (skills) => {
    setSkills([ ...skills ]);
    dispatch(change(formName, "skills", skills));
  };

  const addSkill = () => {
    if (!skillId || !skillYears) {
      return;
    }

    if (errors.skills) {
      delete errors.skills;
      setErrors({ ...errors });
    }

    dispatch(change(formName, "skill", ""));
    dispatch(change(formName, "skill_years", ""));

    const skillIdNumber = +skillId;

    const skillData = skillsList.find(skill => skill.skill_id === skillIdNumber);
    skills = skills.filter(skill => skill.skill_id !== skillIdNumber);

    skills.push({
      skill_id: skillId,
      skill_name: skillData.skill_name,
      years: skillYears,
    });

    setSkillsField(skills);
  };

  const removeSkill = (skillId) => {
    skills = skills.filter(skill => skill.skill_id !== skillId);
    setSkillsField(skills);
  };

  const setLanguagesField = (languages) => {
    setLanguages([ ...languages ]);
    dispatch(change(formName, "languages", languages));
  };

  const addLanguage = () => {
    if (!language || !languageLevel) {
      return;
    }

    if (errors.languages) {
      delete errors.languages;
      setErrors({ ...errors });
    }

    dispatch(change(formName, "language", ""));
    dispatch(change(formName, "language_level", ""));

    const levelIdNumber = +languageLevel;

    const levelData = languageLevels.find(level => level.english_level_id === levelIdNumber);
    languages = languages.filter(level => level.language !== language);

    languages.push({
      language,
      level_id: levelData.english_level_id,
      cerf_level: levelData.cerf_level,
    });

    setLanguagesField(languages);
  };

  const removeLanguage = (language) => {
    languages = languages.filter(level => level.language !== language);
    setLanguagesField(languages);
  };

  const setAreasField = (areas) => {
    setAreas([ ...areas ]);
    dispatch(change(formName, "project_areas", areas));
  };

  const addArea = () => {
    if (!projectArea) {
      return;
    }

    if (errors.project_areas) {
      delete errors.project_areas;
      setErrors({ ...errors });
    }

    dispatch(change(formName, "project_area", ""));

    const areaNumber = +projectArea;

    const areaData = projectAreas.find(area => area.id === areaNumber);
    areas = areas.filter(area => area.id !== areaNumber);

    areas.push(areaData);

    setAreasField(areas);
  };

  const removeArea = (areaId) => {
    areas = areas.filter(area => area.id !== areaId);
    setAreasField(areas);
  };

  const saveHandler = () => {
    dispatch(change(formName, "url_redirect"));
  };

  const nextPageHandler = () => {
    dispatch(change(formName, "url_redirect", "/profile/edit/relocation"));
  };

  const previousPageHandler = () => {
    dispatch(change(formName, "url_redirect", "/profile/edit"));
  };

  return (
    <form onSubmit={submitHandler} className={styles.skills_form}>
      <div className={styles.fields_wrapper}>
        <div className={styles.fields_row}>
          <Field
            input_wrapper_class={cn(styles.select_wrapper, styles.full_width)}
            component={SelectInputKeyValue}
            name="job_title_id"
            label="Title*"
            validate={[required()]}
            options={jobTitles}
            help_text="for example: Front-end developer"
            help_text_design={styles.help_text}
          />
        </div>
        <div className={styles.fields_row}>
          <Field
            input_wrapper_class={cn(styles.select_wrapper, styles.full_width)}
            component={SelectInputKeyValue}
            name="additional_job_title_id"
            label="Additional Title"
            options={jobTitles}
            help_text="for example: Mobile developer"
            help_text_design={styles.help_text}
          />
        </div>
        <div className={styles.fields_row}>
          <Field
            input_wrapper_class={styles.select_wrapper}
            component={SelectInputKeyValue}
            name="position_level_id"
            label="Seniority level*"
            validate={[required()]}
            options={positionLevels}
            help_text="for example: Senior"
            help_text_design={styles.help_text}
            onChange={changeSeniorityHandler}
          />
          <Field
            input_wrapper_class={styles.select_wrapper}
            component={SelectInput}
            name="years_experience"
            label="Years experience*"
            validate={[required()]}
            options={yearsExperienceList}
            help_text="for example: 5"
            help_text_design={styles.help_text}
          />
        </div>

        <div className={cn(styles.field_label, styles.location_label)}>Skills</div>
        <div className={styles.skills_row}>
          <div className={styles.fields_row}>
            <Field
              input_wrapper_class={styles.select_wrapper}
              component={SelectInputKeyValue}
              name="skill"
              label="Skill"
              options={skillsList}
              help_text="for example: React.js"
              help_text_design={styles.help_text}
            />
            <Field
              input_wrapper_class={styles.select_wrapper}
              component={SelectInput}
              name="skill_years"
              label="Years experience"
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              help_text="for example: 5"
              help_text_design={styles.help_text}
            />
          </div>
          {errors.skills && (
            <div className={styles.custom_error}>Select at least 1 skill</div>
          )}
          <div className={styles.add_button} onClick={addSkill} />
        </div>

        <div className={styles.selected_skills}>
          {skills.map((skillData, index) => (
            <div className={styles.skill_item_row} key={index}>
              <div className={styles.skill_item}>
                {skillData.skill_name}, {skillData.years} years
              </div>
              <div className={styles.delete_skill} onClick={() => removeSkill(skillData.skill_id)}>delete</div>
            </div>
          ))}
        </div>
        <div className={cn(styles.field_label, styles.location_label)}>
          Your expertise
        </div>
        <div className={styles.skills_row}>
          <div className={styles.fields_row}>
            <Field
              input_wrapper_class={cn(styles.select_wrapper, styles.full_width)}
              component={SelectInputIdName}
              name="project_area"
              label="Industry"
              options={projectAreas}
              help_text="For example: EdTech and FinTech"
              help_text_design={styles.help_text}
            />
          </div>
          {errors.project_areas && (
            <div className={styles.custom_error}>Select at least 1 industry</div>
          )}
          <div className={styles.add_button} onClick={addArea} />
        </div>

        <div className={styles.selected_skills}>
          {areas.map((areaData) => (
            <div className={styles.skill_item_row} key={areaData.id}>
              <div className={styles.skill_item}>
                {areaData.name}
              </div>
              <div className={styles.delete_skill} onClick={() => removeArea(+areaData.id)}>delete</div>
            </div>
          ))}
        </div>

        <div className={cn(styles.field_label, styles.location_label)}>
          Salary
        </div>
        <div className={cn(styles.fields_row, styles.salary_row)}>
          <Field
            className={cn(styles.input, styles.hourly_rate)}
            component={Input}
            type="number"
            name="hourly_rate"
            label="Hourly rate"
            help_text="for example: 50"
            help_text_design={styles.help_text}
          />
          <Field
            className={cn(styles.input, styles.monthly_rate)}
            component={Input}
            type="number"
            name="monthly_rate"
            label="Monthly*"
            help_text="for example: 5000"
            help_text_design={styles.help_text}
            validate={[required(), numericality({
              int: true,
              '>': 0,
            })]}
          />
          <Field
            input_wrapper_class={cn(styles.select_wrapper, styles.currency_select_wrapper)}
            component={SelectInput}
            name="rate_currency"
            label="Euro or USD*"
            validate={[required()]}
            options={['USD', 'Euro']}
            help_text="for example: USD"
            help_text_design={styles.help_text}
          />
        </div>

        <div className={cn(styles.field_label, styles.location_label)}>Language</div>
        <div className={styles.skills_row}>
          <div className={styles.fields_row}>
            <Field
              input_wrapper_class={styles.select_wrapper}
              component={SelectInput}
              name="language"
              label="Language"
              options={languagesList}
              help_text="for example: English"
              help_text_design={styles.help_text}
            />
            <Field
              input_wrapper_class={styles.select_wrapper}
              component={SelectInputEnglish}
              name="language_level"
              label="Language level"
              options={languageLevels}
              help_text="for example: C1"
              help_text_design={styles.help_text}
            />
          </div>
          {errors.languages && (
            <div className={styles.custom_error}>Select at least 1 language</div>
          )}
          <div className={styles.add_button} onClick={addLanguage} />
        </div>

        <div className={styles.selected_languages}>
          {languages.map((languageData, index) => (
            <div className={styles.skill_item_row} key={index}>
              <div className={styles.skill_item}>
                {languageData.language}, {languageData.cerf_level}
              </div>
              <div className={styles.delete_skill} onClick={() => removeLanguage(languageData.language)}>delete</div>
            </div>
          ))}
        </div>

        <div className={styles.upload_row}>
          <Field
            className={styles.upload_wrapper}
            component={UploadFile}
            name="cv"
            label="Upload PDF file, up to 15 MB"
            title="Upload your CV"
            maxSize={15}
            accept={[".pdf"]}
            hideHelp
            dontHideButton
            validate={[required()]}
            fullPath
            showFileName
          />
          {errors.cv && !cv?.length && (
            <div className={styles.custom_error}>CV is required</div>
          )}
        </div>

        <div className={styles.submit_wrapper}>
          <button className={cn("btn", styles.submit)} onClick={saveHandler}>
            Save
          </button>
        </div>

        <div className={cn(styles.submit_wrapper)}>
          <button className={cn("btn", styles.submit, styles.yellow)} onClick={previousPageHandler}>
            Previous
          </button>
          <button className={cn("btn", styles.submit, styles.yellow)} onClick={nextPageHandler}>
            Next
          </button>
        </div>
      </div>

    </form>
  );
};

const selector = formValueSelector(formName);

let BaseInfoFormReduxForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};

    if (values.additional_job_title_id == values.job_title_id) {
      errors.additional_job_title_id = "Title and Additional title cannot have the same values";
    }

    if (values.hourly_rate) {
      errors.hourly_rate = numericality({
        int: true,
        '>': 0,
      })(values.hourly_rate);
    }

    if (values.cv) {
      delete errors.cv;
    }

    return errors;
  }
})(SkillsForm);

const mapStateToProps = (state) => ({
  initialValues: state.auth.company_info,
  jobTitles: state.app.job_titles_list,
  positionLevels: state.developer.position_levels,
  skillsList: state.app.skills_list,
  languageLevels: state.app.language_level_list,
  skillId: selector(state, 'skill'),
  skillYears: selector(state, 'skill_years'),
  language: selector(state, 'language'),
  languageLevel: selector(state, 'language_level'),
  projectArea: selector(state, 'project_area'),
  projectAreas: state.app.project_areas,
  cv: selector(state, 'cv'),
});

export default connect(mapStateToProps)(BaseInfoFormReduxForm);