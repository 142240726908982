import React from "react";
import moment from "moment";
import { DatePicker } from "antd";

import styles from "./DatePickerWrapper.module.css";

const DatePickerWrapper = (props) => {
  const {
    label,
    input: { onChange, value },
    help_text,
    meta,
    picker = "month",
    dateFormat = "YYYY-MM",
    withCheckMark = false,
    ...restProps
  } = props;

  return (
    <div className={`form_group label_above`}>
      <div className="input_wrapper">
        {label && <label className={"label"}>{label}</label>}

        <DatePicker
          onChange={onChange}
          picker={picker}
          format={dateFormat}
          value={value && moment(value)}
          {...restProps}
        />
      </div>

      {help_text && <div className={styles.input_help}>{help_text}</div>}

      {meta.touched && meta.error && <div className="validation_message">{meta.error}</div>}
      {meta.touched && meta.error && <span className="validation_status_error"/>}
      {withCheckMark && meta.valid && <span className="validation_status_ok"/>}
    </div>
  );
};

export default DatePickerWrapper;
