import { useEffect } from "react";
import { connect } from "react-redux";

import { getOutsourceSearchList } from "../../store/outsource";
import Outsource from "./Outsource";

const OutsourceContainer = (props) => {
    useEffect(() => {
        props.getOutsourceSearchList();
    }, []);

    return <Outsource isFetching={props.outsource.isFetching} searchList={props.outsource.searchList} />;
};

let mapStateToProps = (state) => ({
    outsource: state.outsource,
});

export default connect(mapStateToProps, {
    getOutsourceSearchList,
})(OutsourceContainer);
