import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import { authAPI } from "../../api/auth";
import { siteNameForTitle } from "../../constants/global-constants";
import DashboardMenuContainer from "../../components/DashboardMenu/DashboardMenuContainer";

import styles from "./Confirmation.module.css";

const Confirmation = () => {
  const [message, setMessage] = useState();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");

  useEffect(() => {
    authAPI.confirm({ code }).then((response) => {
      if (response.status === 200) {
        setMessage("Your email has been confirmed.");
      }
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{siteNameForTitle} / Confirmation</title>
        <meta
          name="description"
          content="IBench is a marketplace filled with highly skilled developers from Eastern Europe. Our developers can tackle web projects, mobile apps or MVPs for start-ups."
        />
        <meta
          name="keywords"
          content="software developers, ux/ui designers, bench, developers on demand, realtime developers search, e-commerce developers, fin-tech developers, healthcare developers"
          data-react-helmet="true"
        />
      </Helmet>

      <DashboardMenuContainer/>

      <h1 className={styles.pageTitle}>Confirmation</h1>
      <p className={styles.message}>{message}</p>
    </div>
  );
};

export default Confirmation;
