import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
// import cn from "classnames";

import { siteNameForTitle } from "../../constants/global-constants";
import Preloader from "../common/Preloader/Preloader";
import Verified from "../common/Verified/Verified";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import ChatList from "./ChatList";
import ChatListDropdown from "./ChatListDropdown";
import ChatMessages from "./ChatMessages";

import styles from "./Chat.module.css";

const Chat = (props) => {
  const { chat_user } = props;

  // const toggleInfo = () => {
  //   props.toggleChatInfo();
  // };

  const renderContent = () => {

    if (!props.chat_id && !props.chat_list) {
      return (
        <div className={styles.no_chats_message}>
          You don't have active chats yet
        </div>
      );
    }

    const actualChatList = props.chat_list || props.new_chat_info;

    const renderUserInfo = () => {
      if (!chat_user) {
        return null;
      }

      if (chat_user.is_single_developer) {
        return (
          <>
            <div className={styles.chatTitle}>
              Chat with
              <NavLink
                className={styles.chatTitleName}
                to={`/talent/${chat_user.company_id}`}
              >
                {chat_user.first_name} {chat_user.last_name[0].toUpperCase()}.{' '}
              </NavLink>
            </div>
            <div className={styles.talentInfo}>
              {chat_user.position_level_name}{' '}
              {chat_user.job_title_name && `${chat_user.job_title_name}, `}
              {chat_user.additional_job_title_name && `${chat_user.additional_job_title_name}, `}
              {chat_user.country}
            </div>
          </>
        );
      }

      if (chat_user.type === 1) {
        const clientName = `${chat_user.first_name} ${chat_user.last_name}`;

        return (
          <div className={styles.chatTitle}>
            Chat with
            <div className={styles.chatTitleName}>
              {clientName}
            </div>
          </div>
        );
      } 

      const companyName = chat_user.representative_name || chat_user.company_name;

      return (
        <div className={styles.chatTitle}>
          Chat with
          <NavLink
            className={styles.chatTitleName}
            to={`/company/${chat_user.company_id}`}
          >
            {companyName}
          </NavLink>
          {chat_user.verified === 1 && <Verified className={styles.verifiedWrapper}/>}
        </div>
      );
    };

    return (
      <>
        {/*{props.user.type === 2 && (*/}
          {/*<div className={cn(styles.chatInfo, { [styles.collapse]: props.collapsed_chat_info })}>*/}
            {/*<p>*/}
              {/*Hey there! Just a heads-up: the person you're chatting with could be a direct client or perhaps an*/}
              {/*intermediary, like a Software House looking for developers for WhiteLabel projects. While iBench strives for*/}
              {/*clarity, we can't always confirm who's on the other end. So, when chatting, always keep your guard up and*/}
              {/*maybe save the confidential stuff for email. Stay safe and happy chatting! 😊*/}
            {/*</p>*/}
            {/*<div className={styles.collapseButton} onClick={toggleInfo} />*/}
          {/*</div>*/}
        {/*)}*/}

        <div className={styles.chatTitleWrapper}>
          {renderUserInfo()}
        </div>

        <div className={styles.chat_wrapper}>
          <div className={styles.desktop}>
            {props.chat_list && (
              <ChatList
                chat_list={props.chat_list}
                online_users={props.online_users}
                user={props.user}
                setChatId={props.setChatId}
                deleteChat={props.deleteChat}
              />
            )}
            {!props.chat_list && props.new_chat_info && (
              <ChatList
                chat_list={props.new_chat_info}
                online_users={props.online_users}
                user={props.user}
                setChatId={props.setChatId}
                deleteChat={props.deleteChat}
              />
            )}
          </div>
          <div className={styles.mobile}>
            {actualChatList && (
              <ChatListDropdown
                chat_list={actualChatList}
                user={props.user}
                setChatId={props.setChatId}
                activeChatId={props.chat_id}
              />
            )}
          </div>

          <ChatMessages
            chat_id={props.chat_id}
            user={props.user}
            today_files={props.today_files}
            chat_list={props.chat_list}
            chat_messages={props.chat_messages}
            chat_user={chat_user}
            message_input={props.message_input}
            updateMessageInput={props.updateMessageInput}
            getChatMessages={props.getChatMessages}
            sendMessage={props.sendMessage}
            socketSendMessage={props.socketSendMessage}
            socketRead={props.socketRead}
            socketTyping={props.socketTyping}
            sendChatSeen={props.sendChatSeen}
          />
        </div>
      </>
    )
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Chat | {siteNameForTitle}</title>
      </Helmet>

      {props.is_fetching && <Preloader/>}
      <DashboardMenuContainer type={"company"}/>

      {renderContent()}
    </div>
  );
};

export default Chat;
