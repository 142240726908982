import React, { useState } from "react";
import cn from "classnames";

import Preloader from "../../common/Preloader/Preloader";
import Modal from "../../common/Modal/Modal";
import ProfileMenu from "../ProfileMenu";
import SettingsModal from "./SettingsModal";
import TalentSettingsForm from "./TalentSettingsForm";
import CompanyMenu from "../CompanyMenu";
import FormIndicator from "../../registration/TeamSearch/FormIndicator/FormIndicator";


import styles from "../ProfileEditing/ProfileEditing.module.css";

const TalentSettings = (props) => {
  const { auth, editProfile, profile } = props;
  const [showModal, setShowModal] = useState(false);

  const submitHandler = (formData) => {
    const userData = {
      receive_emails: formData.receive_emails !== "false" && !!formData.receive_emails,
      receive_telegram: formData.receive_telegram !== "false" && !!formData.receive_telegram,
      alert_position_level_id: formData.alert_position_level_id,
      alerts: formData.alerts || [],
      url_redirect: formData.url_redirect,
    };

    if (auth.company_info.profile_fill_step < 5) {
      userData.profile_fill_step = 5;
    }

    editProfile(userData, () => {
      if (!profile || !formData.url_redirect) {
        return setShowModal(true);
      }
    });
  };

  return (
    <div className={cn(styles.settings_wrapper, styles.talent_wrapper)}>
      <div className={styles.modal_wrapper}>
        {showModal && (
          <Modal
            showModal={setShowModal}
            renderComponent={<SettingsModal onSubmit={() => setShowModal(false)} />}
            noBg
          />
        )}
      </div>

      {auth.is_fetching && <Preloader />}

      <h2 className={styles.title}>
        Developer profile
      </h2>
      <ProfileMenu />

      {profile && (
        <>
          <CompanyMenu companyInfo={auth.company_info} />
          <div className={styles.subtitle}>Edit profile / Alerts</div>

          {auth.company_info.profile_fill_step < 5 && <FormIndicator page={5} className={styles.indicator} />}
        </>
      )}

      <TalentSettingsForm onSubmit={submitHandler} profile={profile} />
    </div>
  );
};

export default TalentSettings;
