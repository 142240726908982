import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { siteNameForTitle } from "../../constants";
import { getTalent } from "../../store/candidates";
import { hire } from "../../store/chat";
import TalentDetails from "../Profile/TalentDetails";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import styles from "./Talent.module.css";

const CompanyDeveloperContainer = (props) => {
  const { currentTalent, getTalent, match } = props;

  useEffect(() => {
    getTalent(match.params.talent_id);
  }, [match]);

  if (!currentTalent) {
    return null;
  }

  return (
    <div className={styles.company_details}>
      <Helmet>
        <title>{siteNameForTitle} / Talent Details</title>
      </Helmet>
      <Breadcrumbs
        className={styles.breadcrumbs}
        currentPage={`${currentTalent?.first_name} ${currentTalent?.last_name[0].toUpperCase()}.`}
      />
      {!!currentTalent && <TalentDetails talentData={currentTalent} />}
    </div>
  )
};

const mapStateToProps = (state) => ({
  currentTalent: state.candidates.currentTalent,
  companyInfo: state.auth?.company_info,
});

const mapDispatchToProps = {
  getTalent,
  hire,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDeveloperContainer);
