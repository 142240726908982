import React from "react";
import { notification } from "antd";

import linkImage from "../../../assets/img/link.png";
import styles from "./CopyLink.module.css";

const CopyLink = (props) => {
  const { link, message } = props;

  const copyToClipboard = () => {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = `${window.location.origin}${link}`;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    notification.success({
      message,
      placement: "bottomRight",
      duration: 3,
    });
  };

  return (
    <div className={styles.copyLink}>
      <span onClick={copyToClipboard}>Copy link</span>
      <img
        onClick={copyToClipboard}
        className={styles.copyImage}
        src={linkImage}
        alt="Copy link"
      />
    </div>
  );
};

export default CopyLink;