import React from "react";
import {connect} from "react-redux";

import {logout} from "../../store/store";
import styles from "./Plug.module.css";


const Plug = (props) => {
  return (
    <div className={styles.plug}>
      <div>
        <span className={styles.plug_text}>Desktop version only</span>
        {props.auth.is_auth && (
          <button className={styles.logout} onClick={props.logout}>
            Logout
          </button>
        )}
      </div>
    </div>
  );
};

let mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {logout})(Plug);
