import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { addCase } from "../../../store/cases";
import countries_list from "../../../utils/countries_list";
import CaseAdding from "./CaseAdding";

const CaseAddingContainer = (props) => {
  return (
    <CaseAdding
      {...props}
      isFetching={props.cases.isFetching}
      countries_list={countries_list.map((item) => item.label)}
      markets_list={props.markets_list}
      skills_list={props.skills_list}
      addCase={props.addCase}
    />
  );
};

let mapStateToProps = (state) => ({
  cases: state.cases,
  markets_list: state.app.markets_list,
  skills_list: state.app.skills_list,
  companyInfo: state.auth.company_info,
});

export default compose(
  connect(mapStateToProps, {
    addCase,
  }),
  withAuthRedirect
)(CaseAddingContainer);
