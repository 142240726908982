import React from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import cn from "classnames";

import { renderMultiselect } from "../../common/FormsControls/FormControls";
import FormIndicator from "./FormIndicator/FormIndicator";

import styles from "./TeamSearch.module.css";

const SearchForm = (props) => {
  let markets = "";
  props.markets &&
    props.markets.forEach((market) => {
      markets += `${market.market_name}, `;
    });
  markets = markets.slice(0, -2); // remove last comma

  const addToSearch = (skill) => {
    let skills = props.skills ? [...props.skills] : [];
    // check if skill wasn't added, then add it
    let skillIndex = skills.findIndex((item) => item.skill_id === skill.skill_id);
    if (skillIndex === -1) {
      skills.push(skill);
      props.dispatch(change("team-search", "skills", skills));
    }
  };

  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <div className={styles.advantages}>
          <div className={styles.skills_question}>
            What skills <br className="mobile_visible" /> are you looking for?
          </div>
        </div>

        <FormIndicator page={props.page} />

        <div className={styles.multiselect_wrapper}>
          <Field
            name="skills"
            component={renderMultiselect}
            validate={[required()]}
            data={props.skills_list}
            valueField="skill_id"
            textField="skill_name"
            placeholder="Desired areas of expertise"
          />
        </div>
      </div>

      {props.error && <div className={`${styles.form_erros} text_center validation_message`}>{props.error}</div>}

      <div className={styles.popular_searches}>
        <div className={styles.popular_searches_label}>Popular searches:</div>
        <div className={styles.popular_searches_list}>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 104, skill_name: "React.js" })}
          >
            React.js
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 249, skill_name: "Angular" })}
          >
            Angular
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 163, skill_name: "Node.js" })}
          >
            Node.js
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 204, skill_name: "Flutter" })}
          >
            Flutter
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 48, skill_name: "Golang" })}
          >
            Golang
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 58, skill_name: "iOS" })}
          >
            iOS
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 7, skill_name: "Android" })}
          >
            Android
          </div>
          <div
            className={styles.popular_searches_item}
            onClick={() => addToSearch({ skill_id: 129, skill_name: "UX/UI" })}
          >
            UX/UI
          </div>
        </div>
      </div>

      <div className={`${styles.submit_wrapper} text_center`}>
        <button className={cn(styles.submit, "btn_2", "btn_purple", "mobile_hidden")}>Next step</button>
        <button className={cn(styles.submit, "btn_2", "btn_purple", "mobile_visible")}>Next</button>
      </div>
    </form>
  );
};

let ReduxForm = reduxForm({
  form: "team-search",
  destroyOnUnmount: false,
})(SearchForm);

const formSelector = formValueSelector("team-search");
ReduxForm = connect((state) => {
  const markets = formSelector(state, "markets");
  const skills = formSelector(state, "skills");
  return { markets, skills };
})(ReduxForm);

export default ReduxForm;
