import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { deleteSearch, editSearch, getCurrentSearch, getSearchResults, switchEditMode } from "../../../store/search";
import {
  showModal,
  setBookmarkFormStatus,
  setResponseMessage,
  getBookmarksList,
  getBookmarksDevelopers,
  setAddingToBookmark,
  setCurrentDeveloperId,
  setCurrentDeveloperBookmarks,
  setCurrentBookmarkId,
  setBookmarkDelete,
  deleteBookmark,
} from "../../../store/bookmarks";
import { hire } from "../../../store/chat";
import Bookmark from "./Bookmark";

class BookmarkContainer extends Component {
  state = {
    bookmark_id: this.props.match.params.bookmark_id,
  };

  componentDidMount() {
    this.props.getBookmarksList();
    this.props.getCurrentSearch(this.state.bookmark_id);
    this.props.setCurrentBookmarkId(this.state.bookmark_id);
    this.props.getBookmarksDevelopers(this.state.bookmark_id);
  }

  render() {
    return (
      <Bookmark
        bookmarks={this.props.bookmarks}
        search={this.props.search}
        company_id={this.props.company_id}
        bookmark_id={this.state.bookmark_id}
        switchEditMode={this.props.switchEditMode}
        editSearch={this.props.editSearch}
        deleteSearch={this.props.deleteSearch}
        hire={this.props.hire}
        showModal={this.props.showModal}
        setBookmarkFormStatus={this.props.setBookmarkFormStatus}
        setResponseMessage={this.props.setResponseMessage}
        setAddingToBookmark={this.props.setAddingToBookmark}
        setCurrentDeveloperId={this.props.setCurrentDeveloperId}
        setCurrentDeveloperBookmarks={this.props.setCurrentDeveloperBookmarks}
        setBookmarkDelete={this.props.setBookmarkDelete}
        deleteBookmark={this.props.deleteBookmark}
      />
    );
  }
}

let mapStateToProps = (state) => ({
  bookmarks: state.bookmarks,
  search: state.search,
  company_id: state.auth.company_info.id,
});

BookmarkContainer = withRouter(BookmarkContainer);

export default compose(
  connect(mapStateToProps, {
    switchEditMode,
    getSearchResults,
    getCurrentSearch,
    editSearch,
    deleteSearch,
    hire,
    setResponseMessage,
    showModal,
    setBookmarkFormStatus,
    getBookmarksList,
    getBookmarksDevelopers,
    setAddingToBookmark,
    setCurrentDeveloperId,
    setCurrentDeveloperBookmarks,
    setCurrentBookmarkId,
    setBookmarkDelete,
    deleteBookmark,
  }),
  withAuthRedirect
)(BookmarkContainer);
