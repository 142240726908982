import React from "react";

import { publicFilesUrl } from "../../../../constants/global-constants";
import PriceIndicatorContainer from "../../../common/PriceIndicator/PriceIndicatorContainer";
import Verified from "../../../common/Verified/Verified";

import default_company_photo from "../../../../assets/img/rowing.svg";
import default_developer_photo from "../../../../assets/img/man.svg";
import styles from "./DeveloperCard.module.css";

const DeveloperCard = (props) => {
  
  const { developer } = props;

  let logo;

  if (developer?.company_logo) {
    logo = `${publicFilesUrl}public/img/${developer?.company_logo}`;
  } else {
    logo = default_company_photo;
  }

  return !!developer && (
    <div className={styles.developer_info}>
      <div className={styles.left_col}>
        <img
          src={
            developer?.photo
              ? `${publicFilesUrl}public/img/${developer?.photo}`
              : default_developer_photo
          }
          alt={developer?.name}
          className={styles.developer_photo}
        />
        {props.company_type === 1 && developer?.is_pro_account === 1 && (
          <div className={`pro_icon ${styles.pro_icon}`}>Pro</div>
        )}
        {props.company_type === 2 && props.is_pro_account === 1 && (
          <div className={`pro_icon ${styles.pro_icon}`}>Pro</div>
        )}
        {developer?.is_own &&
        (developer?.on_bench === 0 ? (
          <div className={styles.on_project}>ON PROJECT</div>
        ) : (
          <div className={styles.on_bench}>BENCH</div>
        ))}

        <img
          src={logo}
          alt={`${developer?.name} company`}
          className={styles.company_logo}
        />
      </div>
      <div className={styles.right_col}>
        <div className={`d_flex justify_content_between align_items_center ${styles.top_info}`}>
          <div className={styles.name_job_title_wrapper}>
            <div className="d_flex align_items_center">
              <h4 className={styles.developer_name}>{developer?.name}</h4>

              {developer.company_verified && (
                <Verified className={styles.verified}/>
              )}
            </div>
            <div className={styles.job_title}>{developer?.job_title_name}</div>
          </div>

          {!props.is_single_developer && (
            <div>
              <div className={styles.rate}>${developer?.rate}/h</div>
              <PriceIndicatorContainer
                rate={developer?.rate}
                position_level_id={developer?.position_level_id}
              />
            </div>
          )}

          {!!props.is_single_developer && (
            <div>
              <div className={styles.rate}>Salary: ${developer?.rate_month}</div>
            </div>
          )}
        </div>
        <div className={styles.seniority}>
          {developer?.position_level_name},
          <span className={styles.experience}>{developer?.experience} years exp.</span>
          {developer?.position_calculated === 1 && (
            <div className={styles.confirmed}>Confirmed the seniority level</div>
          )}
        </div>
        <div
          className={styles.general_info}
          dangerouslySetInnerHTML={{__html: developer?.general_info}}
        />
      </div>
    </div>
  );
};

export default DeveloperCard;
