import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { required, length, confirmation } from "redux-form-validators";

import { siteNameForTitle } from "../../../constants/global-constants";
import { Input } from "../../common/FormsControls/FormControls";
import Preloader from "../../common/Preloader/Preloader";
import ProfileMenu from "../ProfileMenu";

import styles from "../ProfileEditing/ProfileEditing.module.css";

const PasswordEditingForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <Field
          component={Input}
          type={"password"}
          name={"old_password"}
          label="Old password"
          validate={[required()]}
        />
        <Field
          component={Input}
          type={"password"}
          name={"password"}
          label="New password"
          validate={[required(), length({ min: 6 })]}
        />
        <Field
          component={Input}
          type={"password"}
          name={"repeated_password"}
          label="Repeat password"
          validate={confirmation({ field: "password", fieldLabel: "New password" })}
        />
      </div>

      {props.error && <div className={`${styles.form_errors} text_center validation_message`}>{props.error}</div>}

      <div className={styles.submit_wrapper}>
        <button className={`btn ${styles.submit}`}>Submit</button>
      </div>
    </form>
  );
};

let PasswordEditingReduxForm = reduxForm({ form: "passwordEditing" })(PasswordEditingForm);

const PasswordEditing = (props) => {
  const onSubmit = (formData) => {
    let userData = {
      old_password: formData.old_password,
      password: formData.password,
    };
    props.editPassword(userData);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Password editing | {siteNameForTitle}</title>
      </Helmet>

      {props.auth.is_fetching && <Preloader />}

      <h2 className={styles.title}>Edit password</h2>

      <ProfileMenu />

      <PasswordEditingReduxForm onSubmit={onSubmit} />
    </div>
  );
};

export default PasswordEditing;
