import React from "react";
import { Field, reduxForm } from "redux-form";
import { required, numericality } from "redux-form-validators";
import cn from "classnames";

import {
  Input,
  Textarea,
  SelectInputKeyValue,
} from "../../common/FormsControls/FormControls";
import DatePickerWrapper from "../../common/DatePickerWrapper/DatePickerWrapper";
import { companyTypeOptions } from "../../../constants";
import { URLValidation } from "../../../utils/validation";

import styles from "./LeadForm.module.css";

let SecondPartForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={cn(styles.form, styles.aboutProjectForm)}>

      <div className={styles.fields_wrapper}>

        <div className={styles.aboutProject}>About Project</div>

        <div className={styles.projectTypeWrapper}>
          <div className={styles.fieldTitle}>
            Project type
          </div>
          <Field
            component={SelectInputKeyValue}
            name="project_type_id"
            label=""
            validate={[required()]}
            options={companyTypeOptions}
            input_design={styles.projectTypeSelect}
            withErrorSign
          />
        </div>

        <div className={styles.nameWrapper}>
          <div className={cn(styles.fieldTitle, styles.titleMargin)}>
            Description of your lead
          </div>
          <Field
            component={Input}
            type="text"
            name={"project_name"}
            placeholder="Project name"
            input_design={styles.projectName}
            validate={[required()]}
            withErrorSign
          />
        </div>

        <div className={styles.descriptionWrapper}>
          <Field
            component={Textarea}
            type="text"
            name={"project_description"}
            placeholder="Project description"
            input_design={styles.projectDescription}
            validate={[required()]}
            withErrorSign
          />
        </div>

        <div className={styles.twoFieldsWrapper}>
          <div className={styles.oneFieldWrapper}>
            <div className={styles.fieldTitle}>
              Project budget, $
            </div>
            <Field
              name={"budget"}
              type="number"
              component={Input}
              validate={[required(), numericality({
                int: true,
                '>': 0,
              })]}
              withErrorSign
            />
          </div>

          <div className={styles.oneFieldWrapper}>
            <div className={styles.fieldTitle}>
              Project start
            </div>
            <Field
              name={"start"}
              component={DatePickerWrapper}
              validate={[required()]}
              picker=""
              placeholder=""
              dateFormat="DD/MM/YYYY"
              withCheckMark
            />
          </div>
        </div>

        <div className={styles.descriptionLinkWrapper}>
          <div className={cn(styles.fieldTitle, styles.titleMargin)}>
            Link to the project description file
          </div>
          <Field
            component={Input}
            type="text"
            name={"description_link"}
            placeholder=""
            input_design={styles.projectName}
            validate={[URLValidation]}
            withErrorSign
          />
        </div>

        <div className={cn(styles.descriptionWrapper, styles.additionalComment)}>
          <Field
            component={Textarea}
            type="text"
            name={"additional_comment"}
            placeholder="Additional comment"
            input_design={styles.projectDescription}
            withErrorSign
          />
        </div>

        <div className={`d_flex ${styles.buttons}`}>
          <div className={styles.previous} onClick={props.onPrevious}>
            &lt;&lt; <span className={styles.underline}>Previous</span>
          </div>
          <button className={`btn ${styles.submit}`}>Save</button>
        </div>
      </div>
    </form>
  );
};

SecondPartForm = reduxForm({
  form: "leadSecondPartForm",
  enableReinitialize: true,
})(SecondPartForm);

export default SecondPartForm;
