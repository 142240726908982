import { axiosInstances, axiosJSONInstances } from "./api";

const axiosInstance = axiosInstances.account;
const axiosJSONInstance = axiosJSONInstances.account;

export const newsAPI = {
  getNews() {
    return axiosInstance.get('api/users/news').then((response) => {
      return response.data;
    });
  },

  getAllNews() {
    return axiosInstance.get('api/users/news/all').then((response) => {
      return response.data;
    });
  },

  markAsRead(newsIds) {
    return axiosJSONInstance.put('api/users/news/seen', JSON.stringify({ news_ids: newsIds }))
      .then((response) => {
        return response.data;
      });
  },
};
