const isLocal = window.location.hostname === "localhost";
// const isLocal = false;
const localApiUrl = "http://localhost:3000/";
const localAuthServiceApiUrl = "http://localhost:3100/";
const localAccountServiceApiUrl = "http://localhost:3200/";
const localChatServiceApiUrl = "http://localhost:3300/";
const localSocketServiceApiUrl = "http://localhost:5001/";

const liveApiUrl = "https://ibench.in/";

export const apiUrl = isLocal ? localApiUrl : liveApiUrl;

export const publicFilesUrl = isLocal ? localAccountServiceApiUrl : liveApiUrl;
export const authServiceApiUrl = isLocal ? localAuthServiceApiUrl : liveApiUrl;
export const accountServiceApiUrl = isLocal ? localAccountServiceApiUrl : liveApiUrl;
export const chatServiceApiUrl = isLocal ? localChatServiceApiUrl : liveApiUrl;
export const socketServiceApiUrl = isLocal ? localSocketServiceApiUrl : liveApiUrl;

export const serviceApiUrls = {
  auth: authServiceApiUrl,
  account: accountServiceApiUrl,
  chat: chatServiceApiUrl,
};

export const chatFilesFolder = `${publicFilesUrl}public/files/`;

export const chatFileSizeLimit = 15;
export const chatFilesLimit = 10;

const chatFileTypesExtensionLabels = ["PDF", "Doc", "Docx"];
export const chatFileTypesExtensionList = chatFileTypesExtensionLabels.join(", ");
export const chatFileExtensions = chatFileTypesExtensionLabels.map(extension => extension.toLowerCase());
const chatFileExtensionsList = chatFileExtensions.map(extension => `.${extension}`);
export const chatFileInputAccept = chatFileExtensionsList.join(", ");

export const telegramSupport = "https://t.me/Gendolf77";
export const linkedinSupport = "https://www.linkedin.com/company/ibenchnet/?viewAsMember=true";
export const facebookSupport = "https://www.facebook.com/ibenchglobal";
export const youtubeSupport = "https://www.youtube.com/channel/UCGr-FuzxP9h2rlH3SbqpmEw";
export const whatsappSupport = "https://wa.me/message/WOXACPW7ZQFZH1";
export const slackSupport = "https://bit.ly/3TEN6r4";
export const emailSupport = "hello@ibench.in";

export const siteNameForTitle = "iBench India - Find indian devs&teams here";

export const defaultDescription = "iBench India is a platform connecting IT companies and specialists from India with clients in the USA and Europe. Tailored for the Indian IT industry. Join and start collaborating today!";

export const englishLevelsDescription = [
  {
    level: "0",
    description: "I do not speak any English.",
  },
  {
    level: "A1",
    description: "I can say and understand a few things in English.",
  },
  {
    level: "A2",
    description: "I can communicate simply and understand in familiar situations but only with some difficulty.",
  },
  {
    level: "B1",
    description:
      "I can speak and understand reasonably well and can use basic tenses but have problems with more complex grammar and vocabulary.",
  },
  {
    level: "B2",
    description:
      "I can communicate without much difficulty but still make quite a lot of mistakes and misunderstand sometimes.",
  },
  {
    level: "C1",
    description:
      "I speak and understand very well but sometimes have problems with unfamiliar situations and vocabulary.",
  },
  {
    level: "C2",
    description: "I speak and understand English completely fluently.",
  },
];

export const degreesList = [
  {
    value: "Bachelor",
    name: "Bachelor",
  },
  {
    value: "Master",
    name: "Master",
  },
  {
    value: "PhD",
    name: "PhD",
  },
];

export const countriesWithRequiredTelegram = [
  "Armenia",
  "Azerbaijan",
  "Belarus",
  "Estonia",
  "Kazakhstan",
  "Latvia",
  "Lithuania",
  "Moldova, Republic of",
  "Ukraine",
];

export const vettedOptions = [
  { key: "0", value: "All companies" },
  { key: "1", value: "Only verified" },
];

export const leadSalesTermsOptions = [
  { key: "before_intro", value: "Payment due before starting the client introduction" },
  { key: "after_intro_email", value: "Payment due after introducing the client via email" },
  { key: "after_intro_zoom", value: "Payment due after introducing the client via Zoom" },
  { key: "after_contract", value: "Payment due after signing the contract" },
];

export const companyTypeOptions = [
  { key: "1", value: "Project estimate" },
  { key: "2", value: "Design UX/UI" },
  { key: "3", value: "Website" },
  { key: "4", value: "Landing" },
  { key: "5", value: "Mobile app" },
  { key: "6", value: "SaaS" },
  { key: "7", value: "CRM" },
  { key: "8", value: "ERP" },
  { key: "9", value: "Blockchain" },
  { key: "10", value: "NFT Marketplace" },
  { key: "11", value: "DevOPs service" },
  { key: "12", value: "Cyber Security service" },
  { key: "13", value: "Recruitment service" },
  { key: "14", value: "Support" },
];

export const trialSearchSkillsLinks = [
  [
    {
      label: "React.js developers",
      // link: "/trial-search/reactjs",
      link: "/trial-search/react.js",
    },
    {
      label: "Vue.js developers",
      // link: "/trial-search/vuejs",
      link: "/trial-search/vue.js",
    },
    {
      label: "Angular developers",
      link: "/trial-search/angular",
    }
  ],
  [
    {
      label: "Node.js developers",
      // link: "/trial-search/nodejs",
      link: "/trial-search/node.js",
    },
    {
      label: "Python developers",
      link: "/trial-search/python",
    },
    {
      label: ".NET developers",
      // link: "/trial-search/net",
      link: "/trial-search/.net",
    }
  ],
  [
    {
      label: "Blockchain developers",
      link: "/trial-search/blockchain",
      highlighted: true,
    },
    {
      label: "Solana developers",
      link: "/trial-search/solana",
    },
    {
      label: "ML developers",
      link: "/trial-search/ml",
    }
  ],
  [
    {
      label: "iOS developers",
      link: "/trial-search/ios",
    },
    {
      label: "Android developers",
      link: "/trial-search/android",
    },
    {
      label: "Flutter developers",
      link: "/trial-search/flutter",
    }
  ],
  [
    {
      label: "UX/UI developers",
      // link: "/trial-search/uxui",
      link: "/trial-search/ux&ui",
    },
    {
      label: "QA developers",
      link: "/trial-search/qa",
    },
    {
      label: "DevOpses developers",
      link: "/trial-search/devops",
      highlighted: true,
    }
  ]
];

export const trialSearchSkillsLinksArray = trialSearchSkillsLinks.reduce((skillsArray, skillsGroup) => {
  skillsGroup.map(linkData => {
    skillsArray.push(linkData);
  });
  return skillsArray;
}, []);

export const skillAliases = {
  'reactjs': 'react.js',
  'vuejs': 'vue.js',
  'nodejs': 'node.js',
  'net': '.net',
  'ux-ui': 'ux&ui',
};

export const popularDeveloperTeams = [
  {
    id: 5,
    label: 'Web app development teams',
    link: '/verifiedteams/web-app-development-teams',
  },
  {
    id: 1,
    label: 'Mobile apps development',
    link: '/verifiedteams/mobile-app-development',
  },
  {
    id: 4,
    label: 'Shopify development teams',
    link: '/verifiedteams/shopify-development-teams',
  },
  {
    id: 2,
    label: 'Landing pages development teams',
    link: '/verifiedteams/landing-pages-development-teams',
  },
  {
    id: 3,
    label: 'Company websites',
    link: '/verifiedteams/company-websites',
  }
];

export const teamSizes = [
  '1-9',
  '10-49',
  '50-99',
  '100-199',
  '400-499',
  '500+',
];

const rates = [];
const rateStep = 10;

for (let minRate = 1; minRate <= 150; minRate += rateStep) {
  rates.push(`${minRate}-${minRate + rateStep - 1}`);
}

rates.push('150+');

export const ratesRanges = rates;

let foundedYearsArray = [];
const currentYear = (new Date()).getFullYear();

for (let year = 2000; year <= currentYear; year++) {
  foundedYearsArray.push(year);
}

export const foundedYears = foundedYearsArray;

export const analyticEvents = {
  VIEW_BANNER: 'VIEW_BANNER',
  CLICK_BANNER: 'CLICK_BANNER',
  VIEW_INDUSTRY_COMPANY: 'VIEW_INDUSTRY_COMPANY',
  CLICK_INDUSTRY_COMPANY: 'CLICK_INDUSTRY_COMPANY',
  VIEW_SERVICE_COMPANY: 'VIEW_SERVICE_COMPANY',
  CLICK_SERVICE_COMPANY: 'CLICK_SERVICE_COMPANY',
  CLICK_SERVICE_COMPANY_TELEGRAM: 'CLICK_SERVICE_COMPANY_TELEGRAM',
  CLICK_SERVICE_COMPANY_WHATSAPP: 'CLICK_SERVICE_COMPANY_WHATSAPP',
  CLICK_SERVICE_COMPANY_EMAIL: 'CLICK_SERVICE_COMPANY_EMAIL',
  CHOOSE_SERVICE_IN_CALCULATOR: 'CHOOSE_SERVICE_IN_CALCULATOR',
};

export const analyticEventsLabels = {
  [analyticEvents.VIEW_INDUSTRY_COMPANY]: 'View TOP-20 banner',
  [analyticEvents.CLICK_INDUSTRY_COMPANY]: 'Click TOP-20 banner',
  [analyticEvents.VIEW_SERVICE_COMPANY]: 'View TOP-10 banner',
  [analyticEvents.CLICK_SERVICE_COMPANY]: 'Click TOP-10 banner',
  [analyticEvents.CLICK_SERVICE_COMPANY_TELEGRAM]: 'Click to Telegram',
  [analyticEvents.CLICK_SERVICE_COMPANY_WHATSAPP]: 'Click to Whatsapp',
  [analyticEvents.CLICK_SERVICE_COMPANY_EMAIL]: 'Click to Email',
};

export const socialEvents = {
  [analyticEvents.CLICK_SERVICE_COMPANY_TELEGRAM]: true,
  [analyticEvents.CLICK_SERVICE_COMPANY_WHATSAPP]: true,
  [analyticEvents.CLICK_SERVICE_COMPANY_EMAIL]: true,
};

const youtubeVideoIds = [
  'IelEAHqU-QU',
  'BbWPAsSIKWY',
  'hxgqVwWBjDM',
  'QvPzyLSgJE4',
  'oXIG2rY3s68',
  'nbv7THPs87g',
];

export const youtubeFrontLinks = youtubeVideoIds.map(videoId => {
  return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&controls=0&loop=1&playlist=${videoId}`
});

export const yearsExperience = {
  1: [1, 2],
  2: [3, 4, 5],
  3: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  4: [7, 8, 9, 10, 11, 12, 13, 14, 15],
  5: [7, 8, 9, 10, 11, 12, 13, 14, 15],
};

export const languagesList = [
  'English',
  'German',
  'French',
  'Spanish',
  'Portuguese',
  'Italian',
  'Ukrainian',
  'Russian',
];

export const startInList = [
  'ASAP',
  '1 week',
  '2 weeks',
  '3 weeks',
  '4 weeks',
  '2 months',
];

export const talentStepLinks = [
  "/profile/edit",
  "/profile/edit/skills",
  "/profile/edit/relocation",
  "/profile/edit/social",
  "/profile/edit/alerts",
];

export const unsubscribeAvailablePages = [
  "/membership",
  "/subscription/success",
  "/subscription/canceled",
  "/confirmation-email",
];