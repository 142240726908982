import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { siteNameForTitle } from '../../constants';
import { getPublicTalents } from '../../store/candidates';
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import styles from "./NewTalents.module.css";
import { NavLink } from "react-router-dom";

const NewTalents = (props) => {
  const {
    getPublicTalents,
    newTalents,
  } = props;

  useEffect(() => {
    getPublicTalents();
  }, []);

  const renderTalentsLinks = (talentsList) => {
    return talentsList.map((talent) => {
      const talentDataParts = [talent.country];
      if (talent.position_level_name) {
        talentDataParts.push(talent.position_level_name);
      }

      if (talent.job_title_name) {
        talentDataParts.push(talent.job_title_name);
      }

      if (talent.additional_job_title_name) {
        talentDataParts.push(talent.additional_job_title_name);
      }

      if (talent.monthly_rate) {
        talentDataParts.push(`Salary: $${talent.monthly_rate}`);
      }

      const talentName = `${talent.first_name} ${talent.last_name[0].toUpperCase()}.`;
      const talentPartsString = talentDataParts.join(', ');

      return (
        <div key={talent.id}>
          <NavLink to={`/talent/${talent.id}`}>
            {talentName}
          </NavLink>, {talentPartsString}
        </div>
      );
    });
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>
          {siteNameForTitle} / New talents: frontend react.js, angular, vue.js developers, backend node.js, java,
          python developers, ux/ui designers, business analytics, project managers
        </title>
        <meta
          name="description"
          content="Unlock a world of possibilities for your business with our platform showcasing top talent. Explore a diverse pool of professionals, including experienced Frontend developers (React.js, Angular, Vue.js), reliable Backend experts (Node.js, Java, Python), talented UX/UI designers, business analysts, and seasoned project managers. Find the perfect candidates to strengthen your team today."
        />
        <meta
          name="keywords"
          content="talents, frontend react.js, angular, vue.js developers, backend node.js, java, python developers, ux/ui designers, business analytics, project managers"
          data-react-helmet="true"
        />
      </Helmet>
      <Breadcrumbs className={styles.breadcrumbs} currentPage="New talents"/>
      <h1 className={styles.title}>
        New talents
      </h1>
      <div>
        {newTalents?.length > 0 && (
          <div className={styles.talentsWrapper}>
            {renderTalentsLinks(newTalents)}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  newTalents: state.candidates.newTalents,
});

const mapDispatchToProps = {
  getPublicTalents,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTalents);
