import { notification } from "antd";

import { history } from "utils";
import { outsourceAPI, outsourceMarketsAPI } from "api/outsource";
import { sendMessage } from "./chat";

const IS_FETCHING = "ibench/outsource/IS_FETCHING";
const SET_SEARCH_LIST = "ibench/outsource/SET_SEARCH_LIST";
const SET_CURRENT_SEARCH = "ibench/outsource/SET_CURRENT_SEARCH";
const SET_SEARCH_RESULTS = "ibench/outsource/SET_SEARCH_RESULTS";

let initialState = {
  isFetching: false,
  searchList: [],
  currentSearch: null,
  searchResults: [],
};

const outsource = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, isFetching: action.payload };

    case SET_SEARCH_LIST:
      return { ...state, searchList: action.payload };

    case SET_CURRENT_SEARCH:
      return { ...state, currentSearch: { ...action.payload, budget: action.payload.budget.toString() } };

    case SET_SEARCH_RESULTS:
      return { ...state, searchResults: action.payload };

    default:
      return state;
  }
};

export const isFetching = (payload) => ({ type: IS_FETCHING, payload });

// GET OUTSOURCE SEARCH LIST
const setOutsourceSearchList = (payload) => ({ type: SET_SEARCH_LIST, payload });

export const getOutsourceSearchList = () => {
  return (dispatch) => {
    outsourceAPI.getOutsourceSearchList().then((data) => {
      dispatch(setOutsourceSearchList(data.searches));
    });
  };
};

// GET OUTSOURCE SEARCH
const setCurrentSearch = (payload) => ({ type: SET_CURRENT_SEARCH, payload });

export const getOutsourceSearch = (searchId) => {
  return (dispatch) => {
    outsourceAPI.getSearch(searchId).then((data) => {
      dispatch(setCurrentSearch(data.searches[0]));
    });
  };
};

// GET OUTSOURCE SEARCH RESULTS
const setSearchResults = (payload) => ({ type: SET_SEARCH_RESULTS, payload });

export const getOutsourceSearchResults = (searchId) => {
  return (dispatch) => {
    outsourceAPI.getSearchResults(searchId).then((data) => {
      dispatch(setSearchResults(data.searchResults));
    });
  };
};

// ADD OUTSOURCE SEARCH
export const addSearch = (formData) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    outsourceAPI
      .addSearch(formData)
      .then(({ search_id }) => {
        const promises = [];

        formData.markets.forEach(({ market_id }) => {
          promises.push(outsourceMarketsAPI.addMarket({ search_id, market_id }));
        });

        Promise.all(promises);
        return search_id;
      })
      .then((search_id) => {
        dispatch(isFetching(false));
        notification.success({
          message: "Outsource search has been added",
          placement: "bottomRight",
          duration: 5,
        });
        return history.push(`/outsource-search/${search_id}`);
      });
  };
};

// EDIT OUTSOURCE SEARCH
export const editSearch = (formData) => {
  let { search_id } = formData;

  return (dispatch) => {
    dispatch(isFetching(true));
    outsourceAPI
      .editSearch(formData)
      .then(() => {
        const promises = [];

        formData.addMarkets.forEach(({ market_id }) =>
          promises.push(outsourceMarketsAPI.addMarket({ search_id, market_id }))
        );

        formData.deleteMarkets.forEach(({ market_id }) => {
          promises.push(outsourceMarketsAPI.deleteMarket({ search_id, market_id }));
        });

        Promise.all(promises);
        return search_id;
      })
      .then((search_id) => {
        dispatch(isFetching(false));
        notification.success({
          message: "Outsource search has been edited",
          placement: "bottomRight",
          duration: 5,
        });
        return history.push(`/outsource-search/${search_id}`);
      });
  };
};

// DELETE OUTSOURCE SEARCH
export const deleteSearch = (data) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    outsourceAPI.deleteSearch(data).then(() => {
      history.push(`/outsource`);
      dispatch(isFetching(false));

      notification.success({
        message: "Outsource search has been deleted",
        placement: "bottomRight",
        duration: 5,
      });
    });
  };
};

// START OUTSOURCE CHAT
export const startOutsourceChat = (data, chatId) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    outsourceAPI.startChat(data).then((projectData) => {
      const link = `https://ibench.in/project/${projectData.project_id}`;
      const linkTag = `<a href="${link}">${link}</a>`;
      const message = `I have a project, perhaps it will be of interest to you. Here's a link: ${linkTag}`;

      dispatch(sendMessage(message, chatId));
      dispatch(isFetching(false));
      history.push(`/chat/${chatId}`);
    });
  };
};

export default outsource;
