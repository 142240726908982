import React, { useEffect } from "react";
import cn from "classnames";

import styles from "./Modal.module.css";

const Modal = (props) => {
  const closeModal = () => {
    props.showModal(false);
  };

  const escHandler = (event) => {
    if (event.keyCode === 27) closeModal();
  };

  useEffect(() => {
    props.showModal && document.addEventListener("keydown", escHandler);
    return () => {
      props.showModal && document.removeEventListener("keydown", escHandler);
    };
  }, []);

  return (
    <div className={styles.modal_wrapper}>
      <div className={cn(styles.modal_window, props.noBg && styles.no_bg)}>
        {props.showModal && <div className={styles.close} onClick={closeModal}></div>}
        {props.title && <h4 className={styles.title}>{props.title}</h4>}
        {props.message && <div className={styles.message}>{props.message}</div>}
        {props.renderComponent}
        <div className={`${styles.buttons_wrapper} d_flex justify_content_center`}>
          {props.buttons &&
            props.buttons.map((btn, index) => (
              <button key={index} className={`btn btn_green`} onClick={btn.btn_handler}>
                {btn.btn_text}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Modal;
