import { axiosInstances } from "./api";

const axiosInstance = axiosInstances.account;

export const activityStatsAPI = {
  getStats() {
    return axiosInstance.get(`api/users/activity/stats`).then((response) => {
      return response;
    });
  },
};
