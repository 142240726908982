import React, { useState, useEffect } from "react";
import { change } from "redux-form";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { publicFilesUrl } from "../../../constants/global-constants";
import styles from "./UploadFile.module.css";

const UploadFile = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const dispatch = useDispatch();
  const maxSize = props.maxSize ? props.maxSize : 15; // MB

  useEffect(() => {
    if (props.meta.initial) {
      if (typeof props.meta.initial === "string") {
        const path = props.fullPath ? props.meta.initial : `public/img/${props.meta.initial}`;
        setSelectedFiles([`${publicFilesUrl}${path}`]); // set one file in array
      } else {
        setSelectedFiles(props.meta.initial.map((imageURL) => `${publicFilesUrl}public/img/${imageURL}`)); // set array of files
      }
    }
  }, [props.meta.initial]);

  useEffect(() => {
    dispatch(change(props.meta.form, props.input.name, selectedFiles));
  }, [dispatch, props.meta.form, props.input.name, selectedFiles, props.meta.initial]);

  const onChange = (e) => {
    const newSelectedFiles = Array.from(e.target.files).filter((file) => {
      const ext = file.name.split(".").pop();
      let fileSize = file.size / 1024 / 1024;
      return props.accept.includes(`.${ext}`) && fileSize < maxSize;
    });

    props.input.onChange(e.target.files);
    if (e.target.files.length) {
      if (props.multiple) {
        newSelectedFiles.unshift(...selectedFiles);
      }

      setSelectedFiles(newSelectedFiles);
    }
  };

  const removeImage = (index) => {
    setSelectedFiles(selectedFiles.filter((file, i) => i !== index));
    if (typeof selectedFiles[index] === "string") {
      setDeletedFiles([...deletedFiles, selectedFiles[index].split(`${publicFilesUrl}public/img/`).pop()]);
    }

    if (!props.multiple) {
      document.querySelector(`#${props.input.name}`).value = null;
    }
  };

  useEffect(() => {
    dispatch(change(props.meta.form, `${props.input.name}Delete`, deletedFiles));
  }, [dispatch, props.meta.form, props.input.name, deletedFiles]);

  const renderFileTypeImage = (file) => {
    let name = typeof file === "string" ? file : file.name;
    const ext = name.split(".").pop();
    return <span className={styles[ext]}/>;
  };

  const getFileName = (file) => file.split('/').pop();

  return (
    <div className={cn(styles.wrapper, props.className)}>
      <div className={styles.title}>{props.title}</div>

      {selectedFiles?.map((file, index) => (
        <div key={`uploadImage-${index}`} className={styles.file}>
          {renderFileTypeImage(file)}
          <span className={styles.fileName}>
            {typeof file === "string" ? (
              <a key={file} href={file} target="_blank" rel="nofollow" className={styles.file}>
                {props.showFileName ? getFileName(file) : `File ${index + 1}`}
              </a>
            ) : (
              file.name
            )}
          </span>
          <span className={styles.removeFile} onClick={() => removeImage(index)}/>
        </div>
      ))}

      {(!selectedFiles.length || props.multiple || props.dontHideButton) && (
        <div className={styles.inputWrapper}>
          <label htmlFor={props.input.name} className={styles.label}>
            {props.label}
          </label>
          <input
            id={props.input.name}
            type="file"
            accept={props.accept}
            className={styles.input}
            onChange={(e) => onChange(e)}
            multiple={props.multiple}
          />
        </div>
      )}

      {!props.hideHelp && (
        <div className={styles.help}>
          Maximum size: {maxSize} MB. File types: <span className={styles.accept}>{props.accept.join(", ")}</span>
        </div>
      )}
    </div>
  );
};

export default UploadFile;
