import React, { useEffect, useState, useMemo } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { notification } from "antd";
import { connect } from "react-redux";
import cn from 'classnames';

import config from "../../config";
import { talentStepLinks, unsubscribeAvailablePages } from '../../constants';
import { clickToBanner } from '../../store/banners';
import { getRandomArrayItem } from '../../utils/utils';
import About from "../About/About";
import ThankYou from "../ThankYou/ThankYou";
import Landing from "../Landing/Landing";
import LandingCalendly from "../Landing/LandingCalendly";
import LandingTwo from "../LandingTwo/LandingTwo";
import LandingTwoCalendly from "../LandingTwo/LandingTwoCalendly";
import ActivityStatsContainer from "../ActivityStats/ActivityStatsContainer";
import NewsContainer from "../News/NewsContainer";
import ChatContainer from "../Chat/ChatContainer";
import CompanyBenchContainer from "../Company/CompanyBench/CompanyBenchContainer";
import CompanyContainer from "../Company/CompanyContainer";
import DeveloperAddingContainer from "../Developer/DeveloperAdding/DeveloperAddingContainer";
import DeveloperEditingContainer from "../Developer/DeveloperEditing/DeveloperEditingContainer";
import DeveloperContainer from "../Developer/DeveloperContainer";
import Talent from "../Talent/Talent";
import CasesContainer from "../Cases/CasesContainer";
import CompanyDetailsContainer from "../CompanyDetails/CompanyDetailsContainer";
import CaseAddingContainer from "../Cases/CaseAdding/CaseAddingContainer";
import Footer from "../Footer/Footer";
import HeaderContainer from "../Header/HeaderContainer";
import LoginContainer from "../Login/LoginContainer";
import PageNotFound from "../PageNotFound/PageNotFound";
import PasswordRecoveryContainer from "../PasswordRecovery/PasswordRecoveryContainer";
import SubscriptionContainer from "../Payment/Subscription/SubscriptionContainer";
import PaymentSuccess from "../Payment/PaymentSuccess/PaymentSuccess";
import PaymentCanceled from "../Payment/PaymentCanceled/PaymentCanceled";
import SubscriptionSuccess from "../Payment/SubscriptionSuccess/SubscriptionSuccess";
import SubscriptionCanceled from "../Payment/SubscriptionCanceled/SubscriptionCanceled";
import ConfirmationEmail from "../ConfirmationEmail/ConfirmationEmail";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "../CookiePolicy/CookiePolicy";
import Terms from "../Terms/Terms";
import VerifiedCompanies from "../VerifiedCompanies/VerifiedCompanies";
import PasswordEditingContainer from "../Profile/PasswordEditing/PasswordEditingContainer";
import SettingsContainer from "../Profile/Settings/SettingsContainer";
import AnalyticsContainer from "../Profile/Analyticss/AnalyticsContainer";
import AlertsContainer from "../Profile/Alerts/AlertsContainer";
import RelocationContainer from "../Profile/Relocation/RelocationContainer";
import ProfileContainer from "../Profile/ProfileContainer";
import ProfileEditingContainer from "../Profile/ProfileEditing/ProfileEditingContainer";
import Skills from "../Profile/Skills/Skills";
import ProfileSocialEditingContainer from "../Profile/ProfileSocialEditing/ProfileSocialEditingContainer";
import ProfileTechnologiesEditingContainer from "../Profile/ProfileTechnologiesEditing/ProfileTechnologiesEditingContainer";
import ProfileClientsEditingContainer from "../Profile/ProfileClientsEditing/ProfileClientsEditingContainer";
import ProfileTopCasesEditingContainer from "../Profile/ProfileTopCasesEditing/ProfileTopCasesEditingContainer";
import RegistrationQuickContainer from "../registration/RegistrationQuick/RegistrationQuickContainer";
import RegistrationStep1Container from "../registration/RegistrationStep1/RegistrationStep1Container";
import RegistrationStep2Container from "../registration/RegistrationStep2/RegistrationStep2Container";
import RegistrationStep3Container from "../registration/RegistrationStep3/RegistrationStep3Container";
import TeamSearchContainer from "../registration/TeamSearch/TeamSearchContainer";
import SearchAddingContainer from "../Startup/SearchAdding/SearchAddingContainer";
import FreelancerSearchAddingContainer from "../Startup/FreelancerSearchAdding/FreelancerSearchAddingContainer";
import SearchResultsContainer from "../Startup/SearchResults/SearchResultsContainer";
import FreelancerSearchResultsContainer from "../Startup/FreelancerSearchResults/FreelancerSearchResultsContainer";
import StartupSlotsContainer from "../Startup/StartupSlots/StartupSlotsContainer";
import CandidateSlots from "../Candidates/CandidateSlots/CandidateSlots";
import CandidateSearchAdding from "../Candidates/CandidateSearchAdding/CandidateSearchAdding";
import CandidateSearchResults from "../Candidates/CandidateSearchResults/CandidateSearchResults";
import CandidateSearchEditing from "../Candidates/CandidateSearchEditing/CandidateSearchEditing";
import FreelancerSlotsContainer from "../Startup/FreelancerSlots/FreelancerSlotsContainer";
import SellLeadsContainer from "../SellLeads/SellLeads/SellLeadsContainer";
import LeadDetailsContainer from "../LeadDetails/LeadDetailsContainer";
import LeadAddingContainer from "../SellLeads/LeadAdding/LeadAddingContainer";
import LeadEditingContainer from "../SellLeads/LeadEditing/LeadEditingContainer";
import TrialSearchContainer from "../common/TrialSearch/TrialSearchContainer";
import BookmarksListContainer from "../Startup/BookmarksList/BookmarksListContainer";
import BookmarkContainer from "../Startup/Bookmark/BookmarkContainer";
import CaseEditingContainer from "../Cases/CaseEditing/CaseEditingContainer";
import OutsourceContainer from "../Outsource/OutsourceContainer";
import OutsourceAddingContainer from "../Outsource/OutsourceAdding/OutsourceAddingContainer";
import OutsourceSearchContainer from "../Outsource/OutsourceSearch/OutsourceSearchContainer";
import OutsourceEditingContainer from "../Outsource/OutsourceEditing/OutsourceEditingContainer";
import ProjectsContainer from "../Projects/ProjectsContainer";
import ProjectPageContainer from "../Projects/ProjectPage/ProjectPageContainer";
import Confirmation from "../Confirmation/Confirmation";
import LeadsMarketplaceContainer from "../LeadsMarketplace/LeadsMarketplaceContainer";
import MarketplaceContainer from "../Marketplace/MarketplaceContainer";
import MarketplaceTalents from "../Marketplace/MarketplaceTalents";
import MarketplaceProjectsContainer from "../Marketplace/MarketplaceProjectsContainer";
import MarketplaceFreelancerContainer from "../Marketplace/MarketplaceFreelancerContainer";
import MarketplaceLeadsContainer from "../Marketplace/MarketplaceLeadsContainer";
import FrontPageContainer from "../FrontPage/FrontPageContainer";
import TeamsPageContainer from "../TeamsPage/TeamsPageContainer";
import HireDevelopersContainer from "../HireDevelopers/HireDevelopersContainer";
import NewTalents from "../NewTalents/NewTalents";
import Plug from "../Plug/Plug";
import ScrollToTop from "../../hoc/ScrollToTop";

import styles from "./App.module.css";

let telegramNotification;
const openNotification = () => {
  telegramNotification = setTimeout(() => {
    notification.info({
      message: "Telegram bot",
      description: (
        <>
          {"Click to receive notification of new messages immediately "}
          <a href="https://t.me/iBenchBot" target="_blank" rel="nofollow">
            @iBenchBot
          </a>
        </>
      ),
      placement: "bottomRight",
      duration: 10,
    });
    clearTimeout(telegramNotification);
  });
};

const profileErrorNotification = () => {
  notification.error({
    message: 'The site will be accessible only after filling out your profile',
    placement: "topRight",
    duration: 2,
  });
};

const App = (props) => {
  const { auth, banners, clickToBanner } = props;
  let { is_auth, company_info } = auth;
  const isAuth = is_auth && !!company_info;

  let history = useHistory();
  const [fullInit, setFullInit] = useState(false);

  const bannerData = useMemo(() => {
    if (banners) {
      return getRandomArrayItem(banners);
    }
  }, [banners]);

  // const isUnsubscribedCompany = company_info && !company_info.is_single_developer && !company_info.stripe_subscribed;
  const isUnsubscribedCompany = false;

  useEffect(() => {
    const hash = history.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      // Check if there is a hash and if an element with that id exists
      document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "smooth" });
    }
  }, [history.location.hash]); // Fires when component mounts and every time hash changes

  useEffect(() => {
    if (auth?.company_info?.telegram && !auth?.company_info?.telegram_chat_id && !telegramNotification) {
      openNotification();
    }
  }, [auth]);

  useEffect(() => {
    if (company_info) {
      setTimeout(() => {
        setFullInit(true);
      });
    }
  }, [company_info]);

  if (
    fullInit &&
    isAuth &&
    company_info?.is_single_developer &&
    company_info.profile_fill_step < talentStepLinks.length &&
    history.location.pathname !== "/thankyou"
  ) {
    const profilePageStep = talentStepLinks.indexOf(history.location.pathname) + 1;

    if (!profilePageStep || profilePageStep - 1 > company_info.profile_fill_step) {
      const profilePageLink = talentStepLinks[+company_info.profile_fill_step];
      history.push(profilePageLink);
      profileErrorNotification();
    }
  }

  if (isUnsubscribedCompany && !unsubscribeAvailablePages.includes(history.location.pathname)) {
    history.push(unsubscribeAvailablePages[0]);
  }

  if (history.location.pathname === "/mobile-and-web-development/calendly") {
    return <LandingCalendly/>;
  }

  if (history.location.pathname === "/website-and-mobile-app-development/calendly") {
    return <LandingTwoCalendly/>;
  }

  let mobileDisabled = false;

  if (auth.is_auth || history.location.pathname.includes("/lead/")) {
    mobileDisabled = true;
  }

  const renderBanner = () => {
    if (!bannerData) {
      return null;
    }

    const imageUrl = `${config.bannerFolder}${bannerData.image}`;

    if (bannerData.link) {
      return (
        <div className={styles.bannerWrapper}>
          <a
            href={bannerData.link}
            onClick={() => clickToBanner(bannerData.id)}
            target="_blank"
            rel="nofollow"
          >
            <img className={styles.banner} src={imageUrl} alt="Banner"/>
          </a>
        </div>
      );
    }

    return (
      <div className={styles.bannerWrapper}>
        <img className={styles.banner} src={imageUrl} alt="Banner"/>
      </div>
    );
  };

  return (
    <div className={cn(styles.app_wrapper, styles[props.theme], {[styles.mobile_disabled]: mobileDisabled})}>
      {mobileDisabled && <div className={styles.plug_wrapper}><Plug /></div>}
      {renderBanner()}
      <ScrollToTop />
      <div className={styles.wrapper}>
        <HeaderContainer/>
        <div className={`container ${styles.app_wrapper_content}`}>
          <Switch>
            {/* GENERAL PAGES */}
            <Route
              exact
              path="/"
              render={() => {
                if (!isAuth) {
                  return <FrontPageContainer/>;
                  // return <HireDevelopersContainer/>;
                }

                if (company_info?.is_single_developer) {
                  return  <Redirect to="/marketplace/talents"/>;
                } else if (company_info.type === 1) {
                  return <Redirect to="/stats"/>;
                }

                return  <Redirect to="/marketplace"/>;
              }}
            />
            {/* Public pages */}
            <Route path="/login" render={() => (isAuth ? <Redirect to="/stats"/> : <LoginContainer/>)}/>
            <Route path="/trial-search/:skill?" render={() => <TrialSearchContainer showPromo={true}/>}/>
            <Route path="/terms-of-use" render={() => <Terms showBreadcrumbs />}/>
            <Route path="/registration" component={RegistrationStep1Container}/>
            <Route path="/development-teams" component={FrontPageContainer}/>
            <Route path="/newtalents" component={NewTalents}/>
            <Route path="/teams" component={TeamsPageContainer}/>
            <Route path="/thankyou" component={ThankYou}/>
            <Route path="/recovery" component={PasswordRecoveryContainer}/>
            <Route exact path="/registration-quick/:developer_id" component={RegistrationQuickContainer}/>
            <Route path="/confirmation" component={Confirmation}/>
            <Route path="/verifiedteams/:service_id?" component={VerifiedCompanies}/>
            <Route path="/team-search" component={TeamSearchContainer}/>
            <Route exact path="/company/:company_id" component={CompanyContainer}/>
            <Route path="/lead/:lead_id" component={LeadDetailsContainer} />
            <Route exact path="/developer/:developer_id" component={DeveloperContainer}/>
            <Route exact path="/talent/:talent_id" component={Talent}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/cookie-policy" component={CookiePolicy}/>
            <Route path="/about" component={About}/>
            <Route path="/website-and-mobile-app-development" component={Landing}/>
            <Route path="/mobile-and-web-development" component={LandingTwo}/>

            {/* Pages available to authorized users */}
            <Route exact path="/profile" component={ProfileContainer}/>
            <Route exact path="/profile/edit" render={() => <ProfileEditingContainer/>}/>
            <Route path="/profile/edit/skills" render={() => <Skills/>}/>
            <Route path="/profile/edit/social" render={() => <ProfileSocialEditingContainer/>}/>
            <Route path="/profile/edit/technologies" render={() => <ProfileTechnologiesEditingContainer/>}/>
            <Route path="/profile/edit/clients" render={() => <ProfileClientsEditingContainer/>}/>
            <Route path="/profile/edit/top-cases" render={() => <ProfileTopCasesEditingContainer/>}/>
            <Route path="/registration-company-info" render={() => <RegistrationStep2Container/>}/>
            <Route path="/registration-extra-info" component={RegistrationStep3Container}/>
            <Route path="/password-edit" component={PasswordEditingContainer}/>
            <Route path="/settings" component={SettingsContainer}/>
            <Route path="/analytics" component={AnalyticsContainer}/>
            <Route path="/profile/edit/alerts" component={AlertsContainer}/>
            <Route path="/profile/edit/relocation" component={RelocationContainer}/>
            <Route path="/chat/:chat_id?" render={() => <ChatContainer/>}/>
            <Route exact path="/leads-marketplace" render={() => <LeadsMarketplaceContainer/>}/>
            <Route exact path="/marketplace" render={() => <MarketplaceContainer/>}/>
            <Route exact path="/marketplace/employees" render={() => <MarketplaceFreelancerContainer/>}/>
            <Route exact path="/marketplace/projects" render={() => <MarketplaceProjectsContainer/>}/>
            <Route exact path="/marketplace/leads" render={() => <MarketplaceLeadsContainer/>}/>
            <Route exact path="/marketplace/talents" render={() => <MarketplaceTalents/>}/>

            <Route path="/stats" render={() => <ActivityStatsContainer/>}/>
            <Route path="/news" component={NewsContainer}/>
            {/* IT COMPANY PAGES */}
            <Route path="/company-bench" render={() => <CompanyBenchContainer/>}/>
            <Route path="/add-developer" render={() => <DeveloperAddingContainer/>}/>
            <Route exact path="/developer/:developer_id/edit" component={DeveloperEditingContainer}/>
            <Route path="/cases" render={() => <CasesContainer/>}/> {/* Own cases for IT Company  */}
            <Route exact path="/outsource-company/:companyId" component={CompanyDetailsContainer}/>
            <Route path="/add-case" render={() => <CaseAddingContainer/>}/>
            <Route exact path="/case/:case_id/edit" component={CaseEditingContainer}/>
            <Route path="/projects" render={() => <ProjectsContainer/>}/>
            <Route exact path="/project/:projectId" component={ProjectPageContainer}/>
            {/*<Route exact path="/membership" render={() => <SubscriptionContainer />} />*/}
            <Route exact path="/payment/success" render={() => <PaymentSuccess/>}/>
            <Route exact path="/payment/canceled" render={() => <PaymentCanceled/>}/>
            <Route exact path="/subscription/success" render={() => <SubscriptionSuccess/>}/>
            <Route exact path="/subscription/canceled" render={() => <SubscriptionCanceled/>}/>
            <Route exact path="/confirmation-email" render={() => <ConfirmationEmail/>}/>
            {/* STARTUP PAGES */}
            <Route path="/search-slots" component={StartupSlotsContainer}/>
            <Route path="/search-candidates" component={CandidateSlots}/>
            <Route path="/add-candidate-search" component={CandidateSearchAdding}/>
            <Route path="/candidate-search/:search_id" component={CandidateSearchResults}/>
            <Route path="/edit-candidate-search/:search_id" component={CandidateSearchEditing}/>
            <Route path="/search-employee-slots" component={FreelancerSlotsContainer}/>
            <Route path="/add-search" component={SearchAddingContainer}/>
            <Route path="/add-employee-search" component={FreelancerSearchAddingContainer}/>
            <Route path="/search/:search_id" component={SearchResultsContainer}/>
            <Route path="/employee-search/:search_id" component={FreelancerSearchResultsContainer}/>
            <Route path="/bookmarks" component={BookmarksListContainer}/>
            <Route path="/bookmark/:bookmark_id" component={BookmarkContainer}/>
            <Route path="/outsource" component={OutsourceContainer}/>
            <Route path="/sell-leads" component={SellLeadsContainer}/>
            <Route path="/add-lead" component={LeadAddingContainer}/>
            <Route path="/edit-lead/:lead_id" component={LeadEditingContainer}/>
            <Route path="/add-outsource-search" component={OutsourceAddingContainer}/>
            <Route exact path="/outsource-search/:searchId" component={OutsourceSearchContainer}/>
            <Route exact path="/outsource-search/:searchId/edit" component={OutsourceEditingContainer}/>
            <Route path="*" render={() => <PageNotFound/>}/>
          </Switch>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

const mapStateToProps = (state) => ({
  banners: state.banners.banners,
});

const mapDispatchToProps = {
  clickToBanner,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
