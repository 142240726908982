import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import cn from "classnames";

import { siteNameForTitle, defaultDescription } from "../../constants";
import { normalizeURL, buildImageLink } from "../../utils/utils";

import default_company_photo from "../../assets/img/rowing.svg";
import styles from "./CompanyDetails.module.css";

const ClientDetails = (props) => {
  let {
    site,
    company_name,
    first_name,
    last_name,
    representative_photo,
    country,
    description,
    popular_service_name,
  } = props?.clientData;

  console.log('===props?.clientData', props?.clientData);

  const titlePartString = useMemo(() => {
    const titleParts = [popular_service_name || 'Web app development', company_name];

    if (country) {
      titleParts.push(`from ${country}`);
    }

    return titleParts.join(' ');

  }, [props.companyData]);

  const h1String = useMemo(() => {
    let title = titlePartString;

    if (site) {
      const normalizedLink = normalizeURL(site);
      title += ` - <a href="${normalizedLink}" target="_blank" rel="nofollow">${normalizedLink}</a>`;
    }

    return title;

  }, [props.companyData]);

  const titleString = useMemo(() => {
    let title = titlePartString;

    if (site) {
      const normalizedLink = normalizeURL(site);
      title += ` - ${normalizedLink}`;
    }

    return title;

  }, [props.companyData]);

  return (
    <>
      <Helmet>
        <title>{siteNameForTitle} / {titleString}</title>
        <meta
          name="description"
          content={defaultDescription}
        />
        <meta
          name="keywords"
          content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
          data-react-helmet="true"
        />
      </Helmet>
      {props.withTitle && <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: h1String }} />}
      <div className={styles.contentWrapper}>
        <div className={styles.topSection}>
          <div className={styles.leftColumn}>
            <img
              className={styles.companyLogo}
              src={representative_photo ? buildImageLink(representative_photo) : default_company_photo}
              alt={company_name}
            />
          </div>
          <div className={styles.rightColumn}>
            <div className={cn(styles.companyInfoRow, styles.flex, styles.mainInfo)}>
              <div className={styles.leftColumn}>
                <div className={cn(styles.companyInfoColumn, styles.companyNameColumn)}>
                  <div className={styles.companyInfoItem}>
                    <span className={styles.bold}>Client: </span>
                    {first_name} {last_name}
                  </div>
                </div>
                <div className={styles.companyInfoColumn}>
                  <div className={styles.companyInfoItem}>
                    <span className={styles.bold}>Location: </span>
                    {country}
                  </div>
                </div>

              </div>
            </div>

            {!!description && (
              <div
                className={cn(styles.companyInfoRow, styles.description, "ql-editor")}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientDetails;
