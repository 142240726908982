import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

import { siteNameForTitle } from "../../constants";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import Preloader from "../common/Preloader/Preloader";
import YoutubeButton from "../common/YoutubeButton/YoutubeButton";

import styles from "./Outsource.module.css";

const Outsource = (props) => {

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Outsource | {siteNameForTitle}</title>
      </Helmet>

      {props.isFetching && <Preloader/>}

      <DashboardMenuContainer/>

      <h1 className={styles.title}>Find IT companies</h1>
      <div className={styles.subtitle}>Find a company for a project</div>

      <div className={styles.searchList}>
        {props.searchList?.map((search) => (
          <NavLink
            key={`outsource-${search.search_id}`}
            to={`/outsource-search/${search.search_id}`}
            className={styles.searchSlot}
          >
            {search.name}
          </NavLink>
        ))}
      </div>

      <div className={styles.freeSlotWrapper}>
        <NavLink to="/add-outsource-search" className={styles.freeSlot}>
          Add Project
        </NavLink>
        <span className={styles.freeSlotTip}>
          Do you need outsource company?
          <br/>
          Click here
        </span>
      </div>
      <YoutubeButton src="https://www.youtube.com/embed/QBRvYvAS9kk"/>
    </div>
  );
};

export default Outsource;
