import React from "react";
import cn from "classnames";

import config from "../../../config";

import styles from "./SubscriptionButton.module.css";

const SubscriptionButton = ({ disabled = false }) => {
  const userToken = JSON.parse(localStorage.getItem("user")).token;

  const handleSubmitSub = () => {
    const stripe = window.Stripe(config.stripe.key);
    stripe.redirectToCheckout({
      lineItems: [{ price: config.stripe.subscription, quantity: 1 }],
      mode: "subscription",
      successUrl: `${config.siteURL}subscription/success?token=${userToken}`,
      cancelUrl: `${config.siteURL}subscription/canceled`,
    }).then(function (result) {
      if (result.error) {
        const displayError = document.getElementById("error-message");
        displayError.textContent = result.error.message;
      }
    });
  };

  return (
    <>
      <div className={styles.priceContainer}>
        <div className={styles.priceWrapper}>
          iBench Subscription
          <div className={styles.price}>10 EURO</div>
          <div className={styles.subscriptionPerPeriod}>/ month</div>
        </div>

        <div className={styles.paymentService}/>
      </div>
      <div className={styles.buttonBlock}>
        <div className={cn(styles.buttonWrapper, 'button-wrapper')}>
          <button
            role="link"
            type="button"
            className={`btn btn_green ${styles.payBtn}`}
            onClick={handleSubmitSub}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={styles.stripeError}>
        <div id="error-message" />
      </div>
    </>
  );
};

export default SubscriptionButton;
