import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";

import { Input } from "../../common/FormsControls/FormControls";
import styles from "./BookmarksListForm.module.css";

const Form = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <Field
        component={Input}
        type={"text"}
        label="Bookmark title"
        name={"bookmark_name"}
        help_text="for example: Front-end Senior for Google"
        validate={[required()]}
      />

      <div className={`d_flex ${styles.buttons}`}>
        <button className={`btn ${styles.submit}`}>
          {props.bookmarkFormStatus === "adding" ? "Add" : "Edit"}
        </button>
      </div>
    </form>
  );
};

let ReduxForm = reduxForm({ form: "bookmarksList" })(Form);

ReduxForm = connect((state) => {
  if (state.bookmarks.bookmarkFormStatus !== "adding") {
    let bookmarkIndex = state.bookmarks.bookmarksList.findIndex(
      (bookmark) => bookmark.bookmark_id === state.bookmarks.bookmarkFormStatus
    );
    return { initialValues: state.bookmarks.bookmarksList[bookmarkIndex] };
  }
}, {})(ReduxForm);

const BookmarksListForm = (props) => {
  const onSubmit = (data) => {
    if (props.bookmarkFormStatus === "adding") {
      props.addBookmark(data);
    } else {
      props.editBookmark(data.bookmark_id, {
        bookmark_name: data.bookmark_name,
      });
    }

    props.setAddingToBookmark(null);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.form_title}>Add Bookmark</div>
      <ReduxForm
        onSubmit={onSubmit}
        bookmarkFormStatus={props.bookmarkFormStatus}
      />
    </div>
  );
};

export default BookmarksListForm;
