import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

import { siteNameForTitle } from "../../../constants";
import Modal from "../../common/Modal/Modal";
import Preloader from "../../common/Preloader/Preloader";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import BookmarksListFormContainer from "../BookmarksListForm/BookmarksListFormContainer";
import styles from "./BookmarksList.module.css";

const BookmarksList = (props) => {
  const showBookmarksForm = () => {
    props.setBookmarkFormStatus("adding");
    props.showModal(true);
  };

  const hideBookmarksForm = () => {
    props.setResponseMessage(null);
    props.showModal(false);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Teams | {siteNameForTitle}</title>
      </Helmet>

      {props.isFetching && <Preloader />}

      {props.isModal && props.bookmarkFormStatus === "adding" && (
        <Modal showModal={props.showModal} renderComponent={<BookmarksListFormContainer />} />
      )}
      {props.isModal && props.responseMessage && (
        <Modal
          title={"Amazing!"}
          message={props.responseMessage}
          buttons={[{ btn_text: "Ok", btn_handler: hideBookmarksForm }]}
        />
      )}

      <DashboardMenuContainer />
      <h1 className={`text_center ${styles.page_title}`}>Teams</h1>
      <div className={`d_flex flex_wrap justify_content_center align_items_center`}>
        {props.bookmarksList &&
          props.bookmarksList.map((bookmark) => (
            <NavLink
              key={bookmark.bookmark_id}
              to={`bookmark/${bookmark.bookmark_id}`}
              className={`d_flex flex_wrap justify_content_center align_items_center ${styles.search_slot} ${styles.slot}`}
            >
              {bookmark.bookmark_name}
              {bookmark.dev_count > 0 && <span className={styles.total}>{bookmark.dev_count}</span>}
            </NavLink>
          ))}
        <div
          className={`d_flex flex_wrap justify_content_center align_items_center ${styles.slot} ${styles.add_link} }`}
          onClick={showBookmarksForm}
        >
          + Add new team
        </div>
      </div>
    </div>
  );
};

export default BookmarksList;
