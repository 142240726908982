import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { formValueSelector, reduxForm } from "redux-form";
import cn from "classnames";

import styles from "./TeamSearch.module.css";

const ResponseMessage = (props) => {
  let markets = "";
  props.markets &&
    props.markets.forEach((market) => {
      markets += `${market.market_name}, `;
    });
  markets = markets.slice(0, -2); // remove last comma

  let skills = "";
  props.skills &&
    props.skills.forEach((skill) => {
      skills += `${skill.skill_name}, `;
    });
  skills = skills.slice(0, -2); // remove last comma

  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={cn(styles.fields_wrapper, "mobile_hidden")}>
        <div className={styles.advantages}>
          <div className={styles.advantages_title}>Project details</div>
          <div className={styles.advantages_markets}>
            Product type: <span className="purple">{markets}</span>
          </div>
          <div className={styles.advantages_skills}>
            Skills: <span className="purple">{skills}</span>
          </div>
          <div className={styles.advantages_monthBudget}>
            Monthly budget: <span className="purple">{props.monthBudget}</span>
          </div>
          <div className={styles.advantages_duration}>
            Project duration: <span className="purple">{props.rentDuration}</span>
          </div>
        </div>
      </div>

      <div className={styles.response_message}>{props.message}</div>

      <div className={styles.suggested}>
        Maybe that will be interesting for you:
        <ul>
          <li className={styles.suggested_link}>
            <NavLink to="/about">About</NavLink>
          </li>
          {/*<li className={styles.suggested_link}>*/}
            {/*<NavLink to="/faq">F.A.Q.</NavLink>*/}
          {/*</li>*/}
        </ul>
      </div>
    </form>
  );
};

let ReduxForm = reduxForm({
  form: "team-search",
  destroyOnUnmount: false,
})(ResponseMessage);

const formSelector = formValueSelector("team-search");
ReduxForm = connect((state) => {
  const markets = formSelector(state, "markets");
  const skills = formSelector(state, "skills");
  const monthBudget = formSelector(state, "monthBudget");
  const rentDuration = formSelector(state, "rentDuration");
  return { markets, skills, monthBudget, rentDuration };
})(ReduxForm);

export default ReduxForm;
