import React from "react";

import Terms from '../../Terms/Terms'
import styles from "./TermsModal.module.css";

const TermsModal = () => {
  return (
    <div className={styles.termsModal}>
      <div className={styles.modalHeader}>
        iBench Terms
      </div>
      <div className={styles.modalBody}>
        <Terms />
      </div>
    </div>
  );
};

export default TermsModal;
