import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import { getAllNews } from "../../store/news";
import News from "./News";

const NewsContainer = (props) => {
  const { news, getAllNews } = props;
  useEffect(() => {
    getAllNews();
  }, []);

  return <News news={news} />;
};

const mapStateToProps = (state) => ({
  news: state.news.fullNewsList,
});

const mapDispatchToProps = { getAllNews };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthRedirect,
)(NewsContainer);
