import { axiosInstances } from "./api";
import qs from "qs";

const axiosInstance = axiosInstances.account;

export const bannersAPI = {
  getBanners() {
    return axiosInstance.get('api/users/banners').then((response) => {
      return response.data;
    });
  },
  clickToBanner(id) {
    return axiosInstance.post('api/users/banners/click', qs.stringify({ id })).then((response) => {
      return response.data;
    });
  },
};
