import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import LeadsMarketplace from "./LeadsMarketplace";
import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import { getLeads } from "../../store/lead";

const LeadsMarketplaceContainer = (props) => {
  useEffect(() => {
    props.getLeads();
  }, []);

  return (
    <LeadsMarketplace
      companyId={props.companyId}
      connect_points={props.connect_points}
      hire={props.hire}
      payForChat={props.payForChat}
      leads={props.leads}
      companyInfo={props.companyInfo}
    />
  );
};

let mapStateToProps = (state) => ({
  connect_points: state.auth?.company_info?.connect_points,
  leads: state.lead.leads,
  companyInfo: state.auth?.company_info,
});

export default compose(
  connect(mapStateToProps, {
    getLeads,
  }),
  withAuthRedirect,
)(LeadsMarketplaceContainer);
