import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";

import { siteNameForTitle } from "../../../constants";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import YoutubeButton from "../../common/YoutubeButton/YoutubeButton";
import styles from "./FreelancerSlots.module.css";

const FreelancerSlots = (props) => {

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Developers | {siteNameForTitle}</title>
      </Helmet>

      <DashboardMenuContainer />

      <h1 className={`text_center ${styles.page_title}`}>Find Employee</h1>

      <div className={`d_flex flex_wrap justify_content_center align_items_center`}>
        {props.search.freelancer_searches?.map((search) => (
          <NavLink
            key={search.search_id}
            to={`/employee-search/${search.search_id}`}
            className={`d_flex flex_wrap justify_content_center align_items_center ${styles.search_slot} ${styles.slot}`}
          >
            {search.job_title_name}
            {search.found_last_run > 0 && <span className={styles.total}>{search.found_last_run}</span>}
          </NavLink>
        ))}
        <NavLink
          to="/add-employee-search"
          className={`d_flex flex_wrap justify_content_center align_items_center ${styles.free_slot} ${styles.slot} ${
            !props.search.freelancer_searches.length && styles.slot_with_arrow
          }`}
        >
          Add Search #{props.search.freelancer_searches && props.search.freelancer_searches.length + 1}
        </NavLink>
      </div>
      <YoutubeButton src="https://www.youtube.com/embed/zILwUM1ryJo" />
    </div>
  );
};

export default FreelancerSlots;
