import React from "react";
import { connect } from "react-redux";

import { editProfile } from "../../../store/auth";
import Settings from "./Settings";
import TalentSettings from "./TalentSettings";

const SettingsContainer = (props) => {
  if (props.auth.user.is_single_developer) {
    return <TalentSettings {...props} />;
  }

  return <Settings {...props} />;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  editProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
