import React from "react";
import { connect } from "react-redux";

import { register } from "../../../store/auth";
import RegistrationStep1 from "./RegistrationStep1";

const RegistrationStep1Container = (props) => {
  const referral = JSON.parse(localStorage.getItem("referral"));

  return (
    <RegistrationStep1
      {...props}
      register={props.register}
      referral={referral}
    />
  );
};

let mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  register,
})(RegistrationStep1Container);
