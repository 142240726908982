import React from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";
import { Row, Col, Modal } from "antd";

import RadioButton from "components/common/RadioButton/RadioButton";
import { Textarea } from "components/common/FormsControls/FormControls";
import styles from "./Marketplace.module.css";

let CloseRequestForm = (props) => {
    return (
        <form onSubmit={props.handleSubmit} className={styles.form}>
            <Row className={styles.fields_wrapper}>
                <Col xs={24} sm={12}>
                <Field
                        component={RadioButton}
                        type="radio"
                        name="reason"
                        value="iBench helped me"
                        text="iBench helped me"
                        validate={[required()]}
                    />
                    <Field
                        component={RadioButton}
                        type="radio"
                        name="reason"
                        value="Got help from a friend"
                        text="Got help from a friend"
                        validate={[required()]}
                    />
                    <Field
                        component={RadioButton}
                        type="radio"
                        name="reason"
                        value="Did a Google search"
                        text="Did a Google search"
                        validate={[required()]}
                    />
                    <Field
                        component={RadioButton}
                        type="radio"
                        name="reason"
                        value="Got help in Facebook groups"
                        text="Got help in Facebook groups"
                        validate={[required()]}
                    />
                    <Field
                        component={RadioButton}
                        type="radio"
                        name="reason"
                        value="Toptal, Upwork or similar service"
                        text="Toptal, Upwork or similar service"
                        validate={[required()]}
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <Field
                        component={RadioButton}
                        type="radio"
                        name="reason"
                        value="Other"
                        text="Other"
                        validate={[required()]}
                    />
                    <Field component={Textarea} name={"comment"} validate={props.reason === "Other" && [required()]} />
                </Col>
            </Row>
            <div className={styles.closeRequestModalSubmitWrapper}>
                <button className={`btn ${styles.submit}`}>Just do it!</button>
            </div>
        </form>
    );
};

CloseRequestForm = reduxForm({ form: "closeMarketplaceRequest" })(CloseRequestForm);
const formSelector = formValueSelector("closeMarketplaceRequest");
CloseRequestForm = connect((state) => {
    const reason = formSelector(state, "reason");
    return { reason };
})(CloseRequestForm);

const CloseRequestModal = (props) => {
    return (
        <Modal
            visible={props.visible}
            title={"We are interested in your request"}
            centered={true}
            width={700}
            footer={null}
            onCancel={props.onCancel}
        >
            <p className={styles.closeRequestModalText}>
                You want to cancel your request. We would be grateful if you provided a reason.
            </p>
            <CloseRequestForm onSubmit={props.onSubmit} />
        </Modal>
    );
};

export default CloseRequestModal;
