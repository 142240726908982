import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import cn from "classnames";

import { getCompanyData } from "../../../store/auth";
import countries_list from "../../../utils/countries_list";
import { Input, SelectInput } from "../../common/FormsControls/FormControls";
import UploadImage from "../../common/UploadImage/UploadImage";
import Editor from "../../common/SimpleEditor/SimpleEditor";

import styles from "./ProfileEditing.module.css";

const formName = "baseInfoForm";

const BaseInfoForm = (props) => {
  const {
    handleSubmit,
    error,
  } = props;

  const countryList = countries_list.map(item => item.label);

  return (
    <form onSubmit={handleSubmit} className={styles.base_info_form}>
      <div className={styles.fields_wrapper}>
        <div className={styles.field_label}>
          Representative person
        </div>
        <div className={cn(styles.fields_section, styles.representative_section)}>
          <div className={cn(styles.upload_photo_wrapper, styles.left_column)}>
            <Field
              component={UploadImage}
              name="representative_photo"
              label="Upload Photo"
              mode="photo"
            />
          </div>
          <div>
            <div className={styles.fields_row}>
              <Field
                className={styles.input}
                component={Input}
                type="text"
                name="first_name"
                label="First Name"
                validate={[required()]}
              />
              <Field
                className={styles.input}
                component={Input}
                type="text"
                name="last_name"
                label="Last Name"
                validate={[required()]}
              />
            </div>
            <div className={styles.fields_row}>
              <Field
                className={styles.input}
                component={Input}
                type="text"
                name="position"
                label="Position"
                validate={[required()]}
                help_text="for example: Founder"
                help_text_design={styles.help_text}
              />
            </div>
          </div>
        </div>
        <div className={styles.location_section}>
          <div className={cn(styles.left_column, styles.field_label)}>Location</div>
          <div>
            <div className={styles.fields_row}>
              <Field
                input_wrapper_class={styles.select_wrapper}
                component={SelectInput}
                name={"country"}
                label="Country"
                options={countryList}
                validate={[required()]}
              />
              <Field
                className={styles.input}
                component={Input}
                type="text"
                name="city"
                label="City"
              />
            </div>
            <div className={styles.fields_row}>
              <Field
                input_wrapper_class={styles.full_width}
                className={styles.input}
                component={Input}
                type="text"
                name="address"
                label="Address"
              />
            </div>
          </div>
        </div>
        <div className={cn(styles.about_section)}>
          <div className={cn(styles.left_column, styles.field_label)}>About</div>
          <div className={cn(styles.fields_row, styles.editor_wrapper)}>
            <Field
              component={Editor}
              name={"description"}
            />
          </div>
        </div>

        {error && (
          <div className={`${styles.form_errors} text_center validation_message`}>
            {error}
          </div>
        )}

        <div className={styles.submit_wrapper}>
          <button className={`btn ${styles.submit}`}>Save</button>
        </div>
      </div>

    </form>
  );
};

let BaseInfoFormReduxForm = reduxForm({
  form: formName,
  enableReinitialize: true,
})(BaseInfoForm);

const mapStateToProps = (state) => ({
  initialValues: {
    ...state.auth.company_info,
  },
});

const mapDispatchToProps = { load: getCompanyData }; // bind account loading action creator

export default connect(mapStateToProps, mapDispatchToProps)(BaseInfoFormReduxForm);