import React, { useEffect } from "react";

import { buildImageLink } from "../../../utils/utils";

import styles from "./LogosList.module.css";

const LogosList = (props) => {
  const {
    items,
  } = props;

  useEffect(() => {

    items.forEach((item, index) => {
      if (item.logo && typeof item.logo !== "string") {
        let reader = new FileReader();
        reader.onload = function () {
          let output = document.getElementById(`item-logo-${index}`);
          output.src = reader.result;
        };
        reader.readAsDataURL(item.logo);
      }
    });

  }, [items]);

  return (
    <div className={styles.logos_list}>
      {items.map((item, index) => (
        <div className={styles.logo_wrapper} key={index}>
          <img
            className={styles.logo}
            id={`item-logo-${index}`}
            src={typeof item.logo === "string" && buildImageLink(item.logo)}
            alt={item.name}
          />
          <div className={styles.item_name}>
            {item.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LogosList;