import qs from "qs";

import { axiosFileInstances, axiosInstances } from "./api";

const axiosInstance = axiosInstances.account;
const axiosFileInstance = axiosFileInstances.account;

export const developersAPI = {
  getDeveloper(developer_id) {
    return axiosInstance.get(`api/users/developers?developer_id=${developer_id}`).then((response) => {
      return response.data;
    });
  },

  getDeveloperInfo(developer_id) {
    // For start ups only
    return axiosInstance.get(`api/users/developers/information?developer_id=${developer_id}`).then((response) => {
      return response.data;
    });
  },

  getDevelopers() {
    return axiosInstance.get(`api/users/developers/`).then((response) => {
      return response.data;
    });
  },

  addDeveloper(data) {
    return axiosInstance.post(`api/users/developers/`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  editDeveloper(data) {
    return axiosInstance.put(`api/users/developers/`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  deleteDeveloper(data) {
    return axiosInstance.delete(`api/users/developers/`, { data: qs.stringify(data) }).then((response) => {
      return response.data;
    });
  },

  uploadPhoto(data) {
    return axiosFileInstance.post(`api/users/upload`, data).then((response) => {
      return response.data;
    });
  },

  editBenchStatus(data) {
    return axiosInstance.put(`api/users/developers/bench`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  incrementCTR(developerId) {
    return axiosInstance
    .post(`api/users/developers/${developerId}/search/clicked`, qs.stringify({}))
    .then((response) => {
      return response.data;
    });
  },

  getJobTitles() {
    return axiosInstance.get(`api/users/list/titles`).then((response) => {
      return response.data.job_title_list;
    });
  },

  getPositionLevels() {
    return axiosInstance.get(`api/users/list/positions`).then((response) => {
      return response.data.position_level_list;
    });
  },

  getLanguageLevels() {
    return axiosInstance.get(`api/users/developers/languages/english/levels`).then((response) => {
      return response.data.result;
    });
  },
};
