import { Helmet } from "react-helmet";

import { siteNameForTitle } from "../../../constants/global-constants";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import styles from "./PaymentCanceled.module.css";
import React from "react";

const PaymentCanceled = () => {
  const payConnects = () => {
    const userToken = JSON.parse(localStorage.getItem("user")).token;

    const stripe = window.Stripe("pk_live_9W6EAwDD6jL6asP3NFoLryM2"); // real key
    // const stripe = window.Stripe("pk_test_vfyCrZ4dWUImEL7nBS4Yl0aA"); // test key
    stripe.redirectToCheckout({
      lineItems: [{ price: "price_1M9XzxLOaM3ehlDReCY1cGOY", quantity: 1 }], // real payment - 99 EURO for 10 connects
      mode: "subscription",
      successUrl: `https://ibench.in/payment/success?token=${userToken}`,
      cancelUrl: "https://ibench.in/payment/canceled",
    }).then(function (result) {
      if (result.error) {
        const displayError = document.getElementById("error-message");
        displayError.textContent = result.error.message;
      }
    });
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Payment Canceled | {siteNameForTitle}</title>
      </Helmet>
      <DashboardMenuContainer/>
      <div className={styles.content}>
        <h1 className={styles.pageTitle}>Payment Canceled</h1>
        <div className={styles.info}/>
        <div className={styles.status}>
          Oops, something went wrong.<br/>
          Try making the payment again.
        </div>
        <div className={styles.stripeBlock}>
          <div>
            <div className={styles.purchase}>
              To purchase 10 connections, use <span onClick={payConnects} className={styles.stripLink}>this link</span>.
            </div>
          </div>
          <div className={styles.paymentService}/>
        </div>
        <div className={styles.stripeError}>
          <div id="error-message" />
        </div>
      </div>
    </div>
  );
};

export default PaymentCanceled;
