import qs from "qs";
import { axiosInstances } from "./api";

const axiosInstance = axiosInstances.account;

export const leadsAPI = {
  getLead(leadId) {
    return axiosInstance.get(`api/users/leads/${leadId}`).then((response) => {
      return response.data;
    });
  },

  getLeads() {
    return axiosInstance.get('api/users/leads/').then((response) => {
      return response.data;
    });
  },

  addLead(data) {
    return axiosInstance.post('api/users/leads/', qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  updateLead(data) {
    return axiosInstance.put(`api/users/leads/${data.id}`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },
};
