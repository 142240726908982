import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import { publicFilesUrl } from "../../../constants/global-constants";
import SocialLinks from "../../common/SocialLinks/SocialLinks";

import default_company_photo from "../../../assets/img/rowing.svg";
import verified_icon from "../../../assets/img/verified.png";
import top10_icon from "../../../assets/img/top-10.png";
import styles from "./AdvCard.module.css";

const AdvCard = (props) => {
  const {
    linkedin,
    facebook,
    youtube,
    telegram,
    whatsapp,
    official_email,
    in_top_10,
    verified,
  } = props.skillSponsor;

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.card, "adv_card")}>
        <div className={styles.logoWrapper}>
          <NavLink to={`/company/${props.skillSponsor.company_id}`}>
            <img
              src={props.skillSponsor.logo ? `${publicFilesUrl}public/img/${props.skillSponsor.logo}` : default_company_photo}
              alt={props.skillSponsor.company_name}
              className={styles.logo}
            />
          </NavLink>
          <div className={styles.top10Verified}>
            {!!in_top_10 && <img src={top10_icon} className={styles.verified} alt="In Top 10" />}
            {!!verified && <img src={verified_icon} className={styles.verified} alt="Verified" />}
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.main_info_wrapper}>
            <div className={styles.main_info}>
              <div>
                <strong>Company Name:</strong>{" "}
                <NavLink
                  to={`/company/${props.skillSponsor.company_id}`}
                  className={styles.company_name}
                >
                  {props.skillSponsor.company_name}
                </NavLink>
              </div>
              <div>
                <strong>Location:</strong> {props.skillSponsor.city}, {props.skillSponsor.country}
              </div>
            </div>
            <div className={styles.statuses}>
              {props.skillSponsor.is_pro_account === 1 && <div className={"pro_icon"}>Pro</div>}
            </div>
            <a
              href="https://t.me/Gendolf77"
              target="_blank"
              className={styles.advertising}
              rel="nofollow"
            >Advertising</a>
          </div>
          <SocialLinks
            className={styles.socialButtons}
            telegram={telegram}
            linkedin={linkedin}
            facebook={facebook}
            whatsapp={whatsapp}
            youtube={youtube}
            official_email={official_email}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: props.skillSponsor.description}}
          />
        </div>
      </div>
    </div>
  );
};

export default AdvCard;
