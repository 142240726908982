import React from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { required } from "redux-form-validators";

import Modal from "../common/Modal/Modal";
import { renderRadioButton } from "../common/FormsControls/FormControls";

import styles from "./LeadsList.module.css";

let CloseForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className={styles.formRow}>
        <Field
          component={renderRadioButton}
          className={styles.radioButton}
          type="radio"
          name="reason"
          value="I found a buyer myself"
          label="I found a buyer myself"
          validate={[required()]}
        />
        <Field
          className={styles.radioButton}
          component={renderRadioButton}
          type="radio"
          name="reason"
          value="I found a buyer on the iBench platform"
          label="I found a buyer on the iBench platform"
          validate={[required()]}
        />
      </div>
      <Field
        className={styles.radioButton}
        component={renderRadioButton}
        type="radio"
        name="reason"
        value="The client disappeared and is not responding"
        label="The client disappeared and is not responding"
        validate={[required()]}
      />
      <div className={styles.modalAttention}>
        Attention, you will not be able to reopen this lead again, you will need to create a new lead for sale again
      </div>
      <button className={`btn ${styles.submit}`}>Submit</button>
    </form>
  );
};

CloseForm = reduxForm({ form: "closeLeadForm" })(CloseForm);

const DescriptionModal = (props) => {

  const renderModal = () => (
    <div className={styles.closeModal}>
      <div className={styles.modalHeader}>
        Close the lead
      </div>
      <div className={styles.modalBody}>
        <div className={styles.modalBodyTitle}>
          How did you close this lead?
        </div>
        <CloseForm onSubmit={props.onSubmit}/>
      </div>
    </div>
  );

  return (
    <div className={styles.modal}>
      <Modal
        showModal={props.onShowModal}
        onCancel={props.onShowModal}
        noBg
        renderComponent={renderModal()}
      />
    </div>
  );
};

export default DescriptionModal;
