import { connect } from "react-redux";

import { editProfile } from "../../../store/auth";
import RegistrationStep2 from "./RegistrationStep2";
import countries_list from "../../../utils/countries_list";

const RegistrationStep2Container = (props) => {
  const registrationType = JSON.parse(localStorage.getItem("registrationType"));

  return (
    <RegistrationStep2
      {...props}
      registrationType={registrationType}
      countries_list={countries_list.map((item) => item.label)}
      editProfile={props.editProfile}
    />
  );
};

let mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  editProfile,
})(RegistrationStep2Container);
