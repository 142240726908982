import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import cn from "classnames";

import { editProfile } from "../../../store/auth";
import { siteNameForTitle } from "../../../constants";
import Preloader from "../../common/Preloader/Preloader";
import ProfileMenu from "../ProfileMenu";
import CompanyMenu from "../CompanyMenu";
import Relocation from "./Relocation";
import FormIndicator from "../../registration/TeamSearch/FormIndicator/FormIndicator";

import styles from "./Relocation.module.css";

const RelocationContainer = (props) => {
  const { auth } = props;

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Profile editing | {siteNameForTitle}</title>
      </Helmet>

      {auth.is_fetching && <Preloader/>}

      <h2 className={cn(styles.title, "text_center")}>
        Developer profile
      </h2>

      <ProfileMenu />

      <CompanyMenu companyInfo={auth.company_info} />

      <div className={styles.subtitle}>Edit profile / Relocation</div>

      {auth.company_info.profile_fill_step < 5 && <FormIndicator page={3} className={styles.indicator} />}

      <Relocation {...props} />
    </div>

  )
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  editProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(RelocationContainer);
