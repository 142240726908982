import qs from "qs";
import { axiosInstances } from "./api";

const axiosInstance = axiosInstances.account;

export const bookmarksAPI = {
  getBookmarksList() {
    return axiosInstance.get(`api/users/bookmarks/`).then((response) => {
      return response.data;
    });
  },

  addBookmark(data) {
    return axiosInstance.post(`api/users/bookmarks/`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  editBookmark(bookmark_id, bookmark_name) {
    return axiosInstance.put(`api/users/bookmarks/${bookmark_id}`, qs.stringify(bookmark_name)).then((response) => {
      return response.data;
    });
  },

  deleteBookmark(bookmark_id) {
    return axiosInstance.delete(`api/users/bookmarks/${bookmark_id}`).then((response) => {
      return response.data;
    });
  },

  getBookmarksDevelopers(bookmark_id) {
    return axiosInstance.get(`/api/users/bookmarks/${bookmark_id}`).then((response) => {
      return response.data;
    });
  },

  addDeveloperToBookmark(bookmark_id, developer_id) {
    return axiosInstance
      .post(`api/users/bookmarks/${bookmark_id}/developers`, qs.stringify({ developer_id }))
      .then((response) => {
        return response.data;
      });
  },

  deleteDeveloperFromBookmark(bookmark_id, developer_id) {
    return axiosInstance
      .delete(`/api/users/bookmarks/${bookmark_id}/developers`, {
        data: qs.stringify({ developer_id }),
      })
      .then((response) => {
        return response.data;
      });
  },
};
