import { axiosInstances } from "./api";

const axiosInstance = axiosInstances.account;

export const analyticsAPI = {
  getCompanyAnalytics(searchParams) {
    return axiosInstance.get(`api/users/analytics/?${new URLSearchParams(searchParams).toString()}`).then((response) => {
      return response.data;
    });
  },
};
