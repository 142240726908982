import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";

import { Input, renderMultiselect, SelectInputKeyValue } from "../../common/FormsControls/FormControls";
import Preloader from "../../common/Preloader/Preloader";
import Editor from "../../common/SimpleEditor/SimpleEditor";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import { siteNameForTitle, vettedOptions } from "../../../constants/global-constants";
import RadioButton from "../../common/RadioButton/RadioButton";

import styles from "./OutsourceAdding.module.css";

let OutsourceAddingForm = (props) => {

  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fieldsWrapper}>
        <Field
          component={SelectInputKeyValue}
          name="vetted"
          label="Verified companies"
          validate={[required()]}
          options={vettedOptions}
        />
        <div className={styles.skillsWrapper}>
          <Field
            name={"location"}
            component={renderMultiselect}
            label="Developer's location"
            // validate={[required()]}
            data={props.countries_list}
            valueField="country_id"
            textField="country_name"
            placeholder="Select the countries from which you need specialists"
          />
        </div>
        <Field
          component={Input}
          type={"text"}
          name={"name"}
          label="Project name"
          validate={[required()]}
          help_text="for example: My first project"
        />
        <Field
          component={Editor}
          type={"text"}
          name={"description"}
          label="Project description"
          validate={[required()]}
          rows={4}
        />
        <Field
          component={renderMultiselect}
          name={"markets"}
          label="What are you building?"
          validate={[required()]}
          data={props.markets_list}
          valueField="market_id"
          textField="market_name"
          placeholder="What are you building?"
        />

        <div className={styles.radioGrouptWrapper}>
          <div className={styles.radioGroupTitle}>Project budget</div>
          <div className={styles.radioGroup}>
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="5000"
              text="Less then $5,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="15000"
              text="Up to $15,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="35000"
              text="Up to $35,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="75000"
              text="Up to $75,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="100000"
              text="Up to $100,000"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="budget"
              value="200000"
              text="Up to $200,000"
              validate={[required()]}
              designType={"greenButton"}
            />
          </div>
        </div>

        <div className={styles.radioGrouptWrapper}>
          <div className={styles.radioGroupTitle}>What time would you like to start?</div>
          <div className={styles.radioGroup}>
            <Field
              component={RadioButton}
              type="radio"
              name="project_start"
              value="asap"
              text="ASAP"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="project_start"
              value="nextMonth"
              text="Next month"
              validate={[required()]}
              designType={"greenButton"}
            />
            <Field
              component={RadioButton}
              type="radio"
              name="project_start"
              value="nextQuartal"
              text="Next Quartal"
              validate={[required()]}
              designType={"greenButton"}
            />
          </div>
        </div>

        <Field component={Input} type={"url"} name={"links"} label="Additional link"/>
      </div>

      <div>
        <button className={`btn ${styles.submit}`}>Search</button>
      </div>
    </form>
  );
};

OutsourceAddingForm = reduxForm({
  form: "outsourceAdding",
  initialValues: {
    vetted: "1",
  }
})(OutsourceAddingForm);

const OutsourceAdding = (props) => {
  const onSubmit = (formData) => {
    formData.location = formData.location?.join?.(", ");
    props.addSearch(formData);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Add new outsource search | {siteNameForTitle}</title>
      </Helmet>

      {props.isFetching && <Preloader/>}

      <DashboardMenuContainer/>
      <h1 className={styles.title}>
        <span>Find IT companies</span>
      </h1>

      <div className={styles.form_title}>Add new outsource search</div>

      <div className={styles.formWrapper}>
        <OutsourceAddingForm
          markets_list={props.markets_list}
          onSubmit={onSubmit}
          countries_list={props.countries_list}
        />
      </div>
    </div>
  );
};

export default OutsourceAdding;
