import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import { getCasesList, getCasesListById, deleteCase } from "../../store/cases";
import { startOutsourceChat } from "../../store/outsource";
import Cases from "./Cases";

const CasesContainer = (props) => {
  useEffect(() => {
    props.getCasesList();
  }, []);

  return (
    <Cases
      companyInfo={props.cases.companyInfo}
      markets_list={props.markets_list}
      isFetching={props.cases.isFetching}
      casesList={props.cases.casesList}
      startOutsourceChat={props.startOutsourceChat}
      deleteCase={props.deleteCase}
      auth={props.auth}
    />
  );
};

let mapStateToProps = (state) => ({
  auth: state.auth,
  markets_list: state.app.markets_list,
  cases: state.cases,
});

export default compose(
  connect(mapStateToProps, {
    getCasesList,
    getCasesListById,
    startOutsourceChat,
    deleteCase,
  }),
  withAuthRedirect,
)(CasesContainer);
