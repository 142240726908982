import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import cn from "classnames";

import { isAfterNow } from "../../../utils/utils";
import { siteNameForTitle } from "../../../constants/global-constants";
import { Checkbox } from "../../common/FormsControls/FormControls";
import Preloader from "../../common/Preloader/Preloader";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import Terms from "../../Terms/Terms";
import SubscriptionButton from "../SubscriptionButton/SubscriptionButton";

import agenciesImage from "../../../assets/img/agencies.png";
import styles from "./Subscription.module.css";

let SubscriptionAgreementForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <Field
        component={Checkbox}
        name="subscriptionAgreement"
        defaultChecked={true}
        label={
          <>
            I accept the <span className={styles.showAgreement}></span>
          </>
        }
        validate={[required()]}
      />
      <div className={styles.termsLinks}>
        <span
          className={styles.termsLink}
          onClick={() => {
            props.setShowAgreement(!props.showAgreement);
          }}
        >
            Terms of Service iBench
        </span>{" "}
      </div>
    </form>
  );
};

SubscriptionAgreementForm = reduxForm({
  form: "subscriptionAgreement",
  initialValues: { subscriptionAgreement: true },
})(SubscriptionAgreementForm);

const Subscription = (props) => {
  const {
    companyInfo,
    subscription,
    subscriptionAgreement,
  } = props;

  const [showAgreement, setShowAgreement] = useState(false);
  const isActiveSubscription = isAfterNow(companyInfo?.stripe_subscription_expires);

  const renderTopPart = () => {
    if (isActiveSubscription) {
      return (
        <div className={styles.isActive}>
          Your membership is active!
        </div>
      );
    }

    return (
      <div className={styles.warning}>
        All functionality will be available to you after paying for the membership.
      </div>
    )
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Membership | {siteNameForTitle}</title>
      </Helmet>

      {subscription.is_fetching && <Preloader/>}

      <DashboardMenuContainer/>

      <h1 className={styles.pageTitle}>Membership</h1>

      <div className={styles.content}>
        {renderTopPart()}
        <div className={styles.mainPart}>
          <div className={cn(styles.benefitsWrapper, { [styles.subscribed]: isActiveSubscription })}>
            <ul className={styles.benefitsList}>
              <li className={styles.benefit}>Outstaff and outsourcing project</li>
              <li className={styles.benefit}>Reduce bench size</li>
              <li className={styles.benefit}>Notifications via Email, Telegram</li>
              <li className={styles.benefit}>Talents marketplace</li>
              <li className={styles.benefit}>Direct Chat with Client</li>
              <li
                className={cn(styles.benefit, styles.benefitPinkArrow)}
              >
                Service fees 0% from each contract
              </li>
              <li className={cn(styles.benefit, styles.benefitPinkArrow, styles.benefitPinkAirplane)}>
                Service fees 21% from hiring
              </li>
              <li className={cn(styles.benefit, styles.benefitPinkArrow)}>
                Unlimited connections with clients
              </li>
              <li className={cn(styles.benefit, styles.benefitPinkArrow)}>
                Free verification
              </li>

              <li className={styles.benefit}>Support via Email</li>
              <li className={styles.benefit}>Live chat via Messenger</li>
              <li className={styles.benefit}>Payments via Stripe</li>
            </ul>
            <img src={agenciesImage} alt="Subscription"/>
          </div>

          {!isActiveSubscription && (
            <SubscriptionAgreementForm
              showAgreement={showAgreement}
              setShowAgreement={setShowAgreement}
            />
          )}

          {showAgreement && (
            <div className={styles.agreement}>
              <Terms/>
            </div>
          )}

          {/* Show payment button only when trial period will be finished */}
          {!isActiveSubscription && (
            <>
              <SubscriptionButton
                disabled={!subscriptionAgreement?.values?.subscriptionAgreement}
              />
              <div className={styles.refunds}>
                Refunds are not provided. The service ibench.in is used on an "as is" basis.
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
