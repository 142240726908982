import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { developersAPI } from "../../../api/developers";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { addFreelancerSearch } from "../../../store/search";
import india_countries_list from "../../../utils/india_countries_list";
import FreelancerSearchAdding from "./FreelancerSearchAdding";

const FreelancerSearchAddingContainer = (props) => {
    const [jobTitlesList, setJobTitlesList] = useState(null);
    const [positionLevelsList, setPositionLevelsList] = useState(null);
    const [englishLevelsList, setEnglishLevelsList] = useState(null);

    useEffect(() => {
        developersAPI.getJobTitles().then((result) => setJobTitlesList(result));
        developersAPI.getPositionLevels().then((result) => setPositionLevelsList(result));
        developersAPI.getLanguageLevels().then((result) => setEnglishLevelsList(result));
    }, []);

    return (
        <FreelancerSearchAdding
            {...props}
            slot_number={props.search.searches.length + 1}
            jobTitlesList={jobTitlesList}
            positionLevelsList={positionLevelsList}
            skills_list={props.skills_list}
            countries_list={india_countries_list.map((item) => item.label)}
            englishLevelsList={englishLevelsList}
            addSearch={props.addFreelancerSearch}
        />
    );
};

let mapStateToProps = (state) => ({
    search: state.search,
    skills_list: state.app.skills_list,
});

export default compose(connect(mapStateToProps, { addFreelancerSearch }), withAuthRedirect)(FreelancerSearchAddingContainer);
