import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import cn from "classnames";

import { getCompanyData } from "../../../store/auth";
import { Input } from "../../common/FormsControls/FormControls";

import UploadImageButton from "../../common/UploadImageButton/UploadImageButton";
import ClientLogos from "../ClientLogos/ClientLogos";

import styles from "./ProfileClientsEditing.module.css";

const formName = "clientsForm";

const ClientsForm = (props) => {
  const {
    handleSubmit,
    error,
    dispatch,
    clientName,
    clientLogo,
  } = props;

  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (props.initialValues?.clients?.length) {
      const initialClients = [];

      props.initialValues.clients.forEach((client) => {
        initialClients.push(client);
      });

      setClients(initialClients);
    }
  }, []);

  const rebuildClientFields = (newClientsArray) => {
    setClients(newClientsArray);
    dispatch(change(formName, 'clients', null));

    newClientsArray.forEach((client, index) => {
      const fieldName = `clients[${index}]`;
      dispatch(change(formName, fieldName, client));
    });
  };

  const addClient = () => {
    if (!clientName || !clientLogo) {
      return;
    }

    const clientData = {
      name: clientName,
      logo: clientLogo,
    };

    const newClientsArray = [clientData, ...clients];
    rebuildClientFields(newClientsArray);

    dispatch(change(formName, "client_name", ""));
    dispatch(change(formName, "client_logo", null));
  };

  const removeClient = (index) => {
    clients.splice(index, 1);
    rebuildClientFields([...clients]);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.clients_form}>
      <div className={styles.fields_wrapper}>
        <div className={cn(styles.clients_section)}>
          <div className={cn(styles.left_column, styles.field_label)}>
            Our clients
          </div>
          <div className={cn(styles.fields_row, styles.clients_wrapper)}>
            <div className={styles.clients_fields_wrapper}>
              <Field
                className={styles.input}
                component={Input}
                name="client_name"
                label="Name"
              />
              <Field
                component={UploadImageButton}
                name="client_logo"
                label="Upload logo"
                mode="photo"
              />
              <button
                className={styles.grey_button}
                type="button"
                onClick={addClient}
              >
                Add new client
              </button>
            </div>

            {!!clients.length && (
              <>
                <div className={styles.clients_items}>
                  {clients.map((client, index) => (
                    <div className={styles.industry_item_row} key={index}>
                      <div className={styles.industry_item}>{client.name}</div>
                      <div className={styles.delete_industry} onClick={() => removeClient(index)}>delete</div>
                    </div>
                  ))}
                </div>

                <div className={styles.client_logos}>
                  <ClientLogos clients={clients} />
                </div>
              </>
            )}
          </div>
        </div>

        {error && (
          <div className={`${styles.form_errors} text_center validation_message`}>
            {error}
          </div>
        )}

        <button className={`btn ${styles.submit}`}>Save</button>
      </div>
    </form>
  );
};


const selector = formValueSelector(formName);

let ClientsReduxForm = reduxForm({
  form: formName,
  enableReinitialize: true,
})(ClientsForm);

const mapStateToProps = (state) => ({
  initialValues: {
    ...state.auth.company_info,
  },
  clientName: selector(state, 'client_name'),
  clientLogo: selector(state, 'client_logo'),
});

const mapDispatchToProps = { load: getCompanyData }; // bind account loading action creator

export default connect(mapStateToProps, mapDispatchToProps)(ClientsReduxForm);