import qs from "qs";
import { axiosInstances } from "./api";

const axiosAuthInstance = axiosInstances.auth;
const axiosAccountInstance = axiosInstances.account;

export const searchAPI = {
  addSearch(searchData) {
    return axiosAccountInstance.post(`api/users/search/`, qs.stringify(searchData)).then((response) => {
      return response.data;
    });
  },

  editSearch(searchData) {
    return axiosAccountInstance.put(`api/users/search/`, qs.stringify(searchData)).then((response) => {
      return response.data;
    });
  },

  deleteSearch(data) {
    return axiosAccountInstance.delete(`api/users/search/`, { data: qs.stringify(data) }).then((response) => {
      return response.data;
    });
  },

  getCurrentSearch(search_id, isSingleDeveloper) {
    return axiosAccountInstance.get(`api/users/search?search_id=${search_id}&is_single_developer=${isSingleDeveloper}`)
    .then(({ data }) => {
      if (data?.searches?.[0]?.location) {
        data.searches[0].location = data.searches[0].location?.split(", ");
      }
      if (data?.searches?.[0]?.exclude_location) {
        data.searches[0].exclude_location = data.searches[0].exclude_location?.split(", ");
      }
      return data;
    });
  },

  getSearches() {
    return axiosAccountInstance.get(`api/users/search/`).then((response) => {
      return response.data.searches;
    });
  },

  getFreelancerSearches() {
    return axiosAccountInstance.get(`api/users/search?is_single_developer=1`).then((response) => {
      return response.data.searches;
    });
  },

  getSearchResults(search_id) {
    return axiosAccountInstance.get(`api/users/search/results?search_id=${search_id}`).then((response) => {
      return response.data;
    });
  },

  addSkill(data) {
    return axiosAccountInstance.post(`api/users/search/skills`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  deleteSkill(data) {
    return axiosAccountInstance
      .delete(`api/users/search/skills`, {
        data: qs.stringify(data),
      })
      .then((response) => {
        return response.data;
      });
  },

  requestAssistant(data) {
    return axiosAccountInstance.post(`api/users/search/assistant/request`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  trialSearch(queryStr) {
    return axiosAuthInstance.get(`api/public/trial/search?${queryStr}`).then((response) => {
      return response;
    });
  },
};
