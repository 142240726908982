import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { getCompanyAnalytics } from "../../../store/analytics";
import Analytics from "./Analytics";

const AnalyticsContainer = (props) => {
  return <Analytics {...props} />;
};

let mapStateToProps = (state) => {
  return {
    analytics: state.analytics.companyAnalytics,
  };
};

export default compose(
  connect(mapStateToProps, {
    getCompanyAnalytics,
  }),
  withAuthRedirect
)(AnalyticsContainer);
