const siteURL = 'https://ibench.in/';

export default {
  siteURL,
  bannerFolder: 'https://ibench.in/public/banners/',
  stripe: {
    key: "pk_live_9W6EAwDD6jL6asP3NFoLryM2",
    subscription: "price_1OeMdBLOaM3ehlDRtqqXQ4UN",
  }
};
