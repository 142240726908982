import React from "react";
import { connect } from "react-redux";

import HireDevelopers from "./HireDevelopers";

const HireDevelopersContainer = ({ stats }) => {

  return <HireDevelopers stats={stats} />;
};

let mapStateToProps = (state) => ({
  stats: state.app.stats,
});

export default connect(mapStateToProps, null)(HireDevelopersContainer);
