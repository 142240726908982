import React from "react";
import { Helmet } from "react-helmet";

import { siteNameForTitle, defaultDescription } from "../../../constants/global-constants";
import countries_list from "../../../utils/countries_list";
import Preloader from "../../common/Preloader/Preloader";
import Breadcrumbs from "../../common/Breadcrumbs/Breadcrumbs";
import RegistrationQuickForm from "./RegistrationQuickForm/RegistrationQuickForm";

import styles from "./RegistrationQuick.module.css";

const RegistrationQuick = (props) => {
  return (
    <div className={styles.wrapper}>
      {props.is_fetching_auth && <Preloader />}

      <Helmet>
        <title>{siteNameForTitle} / I want to hire him</title>
        <meta
          name="description"
          content={defaultDescription}
        />
        <meta
          name="keywords"
          content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
          data-react-helmet="true"
        />
        <script>
          {`
            gtag('event', 'conversion', {'send_to': 'AW-697199352/OWPsCMu95s4BEPjVucwC'});
          `}
        </script>
      </Helmet>
      <Breadcrumbs className={styles.breadcrumbs} currentPage="Recovery password" />

      <RegistrationQuickForm
        developer={props.developer}
        quickRegister={props.quickRegister}
        loginAndHire={props.loginAndHire}
        countries_list={countries_list.map(item => item.label)}
      />
    </div>
  );
};

export default RegistrationQuick;
