import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { editProfile } from "../../../store/auth";
import ProfileSocialEditing from "./ProfileSocialEditing";

const ProfileSocialEditingContainer = (props) => {
  return <ProfileSocialEditing {...props} editProfile={props.editProfile} />;
};

let mapStateToProps = state => ({
  auth: state.auth,
});

export default compose(
  connect(mapStateToProps, {
    editProfile,
  }),
  withAuthRedirect
)(ProfileSocialEditingContainer);
