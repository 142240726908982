import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { email, required } from "redux-form-validators";

import { siteNameForTitle, defaultDescription } from "../../constants/global-constants";
import { Input } from "../common/FormsControls/FormControls";
import Preloader from "../common/Preloader/Preloader";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import styles from "./Login.module.css";

const LoginForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <Field
        component={Input}
        type={"email"}
        name={"email"}
        label="email"
        validate={[required(), email()]}
        help_text="for example: user@company.com"
      />
      <Field
        component={Input}
        type={"password"}
        name={"password"}
        label="password"
        validate={[required()]}
        help_text="for example: iddqd$!@#$"
      />
      {props.error && <div className={"validation_message"}>{props.error}</div>}

      <div className={styles.recovery_link}>
        <NavLink to="/recovery">Recovery password</NavLink>
      </div>
      <div className={styles.submit_wrapper}>
        <button>Log in</button>
      </div>
    </form>
  );
};

let LoginReduxForm = reduxForm({ form: "login" })(LoginForm);

const Login = (props) => {
  const onSubmit = (form_data) => {
    props.login(form_data.email, form_data.password);
  };

  return (
    <>
      <Breadcrumbs className={styles.breadcrumbs} currentPage="Log in" />
      <div className={styles.wrapper}>
        <Helmet>
          <title>{siteNameForTitle} / Log in</title>
          <meta
            name="description"
            content={defaultDescription}
          />
          <meta
            name="keywords"
            content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
            data-react-helmet="true"
          />
        </Helmet>

        {props.is_fetching && <Preloader/>}

        <h1 className={styles.title}>Log in</h1>
        <h5 className={styles.subtitle}>amazing developer’s for startups</h5>

        <LoginReduxForm onSubmit={onSubmit}/>

        <div className={styles.registration_link}>
          <NavLink to="/registration">Register</NavLink>
        </div>
      </div>
    </>
  );
};

export default Login;
