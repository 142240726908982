import React from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import cn from "classnames";

import { getCompanyData } from "../../../store/auth";
import { validatePositiveInt } from "../../../utils/validation";
import { Input } from "../../common/FormsControls/FormControls";
import TelegramBot from "./TelegramBot";

import styles from "./ProfileSocialEditing.module.css";

const formName = "socialForm";

const SocialForm = (props) => {
  const {
    handleSubmit,
    error,
    companyInfo,
    dispatch,
  } = props;

  const saveHandler = () => {
    dispatch(change(formName, "url_redirect"));
  };

  const previousPageHandler = () => {
    dispatch(change(formName, "url_redirect", "/profile/edit/relocation"));
  };

  const nextPageHandler = () => {
    dispatch(change(formName, "url_redirect", "/profile/edit/alerts"));
  };

  const renderButtons = () => {
    if (companyInfo?.is_single_developer) {
      return (
        <>
          <div className={styles.submit_wrapper}>
            <button className={cn("btn", styles.submit)} onClick={saveHandler}>
              Save
            </button>
          </div>

          <div className={cn(styles.submit_wrapper)}>
            <button className={cn("btn", styles.submit, styles.yellow)} onClick={previousPageHandler}>
              Previous
            </button>
            <button className={cn("btn", styles.submit, styles.yellow)} onClick={nextPageHandler}>
              Next
            </button>
          </div>
        </>
      );
    }

    return <button className={cn("btn", styles.submit)}>Save</button>;
  };

  return (
    <form onSubmit={handleSubmit} className={styles.social_form}>
      <div className={styles.fields_wrapper}>
        <div className={styles.social_section}>
          <div className={cn(styles.field_label, styles.left_column)}>Social</div>
          <div>
            <div className={styles.fields_row}>
              <div className={cn(styles.field_wrapper, styles.linkedin)}>
                <Field
                  className={styles.input}
                  component={Input}
                  type="text"
                  name="linkedin"
                  label="LinkedIn"
                />
              </div>
              <div className={cn(styles.field_wrapper, styles.facebook)}>
                <Field
                  className={styles.input}
                  component={Input}
                  type="text"
                  name="facebook"
                  label="Facebook"
                />
              </div>
            </div>
            <div className={styles.fields_row}>
              <div className={cn(styles.field_wrapper, styles.youtube)}>
                <Field
                  className={styles.input}
                  component={Input}
                  type="text"
                  name="youtube"
                  label="Youtube"
                />
              </div>
              <div className={cn(styles.field_wrapper, styles.github)}>
                <Field
                  className={styles.input}
                  component={Input}
                  type="text"
                  name="github"
                  label="Github"
                />
              </div>
            </div>
            <div className={styles.fields_row}>
              <div className={cn(styles.field_wrapper, styles.behance)}>
                <Field
                  className={styles.input}
                  component={Input}
                  type="text"
                  name="behance"
                  label="Behancer"
                />
              </div>
              <div className={cn(styles.field_wrapper, styles.whatsapp)}>
                <Field
                  className={styles.input}
                  component={Input}
                  type="number"
                  name="whatsapp"
                  label="WhatsApp number"
                  validate={validatePositiveInt}
                />
              </div>
            </div>
            <div className={styles.fields_row}>
              <div className={cn(styles.field_wrapper, styles.telegram)}>
                <Field
                  className={styles.input}
                  component={Input}
                  type="number"
                  name="telegram"
                  label="Telegram number"
                  validate={validatePositiveInt}
                />
                <div className={styles.telegramInfo}>
                  This phone number will be used for telegram bot
                </div>
              </div>
            </div>
            <TelegramBot />
          </div>
        </div>

        {error && (
          <div className={`${styles.form_errors} text_center validation_message`}>
            {error}
          </div>
        )}

        {renderButtons()}
      </div>

    </form>
  );
};

let SocialReduxForm = reduxForm({
  form: formName,
  enableReinitialize: true,
})(SocialForm);

const mapStateToProps = (state) => ({
  initialValues: {
    ...state.auth.company_info,
  },
  companyInfo: state.auth.company_info,
});

const mapDispatchToProps = { load: getCompanyData }; // bind account loading action creator

export default connect(mapStateToProps, mapDispatchToProps)(SocialReduxForm);