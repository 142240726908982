import React from "react";
import ReactQuill from "react-quill";
import cn from "classnames";
import "react-quill/dist/quill.snow.css";

import styles from "./SimpleEditor.module.css";

const SimpleEditor = (props) => {
  const {
    input: { value, onChange },
    label,
    meta,
  } = props;

  return (
    <div className={cn({ [styles.error]: meta.touched && meta.error })}>
      <div className={styles.label}>{label}</div>
      <ReactQuill theme="snow" value={value} onChange={onChange} />
      {meta.touched && meta.error && <div className="validation_message">{meta.error}</div>}
    </div>
  );
};

export default SimpleEditor
