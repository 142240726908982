import React from "react";
import { Helmet } from "react-helmet";

import { siteNameForTitle } from "../../constants";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import styles from "./CookiePolicy.module.css";

const CookiePolicy = () => {
  return (
    <>
      <Breadcrumbs className={styles.breadcrumbs} currentPage="Cookie policy"/>
      <div className={styles.wrapper}>
        <Helmet>
          <title>{siteNameForTitle} / Cookie Policy</title>
          <meta
            name="description"
            content="Highly skilled software developers, verified by real companies. Realtime search. No freelancers here."
            data-react-helmet="true"
          />
          <meta
            name="keywords"
            content="software developers, ux/ui designers, bench, developers on demand, realtime developers search, e-commerce developers, fin-tech developers, healthcare developers"
            data-react-helmet="true"
          />
        </Helmet>

        <h1 className={styles.title}>Cookie Policy</h1>

        <p>
          <b>Last updated July 14, 2021</b>
        </p>
        <br />
        <p>
          This Cookie Policy explains how Linenberger Group LTD. (<b>"Company"</b>, <b>"we"</b>, <b>"us"</b>, and <b>"our</b>)
          uses cookies and similar technologies to recognize you when you visit our websites at{" "}
          <a href="https://ibench.in">https://ibench.in</a>, (<b>"Websites"</b>). It explains what these technologies
          are and why we use them, as well as your rights to control our use of them.
        </p>
        <p>
          In some cases we may use cookies to collect personal information, or that becomes personal information if we
          combine it with other information.
        </p>
        <br />

        <h2>What are cookies?</h2>
        <p>
          Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies
          are widely used by website owners in order to make their websites work, or to work more efficiently, as well as
          to provide reporting information.
        </p>
        <p>
          Cookies set by the website owner (in this case, Linenberger Group LTD.) are called "first party cookies". Cookies set
          by parties other than the website owner are called "third party cookies". Third party cookies enable third party
          features or functionality to be provided on or through the website (e.g. like advertising, interactive content
          and analytics). The parties that set these third party cookies can recognize your computer both when it visits
          the website in question and also when it visits certain other websites.
        </p>
        <br />

        <h2>Why do we use cookies?</h2>
        <p>
          We use first and third party cookies for several reasons. Some cookies are required for technical reasons in
          order for our Websites to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other
          cookies also enable us to track and target the interests of our users to enhance the experience on our Online
          Properties. Third parties serve cookies through our Websites for advertising, analytics and other purposes. This
          is described in more detail below.
        </p>
        <p>
          The specific types of first and third party cookies served through our Websites and the purposes they perform
          are described below (please note that the specific cookies served may vary depending on the specific Online
          Properties you visit):
        </p>
        <br />

        <h2>How can I control cookies?</h2>
        <p>
          You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting
          your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories
          of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide
          you with services.
        </p>
        <p>
          The Cookie Consent Manager can be found in the notification banner and on our website. If you choose to reject
          cookies, you may still use our website though your access to some functionality and areas of our website may be
          restricted. You may also set or amend your web browser controls to accept or refuse cookies. As the means by
          which you can refuse cookies through your web browser controls vary from browser-tobrowser, you should visit
          your browser's help menu for more information.
        </p>
        <p>
          In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to
          find out more information, please visit{" "}
          <a href="http://www.aboutads.info/choices/" target="_blank" rel="nofollow">
            http://www.aboutads.info/choices
          </a>{" "}
          or{" "}
          <a href="http:// www.youronlinechoices.com" target="_blank" rel="nofollow">
            http:// www.youronlinechoices.com
          </a>
          .
        </p>
        <p>
          The specific types of first and third party cookies served through our Websites and the purposes they perform
          are described in the table below (please note that the specific cookies served may vary depending on the
          specific Online Properties you visit):
        </p>
        <br />

        <h2>Essential website cookies:</h2>
        <p>
          These cookies are strictly necessary to provide you with services available through our Websites and to use some
          of its features, such as access to secure areas.
        </p>
        <table className={`table ${styles.table}`}>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>__tlbcpv</td>
            </tr>
            <tr>
              <td>Purpose:</td>
              <td>Used to record unique visitor views of the consent banner</td>
            </tr>
            <tr>
              <td>Provider:</td>
              <td>.termly.io</td>
            </tr>
            <tr>
              <td>Service:</td>
              <td>
                Termly{" "}
                <a href="https://termly.io/our-privacy-policy/" target="_blank" rel="nofollow">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>Country:</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>http_cookie</td>
            </tr>
            <tr>
              <td>Expires in:</td>
              <td>1 year</td>
            </tr>
          </tbody>
        </table>
        <br />

        <h2>Analytics and customization cookies:</h2>
        <p>
          These cookies collect information that is used either in aggregate form to help us understand how our Websites
          are being used or how effective our marketing campaigns are, or to help us customize our Websites for you.
        </p>
        <table className={`table ${styles.table}`}>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>_ga</td>
            </tr>
            <tr>
              <td>Purpose:</td>
              <td>
                It records a particular ID used to come up with data about website usage by the user. It is a HTTP cookie
                that expires after 2 years.
              </td>
            </tr>
            <tr>
              <td>Provider:</td>
              <td>.ibench.in</td>
            </tr>
            <tr>
              <td>Service:</td>
              <td>
                Google Analytics{" "}
                <a href="https://policies.google.com/privacy" target="_blank" rel="nofollow">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>Country:</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>http_cookie</td>
            </tr>
            <tr>
              <td>Expires in:</td>
              <td>1 year 11 months 29 days</td>
            </tr>
            <tr>
              <td>Name:</td>
              <td>_gat#</td>
            </tr>
            <tr>
              <td>Purpose:</td>
              <td>
                Enables Google Analytics regulate the rate of requesting. It is a HTTP cookie type that lasts for a
                session.
              </td>
            </tr>
            <tr>
              <td>Provider:</td>
              <td>.ibench.in</td>
            </tr>
            <tr>
              <td>Service:</td>
              <td>
                Google Analytics{" "}
                <a href="https://policies.google.com/privacy" target="_blank" rel="nofollow">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>Country:</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>http_cookie</td>
            </tr>
            <tr>
              <td>Expires in:</td>
              <td>1 minute</td>
            </tr>
            <tr>
              <td>Name:</td>
              <td>697199352/</td>
            </tr>
            <tr>
              <td>Purpose:</td>
              <td>__________</td>
            </tr>
            <tr>
              <td>Provider:</td>
              <td>ibench.in</td>
            </tr>
            <tr>
              <td>Service:</td>
              <td>__________</td>
            </tr>
            <tr>
              <td>Country:</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>pixel_tracker</td>
            </tr>
            <tr>
              <td>Expires in:</td>
              <td>session</td>
            </tr>
            <tr>
              <td>Name:</td>
              <td>cavalry_endpoint.php</td>
            </tr>
            <tr>
              <td>Purpose:</td>
              <td>__________</td>
            </tr>
            <tr>
              <td>Provider:</td>
              <td>ibench.in</td>
            </tr>
            <tr>
              <td>Service:</td>
              <td>__________</td>
            </tr>
            <tr>
              <td>Country:</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>pixel_tracker</td>
            </tr>
            <tr>
              <td>Expires in:</td>
              <td>session</td>
            </tr>
            <tr>
              <td>Name:</td>
              <td>_gid</td>
            </tr>
            <tr>
              <td>Purpose:</td>
              <td>
                Keeps an entry of unique ID which is then used to come up with statistical data on website usage by
                visitors. It is a HTTP cookie type and expires after a browsing session.
              </td>
            </tr>
            <tr>
              <td>Provider:</td>
              <td>.ibench.in</td>
            </tr>
            <tr>
              <td>Service:</td>
              <td>
                Google Analytics{" "}
                <a href="https://policies.google.com/privacy" target="_blank" rel="nofollow">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>Country:</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>pixel_tracker</td>
            </tr>
            <tr>
              <td>Expires in:</td>
              <td>1 day</td>
            </tr>
          </tbody>
        </table>
        <br />

        <h2>Advertising cookies:</h2>
        <p>
          These cookies are used to make advertising messages more relevant to you. They perform functions like preventing
          the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some
          cases selecting advertisements that are based on your interests.
        </p>
        <table className={`table ${styles.table}`}>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>_fbp</td>
            </tr>
            <tr>
              <td>Purpose:</td>
              <td>Facebook tracking pixel used to identify visitors for personalized advertising.</td>
            </tr>
            <tr>
              <td>Provider:</td>
              <td>.ibench.in</td>
            </tr>
            <tr>
              <td>Service:</td>
              <td>
                Facebook{" "}
                <a href="https://www.facebook.com/privacy/explanation" target="_blank" rel="nofollow">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>Country:</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>http_cookie</td>
            </tr>
            <tr>
              <td>Expires in:</td>
              <td>2 months 29 days</td>
            </tr>
            <tr>
              <td>Name:</td>
              <td>test_cookie</td>
            </tr>
            <tr>
              <td>Purpose:</td>
              <td>A session cookie used to check if the user’s browser supports cookies.</td>
            </tr>
            <tr>
              <td>Provider:</td>
              <td>.doubleclick.net</td>
            </tr>
            <tr>
              <td>Service:</td>
              <td>
                DoubleClick{" "}
                <a href="https://policies.google.com/privacy" target="_blank" rel="nofollow">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>Country:</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>server_cookie</td>
            </tr>
            <tr>
              <td>Expires in:</td>
              <td>15 minutes</td>
            </tr>
            <tr>
              <td>Name:</td>
              <td>_gcl_au</td>
            </tr>
            <tr>
              <td>Purpose:</td>
              <td>
                Used by Google AdSense for experimenting with advertisement efficiency across websites using their
                services
              </td>
            </tr>
            <tr>
              <td>Provider:</td>
              <td>.ibench.in</td>
            </tr>
            <tr>
              <td>Service:</td>
              <td>
                AdSense{" "}
                <a href="https://policies.google.com/technologies/ads?hl=en" target="_blank" rel="nofollow">
                  View Service Privacy Policy
                </a>
              </td>
            </tr>
            <tr>
              <td>Country:</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>http_cookie</td>
            </tr>
            <tr>
              <td>Expires in:</td>
              <td>2 months 29 days</td>
            </tr>
          </tbody>
        </table>
        <br />

        <h2>Unclassified cookies:</h2>
        <p>
          These are cookies that have not yet been categorized. We are in the process of classifying these cookies with
          the help of their providers.
        </p>
        <table className={`table ${styles.table}`}>
          <tbody>
            <tr>
              <td>Name:</td>
              <td>__fb_chat_plugin</td>
            </tr>
            <tr>
              <td>Purpose:</td>
              <td>__________</td>
            </tr>
            <tr>
              <td>Provider:</td>
              <td>ibench.in</td>
            </tr>
            <tr>
              <td>Service:</td>
              <td>__________</td>
            </tr>
            <tr>
              <td>Country:</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>Type:</td>
              <td>html_local_storage</td>
            </tr>
            <tr>
              <td>Expires in:</td>
              <td>persistent</td>
            </tr>
          </tbody>
        </table>
        <br />

        <h2>What about other tracking technologies, like web beacons?</h2>
        <p>
          Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies
          from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs"). These are tiny
          graphics files that contain a unique identifier that enable us to recognize when someone has visited our
          Websites or opened an e-mail including them. This allows us, for example, to monitor the traffic patterns of
          users from one page within a website to another, to deliver or communicate with cookies, to understand whether
          you have come to the website from an online advertisement displayed on a third-party website, to improve site
          performance, and to measure the success of e-mail marketing campaigns. In many instances, these technologies are
          reliant on cookies to function properly, and so declining cookies will impair their functioning.
        </p>
        <br />

        <h2>Do you use Flash cookies or Local Shared Objects?</h2>
        <p>
          Websites may also use so-called "Flash Cookies" (also known as Local Shared Objects or "LSOs") to, among other
          things, collect and store information about your use of our services, fraud prevention and for other site
          operations.
        </p>
        <p>
          If you do not want Flash Cookies stored on your computer, you can adjust the settings of your Flash player to
          block Flash Cookies storage using the tools contained in the{" "}
          <a
            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
            target="_blank"
            rel="nofollow"
          >
            Website Storage Settings Panel
          </a>
          . You can also control Flash Cookies by going to the{" "}
          <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html" rel="nofollow">
            Global Storage Settings Panel
          </a>{" "}
          and following the instructions (which may include instructions that explain, for example, how to delete existing
          Flash Cookies (referred to "information" on the Macromedia site), how to prevent Flash LSOs from being placed on
          your computer without your being asked, and (for Flash Player 8 and later) how to block Flash Cookies that are
          not being delivered by the operator of the page you are on at the time).
        </p>
        <p>
          Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede
          the functionality of some Flash applications, including, potentially, Flash applications used in connection with
          our services or online content.
        </p>
        <br />

        <h2>Do you serve targeted advertising?</h2>
        <p>
          Third parties may serve cookies on your computer or mobile device to serve advertising through our Websites.
          These companies may use information about your visits to this and other websites in order to provide relevant
          advertisements about goods and services that you may be interested in. They may also employ technology that is
          used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web
          beacons to collect information about your visits to this and other sites in order to provide relevant
          advertisements about goods and services of potential interest to you. The information collected through this
          process does not enable us or them to identify your name, contact details or other details that directly
          identify you unless you choose to provide these.
        </p>
        <br />

        <h2>How often will you update this Cookie Policy?</h2>
        <p>
          We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we
          use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy
          regularly to stay informed about our use of cookies and related technologies.
        </p>
        <p>The date at the top of this Cookie Policy indicates when it was last updated.</p>
        <br />

        <h2>Where can I get further information?</h2>
        <p>
          If you have any questions about our use of cookies or other technologies, please email us at info@linenberger.uk or
          by post to:
          <br />
          LINENBERGER GROUP LTD
          <br />
          4-8 Ludgate Circus, London, England, EC4M 7LF
          <br />
          Phone: <a href="tel:+38263294789">(+382)63294789</a>
        </p>
      </div>
    </>
  );
};

export default CookiePolicy;
