import React from "react";

import { dataWasSaved } from "../../../utils/notifications";
import RelocationForm from "./RelocationForm";

const Relocation = (props) => {
  const { auth: { company_info }, editProfile } = props;

  const onSubmit = (formData) => {
    const userData = {
      degree: formData.degree,
      prize_winner: formData.prize_winner,
      tried_relocating: formData.tried_relocating,
      ready_to_relocate: formData.ready_to_relocate !== "false" && !!formData.ready_to_relocate,
      send_data_to_partners: formData.send_data_to_partners !== "false" && !!formData.send_data_to_partners,
      relocate_to_usa: formData.relocate_to_usa,
      relocate_to_sweden: formData.relocate_to_sweden,
      relocate_to_britain: formData.relocate_to_britain,
      url_redirect: formData.url_redirect,
    };

    if (company_info.profile_fill_step < 3) {
      userData.profile_fill_step = 3;
    }

    editProfile(userData, () => {
      if (!formData.url_redirect) {
        dataWasSaved();
      }
    });
  };

  return <RelocationForm onSubmit={onSubmit} />;
};

export default Relocation;
