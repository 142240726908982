import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { developersAPI } from "../../../api/developers";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import {
  showModal,
  switchEditMode,
  getCurrentSearch,
  getSearches,
  editSearch,
  deleteSearch,
} from "../../../store/search";
import countries_list from "../../../utils/countries_list";
import SearchEditing from "./SearchEditing";

const SearchEditingContainer = (props) => {
  const [jobTitlesList, setJobTitlesList] = useState(null);
  const [positionLevelsList, setPositionLevelsList] = useState(null);
  const [englishLevelsList, setEnglishLevelsList] = useState(null);

  useEffect(() => {
    developersAPI.getJobTitles().then((result) => setJobTitlesList(result));
    developersAPI.getPositionLevels().then((result) => setPositionLevelsList(result));
    developersAPI.getLanguageLevels().then((result) => setEnglishLevelsList(result));
  }, []);

  return (
    <SearchEditing
      {...props}
      jobTitlesList={jobTitlesList}
      positionLevelsList={positionLevelsList}
      skillsList={props.skillsList}
      englishLevelsList={englishLevelsList}
      countriesList={countries_list.map((item) => item.label)}
      showModal={props.showModal}
      switchEditMode={props.switchEditMode}
      getSearches={props.getSearches}
      editSearch={props.editSearch}
      deleteSearch={props.deleteSearch}
      companyInfo={props.companyInfo}
    />
  );
};

const mapStateToProps = (state) => ({
  search: state.search,
  skillsList: state.app.skills_list,
  companyInfo: state.auth.company_info,
});

const mapDispatchToProps = {
  showModal,
  switchEditMode,
  getCurrentSearch,
  getSearches,
  editSearch,
  deleteSearch,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthRedirect
)(SearchEditingContainer);
