import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { getFreelancerSearches } from "../../../store/search";
import FreelancerSlots from "./FreelancerSlots";

const FreelancerSlotsContainer = (props) => {
  useEffect(() => {
    props.getFreelancerSearches();
  }, []);

  return <FreelancerSlots {...props} />;
};

let mapStateToProps = (state) => ({
  search: state.search,
});

export default compose(
  connect(mapStateToProps, { getFreelancerSearches }),
  withAuthRedirect
)(FreelancerSlotsContainer);
