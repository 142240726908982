import { useEffect } from "react";
import { connect } from "react-redux";
import {
  addBookmark,
  setAddingToBookmark,
  editBookmark,
  getBookmarksList,
} from "../../../store/bookmarks";
import BookmarksListForm from "./BookmarksListForm";

const BookmarksListFormContainer = (props) => {
  useEffect(() => {
    props.getBookmarksList();
  }, []);

  return (
    <BookmarksListForm
      {...props}
      addBookmark={props.addBookmark}
      setAddingToBookmark={props.setAddingToBookmark}
      editBookmark={props.editBookmark}
    />
  );
};

let mapStateToProps = (state) => {
  return {
    bookmarkFormStatus: state.bookmarks.bookmarkFormStatus,
  };
};

export default connect(mapStateToProps, {
  getBookmarksList,
  addBookmark,
  setAddingToBookmark,
  editBookmark,
})(BookmarksListFormContainer);
