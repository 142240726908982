import { axiosInstances } from "./api";

const axiosInstance = axiosInstances.account;

export const countriesAPI = {
  getCountries() {
    return axiosInstance.get('api/users/countries').then((response) => {
      return response.data;
    });
  },

  getCitiesByCountryId(countryId) {
    return axiosInstance.get(`api/users/cities/${countryId}`).then((response) => {
      return response.data;
    });
  },
};
