import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import { getCandidateSearches } from "../../../store/candidates";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";

import styles from "./CandidateSlots.module.css";

const CandidateSlots = (props) => {
  const { getCandidateSearches, searches = [] } = props;

  useEffect(() => {
    getCandidateSearches();
  }, []);

  return (
    <div className={styles.wrapper}>
      <DashboardMenuContainer />

      <h1 className={`text_center ${styles.page_title}`}>
        Find talents
      </h1>

      <div className={`d_flex flex_wrap justify_content_center align_items_center`}>
        {searches.map((search) => (
          <NavLink
            key={search.id}
            to={`candidate-search/${search.id}`}
            className={cn(
              "d_flex",
              "flex_wrap",
              "justify_content_center",
              "align_items_center",
              styles.search_slot,
              styles.slot,
            )}
          >
            {search.name}
          </NavLink>
        ))}
        <NavLink
          to="/add-candidate-search"
          className={cn(
            "d_flex",
            "flex_wrap",
            "justify_content_center",
            "align_items_center",
            styles.free_slot,
            styles.slot,
            { [styles.slot_with_arrow]: !searches.length }
          )}
        >
          Add Search #{1 + searches.length}
        </NavLink>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  searches: state.candidates.searches,
});

const mapDispatchToProps = {
  getCandidateSearches,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSlots);
