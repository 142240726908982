import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { email, required } from "redux-form-validators";

import { siteNameForTitle, defaultDescription } from "../../constants/global-constants";
import { Input } from "../common/FormsControls/FormControls";
import Modal from "../common/Modal/Modal";
import Preloader from "../common/Preloader/Preloader";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import styles from "./PasswordRecovery.module.css";
import React from "react";

const PasswordRecoveryForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <Field
        component={Input}
        type={"email"}
        name={"email"}
        label="email"
        validate={[required(), email()]}
        help_text="for example: user@company.com"
      />
      {props.error && <div className={"validation_message"}>{props.error}</div>}

      <div className={styles.sign_in_link}>
        <NavLink to="/login">Log in</NavLink>
      </div>

      <div className={styles.submit_wrapper}>
        <button>Submit</button>
      </div>
    </form>
  );
};

let PasswordRecoveryFormReduxForm = reduxForm({ form: "passwordRecovery" })(PasswordRecoveryForm);

const PasswordRecovery = (props) => {
  const onSubmit = (form_data) => {
    props.recover(form_data.email);
  };

  return (
    <>
      <Breadcrumbs className={styles.breadcrumbs} currentPage="Recovery password" />
      <div className={styles.wrapper}>
        <Helmet>
          <title>{siteNameForTitle} / Recovery password</title>
          <meta
            name="description"
            content={defaultDescription}
          />
          <meta
            name="keywords"
            content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
            data-react-helmet="true"
          />
        </Helmet>

        {props.is_fetching && <Preloader/>}

        {props.show_modal ? (
          <Modal
            title={"Amazing!"}
            message={"A new password has been sent to your email address."}
            buttons={[{ btn_text: "Ok", btn_handler: props.hideModal }]}
          />
        ) : null}

        <h1 className={styles.title}>Recovery password</h1>
        <div className={styles.subtitle}>check your email in few minutes</div>

        <PasswordRecoveryFormReduxForm onSubmit={onSubmit}/>
      </div>
    </>
  );
};

export default PasswordRecovery;
