import React from "react";

import styles from "./Marketplace.module.css";

const MarketplaceNavigation = (props) => {
  const { pricePerChat = 1 } = props;
  return (
    <div className={styles.chatCost}>
      Chat with client = {pricePerChat} Pin from your wallet
    </div>
  );
};

export default MarketplaceNavigation;
