import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import cn from "classnames";

import Modal from "../common/Modal/Modal";
import WelcomeModal from "./WelcomeModal/WelcomeModal";

import styles from "./DashboardMenu.module.css";
import { isAfterNow } from "../../utils/utils";

const companyMenu = [
  {
    url: "/leads-marketplace",
    text: "Leads marketplace",
    counter: props => props.leadsCounter,
    onlySubscribed: true,
  },
  {
    url: "/sell-leads",
    text: "Sell leads",
    onlySubscribed: true,
  },
  {
    url: "/search-candidates",
    text: "Find talents (new!)",
    counter: props => props.candidateSearchCounter,
    onlySubscribed: true,
    // className: styles.findContractors,
  },
  {
    url: "/search-slots",
    text: "Find contractors",
    counter: props => props.searchCounter,
    onlySubscribed: true,
  },
  {
    url: "/marketplace",
    text: "Public marketplace",
    counter: props => props.marketplaceCounter,
    onlySubscribed: true,
  },
  {
    url: "/marketplace/projects",
    text: "Projects",
  },
  {
    url: "/chat",
    text: "Chat",
    counter: props => props.unreadChatsCounter,
    new: props => props.unreadChatsCounter,
    onlySubscribed: true,
  },
  // {
  //   url: "mailto:hello@ibench.in",
  //   text: "Support",
  //   external: true,
  // },
];

const clientMenu = [
  {
    url: "/search-candidates",
    text: "Find talents (new!)",
    counter: props => props.candidateSearchCounter,
    onlySubscribed: true,
    // className: styles.findContractors,
  },
  {
    url: "/search-slots",
    text: "Find contractors",
    counter: props => props.searchCounter,
    onlySubscribed: true,
  },
  {
    url: "/outsource",
    text: "Find IT companies",
  },
  {
    url: "/chat",
    text: "Chat",
    counter: props => props.unreadChatsCounter,
    new: props => props.unreadChatsCounter,
    onlySubscribed: true,
  },
  // {
  //   url: "/membership",
  //   text: "Membership",
  // },
  // {
  //   url: "mailto:hello@ibench.in",
  //   text: "Support",
  //   external: true,
  // },
];

const singleDeveloperMenu = [
  {
    url: "/marketplace/talents",
    text: "Open vacancies",
    counter: props => {
      if (!props.candidateSearchCounter) {
        return false;
      }
      return `${props.candidateSearchCounter}/+${props.newCandidateSearchCounter}`;
    },
  },
  {
    url: "/chat",
    text: "Chat with company",
    counter: props => props.unreadChatsCounter,
    new: props => props.unreadChatsCounter,
  },
  {
    url: "/profile",
    text: "Profile",
  },
];

const DashboardMenu = (props) => {
  const { companyInfo } = props;

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  useEffect(() => {
    if (localStorage.fireWelcome) {
      setShowWelcomeModal(true);
      localStorage.removeItem("fireWelcome");
    }
  }, [localStorage.fireWelcome]);

  const isSingleDeveloper = companyInfo?.is_single_developer;

  let menu;

  if (isSingleDeveloper) {
    menu = singleDeveloperMenu;
  } else if (companyInfo.type === 1) {
    menu = clientMenu;
  } else {
    menu = companyMenu;
  }

  // const isActiveSubscription = !isSingleDeveloper && isAfterNow(companyInfo?.stripe_subscription_expires);
  const isActiveSubscription = true;

  const renderMenuItem = (item, index) => {
    const newItems = item.new?.(props);
    const counter = item.counter?.(props);

    if (item.external) {
      return (
        <li key={`dashboardMenuItem-${index}`} className={styles.menuItem}>
          <a className={cn(styles.menuLink, item.className)} href={item.url}>
            {item.text}
          </a>
        </li>
      );
    }

    return (
      <li key={`dashboardMenuItem-${index}`} className={styles.menuItem}>
        <NavLink
          to={item.url}
          className={cn(styles.menuLink, item.className, { [styles.new]: newItems > 0 })}
          activeClassName={styles.active}
          disabled={item.onlySubscribed && !isActiveSubscription}
          exact
        >
          {item.text}
          {!!counter && (
            <span className={styles.counter}>
              {" "}
              ({item.text === "Chat" && "+"}
              {counter})
            </span>
          )}
        </NavLink>
      </li>
    );
  };

  return (
    <>
      <div className={styles.welcomeModal}>
        {showWelcomeModal && (
          <Modal
            showModal={setShowWelcomeModal}
            renderComponent={<WelcomeModal />}
            noBg
          />
        )}
      </div>
      <nav className={styles.menuWrapper}>
        <ul className={cn(styles.menu, { [styles.companyMenu]: !companyInfo?.is_single_developer && companyInfo.type === 2 })}>
          {menu.map(renderMenuItem)}
        </ul>
        {!!isSingleDeveloper && <Link to="/profile/edit/alerts" className={styles.alerts}>Alerts</Link> }
      </nav>
    </>
  );
};

export default DashboardMenu;
