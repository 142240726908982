import cn from "classnames";

import styles from "./ClientsStats.module.css";

const ClientsStats = props => {
  return (
    <div className={cn(styles.wrapper, props.className)}>
      <div className={styles.statsItem}>
        Companies: {props.stats?.companiesCount}
      </div>
      <div className={styles.statsItem}>
        Developer on the bench: {props.stats?.developersCount}
      </div>
      <div className={styles.statsItem}>
        Talents: {props.stats?.talentsCount}
      </div>
    </div>
  );
};

export default ClientsStats;
