import React from "react";
import cn from "classnames";

import styles from "./PaymentButton.module.css";

const PaymentButton = ({ disabled = false }) => {
  const userToken = JSON.parse(localStorage.getItem("user")).token;

  const handleSubmitSub = () => {
    // const stripe = window.Stripe("pk_test_vfyCrZ4dWUImEL7nBS4Yl0aA"); // test key
    const stripe = window.Stripe("pk_live_9W6EAwDD6jL6asP3NFoLryM2"); // real key
    stripe.redirectToCheckout({
      // lineItems: [{ price: "price_1LygADLOaM3ehlDRZzrBvv7v", quantity: 1 }], // real payment - 99 EURO
      lineItems: [{ price: "price_1M9XzxLOaM3ehlDReCY1cGOY", quantity: 1 }], // real payment - 99 EURO for 10 connects
      mode: "payment",
      successUrl: `https://ibench.net/payment/success?token=${userToken}`,
      cancelUrl: "https://ibench.net/payment/canceled",
    }).then(function (result) {
      if (result.error) {
        const displayError = document.getElementById("error-message");
        displayError.textContent = result.error.message;
      }
    });
  };

  return (
    <>
      <div className={styles.priceContainer}>
        <div className={styles.priceWrapper}>
          Connects
          <div className={styles.price}>9.9 USD</div>
          <div className={styles.subscriptionPerPeriod}>/ 10 pins</div>
        </div>

        <div className={styles.paymentService}/>
      </div>
      <div className={styles.buttonBlock}>
        <div className={cn(styles.buttonWrapper, 'button-wrapper')}>
          <a
            href="https://buy.stripe.com/4gw2bDeTR9HBalifZ7"
            className={`btn btn_green ${styles.payBtn}`}
            disabled={disabled}
            target="_blank"
            // role="link"
            // onClick={handleSubmitSub}
            // type="button"
          />
          <div className={styles.contactWith}>
            Contact with <a href="mailto:hello@ibench.in">hello@ibench.in</a>
          </div>
        </div>
        {/*<div className={styles.stripeInfo}>
          If your button is not working, click here{" "}
          <a href="https://buy.stripe.com/3cs9Br3za5oQbbW5kT" target="_blank" rel="nofollow">
            https://buy.stripe.com/3cs9Br3za5oQbbW5kT
          </a>{" "}
          and we will manually credit coins to your wallet.
        </div>*/}
      </div>
      <div className={styles.stripeError}>
        <div id="error-message" />
      </div>
    </>
  );
};

export default PaymentButton;
