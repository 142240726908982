import React from "react";
import moment from "moment";

import styles from "./LeadsMarketplace.module.css";

const DescriptionModal = (props) => {

  const getQualificationText = (qualificationLevel) => {
    switch (qualificationLevel) {
      case 'cold':
        return 'Cold';

      case 'warn':
        return 'Warn';

      default:
        return <span className={styles.hot}>Hot</span>
    }
  };

  const getLeadPriceText = (leadData) => {
    const priceParts = [];

    if (leadData.fixed_price) {
      priceParts.push(`$${leadData.fixed_price}`);
    }

    if (leadData.percentage) {
      priceParts.push(`${leadData.percentage}% of project price`);
    }

    return priceParts.join(' + ');
  };

  return (
    <div className={styles.descriptionModal}>
      <div className={styles.modalHeader}>
        Project description
      </div>
      <div className={styles.modalBody}>
        <div className={styles.fieldRow}>
          <span className={styles.bold}>Lead type: </span>
          {props.type === 'qualified' ? 'Qualified lead' : 'Unqualified lead'}
        </div>
        <div className={styles.fieldRow}>
          <span className={styles.bold}>Lead qualification level: </span>
          {getQualificationText(props.qualification_level)}
        </div>
        <div className={styles.fieldRow}>
          <span className={styles.bold}>Working under the white label: </span>
          {props.white_label === 'white label' ? 'Yes' : 'No'}
        </div>
        <div className={styles.fieldRow}>
          <span className={styles.bold}>Lead price: </span>
          {getLeadPriceText(props)}
        </div>
        {props.country && (
          <div className={styles.fieldRow}>
            <span className={styles.bold}>Lead country: </span>
            {props.country}
          </div>
        )}
        <div className={styles.aboutProject}>
          About project
        </div>
        <div className={styles.fieldRow}>
          <div>
            <span className={styles.bold}>Project type: </span>
            {props.project_type}
          </div>
          <div>
            <span className={styles.bold}>Project budget: </span>
            ${props.budget}
          </div>
          <div>
            <span className={styles.bold}>Project start: </span>
            {moment(props.start).format("DD MMM YYYY")}
          </div>
        </div>
        <div className={styles.fieldRow}>
          <span className={styles.bold}>
            Project title: {props.project_name}
          </span>
        </div>
        <div className={styles.fieldRow}>
          <div>Project description:</div>
          <div className={styles.projectDescription}>
            {props.project_description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionModal;
