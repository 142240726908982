import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { Table, Switch, Popover } from "antd";
import moment from "moment";

import { publicFilesUrl, siteNameForTitle } from "../../../constants/global-constants";
import ProfileMenu from "../../Profile/ProfileMenu";
import CompanyMenu from "../../Profile/CompanyMenu";
import PriceIndicatorContainer from "../../common/PriceIndicator/PriceIndicatorContainer";
import YoutubeButton from "../../common/YoutubeButton/YoutubeButton";

import defaultDeveloperPhoto from "../../../assets/img/man.svg";
import styles from "./CompanyBench.module.css";

const CompanyBench = (props) => {
  const { companyInfo, developers = [] } = props;

  const addToBench = async (developer_id) => {
    await props.editBenchStatus({
      on_bench: 1,
      developer_id,
    });
    await props.getDevelopers();
  };

  const deleteFromBench = async (developer_id) => {
    await props.editBenchStatus({
      on_bench: 0,
      developer_id,
    });
    await props.getDevelopers();
  };

  const columns = [
    {
      dataIndex: "photo",
      key: "photo",
      width: 96,
      render: (photo, record) => {
        return (
          <NavLink to={`/developer/${record.developer_id}`}>
            <img
              src={photo ? `${publicFilesUrl}public/img/${photo}` : defaultDeveloperPhoto}
              alt={record.name}
              className={styles.photo}
            />
          </NavLink>
        );
      },
    },
    {
      title: "Developer",
      dataIndex: "name",
      key: "name",
      render: (name, record) => {
        return (
          <NavLink to={`/developer/${record.developer_id}`} className={styles.developerLink}>
            <div className={styles.name}>{name}</div>
            <div>
              {record.position_level_name} {record.job_title_name}
            </div>
            {record.position_calculated === 1 && (
              <div className={styles.confirmed}>Confirmed the seniority level</div>
            )}
          </NavLink>
        );
      },
    },
    {
      title: "On bench",
      dataIndex: "on_bench_since",
      key: "on_bench_since",
      width: 140,
      render: (on_bench_since) => {
        const onBenchDuration = moment().diff(moment(on_bench_since), "days");
        return (
          <>
            <div>{moment(on_bench_since).format("DD/MM/YYYY")}</div>
            <div>
              {onBenchDuration} day{(onBenchDuration > 1 || onBenchDuration === 0) && "s"}
            </div>
          </>
        );
      },
      sorter: (a, b) => moment(a.on_bench_since).unix() - moment(b.on_bench_since).unix()
    },
  ];

  if (!companyInfo.isSingleDeveloper) {
    columns.push({
      title: "Rate, $/h",
      dataIndex: "rate",
      key: "rate",
      width: 140,
      render: (rate, record) => {
        return (
          <>
            <div className={styles.rate}>{rate}$/h</div>
            <PriceIndicatorContainer rate={rate} position_level_id={record.position_level_id}/>
          </>
        );
      },
      sorter: (a, b) => a.rate - b.rate,
    });
  }

  columns.push(
    {
      title: "English level",
      dataIndex: "english_level_name",
      key: "english_level_name",
      render: (english_level_name, record) => `${english_level_name} (${record.cerf_level})`,
    },
    {
      title: "CTR, %",
      dataIndex: "search_clicked",
      key: "search_clicked",
      width: 140,
      render: (search_clicked, record) => {
        const percent =
          record.search_selected > 0 ? ((search_clicked / record.search_selected) * 100).toFixed(2) : null;
        return (
          <>
            <span>{search_clicked}</span>/<span>{record.search_selected}</span>
            {percent && ` (${percent}%)`}
          </>
        );
      },
      sorter: (a, b) => {
        const aPercent =
          a.search_selected > 0 ? ((a.search_clicked / a.search_selected) * 100).toFixed(2) : null;
        const bPercent =
          b.search_selected > 0 ? ((b.search_clicked / b.search_selected) * 100).toFixed(2) : null;
        return aPercent - bPercent;
      },
    },
    {
      title: "Action",
      key: "operation",
      width: 96,
      render: (record) => {
        const updateBenchStatus = async (status) => {
          if (status) {
            await addToBench(record.developer_id);
          } else {
            await deleteFromBench(record.developer_id);
          }
        };
        return record.projects.length ? (
          <Switch
            loading={props.is_fetching}
            checkedChildren="On"
            unCheckedChildren="Off"
            defaultChecked={record.on_bench === 1 ? true : false}
            onChange={(status) => updateBenchStatus(status)}
          />
        ) : (
          <Popover
            content={
              "Developers without any projects can't be showed. Please open the edit mode, and add projects."
            }
          >
            <Switch disabled={true} checkedChildren="On" unCheckedChildren="Off" defaultChecked={false}/>
          </Popover>
        );
      },
    }
  );

  console.log('===developers', developers);

  let dashboardMessage = "";
  if (developers.some((developer) => moment().diff(moment(developer.developer_updated), "days") > 7)) {
    dashboardMessage =
      "Important: information on your developers has not been updated for a long time. This affects the search results.";
  }

  const renderClickMessage = () => {
    if (companyInfo.isSingleDeveloper) {
      return (
        <>
          Click to <strong>Add my profile</strong>
        </>
      );
    }

    return (
      <>
        Click on an empty slot
        <br/> to fill the developers CV
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>My developers | {siteNameForTitle}</title>
      </Helmet>

      <h2 className={`${styles.title} text_center`}>
        Company profile
      </h2>

      <ProfileMenu />

      <CompanyMenu companyInfo={companyInfo} />

      <h1 className={styles.pageTitle}>
        {companyInfo.isSingleDeveloper ? 'My information' : 'My developers'}
      </h1>

      {dashboardMessage && <div className={styles.dashboardMessage}>{dashboardMessage}</div>}

      <Table dataSource={[...developers]} columns={columns} pagination={false}/>

      {(!companyInfo.isSingleDeveloper || developers.length < 3) && (
        <div className={`${styles.free_slot} ${!developers.length && styles.slot_with_arrow}`}>
          <NavLink to="/add-developer" className={styles.free_slot_link}>
            {companyInfo.isSingleDeveloper ? 'Add my profile' : `Add Developer #${developers.length + 1}`}
          </NavLink>
        </div>
      )}

      <div className={styles.tip}>
        {renderClickMessage()}
      </div>
      <div className={styles.youtubeWrapper}>
        {!!companyInfo.isSingleDeveloper && <YoutubeButton src="https://www.youtube.com/embed/Kc_F7fahOO8"/>}
        {/*{!companyInfo.isSingleDeveloper && <YoutubeButton src="https://www.youtube.com/embed/Kc_F7fahOO8"/>}*/}
      </div>
    </div>
  );
};

export default CompanyBench;
