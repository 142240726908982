import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";

import { hire } from "../../store/chat";
import { buildImageLink } from "../../utils/utils";

import default_profile_photo from "../../assets/img/boy.svg";
import styles from "./TalentDetails.module.css";

const TalentCard = (props) => {
  const {
    talentData: {
      id,
      first_name,
      last_name,
      representative_photo,
      company_name,
      country,
      description,
      open_to_work,
      monthly_rate,
      job_title_name,
      additional_job_title_name,
      position_level_name,
      years_experience,
      languages,
      updated,
      start_in,
      project_areas,
    },
    profile,
    companyInfo,
    hire,
    withLink,
  } = props;

  const ASAP = start_in === 'ASAP';

  const blockChat = !open_to_work || !companyInfo || companyInfo.id === id || companyInfo.is_single_developer;

  const chatWithMe = () => {
    if (blockChat) {
      return;
    }


    const chatId = `${id}:${companyInfo.id}`;
    const message = `Hey! We are ${companyInfo.company_name}, 
      and we would like to chat with you about our open position!`;

    hire(message, chatId);
  };

  const getLanguagesString = () => {
    const lastIndex = languages.length - 1;

    return languages?.map((language, index) => {
      return (
        <>
          <span className={styles.blueFont}>
            {language.language}
          </span>: {language.cerf_level} ({language.english_level_name})
          {index < lastIndex && ', '}
        </>
      );
    });
  };

  const renderAdditionalInfo = () => (
    <div className={styles.additionalInfo}>
      <div className={styles.updated}>
        Update: {moment(updated).format('DD MMM YYYY')}
      </div>
      <div className={cn(styles.startIn, { [styles.asap]: ASAP })}>
        Start on new job: {start_in}
      </div>
    </div>
  );

  const renderName = () => {
    const name = `${first_name} ${last_name[0].toUpperCase()}.`;

    if (withLink) {
      return (
        <Link to={`/talent/${id}`} className={styles.name}>
          {name}
        </Link>
      );
    }

    return (
      <div className={styles.name}>
        {name}
      </div>
    );
  };

  return (
    <div className={cn(styles.flex, { [styles.profile]: profile })}>
      <div className={styles.leftPart}>
        <img
          className={styles.photo}
          src={representative_photo ? buildImageLink(representative_photo) : default_profile_photo}
          alt={company_name}
        />
        <div className={cn(styles.openToWork, { [styles.disabled]: !open_to_work })}>
          #OpenToWork
        </div>
        {!blockChat && (
          <div
            className={cn(styles.chatButton, { [styles.disabled]: blockChat })}
            onClick={chatWithMe}
          >
            Chat with me
          </div>
        )}
        <div className={cn(styles.rate, { [styles.yellowBackground]: ASAP })}>
          {monthly_rate ? `$${monthly_rate}` : 'contract'}
        </div>
      </div>
      <div className={styles.rightPart}>
        <div className={styles.cardTopPart}>
          <div className={styles.mainData}>
            <div className={styles.mainLeftPart}>
              {renderName()}
              {profile && renderAdditionalInfo()}
              <div className={styles.jobTitle}>
                {position_level_name} {job_title_name}
              </div>
              <div className={styles.additionalJobTitle}>
                {additional_job_title_name}
              </div>
            </div>
            <div className={cn(styles.rate, { [styles.yellowBackground]: ASAP })}>
              {monthly_rate ? `$${monthly_rate}` : 'contract'}
            </div>
          </div>

          <div className={styles.experienceData}>
            <div>
                <span className={styles.blueFont}>
                  Experience:
                </span> {years_experience}+ years,
              <span className={styles.blueFont}> {project_areas?.map(area => area.name).join(', ')}</span>
            </div>
            <div>
              <span className={styles.blueFont}>
                Location:
              </span> {country}
            </div>
            <div>
              {getLanguagesString()}
            </div>
          </div>
          <div className={styles.description}>
            {description}
          </div>
        </div>
        {!profile && renderAdditionalInfo()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  companyInfo: state.auth?.company_info,
});

const mapDispatchToProps = {
  hire,
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentCard);
