import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { email, required } from "redux-form-validators";
import cn from "classnames";

import { getCompanyData } from "../../../store/auth";
import india_countries_list from "../../../utils/india_countries_list";
import { validatePositiveInt } from "../../../utils/validation";
import { teamSizes, foundedYears } from "../../../constants/global-constants";
import { Input, SelectInput, SelectInputKeyValue } from "../../common/FormsControls/FormControls";
import UploadImage from "../../common/UploadImage/UploadImage";
import UploadImageButton from "../../common/UploadImageButton/UploadImageButton";
import Editor from "../../common/SimpleEditor/SimpleEditor";
import LogosList from "../LogosList/LogosList";
import CopyLink from "../../common/CopyLink/CopyLink";

import styles from "./ProfileEditing.module.css";

const formName = "baseInfoForm";

const BaseInfoForm = (props) => {
  const {
    isCompany,
    newMarket,
    awardName,
    awardLogo,
    marketsList,
    handleSubmit,
    error,
    dispatch,
    initialValues,
  } = props;

  const [markets, setMarkets] = useState([]);
  const [awards, setAwards] = useState([]);

  useEffect(() => {
    if (initialValues?.marketList?.length) {
      const initialMarkets = [];

      initialValues.marketList.forEach((market) => {
        initialMarkets.push(market);
      });

      setMarkets(initialMarkets);
    }

    if (initialValues?.awards?.length) {
      const initialAwards = [];

      initialValues.awards.forEach((award) => {
        initialAwards.push(award);
      });

      setAwards(initialAwards);
    }
  }, []);

  const rateToValidate = useCallback((value, props) => {
    if (value) {
      if (value <= props.rate_from) {
        return 'must be more than Rate from'
      }
    } else if (props.rate_from) {
      return 'you need enter Rate to';
    }
  }, []);

  const rateFromValidate = useCallback((value, props) => {
    if (props.rate_to && !value) {
      return 'you need enter Rate from';
    }
  }, []);

  const countryList = india_countries_list.map(item => item.label);

  const rebuildMarketFields = (newMarketsArray) => {
    setMarkets(newMarketsArray);
    dispatch(change(formName, 'markets', null));

    newMarketsArray.forEach((market, index) => {
      const fieldName = `markets[${index}]`;
      dispatch(change(formName, fieldName, market.market_id));
    });
  };

  const addMarket = () => {
    const marketId = +newMarket;
    if (!marketId) {
      return;
    }

    const marketData = marketsList.find(market => market.market_id === marketId);

    const marketIndex = markets.findIndex(market => market.market_id === marketId);

    if (~marketIndex) {
      removeMarket(marketIndex);
    }

    const newMarketsArray = [marketData, ...markets];
    rebuildMarketFields(newMarketsArray);

    dispatch(change(formName, "new_market", ""));
  };

  const removeMarket = (index) => {
    markets.splice(index, 1);
    rebuildMarketFields([...markets]);
  };

  const rebuildAwardFields = (newAwardsArray) => {
    setAwards(newAwardsArray);
    dispatch(change(formName, 'awards', null));

    newAwardsArray.forEach((award, index) => {
      const fieldName = `awards[${index}]`;
      dispatch(change(formName, fieldName, award));
    });
  };

  const addAward = () => {
    if (!awardName || !awardLogo) {
      return;
    }

    const awardData = {
      name: awardName,
      logo: awardLogo,
    };

    const newAwardsArray = [awardData, ...awards];
    rebuildAwardFields(newAwardsArray);

    dispatch(change(formName, "award_name", ""));
    dispatch(change(formName, "award_logo", null));
  };

  const removeAward = (index) => {
    awards.splice(index, 1);
    rebuildAwardFields([...awards]);
  };

  const companyLink = `/company/${initialValues.id}`;

  return (
    <form onSubmit={handleSubmit} className={styles.base_info_form}>
      <div className={styles.fields_wrapper}>
        <div className={styles.field_label}>
          Representative person
          <div className={styles.copy_link_wrapper}>
            <Link to={companyLink} className={styles.view_page}>View page</Link>
            <CopyLink
              link={companyLink}
              message="Company link has been copied to clipboard"
            />
          </div>
        </div>
        <div className={cn(styles.fields_section, styles.representative_section)}>
          <div className={cn(styles.upload_photo_wrapper, styles.left_column)}>
            <Field
              component={UploadImage}
              name="representative_photo"
              label="Upload Photo"
              mode="photo"
            />
          </div>
          <div className={styles.fields_row}>
            <Field
              className={styles.input}
              component={Input}
              type="text"
              name="representative_name"
              label="Name"
              validate={[required()]}
            />
            <Field
              className={styles.input}
              component={Input}
              type="text"
              name="position"
              label="Position"
              validate={[required()]}
              help_text="for example: Founder"
              help_text_design={styles.help_text}
            />
          </div>
        </div>
        <div className={cn(styles.field_label, styles.company_info)}>Company info</div>
        <div className={cn(styles.fields_section, styles.company_info_section)}>
          <div className={cn(styles.upload_photo_wrapper, styles.left_column)}>
            <Field
              component={UploadImage}
              name="logo"
              label="Upload logo"
              mode="photo"
            />
          </div>
          <div className={styles.company_info_fields}>
            <div className={styles.fields_row}>
              <Field
                className={styles.input}
                component={Input}
                type="text"
                name="company_name"
                label="Company name"
                validate={[required()]}
              />
              <div className={styles.website_wrapper}>
                <Field
                  className={styles.input}
                  component={Input}
                  type="text"
                  name="site"
                  label="Website"
                />
              </div>
            </div>
            <div className={styles.fields_row}>
              <Field
                className={styles.input}
                component={Input}
                name="official_email"
                label="Official email"
                validate={[email()]}
              />
              <Field
                className={styles.input}
                component={Input}
                type="text"
                name="business_phone"
                label="Phone"
              />

            </div>
            <div className={styles.fields_row}>
              <Field
                className={styles.input}
                component={Input}
                name="backup_email"
                label="Backup email"
                validate={[required(), email()]}
              />

            </div>
            {isCompany && (
              <>
                <div className={styles.fields_row}>
                  <Field
                    input_wrapper_class={cn(styles.select_wrapper, styles.short_select)}
                    component={SelectInput}
                    name="founded"
                    label="Founded"
                    options={foundedYears}
                  />
                  <Field
                    input_wrapper_class={cn(styles.select_wrapper, styles.short_select)}
                    component={SelectInput}
                    name="team_size"
                    label="Team"
                    options={teamSizes}
                  />
                </div>
                <div className={cn(styles.fields_row, styles.rates_row)}>
                  <Field
                    className={cn(styles.input, styles.short_input)}
                    component={Input}
                    name="rate_from"
                    label="Rate from"
                    validate={[validatePositiveInt, rateFromValidate]}
                  />
                  <Field
                    className={cn(styles.input, styles.short_input)}
                    component={Input}
                    name="rate_to"
                    label="Rate to"
                    validate={[validatePositiveInt, rateToValidate]}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.location_section}>
          <div className={cn(styles.left_column, styles.field_label)}>Location</div>
          <div>
            <div className={styles.fields_row}>
              <Field
                input_wrapper_class={styles.select_wrapper}
                component={SelectInput}
                name={"country"}
                label="Country"
                options={countryList}
                validate={[required()]}
              />
              <Field
                className={styles.input}
                component={Input}
                type="text"
                name="city"
                label="City"
              />
            </div>
            <div className={styles.fields_row}>
              <Field
                input_wrapper_class={styles.full_width}
                className={styles.input}
                component={Input}
                type="text"
                name="address"
                label="Address"
              />
            </div>
          </div>
        </div>
        <div className={cn(styles.about_section)}>
          <div className={cn(styles.left_column, styles.field_label)}>About</div>
          <div className={cn(styles.fields_row, styles.editor_wrapper)}>
            <Field
              component={Editor}
              name={"description"}
              validate={[required()]}
            />
          </div>
        </div>
        <div className={cn(styles.industry_section)}>
          <div className={cn(styles.left_column, styles.field_label)}>
            Areas<br />
            of experience
          </div>
          <div className={cn(styles.fields_row, styles.industry_wrapper)}>
            <div className={styles.industry_select_wrapper}>
              <Field
                input_wrapper_class={styles.select_wrapper}
                component={SelectInputKeyValue}
                label="Industry"
                name="new_market"
                options={marketsList}
              />
              <button
                className={styles.button}
                onClick={addMarket}
                type="button"
              >
                Add new
              </button>
            </div>
            {markets.map((market, index) => (
              <div className={styles.industry_item_row} key={index}>
                <div className={styles.industry_item}>{market.market_name}</div>
                <div className={styles.delete_industry} onClick={() => removeMarket(index)}>delete</div>
              </div>
            ))}
          </div>
        </div>
        {isCompany && (
          <div className={cn(styles.awards_section)}>
            <div className={cn(styles.left_column, styles.field_label)}>
              Awards
            </div>
            <div className={cn(styles.fields_row, styles.awards_wrapper)}>
              <div className={styles.awards_fields_wrapper}>
                <Field
                  className={styles.input}
                  component={Input}
                  name="award_name"
                  label="Name"
                />
                <Field
                  component={UploadImageButton}
                  name="award_logo"
                  label="Upload logo"
                  mode="photo"
                />
                <button
                  className={styles.grey_button}
                  type="button"
                  onClick={addAward}
                >
                  Add new award
                </button>
              </div>

              {!!awards.length && (
                <>
                  <div className={styles.awards_items}>
                    {awards.map((award, index) => (
                      <div className={styles.industry_item_row} key={index}>
                        <div className={styles.industry_item}>{award.name}</div>
                        <div className={styles.delete_industry} onClick={() => removeAward(index)}>delete</div>
                      </div>
                    ))}
                  </div>

                  <div className={styles.award_logos}>
                    <LogosList items={awards}/>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {error && (
          <div className={`${styles.form_errors} text_center validation_message`}>
            {error}
          </div>
        )}

        <div className={styles.submit_wrapper}>
          <button className={`btn ${styles.submit}`}>Save</button>
        </div>
      </div>

    </form>
  );
};


const selector = formValueSelector(formName);

let BaseInfoFormReduxForm = reduxForm({
  form: formName,
  enableReinitialize: true,
})(BaseInfoForm);

const mapStateToProps = (state) => {
  const rateParts = state.auth.company_info?.rates?.split('-');
  const rate_from = rateParts?.[0];
  const rate_to = rateParts?.[1];

  return {
    initialValues: {
    ...state.auth.company_info,
      rate_from,
      rate_to,
      markets: state.auth.company_info?.marketList?.map(market => market.market_id) || [],
    },
    newMarket: selector(state, 'new_market'),
    awardName: selector(state, 'award_name'),
    awardLogo: selector(state, 'award_logo'),
  }
};

const mapDispatchToProps = { load: getCompanyData }; // bind account loading action creator

export default connect(mapStateToProps, mapDispatchToProps)(BaseInfoFormReduxForm);