import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { editPassword } from "../../../store/auth";
import PasswordEditing from "./PasswordEditing";

const PasswordEditingContainer = (props) => {
  return <PasswordEditing {...props} editPassword={props.editPassword} />;
};

let mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default compose(
  connect(mapStateToProps, {
    editPassword,
  }),
  withAuthRedirect
)(PasswordEditingContainer);
