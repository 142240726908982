import React from "react";
import { connect } from "react-redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import cn from "classnames";

import { Textarea } from "../../common/FormsControls/FormControls";
import { renderMultiselect } from "../../common/FormsControls/FormControls";
import FormIndicator from "./FormIndicator/FormIndicator";

import styles from "./TeamSearch.module.css";

const TeamSearchForm5 = (props) => {
  let markets = "";
  props.markets &&
    props.markets.forEach((market) => {
      markets += `${market.market_name}, `;
    });
  markets = markets.slice(0, -2); // remove last comma

  let skills = "";
  props.skills &&
    props.skills.forEach((skill) => {
      skills += `${skill.skill_name}, `;
    });
  skills = skills.slice(0, -2); // remove last comma

  const rentDurationOptions = ["1 month", "2 month", "3 month", "6 month", "1 year"];

  const addToSearch = (rentDuration) => {
    props.dispatch(change("team-search", "rentDuration", [rentDuration]));
  };

  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>

        <FormIndicator page={props.page} />

        <div className={styles.multiselect_wrapper}>
          <Field
            name="rentDuration"
            component={renderMultiselect}
            validate={[required()]}
            data={rentDurationOptions}
            placeholder="How long do you need the developer?"
          />
        </div>

        <div className={styles.popular_searches}>
          <div className={styles.popular_searches_label}>Popular searches:</div>
          <div className={styles.popular_searches_list}>
            <div className={styles.popular_searches_item} onClick={() => addToSearch("1 month")}>
              1 month
            </div>
            <div className={styles.popular_searches_item} onClick={() => addToSearch("2 month")}>
              2 month
            </div>
            <div className={styles.popular_searches_item} onClick={() => addToSearch("3 month")}>
              3 month
            </div>
            <div className={styles.popular_searches_item} onClick={() => addToSearch("6 month")}>
              6 month
            </div>
            <div className={styles.popular_searches_item} onClick={() => addToSearch("1 year")}>
              1 year
            </div>
          </div>
        </div>

        <Field
          component={Textarea}
          name={"productSpecification"}
          input_wrapper_class="form_control_2_wrapper"
          className="form_control_2"
          rows={5}
          validate={[required()]}
          placeholder="Do you have product specifications ready?"
        />
      </div>

      <div className={`${styles.submit_wrapper} text_center`}>
        <button className={cn(styles.submit, "btn_2", "btn_purple", "mobile_hidden")}>Next step</button>
        <button className={cn(styles.submit, "btn_2", "btn_purple", "mobile_visible")}>Next</button>
      </div>

      {props.error && <div className={`${styles.form_erros} text_center validation_message`}>{props.error}</div>}
    </form>
  );
};

let ReduxForm = reduxForm({
  form: "team-search",
  destroyOnUnmount: false,
})(TeamSearchForm5);

const formSelector = formValueSelector("team-search");
ReduxForm = connect((state) => {
  const markets = formSelector(state, "markets");
  const skills = formSelector(state, "skills");
  const monthBudget = formSelector(state, "monthBudget");
  const rentDuration = formSelector(state, "rentDuration");
  return { markets, skills, monthBudget, rentDuration };
})(ReduxForm);

export default ReduxForm;
