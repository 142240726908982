import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import { Button, Modal as AntModal } from "antd";
import moment from "moment";
import cn from "classnames";

import { siteNameForTitle, defaultDescription } from "../../constants";
import { history } from "../../utils";
import {printLeadPrice} from "../../utils/utils";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import WalletModal from "../Payment/WalletModal/WalletModal";
import ChatWithClientButton from "../LeadsMarketplace/ChatWithClientButton/ChatWithClientButton";
import Modal from "../common/Modal/Modal";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import styles from "./LeadDetails.module.css";

const LeadDetails = ({ leadData, auth }) => {

  const [showWalletModal, setShowWalletModal] = useState(false);
  const isCompany = auth.company_info?.type === 2 && !auth.company_info?.is_single_developer;

  useEffect(() => {
    if (localStorage.getItem('redirect_after_login')) {
      localStorage.removeItem('redirect_after_login');
      if (!isCompany) {
        showWarningModal();
      }
    }
  }, []);

  const showWarningModal = () => {
    AntModal.info({
      title: <div className={styles.warningModalTitle}>Warning</div>,
      icon: null,
      content: (
        <div className={styles.warningModal}>
          <p>
            Only companies are allowed to purchase leads, please register as a company
          </p>
          <Button className={styles.hideWarningModal} key="submit" type="primary" onClick={AntModal.destroyAll}>
            Close
          </Button>
        </div>
      ),
      width: 600,
      okButtonProps: { style: { display: "none" } },
    });
  };

  const chatWithClientHandler = (chatWithClient) => {
    if (!auth.is_auth) {
      localStorage.setItem('redirect_after_login', window.location.pathname);
      return history.push('/login');
    }

    if (auth.company_info?.type !== 2 || auth.company_info?.is_single_developer) {
      return showWarningModal();
    }

    chatWithClient();
  };

  const renderWalletModal = () => {
    if (showWalletModal) {
      return (
        <Modal
          showModal={setShowWalletModal}
          renderComponent={<WalletModal closeModal={() => setShowWalletModal(false)} />}
          noBg
        />
      )
    }

    return null;
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>{siteNameForTitle} / Lead page</title>
        <meta
          name="description"
          content={defaultDescription}
        />
        <meta
          name="keywords"
          content="Remote Development Teams, Web Development, App Development, MVP Development, Startups, iBench, Agile Development, Tech Startups, Web & App Solutions for Startups"
          data-react-helmet="true"
        />
      </Helmet>
      {!auth.is_auth && <Breadcrumbs currentPage="Lead page" />}

      <div className={styles.walletModal}>
        {renderWalletModal()}
      </div>

      {auth.is_auth && <DashboardMenuContainer />}

      <h1 className={`text_center ${styles.pageTitle}`}>Lead Page</h1>

      <div className={styles.chatCost}>
        Chat with client = 2 Pin from your wallet
      </div>

      {leadData.id && (
        <div className={styles.leadDetailsBlock}>
          <div className={styles.publishDate}>
            <div>
              Publish date: {moment(leadData.created).format("DD MMM YYYY")}
            </div>
            <div>
              Start project: {moment(leadData.start).format("DD MMM YYYY")}
            </div>
          </div>
          <div className={styles.mainData}>
            <div className={styles.budgetBlock}>
              <div>Budget: ${leadData.budget}</div>
              <div className={cn(styles.leadPrice, { [styles.hotLead]: leadData.qualification_level === 'hot'})}>
                Lead Price: {printLeadPrice(leadData.fixed_price, leadData.percentage)}
              </div>
            </div>
            <div className={styles.details}>
              <div>
                Lead country: {leadData.country}
              </div>
              <div>
                Lead type:&nbsp;
                <span>
                {leadData.type === 'qualified' ? 'Qualified lead' : 'Unqualified lead'}
              </span>
              </div>
              <div>
                Working under white label:&nbsp;
                <span>{leadData.white_label === 'white label' ? 'Yes' : 'No'}</span>
              </div>
              <div>
                Buyers: {leadData.buyers}
              </div>
              <div>
                Lead qualification level:&nbsp;
                <span className={styles.qualificationLevel}>{leadData.qualification_level}</span>
              </div>
            </div>
          </div>
          <div className={styles.otherDetails}>
            <div className={styles.title}>
              <span className={styles.bold}>Title:</span> {leadData.project_name}
            </div>
            <div className={styles.title}>
              <span className={styles.bold}>Project type:</span> {leadData.project_type}
            </div>
            <div className={styles.description}>
              {leadData.project_description}
            </div>
            <ChatWithClientButton
              leadData={leadData}
              errorHandler={() => setShowWalletModal(true)}
              className={styles.chatWithClient}
              wrapperHandler={chatWithClientHandler}
            />
          </div>
        </div>
      )}
      {!leadData.id && <div className={styles.noSuchLead}>There is no such lead</div>}
    </div>
  );
};

export default LeadDetails;
