import React from "react";

import styles from "../ProfileEditing/ProfileEditing.module.css"

const SettingsModal = (props) => {
  return (
    <div className={styles.settingsModal}>
      <div className={styles.modalHeader}>
        Settings
      </div>
      <div className={styles.modalBody}>
        The new settings have been saved successfully. The settings will be applied within the next 24 hours.
        <button className={styles.modalButton} onClick={props.onSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default SettingsModal;
