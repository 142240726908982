import { bannersAPI } from "../api/banners";

const SET_BANNERS_LIST = "ibench/banners/SET_BANNERS_LIST";

let initialState = {
  banners: [],
};

const banners = (state = initialState, action) => {
  switch (action.type) {
    case SET_BANNERS_LIST:
      return { ...state, banners: action.payload };

    default:
      return state;
  }
};

// GET OUTSOURCE SEARCH LIST
const setBannersList = payload => ({ type: SET_BANNERS_LIST, payload });

export const getBannersList = () => (dispatch) => {
  bannersAPI.getBanners().then((data) => {
    dispatch(setBannersList(data.banners));
  });
};

export const clickToBanner = (id) => () => {
  bannersAPI.clickToBanner(id);
};

export default banners;
