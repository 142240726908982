import io from "socket.io-client";
import { socketServiceApiUrl } from "../constants/global-constants";


// socket.on("error", function(err) {
//   console.log("Connection Failed: " + err);
// });

const socket = io.connect(socketServiceApiUrl, { transports: ["websocket"] });
// const socket = io.connect('http://localhost:5000/', { transports: ["websocket"] });

export const socketRegister = (id, name, user_type) => {

  socket.on("connect", () => {
    console.log("- - - Socket connected status: ", socket.connected);
  });

  let registerObj = {
    id,
    name,
    user_type,
  };

  socket.emit("register", JSON.stringify(registerObj));

  socket.on("disconnect", () => {
    console.log("Got disconnect!");
    socket.emit("register", JSON.stringify(registerObj));
  });
};

export const chatListener = (callback) => {
  socket.on("chat_message", function (data) {
    data = JSON.parse(data);
    console.log("Message received: ", data);
    callback(data.chat_id);
  });
};

export const chatReadListener = (callback) => {
  socket.on("chat_read", function (data) {
    data = JSON.parse(data);
    console.log("Chat read: ", data);
    callback(data.chat_id);
  });
};

export const onlineUsersListener = (callback) => {
  socket.on("online_users", function (data) {
    data = JSON.parse(data);
    callback(data);
  });
};

export const socketSendMessage = (to, chat_id, m) => {
  let chatMsg = {
    to,
    chat_id,
    m,
  };
  console.log("Message send: ", chatMsg);
  socket.emit("chat_message", JSON.stringify(chatMsg));
};

export const socketTyping = (chat_id) => {
  socket.emit("chat_typing", chat_id);
  console.log(chat_id);
};

export const socketRead = (data) => {
  console.log('chat_read: ', data);
  socket.emit("chat_read", JSON.stringify(data));
};

export const getOnlineUsers = (data) => {
  console.log('get_online_users: ', data);
  socket.emit("get_online_users", JSON.stringify(data));

};

socket.on("chat_typing", function (data) {
  console.log(data);
});
