import React from "react";
import { connect } from "react-redux";

import { trialSearch } from "../../../store/search";
import { sendTeamRequest } from "../../../store/app";
import TeamSearch from "./TeamSearch";

const TeamSearchContainer = (props) => {
  return <TeamSearch {...props} trialSearch={props.trialSearch} sendTeamRequest={props.sendTeamRequest} />;
};

let mapStateToProps = (state) => ({
  app: state.app,
  is_fetching: state.search.is_fetching,
  trial_search: state.search.trial_search,
});

export default connect(mapStateToProps, { trialSearch, sendTeamRequest })(TeamSearchContainer);
