import React, {useEffect} from "react";
import { connect } from "react-redux";

import LeadDetails from "./LeadDetails";
import {getLeadById} from "../../store/lead";

const LeadDetailsContainer = (props) => {

  useEffect(() => {
    props.getLeadById(props.match.params.lead_id);
  }, []);

  return (
    <LeadDetails
      auth={props.auth}
      leadData={props.leadData}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  leadData: state.lead.current_lead,
});

export default connect(mapStateToProps, {
  getLeadById,
})(LeadDetailsContainer);
