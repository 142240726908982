import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { editClients } from "../../../store/auth";
import ProfileClientsEditing from "./ProfileClientsEditing";

const ProfileClientsEditingContainer = (props) => {
  return <ProfileClientsEditing {...props} editTechnologies={props.editClients} />;
};

let mapStateToProps = state => ({
  auth: state.auth,
});

export default compose(
  connect(mapStateToProps, {
    editClients,
  }),
  withAuthRedirect
)(ProfileClientsEditingContainer);
