import { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { withAuthRedirect } from "hoc/withAuthRedirect";
import { getOutsourceSearch, getOutsourceSearchResults, deleteSearch, startOutsourceChat } from "store/outsource";
import { addProjectToMarketplace } from "store/marketplace";

import OutsourceSearch from "./OutsourceSearch";

let OutsourceSearchContainer = (props) => {
    useEffect(() => {
        props.getOutsourceSearch(props.match.params.searchId);
        props.getOutsourceSearchResults(props.match.params.searchId);
    }, [props.match.params.searchId]);

    return (
        <OutsourceSearch
            startupId={props.auth.company_info.id}
            searchId={props.match.params.searchId}
            isFetching={props.outsource.isFetching}
            currentSearch={props.outsource.currentSearch}
            searchResults={props.outsource.searchResults}
            deleteSearch={props.deleteSearch}
            startOutsourceChat={props.startOutsourceChat}
            addProjectToMarketplace={props.addProjectToMarketplace}
        />
    );
};

let mapStateToProps = (state) => {
    return {
        auth: state.auth,
        outsource: state.outsource,
    };
};

OutsourceSearchContainer = withRouter(OutsourceSearchContainer);

export default compose(
    connect(mapStateToProps, {
        getOutsourceSearch,
        getOutsourceSearchResults,
        deleteSearch,
        startOutsourceChat,
        addProjectToMarketplace,
    }),
    withAuthRedirect
)(OutsourceSearchContainer);
