import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Table } from "antd";
import moment from "moment";
import cn from "classnames";

import { siteNameForTitle } from "../../constants/global-constants";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import DescriptionModal from "./DescriptionModal";
import WalletModal from "../Payment/WalletModal/WalletModal";
import SellLeadButton from "../SellLeads/SellLeadButton/SellLeadButton";
import ChatWithClientButton from "./ChatWithClientButton/ChatWithClientButton";
import Modal from "../common/Modal/Modal";
import { printLeadPrice } from "../../utils/utils"

import styles from "./LeadsMarketplace.module.css";

const LeadsMarketplace = (props) => {
  const { companyInfo } = props;

  const [showWalletModal, setShowWalletModal] = useState(false);
  const [modalData, setModalData] = useState(false);

  const subscribedCompany = companyInfo.legal_documents && companyInfo.stripe_subscribed;
  const columns = [
    {
      title: "Date",
      dataIndex: "created",
      key: "created",
      defaultSortOrder: "descend",
      render: (created, record) => (
        <div className={styles.created}>
          {!!record.is_deleted && (
            <div className={styles.lock} />
          )}
          <div className={styles.bold}>
            {moment(created).format("DD MMM")}
          </div>
        </div>
      ),
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
    },
    {
      title: "Lead",
      dataIndex: "project_name",
      key: "project_name",
      render: (project_name, record) => (
        <>
          <div className={styles.bold}>
            {project_name}
          </div>
          <div className={styles.bold}>
            Project type: {record.project_type}
          </div>
          <div>
            Date start: {moment(record.start).format('DD MMM YYYY')}
          </div>
        </>
      ),
    },
    {
      title: "Price",
      dataIndex: "fixed_price",
      key: "fixed_price",
      render: (fixed_price, record) => (
        <div className={record.qualification_level === 'hot' ? styles.hot : ''}>
          {printLeadPrice(fixed_price, record.percentage)}
        </div>
      )
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
      render: (budget) => (
        <div>${budget}</div>
      ),
    },
    {
      title: "Project description",
      dataIndex: "project_description",
      key: "project_description",
      render: (description, record) => (
        <div className={styles.projectDescription}>
          <div className={styles.descriptionText}>
            {description}
          </div>
          <div className={styles.helpLink} onClick={() => setModalData(record)}>
            ?
          </div>
        </div>
      ),
    },
    {
      title: "Verified / All",
      dataIndex: "vetted",
      key: "vetted",
      render: (vetted) => (vetted ? "Verified only" : "All companies"),
    },
    {
      title: "Total companies",
      dataIndex: "buyers",
      key: "buyers",
      render: (buyers, record) => (
        <div>
          {!!record.is_deleted && (
            <div className={styles.winner}>WINNER</div>
          )}
          <div className={styles.buyers}>Buyers: {buyers}</div>
        </div>
      )
    },
    {
      title: "Action",
      key: "operation",
      width: 190,
      align: "center",
      render: (record) => (
        <ChatWithClientButton
          leadData={record}
          disabled={record.is_deleted}
          errorHandler={() => setShowWalletModal(true)}
          subscribedCompany={subscribedCompany}
        />
      ),
    },
  ];

  const renderDescriptionModal = () => {
    return modalData && (
      <Modal
        showModal={setModalData}
        noBg={true}
        renderComponent={<DescriptionModal {...modalData} />}
      />
    )
  };

  const renderWalletModal = () => {
    if (showWalletModal) {
      return (
        <Modal
          showModal={setShowWalletModal}
          renderComponent={<WalletModal closeModal={() => setShowWalletModal(false)} />}
          noBg
        />
      )
    }

    return null;
  };

  return (
    <div className={cn(styles.wrapper, styles.companies)}>
      <Helmet>
        <title>Leads marketplace | {siteNameForTitle}</title>
      </Helmet>

      <div className={styles.modal}>
        {renderDescriptionModal()}
      </div>
      <div className={styles.walletModal}>
        {renderWalletModal()}
      </div>

      <DashboardMenuContainer/>

      <h1 className={styles.pageTitle}>
        Leads marketplace
      </h1>

      <div className={styles.chatCost}>
        Chat with client = 2 Pin from your wallet
      </div>
      <div className={styles.warning}>
        All payments are made between the lead seller and the buyer in a convenient manner for them. iBench is not
        responsible for the quality of the leads.
      </div>

      <Table
        dataSource={props.leads}
        columns={columns}
        pagination={false}
        className={styles.tableWrapper}
      />
      <div className={styles.sellLeadButton}>
        <SellLeadButton />
      </div>
    </div>
  );
};

export default LeadsMarketplace;
