import styles from "./Preloader.module.css";

const Preloader = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.preloader}>Loading...</div>
    </div>
  );
};

export default Preloader;
