import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { change, Field, reduxForm, formValueSelector } from "redux-form";
import { Link } from "react-router-dom";
import { length, required, numericality, acceptance } from "redux-form-validators";
import cn from "classnames";

import india_countries_list from "../../../utils/india_countries_list";
import { languagesList, startInList } from "../../../constants/global-constants";
import { getJobTitlesList, getLanguageLevelsList, getProjectAreas } from "../../../store/app";
import {
  Input,
  SelectInput,
  SelectInputKeyValue,
  SelectInputEnglish,
  RadioButton,
  renderMultiselect,
  Checkbox,
  SelectInputIdName,
} from "../../common/FormsControls/FormControls";
import Editor from "../../common/SimpleEditor/SimpleEditor";

import styles from "./CandidateSearchForm.module.css";

const formName = "candidateSearchForm";

const CandidateSearchForm = (props) => {
  let {
    handleSubmit,
    jobTitles,
    positionLevels,
    languageLevels,
    projectAreas,
    language,
    languages = [],
    languageLevel,
    getJobTitlesList,
    getLanguageLevelsList,
    getProjectAreas,
    dispatch,
    edit,
  } = props;

  const [termsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    getJobTitlesList();
    getLanguageLevelsList();
    getProjectAreas();
  }, []);

  const countriesList = useMemo(() => {
    return india_countries_list.map(country => country.label);
    // return countries.map(item => item.name);
  }, []);

  const setLanguages = (languages) => {
    dispatch(change(formName, "languages", [...languages]));
  };

  const addLanguage = () => {
    if (!language || !languageLevel) {
      return;
    }

    dispatch(change(formName, "language", ""));
    dispatch(change(formName, "language_level", ""));

    const levelIdNumber = +languageLevel;

    const levelData = languageLevels.find(level => level.english_level_id === levelIdNumber);
    languages = languages.filter(level => level.language !== language);

    languages.push({
      language,
      level_id: levelData.english_level_id,
      cerf_level: levelData.cerf_level,
    });

    setLanguages(languages);
  };

  const removeLanguage = (language) => {
    languages = languages.filter(level => level.language !== language);
    setLanguages(languages);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.field_label}>
        General Information
      </div>
      <div className={styles.fields_row}>
        <Field
          className={styles.input}
          input_wrapper_class={styles.full_width}
          component={Input}
          label="Search slot name*"
          name="name"
          help_text="for example: Front-end Senior for US client"
          validate={[required(), length({ maximum: 50 })]}
        />
      </div>
      <div className={cn(styles.fields_row, styles.rate_row)}>
        <Field
          input_wrapper_class={cn(styles.select_wrapper, styles.start_in_select)}
          component={SelectInput}
          name="start_in"
          label="Start in"
          options={startInList}
          withoutEmpty
        />
        <Field
          className={cn(styles.input, styles.short_input)}
          component={Input}
          label="Salary, $*"
          placeholder="From"
          type="number"
          name="rate_from"
          help_text="for example: 5000"
          validate={[required(), numericality({ int: true, '>': 0 })]}
        />
        <Field
          className={cn(styles.input, styles.short_input)}
          component={Input}
          placeholder="To"
          label="&nbsp;"
          type="number"
          name="rate_to"
          help_text="for example: 7000"
          validate={[required(), numericality({ int: true, '>': 0 })]}
        />
      </div>
      <div className={styles.field_label}>
        Candidate location
      </div>
      <div className={cn(styles.fields_row, styles.multiselect_row)}>
        <Field
          name="location"
          component={renderMultiselect}
          data={countriesList}
          valueField="country_id"
          textField="country_name"
          placeholder="Select the countries from which you need specialists"
        />
      </div>

      <div className={cn(styles.field_label, styles.seniority_level)}>
        Experience in industries
      </div>

      <div className={styles.fields_row}>
        <Field
          input_wrapper_class={styles.select_wrapper}
          help_text_design={styles.help_text}
          component={SelectInputIdName}
          name="project_area_id"
          label="Industry*"
          options={projectAreas}
          help_text="For example: EdTech"
          validate={[required()]}
        />
      </div>

      <div className={cn(styles.field_label, styles.seniority_level)}>
        Seniority level & Skills
      </div>

      <div className={styles.fields_row}>
        <Field
          input_wrapper_class={styles.select_wrapper}
          help_text_design={styles.help_text}
          component={SelectInputKeyValue}
          name="job_title_id"
          label="Job title*"
          options={jobTitles}
          help_text="for example: Front-end developer"
          validate={[required()]}
        />
        <Field
          input_wrapper_class={styles.select_wrapper}
          help_text_design={styles.help_text}
          component={SelectInputKeyValue}
          name="position_level_id"
          label="Position level*"
          options={positionLevels}
          help_text="for example: Senior"
          validate={[required()]}
        />
      </div>

      <div className={styles.custom_label}>
        Years experience
      </div>
      <div className={styles.radio}>
        <Field
          component={RadioButton}
          type="radio"
          name="years_experience"
          value="1-2"
          text="1-2 years"
          validate={[required()]}
        />
        <Field
          component={RadioButton}
          type="radio"
          name="years_experience"
          value="3-5"
          text="3-5 years"
          validate={[required()]}
        />
        <Field
          component={RadioButton}
          type="radio"
          name="years_experience"
          value="7-10"
          text="7-10 years"
          validate={[required()]}
        />
        <Field
          component={RadioButton}
          type="radio"
          name="years_experience"
          value="10+"
          text="10+ years"
          validate={[required()]}
        />
      </div>
      <div className={styles.custom_help}>
        for example: Senior level , 7-10 years is good
      </div>

      <div className={cn(styles.field_label, styles.seniority_level)}>
        Language
      </div>
      <div className={styles.skills_row}>
        <div className={styles.fields_row}>
          <Field
            input_wrapper_class={cn(styles.select_wrapper, styles.language_select)}
            help_text_design={styles.help_text}
            component={SelectInput}
            name="language"
            label="Language"
            options={languagesList}
            help_text="for example: English"
          />
          <Field
            input_wrapper_class={cn(styles.select_wrapper, styles.language_select)}
            help_text_design={styles.help_text}
            component={SelectInputEnglish}
            name="language_level"
            label="Language level"
            options={languageLevels}
            help_text="for example: C1"
          />
        </div>
        <div className={styles.add_button} onClick={addLanguage} />
      </div>

      <div className={styles.selected_languages}>
        {languages.map((languageData, index) => (
          <div className={styles.skill_item_row} key={index}>
            <div className={styles.skill_item}>
              {languageData.language}, {languageData.cerf_level}
            </div>
            <div className={styles.delete_skill} onClick={() => removeLanguage(languageData.language)}>delete</div>
          </div>
        ))}
      </div>

      <div className={cn(styles.field_label, styles.seniority_level)}>
        Job description & Benefits
      </div>

      <div className={styles.editor_wrapper}>
        <Field
          className={styles.textarea}
          component={Editor}
          name="description"
          validate={[length({ maximum: 5000 })]}
        />
      </div>
      {!edit && (
        <div className={styles.checkbox_wrapper}>
          <Field
            component={props => <Checkbox {...props}/>}
            name="terms_accepted"
            label={(
              <span>
                I agree with the{' '}
                <Link className={styles.termsLink} to="/terms-of-use" target="_blank">
                  terms of the iBench service
                </Link>.
              </span>
            )}
            validate={[acceptance()]}
            className={styles.checkbox}
            onChange={() => setTermsChecked(!termsChecked)}
            checked={termsChecked}
          />
        </div>
      )}

      <button className={styles.submit}>
        Activate search
      </button>
    </form>
  );
};

const selector = formValueSelector(formName);

let BaseInfoFormReduxForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  initialValues: {
    years_experience: '1-2',
    start_in: 'ASAP',
  },
  validate: (values) => {
    const errors = {};
    if (values.rate_to && +values.rate_from > +values.rate_to) {
      errors.rate_to = "must be more than from";
    }

    return errors;
  }
})(CandidateSearchForm);

const mapStateToProps = (state) => ({
  jobTitles: state.app.job_titles_list,
  positionLevels: state.developer.position_levels,
  languageLevels: state.app.language_level_list,
  countries: state.countries.countryList,
  languages: selector(state, 'languages'),
  language: selector(state, 'language'),
  languageLevel: selector(state, 'language_level'),
  projectAreas: state.app.project_areas,
});

const mapDispatchToProps = {
  getJobTitlesList,
  getLanguageLevelsList,
  getProjectAreas,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseInfoFormReduxForm);