import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setTrialSearch, trialSearch, setSelectedTrialDeveloper } from "../../../store/search";
import TrialSearch from "./TrialSearch";

let TrialSearchContainer = (props) => {
  return (
    <TrialSearch
      {...props}
      is_auth={props.is_auth}
      is_fetching_auth={props.is_fetching_auth}
      skills_list={props.app.skills_list}
      trial_search={props.trial_search}
      trialSearch={props.trialSearch}
      skillSponsor={props.skillSponsor}  // id need for
      skillSponsorDevelopers={props.skillSponsorDevelopers}
      selected_trial_developer={props.selected_trial_developer}
      setSelectedTrialDeveloper={props.setSelectedTrialDeveloper}
    />
  );
};

TrialSearchContainer = withRouter(TrialSearchContainer);

let mapStateToProps = (state) => ({
  app: state.app,
  is_auth: state.auth.is_auth,
  is_fetching: state.search.is_fetching,
  is_fetching_auth: state.auth.is_fetching_auth,
  skills_list: state.search.skills_list,
  trial_search: state.search.trial_search,
  selected_trial_developer: state.search.selected_trial_developer,
  skillSponsor: state.search.trial_search_sponsor,
  skillSponsorDevelopers: state.search.trial_search_sponsor_developers,
});

export default connect(mapStateToProps, {
  setTrialSearch,
  trialSearch,
  setSelectedTrialDeveloper,
})(TrialSearchContainer);
