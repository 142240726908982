import { useState } from "react";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import Linkify from "react-linkify";
import moment from "moment";
import { Modal } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";

import { publicFilesUrl, siteNameForTitle } from "../../../constants";
import default_company_photo from "assets/img/rowing.svg";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import Preloader from "../../common/Preloader/Preloader";
import { formatBudget, formatProjectStart } from "utils/utils";
import linkifyDecorator from "utils/linkifyDecorator";
import styles from "./OutsourceSearch.module.css";
import Verified from "components/common/Verified/Verified";

const OutsourceSearch = (props) => {
  const [showRequestModal, setShowRequestModal] = useState(false);

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this outsource search?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        props.deleteSearch({ search_id: props.searchId });
      },
    });
  };

  const addSearchToMarketplace = () => {
    props.addProjectToMarketplace({ search_id: props.searchId });
    setShowRequestModal(false);
  };

  const startChat = (company_id) => {
    props.startOutsourceChat({ company_id, search_id: props.searchId }, `${props.startupId}:${company_id}`);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Outsource search results | {siteNameForTitle}</title>
      </Helmet>

      {props.isFetching && <Preloader/>}

      <Modal
        title={"Request visibility!"}
        visible={showRequestModal}
        footer={null}
        onCancel={() => setShowRequestModal(false)}
      >
        <p className={styles.marketplaceText}>
          We suggest making your request visible to other companies. Perhaps they can help you. You will
          receive offers from them inside our chat.
        </p>
        <button className={styles.marketplaceBtn} onClick={addSearchToMarketplace}>
          Yes, share it!
        </button>
        <div className={styles.marketplaceBtnText} onClick={() => setShowRequestModal(false)}>
          no, thanks
        </div>
      </Modal>

      <DashboardMenuContainer/>

      <h1 className={styles.title}>Find IT companies \ Project {props.currentSearch?.name}</h1>

      <div className={styles.actions}>
        <NavLink to={`/outsource-search/${props.searchId}/edit`} className={cn(styles.actionBtn, styles.edit)}>
          Edit slot
        </NavLink>
        <span className={cn(styles.actionBtn, styles.yellowBtn)} onClick={() => setShowRequestModal(true)}>
          <PlusCircleFilled className={styles.shareSearch}/>
          Add search to the marketplace
        </span>
        <span className={cn(styles.actionBtn, styles.delete)} onClick={showDeleteConfirm}>
          Delete slot
        </span>
      </div>

      {props.currentSearch && (
        <div className={styles.searchInfo}>
          <div className={styles.row}>
            <span className={styles.label}>Verified companies:</span>
            {props.currentSearch.vetted ? "Only verified" : "All companies"}
          </div>
          <div className={styles.row}>
            <span className={styles.label}>Countries:</span>
            {props.currentSearch.location?.join?.(", ")}
          </div>
          <div className={styles.row}>
            <span className={styles.label}>Project name:</span>
            {props.currentSearch.name}
          </div>
          <div className={styles.row}>
            <span className={styles.label}>Date start:</span>
            {formatProjectStart(props.currentSearch.project_start)}
            <span className={styles.budget}>
              Budjet: {formatBudget(Number(props.currentSearch.budget))}
            </span>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>Project details</div>
            <div className={styles.searchInfoDescription}
                 dangerouslySetInnerHTML={{ __html: props.currentSearch.description }}/>
          </div>
          <div className={cn(styles.row, styles.casesList)}>
            {props.currentSearch.markets.map(({ market_name }) => (
              <div key={`outsource-info-${props.searchId}-${market_name}`} className={styles.case}>
                {market_name}
              </div>
            ))}
          </div>
          {props.currentSearch.links && (
            <div className={cn(styles.row, styles.searchInfoLink)}>
              <div className={styles.label}>Additional links</div>
              <Linkify
                componentDecorator={() =>
                  linkifyDecorator(props.currentSearch.links, props.currentSearch.links)
                }
              >
                {props.currentSearch.links}
              </Linkify>
            </div>
          )}
        </div>
      )}

      <div className={styles.resultsCounter}>Total companies: {props.searchResults?.length}</div>

      {props.searchResults?.map((search) => (
        <div key={`outsource-company-${search.company_id}`} className={styles.company}>
          <div className={styles.companyLogoWrapper}>
            <NavLink to={`/outsource-company/${search.company_id}`}>
              <img
                src={
                  search.company_logo
                    ? `${publicFilesUrl}public/img/${search.company_logo}`
                    : default_company_photo
                }
                alt={search.company_name}
                className={styles.companyLogo}
              />
            </NavLink>
            {search.company_verified === 1 && <Verified/>}
          </div>
          <div className={styles.companyInfo}>
            <div className={styles.companyName}>
              <NavLink
                to={`/outsource-company/${search.company_id}`}
                target="_blank"
                rel="nofollow"
              >
                Company: {search.company_name}
              </NavLink>
            </div>
            <div className={styles.companyLocationWrapper}>
              <div className={styles.companyLocation}>
                {search.company_country}, {search.company_city}
              </div>
              <div className={styles.companyUpdate}>
                Update:{" "}
                <span className={styles.companyUpdateDate}>
                  {moment(search.last_seen).format("DD MMM YYYY")}
                </span>
              </div>
            </div>
            <p className={styles.companyDescription} dangerouslySetInnerHTML={{ __html: search.company_description }}/>
            <div className={styles.casesWrapper}>
              <div className={styles.casesTitle}>Cases</div>
              <div className={styles.casesList}>
                {search.cases.map(({ market_name, count }, i) => (
                  <div
                    key={`outsource-company-${search.company_id}-case-${i}`}
                    className={styles.case}
                  >
                    {market_name} ({count})
                  </div>
                ))}
              </div>
            </div>
            <button className={styles.companyChat} onClick={() => startChat(search.company_id)}>
              Chat with company
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OutsourceSearch;
