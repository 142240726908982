import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Modal, Tooltip } from "antd";
import { PlusCircleOutlined, PlusCircleFilled } from "@ant-design/icons";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import { showModal } from "../../../store/search";
import { hire } from "../../../store/chat";
import {
  getCandidateSearchById,
  getCandidateSearchResults,
  deleteCandidateSearch,
  addCandidateSearchToMarketplace,
} from "../../../store/candidates";
import CustomModal from "../../common/Modal/Modal";
import Preloader from "../../common/Preloader/Preloader";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import TalentCard from "../../Profile/TalentCard";

import styles from "./CandidateSearchResults.module.css";

const CandidateSearchResults = (props) => {
  const {
    getCandidateSearchResults,
    getCandidateSearchById,
    deleteCandidateSearch,
    addCandidateSearchToMarketplace,
    match,
    search,
    searchResults,
    additionalResults,
    showModal,
    modalIsVisible,
    isFetching,
  } = props;

  const [showRequestModal, setShowRequestModal] = useState(false);

  useEffect(() => {
    if (match.params.search_id) {
      getCandidateSearchById(match.params.search_id);
      getCandidateSearchResults(match.params.search_id);
    }
  }, [match.params.search_id]);

  useEffect(() => {
    if (!!isFetching && !search?.for_marketplace) {
      setShowRequestModal(true);
    }
  }, [search]);

  const addToMarketplace = () => {
    addCandidateSearchToMarketplace(match.params.search_id);
    setShowRequestModal(false);
  };
  const deleteSearch = () => {
    showModal(false);
    deleteCandidateSearch(search.id);
  };

  const deleteSearchConfirmation = () => {
    showModal(true);
  };

  const renderLanguagesData = () => {
    if (!search?.languages?.length) {
      return '';
    }

    const lastIndex = search.languages.length - 1;

    return search.languages.map((languageData, index) => (
      <span key={index}>
        <strong>{languageData.language}: </strong>
        {languageData.cerf_level}{index < lastIndex && ', '}
      </span>
    ));
  };

  const renderTalentResults = (results) => (
    <div className={styles.search_results_wrapper}>
      {results.map((talentData) => (
        <div key={talentData.id} className={styles.search_results_item}>
          <TalentCard talentData={talentData} withLink />
        </div>
      ))}
    </div>
  );

  if (!search) {
    return null;
  }

  const renderAdditionalResults = () => {
    let title;

    if (searchResults?.length) {

      if (!additionalResults?.length) {
        return;
      }

      title = 'Exact search produced several results, but we found other candidates similar to your query.';
    } else if (additionalResults?.length) {
      title = 'The exact search did not return any results, but we found other candidates similar to your request.';
    } else {
      title = `Unfortunately, at the moment, there are no relevant specialists in our database,
        but we will notify you as soon as relevant candidates become available`;
    }

    return (
      <>
        <div className={styles.search_results_title}>
          {title}
        </div>

        {!!additionalResults?.length && renderTalentResults(additionalResults)}
      </>
    )

  };

  return (
    <div className={styles.wrapper}>
      {isFetching && <Preloader/>}
      {modalIsVisible && (
        <CustomModal
          title={"Attention!"}
          message={"You are ready to delete search?"}
          buttons={[
            { btn_text: "Yes, delete", btn_handler: deleteSearch },
            {
              btn_text: "No",
              btn_handler: () => showModal(false),
            },
          ]}
        />
      )}

      <Tooltip title="Add search to the marketplace" placement="left">
        <PlusCircleOutlined className={styles.requestModalBtn} onClick={() => setShowRequestModal(true)}/>
      </Tooltip>

      <Modal
        title={"Request visibility!"}
        visible={showRequestModal}
        onCancel={() => setShowRequestModal(false)}
        footer={false}
      >
        <p className={styles.marketplaceText}>
          We suggest making your request visible to other companies. Perhaps they can help you. You will
          receive offers from them inside our chat.
        </p>
        <button className={styles.marketplaceBtn} onClick={addToMarketplace}>
          Yes, share it!
        </button>
        <div className={styles.marketplaceBtnText} onClick={() => setShowRequestModal(false)}>
          no, thanks
        </div>
      </Modal>

      <DashboardMenuContainer/>

      <h1 className={styles.title}>
        <span>Find talents</span> / {search.name}
      </h1>

      <div className={`${styles.search_actions} d_flex justify_content_center`}>
        <Link
          to={`/edit-candidate-search/${search.id}`}
          className={styles.edit_search}
        >
          Edit slot
        </Link>
        <button className={styles.yellowBtn} onClick={() => setShowRequestModal(true)}>
          <PlusCircleFilled className={styles.shareSearch}/>
          Add search to the marketplace
        </button>
        <button className={styles.delete_search} onClick={deleteSearchConfirmation}>
          Delete slot
        </button>
      </div>

      <div className={styles.details}>
        <div className={styles.form_header}>
          {search.name}
        </div>

        <div className={styles.short_data}>
          <div className={styles.short_data_item}>
            <strong> Position: </strong>
            {search.position_level_name} {search.job_title_name}
          </div>
          <div className={styles.short_data_item}>
            <strong> Location: </strong>
            {search.location || '-'}
          </div>
          <div className={styles.short_data_item}>
            <strong> Salary: </strong>
            ${search.rate_from}
          </div>
          <div className={styles.short_data_item}>
            {renderLanguagesData()}
          </div>
        </div>
      </div>
      {!!searchResults?.length && (
        <div className={styles.search_results_title}>
        <span>
          <strong>{searchResults.length} candidates</strong>, ready to work
        </span>
        </div>
      )}

      {renderTalentResults(searchResults)}

      {renderAdditionalResults()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  search: state.candidates.currentSearch,
  modalIsVisible: state.search.show_modal,
  isFetching: state.candidates.isFetching,
  searchResults: state.candidates.searchResults,
  additionalResults: state.candidates.additionalResults,
  companyInfo: state.auth?.company_info,
});

const mapDispatchToProps = {
  getCandidateSearchResults,
  getCandidateSearchById,
  deleteCandidateSearch,
  hire,
  showModal,
  addCandidateSearchToMarketplace,
};


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthRedirect,
)(withRouter(CandidateSearchResults));
