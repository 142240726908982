import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Table } from "antd";

import { publicFilesUrl, siteNameForTitle } from "../../constants/global-constants";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import Preloader from "../common/Preloader/Preloader";
import { formatBudget, formatProjectStart } from "../../utils/utils";
import defaultProfilePhoto from "../../assets/img/boy.svg";
import styles from "./Projects.module.css";

const Projects = (props) => {
    const columns = [
        {
            title: "Project name",
            dataIndex: "name",
            key: "name",
            render: (name, record) => {
                return (
                    <NavLink to={`/project/${record.project_id}`} className={styles.projectLink}>
                        {name}
                    </NavLink>
                );
            },
        },
        {
            title: "Markets",
            dataIndex: "market_names",
            key: "market_names",
            width: 200,
        },
        {
            title: "Budget",
            dataIndex: "budget",
            key: "budget",
            width: 200,
            render: (budget) => {
                return <div className={styles.budget}>{formatBudget(budget)}</div>;
            },
            sorter: (a, b) => a.budget - b.budget,
        },
        {
            title: "Start",
            dataIndex: "project_start",
            key: "project_start",
            width: 140,
            render: (project_start) => {
                return formatProjectStart(project_start);
            },
            sorter: (a, b) => a.project_start.localeCompare(b.project_start),
        },
        {
            title: "Client",
            dataIndex: "representative_name",
            key: "representative_name",
            width: 280,
            render: (representative_name, record) => {
                return (
                    <div className={styles.client}>
                        <img
                            src={
                                record.representative_photo
                                    ? `${publicFilesUrl}public/img/${record.representative_photo}`
                                    : defaultProfilePhoto
                            }
                            alt={representative_name}
                            className={styles.photo}
                        />
                        <div className={styles.clientInfo}>
                            <div className={styles.clientName}>{representative_name}</div>
                            <div className={styles.clientLocation}>
                                {record.country}, {record.city}
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Activity",
            key: "activity",
            width: 120,
            render: (record) => {
                return (
                    <NavLink to={`/chat/${record.startup_id}:${props.companyId}`} className={styles.chatLink}>
                        Chat
                    </NavLink>
                );
            },
        },
    ];

    return (
        <div className={styles.wrapper}>
            <Helmet>
                <title>Projects | {siteNameForTitle}</title>
            </Helmet>

            {props.isFetching && <Preloader />}

            <DashboardMenuContainer />

            <h1 className={styles.title}>Projects</h1>

            <Table dataSource={[...props.projectsList]} columns={columns} pagination={false} />
        </div>
    );
};

export default Projects;
