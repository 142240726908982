import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { siteNameForTitle } from "../../../constants/global-constants";
import Preloader from "../../common/Preloader/Preloader";
import ProfileMenu from "../ProfileMenu";
import TopCasesForm from "./TopCasesForm";
import CompanyMenu from "../CompanyMenu";

import styles from "./ProfileTopCasesEditing.module.css";

const ProfileTopCasesEditing = (props) => {
  useEffect(() => {
    props.getCasesList();
  }, []);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Profile editing | {siteNameForTitle}</title>
      </Helmet>

      {props.auth.is_fetching && <Preloader/>}

      <h2 className={`${styles.title} text_center`}>
        Company profile
      </h2>

      <ProfileMenu />

      <CompanyMenu companyInfo={props.auth.company_info} />

      <div className={styles.subtitle}>Edit profile / Top cases</div>

      <TopCasesForm updateTopCases={props.updateTopCases} cases={props.cases.casesList} />
    </div>
  );
};

export default ProfileTopCasesEditing;
