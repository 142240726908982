import cn from "classnames";
import Linkify from "react-linkify";
import moment from "moment";

import styles from "./DeveloperProject.module.css";
import linkifyDecorator from "../../../utils/linkifyDecorator";

const DeveloperProject = (props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.number}>Project #{props.number}</div>
      <h4 className={cn(styles.row, styles.name)}>
        {props.link ? (
          <Linkify componentDecorator={() => linkifyDecorator(props.link, props.project_name)}>{props.link}</Linkify>
        ) : (
          props.project_name
        )}
      </h4>
      <div className={cn(styles.row, styles.duration)}>
        {`${moment(props.from_year).format("MMMM YYYY")} - ${moment(props.to_year).format("MMMM YYYY")}`}
      </div>
      <div className={cn(styles.row, styles.description)} dangerouslySetInnerHTML={{ __html: props.description }} />
      <div className={styles.row}>
        <span className={styles.label}>Technologies: </span> {props.main_skill_name}
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Additional technologies: </span>
        {props.skills.map((skill, index) => (
          <span key={`projectSkill-${skill.skill_id}`}>
            {skill.skill_name + (index !== props.skills.length - 1 ? ", " : "")}
          </span>
        ))}
      </div>
      <div className={styles.row}>
        <span className={styles.label}>Team size:</span> {props.team_size} members
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Responsibilities</div>
        <div className={styles.responsibilities} dangerouslySetInnerHTML={{ __html: props.responsibilities }} />
      </div>
    </div>
  );
};

export default DeveloperProject;
