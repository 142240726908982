import React, { useMemo } from 'react';
import { connect } from "react-redux";

import { isAfterYesterday } from "../../utils/utils";

import DashboardMenu from "./DashboardMenu";

const DashboardMenuContainer = (props) => {

  const {
    auth,
    theme,
    marketplace,
    candidates,
    search,
    developer,
    cases,
    outsource,
    lead,
    chat,
  } = props;

  const newCandidateSearches = useMemo(() => {
    return candidates.searches.filter(search => isAfterYesterday(search.created_at));
  }, [candidates]);

  return (
    <DashboardMenu
      userType={auth.user.type}
      companyInfo={auth.company_info}
      theme={theme}
      marketplaceCounter={marketplace.developers.length}
      candidateSearchCounter={candidates.searches.length}
      newCandidateSearchCounter={newCandidateSearches.length}
      searchCounter={search.searches.length}
      freelancerSearchCounter={search.freelancer_searches.length}
      developersCounter={developer.developers.length}
      casesCounter={cases.casesList.length}
      projectsCounter={cases.projectsList.length}
      outsourceCounter={outsource.searchList.length}
      leadsCounter={lead.leads?.length}
      unreadChatsCounter={chat.unread_chats_counter}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  marketplace: state.marketplace,
  developer: state.developer,
  cases: state.cases,
  outsource: state.outsource,
  lead: state.lead,
  search: state.search,
  candidates: state.candidates,
  notifications: state.notifications,
  chat: state.chat,
});

export default connect(mapStateToProps)(DashboardMenuContainer);
