export const validateHTMLLength = (input, length = 100) => {
  const tagsEnd = /(<([^>\/]+)>)/ig;
  const tagsStart = /(<([^>]+)>)/ig;
  input = input.replace(tagsEnd, " ");
  input = input.replace(tagsStart, "");
  input = input.trim();
  if (input.length < length) {
    return `is too short (minimum is ${length} characters)`;
  }
};

export const checkPasswordStrength = (password) => {
  // Checking spaces
  if (password.includes(' ')) {
    return 'the space character is not allowed';
  }

  // Checking if there are different characters
  const firstChar = password[0];
  const regExp = new RegExp(`[^${firstChar}]`, 'g');
  if (!password.match(regExp)) {
    return 'too simple password';
  }
};

export const URLValidation = (url) => {
  if (url && !/^(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url)) {
    return 'must be valid link';
  }
};

export const validatePositiveInt = (value) => {
  if (value && (value <= 0 || value !== (+value).toString())) {
    return "is not a number";
  }
};
