import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { acceptance, email, required } from "redux-form-validators";
import cn from "classnames";

import { Checkbox, Input } from "../../common/FormsControls/FormControls";
import FormIndicator from "./FormIndicator/FormIndicator";

import styles from "./TeamSearch.module.css";

const AgreementLabel = () => {
  return (
    <>
      I agree to the{" "}
      <NavLink to="/privacy-policy" target="_blank" rel="nofollow">
        Privacy Policy
      </NavLink>
    </>
  );
};

const TeamSearchForm1 = (props) => {
  const [checked, setChecked] = useState(true);

  const changeChecked = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <FormIndicator page={props.page} />

      <div>
        <Field
          component={Input}
          name="name"
          input_wrapper_class="form_control_2_wrapper"
          className="form_control_2"
          validate={[required()]}
          placeholder="Name"
        />
        <Field
          component={Input}
          name="email"
          input_wrapper_class="form_control_2_wrapper"
          className="form_control_2"
          validate={[required(), email()]}
          placeholder="Work email address"
        />
        <Field
          component={Checkbox}
          name="privacy-agreement"
          id="privacy-agreement"
          className="justify_content_center"
          label={AgreementLabel()}
          validate={[acceptance()]}
          checked={checked}
          onChange={changeChecked}
        />
      </div>

      <div className={cn(styles.submit_wrapper, "text_center")}>
        <button className={cn(styles.submit, "btn_2", "btn_purple", "mobile_hidden")}>Next step</button>
        <button className={cn(styles.submit, "btn_2", "btn_purple", "mobile_visible")}>Next</button>
      </div>

      {props.error && <div className={`${styles.form_erros} text_center validation_message`}>{props.error}</div>}
    </form>
  );
};

export default reduxForm({
  form: "team-search",
  destroyOnUnmount: false,
  initialValues: {
    "privacy-agreement": true,
  }
})(TeamSearchForm1);
