import React from "react";
import { connect } from "react-redux";

import { editProfile } from "../../../store/auth";
import TalentSettings from "../Settings/TalentSettings";

const AlertsContainer = (props) => {
  const { auth, editProfile } = props;
  return (
    <TalentSettings
      {...auth.company_info}
      editProfile={editProfile}
      auth={auth}
      profile
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  editProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContainer);
