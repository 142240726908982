import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { developersAPI } from "../../../api/developers";
import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import {
  setCurrentDeveloper,
  addDeveloper,
  editDeveloper,
  setDeveloperId,
  addDeveloperProject,
  editDeveloperProject,
  deleteDeveloperProject,
} from "../../../store/developer";
import { degreesList } from "../../../constants/global-constants";
import countries_list from "../../../utils/countries_list";
import DeveloperAdding from "./DeveloperAdding";

const DeveloperAddingContainer = (props) => {
  const [jobTitlesList, setJobTitlesList] = useState(null);
  const [englishLevelsList, setEnglishLevelsList] = useState(null);

  useEffect(() => {
    props.setCurrentDeveloper(null);
    developersAPI.getJobTitles().then((result) => setJobTitlesList(result));
    developersAPI.getLanguageLevels().then((result) => setEnglishLevelsList(result));
  }, []);

  return (
    <DeveloperAdding
      {...props}
      slot_number={props.developer.developers.length + 1}
      jobTitlesList={jobTitlesList}
      countries_list={countries_list.map((item) => item.label)}
      skills_list={props.skills_list}
      englishLevelsList={englishLevelsList}
      degreesList={degreesList}
      setDeveloperId={props.setDeveloperId}
      addDeveloper={props.addDeveloper}
      editDeveloper={props.editDeveloper}
      addDeveloperProject={props.addDeveloperProject}
      editDeveloperProject={props.editDeveloperProject}
      deleteDeveloperProject={props.deleteDeveloperProject}
      isSingleDeveloper={props.companyInfo?.is_single_developer}
    />
  );
};

let mapStateToProps = (state) => ({
  developer: state.developer,
  skills_list: state.app.skills_list,
  companyInfo: state.auth.company_info,
});

export default compose(
  connect(mapStateToProps, {
    setCurrentDeveloper,
    setDeveloperId,
    addDeveloper,
    editDeveloper,
    addDeveloperProject,
    editDeveloperProject,
    deleteDeveloperProject,
  }),
  withAuthRedirect
)(DeveloperAddingContainer);
