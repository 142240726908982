import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { Row, Col, Table, Tooltip } from "antd";
import moment from "moment";
import cn from "classnames";

import { marketplaceChatAPI } from "../../api/marketplace";
import { siteNameForTitle } from "../../constants/global-constants";
import Verified from "../common/Verified/Verified";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import MarketplaceNavigation from "./MarketplaceNavigation";
import CloseRequestModal from "./CloseRequestModal";
import ProjectDescriptionModal from "./ProjectDescriptionModal";
import WalletModal from "../Payment/WalletModal/WalletModal";
import Modal from "../common/Modal/Modal";

import styles from "./Marketplace.module.css";
import { history } from "../../utils";

const MarketplaceProjects = (props) => {

  const [showCloseRequestModal, setShowCloseRequestModal] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [searchId, setSearchId] = useState();

  const connectToChat = (message, chatId, searchId) => {
    return props.hire(
      message,
      chatId,
      true,
      () => marketplaceChatAPI.increaseCounter({ search_id: searchId, type: "dev" }),
      () => setShowWalletModal(true)
    );
  };

  const onCloseRequestConfirm = (search_id) => {
    setShowCloseRequestModal(true);
    setSearchId(search_id);
  };

  const onCloseRequest = async () => {
    await props.deleteProjectFromMarketplace({
      search_id: searchId,
      comment:
        props.closeMarketplaceRequestForm.values.reason !== "Other"
          ? props.closeMarketplaceRequestForm.values.reason
          : props.closeMarketplaceRequestForm.values.comment,
    });
    await props.getMarketplaceProjects();
    setShowCloseRequestModal(false);
  };

  const chatToClient = (record) => {
    const marketList = record.markets.map((market) => market.market_name).join(", ");
    const dateString = moment(record.created).format("DD/MM/YYYY");

    const message = `I would like to discuss your project: ${record.name}; ${marketList}; ${dateString}`;

    const chatId = `${record.company_id}:${props.companyId}`;

    if (props.companyInfo.legal_documents && props.companyInfo.stripe_subscribed) {
      return connectToChat(message, chatId, record.search_id);
    }

    const payData = {
      search_id: record.search_id,
      for_outsource: 1,
    };

    props.payForChat(
      payData,
      (response) => {
        if (response.pointsCharged) {
          connectToChat(message, chatId, record.search_id);
        }

        history.push(`/chat/${chatId}`);
      },
      () => setShowWalletModal(true)
    );
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "created",
      key: "created",
      width: 110,
      defaultSortOrder: "descend",
      render: (created) => moment(created).format("DD/MM/YYYY"),
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
    },
    {
      title: "Project",
      dataIndex: "name",
      key: "name",
      render: (name, record) => {
        return (
          <div className={styles.projectColumn}>
            <div>
              <div className={styles.projectTitle} onClick={() => setModalData(record)}>
                {name}
              </div>
              <div className={styles.technologies}>
                {record.markets?.map((market) => market.market_name).join(", ")}
              </div>
              {!!record.vetted && (
                <Verified text={"For VERIFIED Companies only"} className={styles.verified}/>
              )}
            </div>
            <div className={styles.helpLink} onClick={() => setModalData(record)}>
              ?
            </div>
          </div>
        );
      },
    },
    {
      title: "Budget, $",
      dataIndex: "budget",
      key: "budget",
      width: 150,
      render: (rate_to) => {
        return <div className={styles.rate}>$ {rate_to}</div>;
      },
      sorter: (a, b) => a.budget - b.budget,
    },
    {
      title: "Client's country",
      dataIndex: "client_country",
      key: "client_country",
      render: (client_country) => client_country,
    },
    {
      title: "Total companies",
      dataIndex: "found_last_run",
      key: "found_last_run",
      render: (found_last_run, record) =>
        `Search: ${found_last_run} ${!!record.total_requests ? `/ +${record.total_requests}` : ""}`,
    },
    {
      title: "Action",
      key: "operation",
      width: 190,
      align: "center",
      render: (record) => {
        let canChatToClient = true;
        if (record.vetted === 1 && props.companyVerificationChecks.length > 0) {
          canChatToClient = false;
        }

        return (
          <Tooltip
            title={
              record.vetted && props.companyVerificationChecks.length
                ? "Please add verification information to be able to chat with clients"
                : null
            }
            overlayInnerStyle={{ textAlign: "center", maxWidth: "240px" }}
          >
            <div
              className={cn(
                "btn",
                styles.chatBtn,
                record.vetted && props.companyVerificationChecks.length && styles.chatBtnDisabled
              )}
              onClick={() => (canChatToClient) && chatToClient(record)}
            >
              Chat with client
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const renderDescriptionModal = () => {
    if (!modalData) {
      return false;
    }

    let projectStartString = '';

    switch (modalData.project_start) {
      case 'asap':
        projectStartString = 'ASAP';
        break;

      case 'nextMonth':
        projectStartString = 'Next Month';
        break;

      case 'nextQuartal':
        projectStartString = 'Next Quartal';
        break;
    }

    const projectModalData = {
      description: `
        <strong>Start project:</strong> ${projectStartString}<br  />
        <strong>Project details</strong>:
        ${modalData.description}
      `,
    };

    return (
      <Modal
        showModal={setModalData}
        noBg={true}
        renderComponent={<ProjectDescriptionModal {...projectModalData} />}
      />
    )
  };

  const renderWalletModal = () => {
    if (showWalletModal) {
      return (
        <Modal
          showModal={setShowWalletModal}
          noBg={true}
          renderComponent={<WalletModal />}
        />
      )
    }

    return null;
  };

  return (
    <div className={cn(styles.wrapper, styles.projects)}>
    <div className={styles.modal}>
      {renderDescriptionModal()}
    </div>
      <div className={styles.WalletModal}>
        {renderWalletModal()}
      </div>

      <Helmet>
        <title>Marketplace / Developers | {siteNameForTitle}</title>
      </Helmet>

      <CloseRequestModal
        visible={showCloseRequestModal}
        onSubmit={onCloseRequest}
        onCancel={() => setShowCloseRequestModal(false)}
      />

      <DashboardMenuContainer/>

      <h1 className={styles.pageTitle}>Marketplace / Projects</h1>
      <MarketplaceNavigation pricePerChat={3} {...props} />

      {props.companyType === 1 ? (
        <div className={styles.cardsList}>
          {props.marketplace.projects.map((project) => (
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.cardDate}>
                  date: {moment(project.created).format("DD/MM/YYYY")}
                </div>
                <NavLink to={`/outsource-search/${project.search_id}`} className={styles.searchLink}>
                  {project.name}
                </NavLink>
              </div>
              <div className={styles.cardContent}>
                <Row gutter={16} className={styles.cardRow}>
                  <Col xs={24} sm={12}>
                    <span className={styles.cardLabel}>Date start: </span>
                    {project.project_start}
                  </Col>
                  <Col xs={24} sm={12}>
                    <span className={styles.cardLabel}>Budget up: </span>${project.budget}
                  </Col>
                </Row>
                <Row className={styles.cardRow}>
                  <Col>
                    <span className={styles.cardLabel}>Technologies: </span>
                    {project.markets.map((market) => market.market_name).join(", ")}
                  </Col>
                </Row>
                <Row gutter={16} className={styles.cardRow}>
                  <Col xs={24} sm={12}>
                    <span className={styles.cardLabel}>Companies: </span>
                    {project.vetted === 1 ? "Only verified" : "All"}
                  </Col>
                  <Col xs={24} sm={12}>
                    <span className={styles.cardLabel}>Search results: </span>
                    {project.found_last_run}
                  </Col>
                </Row>
              </div>
              <div className={styles.cardFooter}>
                <NavLink to={`/chat`} className={styles.cardBtn}>
                  View proposals
                </NavLink>
                <span
                  className={styles.closeRequest}
                  onClick={() => onCloseRequestConfirm(project.search_id)}
                >
                  close request
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Table
          dataSource={[...props.marketplace.projects]}
          columns={columns}
          pagination={false}
          className={styles.tableWrapper}
        />
      )}
    </div>
  );
};

export default MarketplaceProjects;
