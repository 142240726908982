import React, { useState, useEffect } from "react";
import moment from "moment";
import cn from "classnames";

import { buildImageLink } from "../../../utils/utils";

import styles from "./ProfileTopCasesEditing.module.css";
import { Link } from "react-router-dom";

const maxTopCases = 2;

const TopCasesForm = (props) => {
  const {
    updateTopCases,
    cases = [],
  } = props;

  const [topCases, setTopCases] = useState([]);
  const [otherCases, setOtherCases] = useState([]);

  useEffect(() => {
    let topCases = [];
    let otherCases = [];

    cases.forEach(caseData => {
      if (caseData.in_top) {
        return topCases.push(caseData);
      }

      otherCases.push(caseData);
    });
    setTopCases(topCases);
    setOtherCases(otherCases);
  }, [cases]);

  const saveTopCases = () => {
    const topCasesIds = topCases.map(caseData => caseData.case_id);
    updateTopCases(topCasesIds);
  };

  const addCaseToTop = (caseToTop) => {
    const newOtherCases = otherCases.filter(caseData => caseData.case_id !== caseToTop.case_id);
    setOtherCases([...newOtherCases]);
    setTopCases([...topCases, caseToTop]);
  };

  const removeCaseFromTop = (caseFromTop) => {
    const newTopData = topCases.filter(caseData => caseData.case_id !== caseFromTop.case_id);

    const newOtherCases = [...otherCases, caseFromTop].sort((currentCase, previousCase) => {
      return currentCase.case_id > previousCase.case_id ? 1 : -1;
    });

    setTopCases([...newTopData]);
    setOtherCases(newOtherCases);
  };

  const renderCaseCard = (caseData, index, inTop = false) => {
    const startDateString = moment(caseData.from_date).format('MMMM YYYY');
    const endDateString = moment(caseData.to_date).format('MMMM YYYY');

    return (
      <div key={index} className={styles.case_card}>
        {!!caseData.case_logo && (
          <img className={styles.case_image} src={buildImageLink(caseData.case_logo)}/>
        )}
        {!caseData.case_logo && (
          <div className={styles.without_photo}>Without photo</div>
        )}
        <div className={styles.case_info_block}>
          <div className={styles.case_info_row}>
            <span className={styles.bold}>Project name: </span>
            {caseData.name}
          </div>
          <div className={styles.case_info_row}>
            {caseData.links}&nbsp;
          </div>
          <div className={styles.case_info_row}>
            Up to ${caseData.budget}
          </div>
          <div className={styles.case_info_row}>
            <span className={styles.bold}>Project duration: </span>
            {startDateString} - {endDateString}
          </div>
        </div>
        <div className={styles.case_buttons}>
          {!inTop && topCases.length < maxTopCases && (
            <button
              className={styles.button}
              onClick={() => addCaseToTop(caseData)}
            >
              Add case to top
            </button>
          )}
          {inTop && (
            <>
              <button
                className={cn(styles.button, styles.grey_button)}
                onClick={() => removeCaseFromTop(caseData)}
              >
                Delete case from top
              </button>
              <div className={cn(styles.button, styles.top_case_button)}>Top case</div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderFormContent = () => {
    if (cases?.length === 0) {
      return (
        <div className={styles.addCasesSection}>
          <div className={styles.addCasesMessage}>
            To add cases to the company page, you need to add your cases. Click on this button and go to the section
            for adding cases. When you add, come back here and choose which of them will be TOP.
          </div>
          <Link to="/cases" className={styles.button}>Add new cases</Link>
        </div>
      );
    }

    return (
      <>
        {topCases.map((caseData, index) => renderCaseCard(caseData, index, true))}
        {otherCases.map((caseData, index) => renderCaseCard(caseData, index, false))}
        <button className={`btn ${styles.submit}`} onClick={saveTopCases}>
          Save
        </button>
      </>

    );
  };

  return (
    <div className={styles.top_cases}>
      <div className={styles.fields_wrapper}>
        <div className={styles.total_top_cases}>
          Total top cases: {topCases.length}
        </div>
        {renderFormContent()}
      </div>

    </div>
  );
};

export default TopCasesForm;