import { searchAPI } from "../api/search";
import { skillsAPI } from "../api/skills";
import { history } from "../utils";

const IS_FETCHING = "ibench/search/IS_FETCHING";
const SHOW_MODAL = "ibench/search/SHOW_MODAL";
const SHOW_ASSISTANT_REQUEST_MODAL = "ibench/search/SHOW_ASSISTANT_REQUEST_MODAL";
const EDIT_MODE_DISABLED = "ibench/search/EDIT_MODE_DISABLED";
const ADD_SEARCH = "ibench/search/ADD_SEARCH";
const ADD_FREELANCER_SEARCH = "ibench/search/ADD_FREELANCER_SEARCH";
const EDIT_SEARCH = "ibench/search/EDIT_SEARCH";
const DELETE_SEARCH = "ibench/search/DELETE_SEARCH";
const SET_CURRENT_SEARCH = "ibench/search/SET_CURRENT_SEARCH";
const SET_CURRENT_SEARCH_RESULTS = "ibench/search/SET_CURRENT_SEARCH_RESULTS";
const SET_ALTERNATIVE_SEARCH_RESULTS = "ibench/search/SET_ALTERNATIVE_SEARCH_RESULTS";
const GET_SEARCHES = "ibench/search/GET_SEARCHES";
const GET_FREELANCER_SEARCHES = "ibench/search/GET_FREELANCER_SEARCHES";
const SET_NEW_SEARCH_ID = "ibench/search/SET_NEW_SEARCH_ID";
const TRIAL_SEARCH = "ibench/search/TRIAL_SEARCH";
const TRIAL_SEARCH_SPONSOR = "ibench/search/TRIAL_SEARCH_SPONSOR";
const TRIAL_SEARCH_SPONSOR_DEVELOPERS = "ibench/search/TRIAL_SEARCH_SPONSOR_DEVELOPERS";
const SELECTED_TRIAL_DEVELOPER = "ibench/search/SELECTED_TRIAL_DEVELOPER";
const CURRENT_COMPANY = "ibench/search/CURRENT_COMPANY";
const SKILL_SPONSOR = "ibench/search/SKILL_SPONSOR";
const SKILL_SPONSOR_DEVELOPERS = "ibench/search/SKILL_SPONSOR_DEVELOPERS";

let initialState = {
  is_fetching: false,
  show_modal: false,
  show_assistant_request_modal: false,
  edit_mode_disabled: true,
  freelancer_searches: [],
  searches: [],
  new_search_id: null,
  current_search: null,
  current_search_results: null,
  alternative_search_results: null,
  trial_search: null,
  trial_search_sponsor: null,
  trial_search_sponsor_developers: null,
  selected_trial_developer: null,
  currentCompany: null,
  skillSponsor: null,
  skillSponsorDevelopers: null,
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return {
        ...state,
        is_fetching: action.is_fetching,
      };
    case SHOW_MODAL:
      return {
        ...state,
        show_modal: action.payload,
      };
    case SHOW_ASSISTANT_REQUEST_MODAL:
      return {
        ...state,
        show_assistant_request_modal: action.payload,
      };

    case EDIT_MODE_DISABLED:
      return {
        ...state,
        edit_mode_disabled: action.payload !== null ? action.payload : !state.edit_mode_disabled,
      };

    case ADD_SEARCH:
      return {
        ...state,
        searches: [...state.searches, action.current_search],
      };
    case ADD_FREELANCER_SEARCH:
      return {
        ...state,
        freelancer_searches: [...state.freelancer_searches, action.current_search],
      };
    case SET_NEW_SEARCH_ID:
      return {
        ...state,
        new_search_id: action.new_search_id,
      };
    case GET_SEARCHES:
      return {
        ...state,
        searches: action.searches,
      };
    case GET_FREELANCER_SEARCHES:
      return {
        ...state,
        freelancer_searches: action.searches,
      };
    case SET_CURRENT_SEARCH:
      return {
        ...state,
        current_search: action.current_search,
      };
    case SET_CURRENT_SEARCH_RESULTS:
      return {
        ...state,
        current_search_results: action.payload,
      };
    case SET_ALTERNATIVE_SEARCH_RESULTS:
      return {
        ...state,
        alternative_search_results: action.payload,
      };

    case TRIAL_SEARCH:
      return {
        ...state,
        trial_search: action.payload,
      };

    case TRIAL_SEARCH_SPONSOR:
      return {
        ...state,
        trial_search_sponsor: action.payload,
      };

    case TRIAL_SEARCH_SPONSOR_DEVELOPERS:
      return {
        ...state,
        trial_search_sponsor_developers: action.payload,
      };

    case SELECTED_TRIAL_DEVELOPER:
      return {
        ...state,
        selected_trial_developer: action.payload,
      };

    case CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.payload,
      };

    case SKILL_SPONSOR:
      return {
        ...state,
        skillSponsor: action.payload,
      };

    case SKILL_SPONSOR_DEVELOPERS:
      return {
        ...state,
        skillSponsorDevelopers: action.payload,
      };

    default:
      return state;
  }
};

// FETCHING
export const isFetching = (is_fetching) => ({
  type: IS_FETCHING,
  is_fetching,
});

// SHOW MODAL
export const showModal = (payload) => ({
  type: SHOW_MODAL,
  payload,
});
//  SHOW ASSISTANT REQUEST MODAL
export const showAssistantRequestModal = (payload) => ({
  type: SHOW_ASSISTANT_REQUEST_MODAL,
  payload,
});

// EDIT MODE
export const switchEditMode = (payload = null) => ({
  type: EDIT_MODE_DISABLED,
  payload,
});

// SET SEARCH ID
export const setNewSearchId = (new_search_id) => ({
  type: SET_NEW_SEARCH_ID,
  new_search_id,
});

// SET CURRENT SEARCH
export const setCurrentSearch = (current_search) => ({
  type: SET_CURRENT_SEARCH,
  current_search,
});

export const getCurrentSearch = (search_id, is_single_developer = 0) => {
  return (dispatch) => {
    searchAPI.getCurrentSearch(search_id, is_single_developer).then((data) => {
      dispatch(setCurrentSearch(data.searches[0]));
    });
  };
};

// GET ALL SEARCHES
export const getSearchesSuccess = (searches) => ({
  type: GET_SEARCHES,
  searches,
});

// GET ALL SEARCHES
export const getFreelancerSearchesSuccess = (searches) => ({
  type: GET_FREELANCER_SEARCHES,
  searches,
});

export const getSearches = () => {
  return (dispatch) => {
    searchAPI.getSearches().then((data) => {
      dispatch(getSearchesSuccess(data));
    });
  };
};

export const getFreelancerSearches = () => {
  return (dispatch) => {
    searchAPI.getFreelancerSearches().then((data) => {
      dispatch(getFreelancerSearchesSuccess(data));
    });
  };
};

// GET CERTAIN SEARCH RESULTS
export const setCurrentSearchResults = (payload) => ({
  type: SET_CURRENT_SEARCH_RESULTS,
  payload,
});

// GET ALTERNATIVE SEARCH RESULTS
export const setAlternativeSearchResults = (payload) => ({
  type: SET_ALTERNATIVE_SEARCH_RESULTS,
  payload,
});

export const getSearchResults = (search_id) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    dispatch(setCurrentSearchResults(null)); //reset value
    dispatch(setAlternativeSearchResults(null)); //reset value
    dispatch(setSkillSponsor(null)); //reset value

    searchAPI.getSearchResults(search_id).then((data) => {
      dispatch(setCurrentSearchResults(data.searchResults));

      if (data.skillSponsor) {
        dispatch(setSkillSponsor(data.skillSponsor));
      }

      if (data.skillSponsorDevelopers) {
        dispatch(setSkillSponsorDevelopers(data.skillSponsorDevelopers));
      }

      if (data?.alternativeResults?.length) {
        const alternativeResults = { verified: [], unverified: [] };

        data.alternativeResults.forEach((developer) => {
          if (developer.company_verified) {
            return alternativeResults.verified.push(developer);
          }
          alternativeResults.unverified.push(developer);
        });

        dispatch(setAlternativeSearchResults(alternativeResults));
      }
      dispatch(isFetching(false));
    });
  };
};

// ADD SEARCH
export const addSearchSuccess = (current_search) => ({
  type: ADD_SEARCH,
  current_search,
});

export const addFreelancerSearchSuccess = (current_search) => ({
  type: ADD_FREELANCER_SEARCH,
  current_search,
});

export const addSearch = (form_data) => {
  const skills = form_data.skills;

  return (dispatch, getState) => {
    dispatch(isFetching(true));

    searchAPI
      .addSearch(form_data)
      .then((data) => {
        dispatch(setNewSearchId(data.search.search_id));
        dispatch(addSearchSuccess(data.search));
        return data;
      })
      .then((data) => {
        // ADD SKILLS
        if (skills) {
          skills.forEach((skill) => {
            searchAPI.addSkill({
              ...skill,
              search_id: data.search.search_id,
            });
          });
        }
        return data;
      })
      .then((data) => {
        // TODO add promise.all or axios.all for waiting responses from all skills
        setTimeout(() => {
          dispatch(isFetching(false));

          // dispatch(showInfoModal({
          //   title: 'Placing requests on the platform',
          //   message: 'Your request has been successfully sent to iBench managers and will be published by the platform after additional verification'
          // }));

          return history.push(`/search/${data.search.search_id}`);
        }, 2000);
      });
  };
};

export const addFreelancerSearch = (form_data) => {
  const skills = form_data.skills;

  return (dispatch) => {
    dispatch(isFetching(true));

    searchAPI
      .addSearch(form_data)
      .then((data) => {
        dispatch(setNewSearchId(data.search.search_id));
        dispatch(addFreelancerSearchSuccess(data.search));
        return data;
      })
      .then((data) => {
        // ADD SKILLS
        if (skills) {
          skills.forEach((skill) => {
            searchAPI.addSkill({
              ...skill,
              search_id: data.search.search_id,
            });
          });
        }
        return data;
      })
      .then((data) => {
        // TODO add promise.all or axios.all for waiting responses from all skills
        setTimeout(() => {
          dispatch(isFetching(false));
          return history.push(`/employee-search/${data.search.search_id}`);
        }, 2000);
      });
  };
};

// EDIT SEARCH
export const editSearchSuccess = (current_search) => ({
  type: EDIT_SEARCH,
  current_search,
});

export const editFreelancerSearch = form_data => editSearch(form_data, 1);

export const editSearch = (form_data, employee = 0) => {
  let editing_data = { ...form_data };

  delete editing_data.add_skills;
  delete editing_data.delete_skills;

  return (dispatch) => {
    dispatch(isFetching(true));

    const promises = [];

    if (Object.keys(editing_data).length > 1) {
      promises.push(searchAPI.editSearch(editing_data));
    }

    // ADD SKILLS
    if (form_data.add_skills) {
      form_data.add_skills.forEach((skill) => {
        promises.push(
          searchAPI.addSkill({
            search_id: form_data.search_id,
            skill_id: skill,
          })
        );
      });
    }

    // DELETE SKILLS
    if (form_data.delete_skills) {
      form_data.delete_skills.forEach((skill) => {
        promises.push(
          searchAPI.deleteSkill({
            search_id: form_data.search_id,
            skill_id: skill,
          })
        );
      });
    }

    Promise.all(promises)
      .catch((err) => {
        dispatch(isFetching(false));
        dispatch(getCurrentSearch(form_data.search_id, employee));
        // log that I have an error, return the entire array;
        console.log("A promise failed to resolve", err);
      })
      .then(() => {
        dispatch(isFetching(false));
        dispatch(getCurrentSearch(form_data.search_id, employee));
        dispatch(getSearchResults(form_data.search_id));
        dispatch(switchEditMode());
        console.log(" *** SEARCH EDITING PROMISES ALL SUCCESS *** ");
      });
  };
};

// DELETE SEARCH
export const deleteSearchSuccess = () => ({
  type: DELETE_SEARCH,
});
export const deleteSearch = (searchData, freelancer = false) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    searchAPI.deleteSearch(searchData).then((data) => {
      if (freelancer) {
        history.push("/search-employee-slots");
      } else {
        history.push("/");
      }
      dispatch(isFetching(false));
    });
  };
};

// REQUEST assistance of recruiter
export const requestAssistant = (request_data) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    searchAPI.requestAssistant(request_data).then(() => {
      dispatch(isFetching(false));
      dispatch(showAssistantRequestModal(true));
    });
  };
};

// TRIAL SEARCH
export const setTrialSearch = (payload) => ({
  type: TRIAL_SEARCH,
  payload,
});

export const setTrialSponsor = (payload) => ({
  type: TRIAL_SEARCH_SPONSOR,
  payload,
});

export const setTrialSponsorDevelopers = (payload) => ({
  type: TRIAL_SEARCH_SPONSOR_DEVELOPERS,
  payload,
});

export const trialSearch = (skills, company_id) => {
  let queryStr = `skill_ids=${skills}`;
  if (company_id) queryStr += `&company_id=${company_id}`;

  return (dispatch) => {
    dispatch(isFetching(true));
    searchAPI.trialSearch(queryStr).then((response) => {
      dispatch(setTrialSearch(response.data.searchResults));
      dispatch(setTrialSponsor(response.data.skillSponsor));
      dispatch(setTrialSponsorDevelopers(response.data.skillSponsorDevelopers));
      dispatch(getSkillSponsor(skills[0]));
      dispatch(isFetching(false));
    }).catch((error) => {
      console.log('===error', error);
    });
  };
};

export const setSelectedTrialDeveloper = (payload) => ({
  type: SELECTED_TRIAL_DEVELOPER,
  payload,
});

// SET CURRENT COMPANY
export const setCurrentCompany = (payload) => ({
  type: CURRENT_COMPANY,
  payload,
});

export const getCurrentCompany = (skill_id) => {
  return (dispatch) => {
    skillsAPI.getSkillSponsor(skill_id).then((response) => {
      dispatch(setCurrentCompany(response.data.company));
    });
  };
};

// SET SKILL SPONSOR
export const setSkillSponsor = (payload) => ({
  type: SKILL_SPONSOR,
  payload,
});

// SET SKILL SPONSOR
export const setSkillSponsorDevelopers = (payload) => ({
  type: SKILL_SPONSOR_DEVELOPERS,
  payload,
});

export const getSkillSponsor = (skill_id) => {
  return (dispatch) => {
    skillsAPI.getSkillSponsor(skill_id).then((response) => {
      dispatch(setSkillSponsor(response.data.company));
    });
  };
};

export default search;
