import React, { useEffect } from "react";

import styles from "./ClientLogos.module.css";
import { buildImageLink } from "../../../utils/utils";

const ClientLogos = (props) => {
  const { clients } = props;

  useEffect(() => {
    clients.forEach((client, index) => {
      if (client.logo && typeof client.logo !== "string") {
        let reader = new FileReader();
        reader.onload = function () {
          let output = document.getElementById(`client-logo-${index}`);
          output.src = reader.result;
        };
        reader.readAsDataURL(client.logo);
      }
    });
  }, [clients]);

  return (
    <div className={styles.client_logos}>
      {clients.map((client, index) => (
        <div className={styles.client_logo_wrapper} key={index}>
          <img
            className={styles.client_logo}
            id={`client-logo-${index}`}
            src={typeof client.logo === "string" && buildImageLink(client.logo)}
            alt="Client logo"
          />
        </div>
      ))}
    </div>
  );
};

export default ClientLogos;