import { history } from "../../../utils";
import Modal from "../../common/Modal/Modal";
import Preloader from "../../common/Preloader/Preloader";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import BookmarksFormContainer from "../BookmarksForm/BookmarksFormContainer";
import BookmarksListFormContainer from "../BookmarksListForm/BookmarksListFormContainer";
import SearchResultsItem from "../SearchResultsItem/SearchResultsItem";
import styles from "./Bookmark.module.css";

const Bookmark = (props) => {
  let search_results_unique_companies = 0;
  if (props.bookmarks.bookmarkData) {
    let all_ids = props.bookmarks.bookmarkData.map((item) => {
      return item.company_id;
    });
    search_results_unique_companies = Array.from(new Set(all_ids)).length;
  }

  const edit = () => {
    props.setBookmarkFormStatus(props.bookmark_id);
    props.showModal(true);
    props.setBookmarkDelete(false);
  };

  const deleteBookmarkConfirmation = () => {
    props.showModal(true);
    props.setBookmarkDelete(true);
  };

  const deleteBookmark = () => {
    props.deleteBookmark(props.bookmark_id);
    history.push("/bookmarks");
  };

  const hideBookmarksForm = () => {
    props.setResponseMessage(null);
    props.showModal(false);
    props.setBookmarkDelete(false);
  };

  return (
    <div className={`d_flex flex_wrap justify_content_center align_items_center ${styles.wrapper}`}>
      {props.bookmarks.isFetching && <Preloader />}

      {props.bookmarks.isModal && props.bookmarks.responseMessage && (
        <Modal
          title={"Amazing!"}
          message={props.bookmarks.responseMessage}
          buttons={[{ btn_text: "Ok", btn_handler: hideBookmarksForm }]}
        />
      )}

      {props.bookmarks.isModal && props.bookmarks.bookmarkFormStatus && (
        <Modal showModal={props.showModal} renderComponent={<BookmarksListFormContainer />} />
      )}

      {props.bookmarks.isModal && props.bookmarks.isBookmarkDelete && (
        <Modal
          title={"Attention!"}
          message={"You are ready to delete bookmark?"}
          buttons={[
            { btn_text: "Yes, delete", btn_handler: deleteBookmark },
            {
              btn_text: "No",
              btn_handler: () => props.showModal(false),
            },
          ]}
        />
      )}

      {props.bookmarks.isModal && props.bookmarks.isBookmarkAdding && (
        <Modal showModal={props.showModal} renderComponent={<BookmarksFormContainer />} />
      )}

      {props.bookmarks.isModal && props.bookmarks.responseMessage && (
        <Modal
          title={"Amazing!"}
          message={props.bookmarks.responseMessage}
          buttons={[{ btn_text: "Ok", btn_handler: hideBookmarksForm }]}
        />
      )}

      <DashboardMenuContainer />

      <div className={styles.search_results_wrapper}>
        <h1 className={styles.title}>Bookmarks / {props.bookmarks.bookmarkName}</h1>

        <div className={`${styles.bookmark_actions} d_flex justify_content_center`}>
          <button
            className={`${styles.edit_bookmark} ${!props.search.edit_mode_disabled && styles.active}`}
            onClick={edit}
          >
            Edit bookmark
          </button>
          <button className={styles.delete_bookmark} onClick={deleteBookmarkConfirmation}>
            Delete bookmark
          </button>
        </div>

        {props.bookmarks.bookmarkData && props.bookmarks.bookmarkData.length > 0 && (
          <div className={styles.search_statistics}>
            <span className={styles.label}>Saved:</span>{" "}
            <span className={styles.result_counter}>
              {props.bookmarks.bookmarkData && props.bookmarks.bookmarkData.length}
            </span>{" "}
            developer
            {props.bookmarks.bookmarkData && props.bookmarks.bookmarkData.length > 1 && "s"} from{" "}
            <span className={styles.result_counter}>{search_results_unique_companies}</span>
            {search_results_unique_companies > 1 ? " companies" : " company"}
          </div>
        )}

        {props.bookmarks.bookmarkData &&
          props.bookmarks.bookmarkData.map((bookmark) => (
            <SearchResultsItem
              key={bookmark.developer_id}
              search={bookmark}
              // company_id={props.search.current_search.company_id}
              company_id={props.company_id}
              // search_id={props.search.current_search.search_id}
              hire={props.hire}
              showModal={props.showModal}
              setAddingToBookmark={props.setAddingToBookmark}
              setCurrentDeveloperId={props.setCurrentDeveloperId}
              setCurrentDeveloperBookmarks={props.setCurrentDeveloperBookmarks}
            />
          ))}
      </div>
    </div>
  );
};

export default Bookmark;
