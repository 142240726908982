import React, { useState } from "react";
import { Switch } from "antd";

import Preloader from "../../common/Preloader/Preloader";
import Modal from "../../common/Modal/Modal";
import ProfileMenu from "../ProfileMenu";
import SettingsModal from "./SettingsModal";

import styles from "../ProfileEditing/ProfileEditing.module.css";

const Settings = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [onDailyEmail, setDailyEmail] = useState(props.auth?.company_info?.receive_emails);
  const [onDailyTelegram, setDailyTelegram] = useState(props.auth?.company_info?.receive_telegram);

  const onSubmit = () => {
    let userData = {
      receive_emails: +onDailyEmail,
      receive_telegram: +onDailyTelegram,
    };
    props.editProfile(userData, () => setShowModal(true));
  };

  return (
    <div className={styles.settings_wrapper}>
      <div className={styles.modal_wrapper}>
        {showModal && (
          <Modal
            showModal={setShowModal}
            renderComponent={<SettingsModal onSubmit={() => setShowModal(false)} />}
            noBg
          />
        )}
      </div>

      {props.auth.is_fetching && <Preloader />}

      <h2 className={styles.title}>
        Company profile
      </h2>
      <ProfileMenu />

      <div className={styles.form}>
        <div className={styles.fields_wrapper}>
          <div className={styles.settings_title}>Settings</div>
          <div className={styles.switch_wrapper}>
            <span className={styles.switch_label}>Daily email updates</span>
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              defaultChecked={onDailyEmail}
              onChange={(status) => setDailyEmail(status)}
            />
          </div>
          <div className={styles.switch_wrapper}>
            <span className={styles.switch_label}>Daily Telegram updates</span>
            <Switch
              checkedChildren="On"
              unCheckedChildren="Off"
              defaultChecked={onDailyTelegram}
              onChange={(status) => setDailyTelegram(status)}
            />
          </div>
          {props.error && <div className={`${styles.form_errors} text_center validation_message`}>{props.error}</div>}

          <div className={styles.submit_wrapper}>
            <button onClick={onSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
