import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import { getActivityStats } from "../../store/app";
import ActivityStats from "./ActivityStats";

const ActivityStatsContainer = (props) => {
  useEffect(() => {
    props.getActivityStats();
  }, []);

  return <ActivityStats stats={props.stats} activity_stats={props.activity_stats}/>;
};

let mapStateToProps = (state) => ({
  stats: state.app.stats,
  activity_stats: state.app.activity_stats,
});

export default compose(
  connect(mapStateToProps, { getActivityStats }),
  withAuthRedirect,
)(ActivityStatsContainer);
