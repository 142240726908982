import React from "react";
import Linkify from "react-linkify";
import cn from "classnames";

import linkifyDecorator from "../../utils/linkifyDecorator";
import { publicFilesUrl } from "../../constants/global-constants";
import default_profile_photo from "../../assets/img/boy.svg";
import styles from "./Chat.module.css";

const Message = (props) => {

  const ownMessage = props.user && props.user.id === props.author_id;


  const renderFiles = (files) => {
    if (!files?.length) {
      return null;
    }

    if (files[0].isDeleted) {
      const message = files.length > 1
        ? 'The files are not available, please upload it again'
        : 'The file is not available, please upload it again';

      return (
        <div className={styles.filesWasDeleted}>
          {message}
        </div>
      );
    }

    return (
      <>
        {props.files.map(fileData => (
          <a
            className={styles.fileLink}
            href={fileData.url}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            {fileData.fileName}
          </a>
        ))}
      </>
    );
  };

  return (
    <div
      className={cn(styles.messageWrapper, ownMessage && styles.ownMessageWrapper)}
    >
      {props.chat_user && props.chat_user.company_id === props.author_id && (
        <img
          src={
            props.chat_user.representative_photo
              ? `${publicFilesUrl}public/img/${props.chat_user.representative_photo}`
              : default_profile_photo
          }
          alt={props.chat_user.representative_name}
          className={styles.chatPhoto}
        />
      )}

      <div className={cn(styles.message, ownMessage && props.status && styles.readMessage)}>
        <Linkify componentDecorator={linkifyDecorator}>
          {props.message && (
            <div
              className={styles.messageContent}
              dangerouslySetInnerHTML={{ __html: props.message }}
            />
          )}
          {renderFiles(props.files)}
        </Linkify>
      </div>
    </div>
  );
};

export default Message;
