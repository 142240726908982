import React from "react";
import { connect } from "react-redux";
import cn from "classnames";

import { hire, payForChat } from "../../../store/chat";
import { marketplaceChatAPI } from "../../../api/marketplace";

import styles from "./ChatWithClientButton.module.css";
import {printLeadPrice} from "../../../utils/utils";

const ChatWithClientButton = (props) => {
  const {
    disabled,
    leadData,
    payForChat,
    wrapperHandler,
    errorHandler,
    className,
    companyId,
    subscribedCompany,
  } = props;

  const connectToChat = () => {
    const message = `
      <div class="lead-message">
        <span class="purple">Hi, I’m interested in buying this lead:</span> 
        <span class="lighter">${leadData.project_name},</span> 
        Budget:&nbsp;<span class="lighter">$${leadData.budget},</span> 
        Lead price:&nbsp;<span class="lighter">${printLeadPrice(leadData.fixed_price, leadData.percentage)}</span>
      </div>
    `;

    const chatId = `${leadData.company_id}:${companyId}`;
    // const chatId = `${companyId}:${leadData.company_id}`;

    return props.hire(
      message,
      chatId,
      true,
      () => marketplaceChatAPI.increaseCounter({ search_id: leadData.id, type: "dev" }),
      errorHandler
    );
  };

  const chatToClient = () => {
    if (subscribedCompany) {
      return connectToChat();
    }

    const payData = {
      search_id: leadData.id,
      for_lead: 1,
    };

    payForChat(
      payData,
      connectToChat,
      errorHandler
    );
  };

  const chatToClientWrapper = () => {
    if (wrapperHandler) {
      return wrapperHandler(chatToClient);
    }
    chatToClient();
  };

  if (leadData.company_id === companyId) {
    return null;
  }

  let canChatToClient = true;
  if (disabled || (leadData.vetted && props?.companyVerificationChecks?.length)) {
    canChatToClient = false;
  }

  return (
    <div
      className={cn("btn", styles.chatBtn, !canChatToClient && styles.chatBtnDisabled, className)}
      onClick={() => canChatToClient && chatToClientWrapper()}
    >
      Chat with client
    </div>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.auth?.company_info?.id,
  companyVerificationChecks: state.auth.companyVerificationChecks,
});

const mapDispatchToProps = {
  payForChat,
  hire,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatWithClientButton);
