import moment from 'moment';

import { leadsAPI } from "../api/leads";
import { publicAPI } from "../api/public";

const IS_FETCHING = "ibench/developer/IS_FETCHING";
const SET_LEADS = "ibench/developer/SET_LEADS";
const SET_CURRENT_LEAD = "ibench/developer/SET_CURRENT_LEAD";

let initialState = {
  is_fetching: false,
  current_lead: {},
  leads: [],
};

const lead = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, is_fetching: action.is_fetching };
    case SET_LEADS:
      return { ...state, leads: action.leads };
    case SET_CURRENT_LEAD:
      return { ...state, current_lead: action.payload };
    default:
      return state;
  }
};

export const isFetching = (is_fetching) => ({ type: IS_FETCHING, is_fetching });
const setLeads = (leads) => ({ type: SET_LEADS, leads });
export const setCurrentLead = (payload) => ({ type: SET_CURRENT_LEAD, payload });

export const getLeadById = (leadId) => {
  return (dispatch) => {
    publicAPI.getLead(leadId).then((response) => {
      const leadData = response.data;
      dispatch(setCurrentLead(leadData));
    });
  };
};

export const getLeads = () => {
  return (dispatch) => {
    leadsAPI.getLeads().then((response) => {
      dispatch(setLeads(response.data));
    });
  };
};


const preparedData = (formData) => {
  // if (formData.fixed_price) {
  //   delete formData.percentage;
  // } else {
  //   delete formData.fixed_price;
  // }

  delete formData.price_type;
  const start = typeof formData.start === 'string' ? moment(formData.start) : formData.start;
  formData.start = start.format('YYYY-MM-DD');

  return formData;
};

export const addLead = (formData, callback) => {
  formData = preparedData(formData);

  return (dispatch) => {
    dispatch(isFetching(true));

    leadsAPI.addLead(formData).then(() => {
      dispatch(isFetching(false));
      callback && callback();
    });
  };
};

export const updateLead = (formData, callback) => {
  formData = preparedData(formData);

  return (dispatch) => {
    dispatch(isFetching(true));

    leadsAPI.updateLead(formData).then(() => {
      dispatch(isFetching(false));
      callback && callback();
    });
  };
};

export default lead;
