import { chatAPI } from "api/chat";
import { authAPI } from "api/auth";
import { history } from "utils";
import { getCompanyData } from "./auth";
import store from "./store";
import { notification } from "antd";

const IS_FETCHING = "ibench/chat/IS_FETCHING";
const SET_CHAT_LIST = "ibench/chat/SET_CHAT_LIST";
const SET_UNREAD_CHATS_COUNTER = "ibench/chat/SET_UNREAD_CHATS_COUNTER";
const SET_CHAT_SEEN = "ibench/chat/SET_CHAT_SEEN";
const SET_NEW_CHAT_INFO = "ibench/chat/SET_NEW_CHAT_INFO";
const SET_CHAT_ID = "ibench/chat/SET_CHAT_ID";
const SET_CHAT_USER = "ibench/chat/SET_CHAT_USER";
const UPDATE_MESSAGE_INPUT = "ibench/chat/UPDATE_MESSAGE_INPUT";
const SET_CHAT_MESSAGES = "ibench/chat/SET_CHAT_MESSAGES";
const SET_CHAT_ONLINE_USERS = "ibench/chat/SET_CHAT_ONLINE_USERS";
const SET_CHAT_READ_MESSAGES = "ibench/chat/SET_CHAT_READ_MESSAGES";
const ADD_TODAY_FILES = "ibench/chat/ADD_TODAY_FILES";
const SET_CHAT_INFO_STATE = "ibench/chat/SET_CHAT_INFO_STATE";

let initialState = {
  is_fetching: false,
  chat_list: null,
  today_files: 0,
  unread_chats_counter: 0,
  new_chat_info: null,
  chat_id: null,
  chat_user: null,
  chat_messages: [],
  message_input: "",
  online_users: [],
  collapsed_chat_info: localStorage.getItem('collapsed_chat_info') === 'true',
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return {
        ...state,
        is_fetching: action.is_fetching,
      };
    case UPDATE_MESSAGE_INPUT:
      return {
        ...state,
        message_input: action.body,
      };
    case SET_CHAT_LIST:
      return {
        ...state,
        chat_list: action.chatListData.chatList,
        today_files: action.chatListData.todayFiles,
      };

    case SET_UNREAD_CHATS_COUNTER:
      let counter = 0;

      state.chat_list.forEach((item) => {
        if (item.status === 0 && action.company_id && item.author_id !== action.company_id) {
          counter++;
        }
      });
      return {
        ...state,
        unread_chats_counter: counter,
      };

    case SET_CHAT_SEEN:
      if (state.chat_list) {
        const index = state.chat_list.findIndex((obj) => obj.chat_id === action.payload);

        return {
          ...state,
          chat_list: [
            ...state.chat_list.slice(0, index),
            {
              ...state.chat_list[index],
              status: 1,
            },
            ...state.chat_list.slice(index + 1),
          ],
        }
      }

    case SET_NEW_CHAT_INFO:
      return {
        ...state,
        new_chat_info: action.payload,
      };
    case SET_CHAT_ID:
      return {
        ...state,
        chat_id: action.chat_id,
      };
    case SET_CHAT_USER:
      return {
        ...state,
        chat_user: action.chat_user,
      };

    case SET_CHAT_READ_MESSAGES:
      const messages = state.chat_messages[action.chat_id];
      messages.forEach(message => message.status = 1);
      return {
        ...state,
        chat_messages: {
          ...state.chat_messages,
          [action.chat_id]: [...messages],
        },
      };

    case SET_CHAT_ONLINE_USERS:
      return {
        ...state,
        online_users: action.payload,
      };

    case ADD_TODAY_FILES:
      return {
        ...state,
        today_files: state.today_files + action.new_files,
      };

    case SET_CHAT_MESSAGES:
      let merged_messages;
      let old_messages = state.chat_messages[action.payload.chat_id];
      let new_messages = action.payload.messages;

      // order messages by date
      // new_messages.sort(function (x, y) {
      //   let date1 = new Date(x.created);
      //   let date2 = new Date(y.created);
      //   return date1 - date2;
      // });
      //
      // return {
      //   ...state,
      //   chat_messages: {
      //     ...state.chat_messages,
      //     [action.payload.chat_id]: [...new_messages],
      //   },
      // };

      if (old_messages) {
        var ids = new Set(old_messages.map((d) => d.message_id));
        merged_messages = [...old_messages, ...new_messages.filter((d) => !ids.has(d.message_id))];
        // order messages by date
        merged_messages.sort(function (x, y) {
          let date1 = new Date(x.created);
          let date2 = new Date(y.created);
          return date1 - date2;
        });

        return {
          ...state,
          chat_messages: {
            ...state.chat_messages,
            [action.payload.chat_id]: [...merged_messages],
          },
        };
      } else {
        // order messages by date
        new_messages.sort(function (x, y) {
          let date1 = new Date(x.created);
          let date2 = new Date(y.created);
          return date1 - date2;
        });
        return {
          ...state,
          chat_messages: {
            ...state.chat_messages,
            [action.payload.chat_id]: [...new_messages],
          },
        };
      }

    case SET_CHAT_INFO_STATE:
      const collapsed_chat_info = !state.collapsed_chat_info;
      localStorage.setItem('collapsed_chat_info', collapsed_chat_info);

      return {
        ...state,
        collapsed_chat_info,
      };

    default:
      return state;
  }
};

// FETCHING
export const isFetching = (is_fetching) => ({
  type: IS_FETCHING,
  is_fetching,
});

// UPDATE FIELD
export const updateMessageInput = (body) => ({
  type: UPDATE_MESSAGE_INPUT,
  body: body,
});

// SET CHAT ID
export const setChatId = (chat_id) => ({
  type: SET_CHAT_ID,
  chat_id,
});

// SET CHAT USER
const setChatUser = (chat_user) => ({
  type: SET_CHAT_USER,
  chat_user,
});

export const setNewChatInfo = (payload) => ({
  type: SET_NEW_CHAT_INFO,
  payload,
});

// GET CHAT LIST
export const setChatList = (chatListData) => ({
  type: SET_CHAT_LIST,
  chatListData,
});

export const getChatList = (chat_id) => {
  return (dispatch) => {
    chatAPI.getChatList().then((data) => {
      const { chatList } = data;
      if (chatList.length) {
        dispatch(setChatList(data));
        if (chat_id) {
          // if chat_id has value
          let chat_user = chatList.filter((item) => {
            return item.chat_id === chat_id;
          });
          if (chat_user[0]) {
            dispatch(setChatUser(chat_user[0].user));
          }
        } else if (chatList[0]) {
          // if response not empty
          dispatch(setChatUser(chatList[0].user));
          dispatch(setChatId(chatList[0].chat_id));
          dispatch(getChatMessages(chatList[0].chat_id));
          history.push(`/chat/${chatList[0].chat_id}`);
        }
      }
    });
  };
};

// SET UNREAD CHATS COUNTER
export const setUnreadChatsCounter = (company_id) => ({
  type: SET_UNREAD_CHATS_COUNTER,
  company_id,
});

// GET UNREAD CHATS COUNTER
export const getUnreadChatsCounter = () => {
  return (dispatch) => {
    let company_id = store.getState().auth.company_info.id;

    chatAPI.getChatList().then((data) => {
      if (data.chatList.length) {
        dispatch(setChatList(data));
        dispatch(setUnreadChatsCounter(company_id));
      }
    });
  };
};

// GET UNREAD CHATS COUNTER
export const deleteChat = (chat_id, callback) => {
  return () => {
    chatAPI.deleteChat(chat_id).then((data) => {
      if (data) {
        callback && callback();
        history.push('/chat');
      }
    });
  };
};

// SET CHAT SEEN
export const setChatSeen = (payload) => ({
  type: SET_CHAT_SEEN,
  payload,
});
export const sendChatSeen = (chat_id, callback) => {
  return (dispatch) => {
    chatAPI.sendChatSeen(chat_id).then((data) => {
      dispatch(setChatSeen(chat_id));
      dispatch(setUnreadChatsCounter());
      callback && callback();
    });
  };
};

// GET CHAT MESSAGES
export const setChatMessages = (payload) => ({
  type: SET_CHAT_MESSAGES,
  payload,
});

export const setChatReadMessages = (chat_id) => ({
  type: SET_CHAT_READ_MESSAGES,
  chat_id,
});

export const setChatOnlineStatuses = (onlineUsers) => ({
  type: SET_CHAT_ONLINE_USERS,
  payload: onlineUsers,
});

// export const setChatOnlineStatuses = (onlineUsers) => ({
//   type: SET_CHAT_ONLINE_USERS,
//   onlineUsers,
// });

export const getChatMessages = (chat_id, message_id) => {
  return (dispatch) => {
    chatAPI.getChatMessages(chat_id, message_id).then((data) => {
      dispatch(setChatMessages({ chat_id, messages: data }));
    });
  };
};

// SEND MESSAGE
export const addTodayFiles = (new_files) => ({
  type: ADD_TODAY_FILES,
  new_files,
});

export const sendMessage = (message, chat_id, files) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    chatAPI.sendMessage(message, chat_id, false, files).then(() => {
      if (files) {
        dispatch(addTodayFiles(files.length));
      }
      dispatch(getChatMessages(chat_id));
      dispatch(isFetching(false));
    }).catch((error) => {
      const message = error?.message || "Internal server error";

      notification.error({
        message,
        placement: "topRight",
        duration: 2,
      });
      dispatch(isFetching(false));
    });
  };
};

// HIRE
export const hire = (message, chat_id, for_outstaff, callback, errorCallback) => {
  return (dispatch) => {
    chatAPI
    .sendMessage(message, chat_id, for_outstaff)
    .then(() => {
      dispatch(getChatMessages(chat_id));

      const user = JSON.parse(localStorage.getItem("user"));
      dispatch(getCompanyData(user.token));
      callback?.();

      const chatParts = chat_id.split(':');
      const chatPath = `/chat/${chatParts[0]}:${chatParts[1]}`;
      // const chatPath = `/chat/${chatParts[1]}:${chatParts[0]}`;

      history.push(chatPath);
    })
    .catch(() => {
      return errorCallback?.();
    });
  };
};

export const payForChat = (data, callback, errorCallback) => {
  return () => {
    authAPI.payForChat(data).then((response) => {
      if (response.data.error) {
        errorCallback && errorCallback();
        return;
      }

      callback && callback(response.data);
    });
  };
};

export const toggleChatInfo = () => ({
  type: SET_CHAT_INFO_STATE,
});

export default chatReducer;
