import { history } from "utils";
import { publicAPI } from "../api/public";

const SET_CURRENT_COMPANY = "ibench/company/SET_CURRENT_COMPANY";
const SET_VERIFIED_COMPANIES = "ibench/company/SET_VERIFIED_COMPANIES";

let initialState = {
  currentCompany: null,
  verifiedCompanies: [],
};

const company = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_COMPANY:
      return { ...state, currentCompany: action.payload };

    case SET_VERIFIED_COMPANIES:
      return { ...state, verifiedCompanies: action.payload };

    default:
      return state;
  }
};

// GET OUTSOURCE SEARCH
const setCurrentCompany = (payload) => ({ type: SET_CURRENT_COMPANY, payload });

export const getCompany = (companyId) => {
  return (dispatch) => {
    publicAPI.getCompany(companyId).then((data) => {
      dispatch(setCurrentCompany(data?.companyInfo));
    });
  };
};

// GET OUTSOURCE SEARCH
const setVerifiedCompanies = (payload) => ({ type: SET_VERIFIED_COMPANIES, payload });

export const getVerifiedCompanies = () => {
  return (dispatch) => {
    publicAPI.getVerifiedCompanies().then((data) => {
      dispatch(setVerifiedCompanies(data?.companies));
    });
  };
};

export const getVerifiedCompaniesByService = (serviceId) => {
  return (dispatch) => {
    publicAPI.getVerifiedCompaniesByService(serviceId).then((data) => {
      dispatch(setVerifiedCompanies(data?.companies));
    });
  };
};

export default company;
