import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import cn from "classnames";

import default_developer_photo from "../../../assets/img/man.svg";
import default_company_photo from "../../../assets/img/rowing.svg";
import { apiUrl, publicFilesUrl } from "../../../constants/global-constants";
import PriceIndicatorContainer from "../../common/PriceIndicator/PriceIndicatorContainer";
import Verified from "../../common/Verified/Verified";
import { history } from "../../../utils";

import mobile_verified_icon from "../../../assets/img/mobile_verified.svg";

import styles from "./SearchResultsItem.module.css";

const SearchResultsItem = (props) => {
  const addToBookmark = (developerId, developerBookmarks) => {
    props.setCurrentDeveloperId(developerId);
    props.setCurrentDeveloperBookmarks(developerBookmarks);
    props.setAddingToBookmark(true);
    props.showModal(true);
  };

  const hire = () => {
    if (props.setSelectedTrialDeveloper) {
      props.setSelectedTrialDeveloper({
        developer_id: props.search.developer_id,
        company_id: props.search.company_id,
      });
      history.push(`/registration-quick/${props.search.developer_id}`);
      return;
    }

    if (props.hire) {
      const developerLink = `${apiUrl}developer/${props.search.developer_id}`;

      props.hire(
        `Hi, we would like to chat about your Developer: <a href="${developerLink}">${developerLink}</a>`,
        `${props.company_id}:${props.search.company_id}`
      );
      props.incrementCTR(props.search.developer_id);
    }
  };

  const skillsList = useMemo(() => {
    if (props.search.skills.length) {
      const skillsArray = props.search.skills.map(skill => skill.skill_name);
      return skillsArray.join(', ');
    }
    return '';
  }, [props.search.skills]);

  return (
    <>
      <div className={cn("d_flex", "flex_wrap", "justify_content_center", "align_items_center", "mobile_hidden", styles.wrapper)}>
        <div className={styles.developer_info}>
          <div className={styles.left_col}>
            <NavLink to={`/developer/${props.search.developer_id}`} target="_blank">
              <img
                src={
                  props.search.photo
                    ? `${publicFilesUrl}public/img/${props.search.photo}`
                    : default_developer_photo
                }
                alt={props.search.name}
                className={styles.developer_photo}
              />
            </NavLink>
            {props.search.is_pro_account === 1 && <div className={`pro_icon ${styles.pro_icon}`}>Pro</div>}
            <NavLink className={styles.logo_company_link} to={`/company/${props.search.company_id}`} target="_blank">
              <img
                src={
                  props.search.company_logo
                    ? `${publicFilesUrl}public/img/${props.search.company_logo}`
                    : default_company_photo
                }
                alt={props.search.company_name}
                className={styles.company_logo}
              />
            </NavLink>
            {props.search.company_verified === 1 && <Verified className={styles.verified}/>}
          </div>
          <div className={styles.right_col}>
            <div className={styles.top_info}>
              <div className={styles.name_job_title_wrapper}>
                <div className={styles.developerNameWrapper}>
                  <h2 className={styles.developer_name}>
                    <NavLink
                      to={`/developer/${props.search.developer_id}`}
                      className={styles.developer_name_link}
                      target="_blank"
                    >
                      {props.search.name}
                    </NavLink>
                  </h2>
                  {props.company_id && props.search.bookmarks && props.search.bookmarks.length > 0 && (
                    <div
                      className={styles.savedBookmark}
                      onClick={() =>
                        addToBookmark(props.search.developer_id, props.search.bookmarks)
                      }
                    >
                      Saved on Bookmarks
                    </div>
                  )}
                  {props.company_id && props.search.bookmarks && !props.search.bookmarks.length > 0 && (
                    <div
                      className={styles.addBookmark}
                      onClick={() =>
                        addToBookmark(props.search.developer_id, props.search.bookmarks)
                      }
                    >
                      Add to Bookmark
                    </div>
                  )}
                </div>

                <div className={styles.jobTitleWrapper}>
                  <div className={styles.jobTitle}>{props.search.job_title_name}</div>
                  <div className={styles.updateInfo}>
                    <span className={styles.updateInfoLabel}>Update:</span>{" "}
                    {moment(props.search.developer_updated).format("DD MMM YYYY")}
                  </div>
                </div>
              </div>

              {!props.search.is_single_developer && (
                <div>
                  <div className={styles.rate}>${props.search.rate}/h</div>
                  <PriceIndicatorContainer rate_difference={props.search.rate_difference}/>
                </div>
              )}

              {!!props.search.is_single_developer && (
                <div>
                  <div className={styles.rate_month}>
                    Salary: {props.search.rate_month} $/month
                  </div>
                </div>
              )}
            </div>

            <div className={styles.experience_english_wrapper}>
              <span className={styles.experience_wrapper}>
                <span className={styles.position_level}>{props.search.position_level_name}, </span>
                <span className={styles.experience}>{props.search.experience} years exp.</span>
              </span>
              {props.search.position_calculated === 1 && (
                <div className={styles.confirmed}>Confirmed the seniority level</div>
              )}
              <span className={styles.english_level}>
                English: {props.search.english_level_name} ({props.search.cerf_level})
              </span>
            </div>
            <div
              className={styles.general_info}
              dangerouslySetInnerHTML={{ __html: props.search.general_info }}
            />
            {props.search.skills.length > 0 && (
              <div className={styles.skills_wrapper}>
                Skills: {skillsList}
              </div>
            )}
          </div>
        </div>
        <div className={styles.hireWrapper}>
          {(props.setSelectedTrialDeveloper || props.hire) && (
            <button className={styles.hireBtn} onClick={hire} />
          )}
        </div>
      </div>

      <div className={cn(styles.mobileWrapper, "mobile_visible")}>
        <div className={styles.leftColumn}>
          <NavLink to={`/developer/${props.search.developer_id}`} target="_blank">
            <img
              src={
                props.search.photo
                  ? `${publicFilesUrl}public/img/${props.search.photo}`
                  : default_developer_photo
              }
              alt={props.search.name}
              className={styles.developer_photo}
            />
          </NavLink>
          <NavLink className={styles.logo_company_link} to={`/company/${props.search.company_id}`} target="_blank">
            <img
              src={
                props.search.company_logo
                  ? `${publicFilesUrl}public/img/${props.search.company_logo}`
                  : default_company_photo
              }
              alt={props.search.company_name}
              className={styles.company_logo}
            />
          </NavLink>
          {props.search.company_verified === 1 && <img src={mobile_verified_icon} className={styles.mobileVerified}/>}
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.updateDate}>
            Update:&nbsp;
            {moment(props.search.developer_updated).format("DD MMM YYYY")}
          </div>
          <div className={styles.mobileTop}>
            <div className={styles.leftColumn}>
              <div className={styles.mobileDeveloperName}>
                {props.search.name}
              </div>
              <div className={styles.mobileDeveloperDetails}>
                {props.search.job_title_name}
              </div>
              <div className={styles.mobileDeveloperDetails}>
                <span className={styles.position_level}>{props.search.position_level_name}, </span>
                <span className={styles.experience}>{props.search.experience} years exp.</span>
              </div>
            </div>
            <div className={styles.mobileRate}>
              ${props.search.rate}/h
            </div>
          </div>
          {props.search.position_calculated === 1 && (
            <div className={styles.confirmed}>Confirmed the seniority level</div>
          )}
          <div
            className={styles.general_info}
            dangerouslySetInnerHTML={{ __html: props.search.general_info }}
          />
          {props.search.skills.length > 0 && (
            <div className={styles.skills_list}>
              Skills: {skillsList}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchResultsItem;
