const SHOW_MODAL = "ibench/modal/SHOW_MODAL";
const HIDE_MODAL = "ibench/modal/HIDE_MODAL";

let initialState = {
  show: false,
  modalData: null,
};

const modal = (state = initialState, action) => {
  switch (action.type) {

    case SHOW_MODAL:
      return { ...state, show: true, modalData: action.payload };

    case HIDE_MODAL:
      return { ...state, show: false, modalData: null };

    default:
      return state;
  }
};


export const showInfoModal = (data) => {
  return {
    type: SHOW_MODAL,
    payload: data,
  };
};

export const closeInfoModal = () => {
  return {
    type: HIDE_MODAL,
  };
};

export default modal;
