import React, { useState, useEffect } from "react";
import { change } from "redux-form";
import { useDispatch } from "react-redux";

import { publicFilesUrl } from "../../../constants/global-constants";
import styles from "./UploadImageButton.module.css";

const UploadImageButton = (props) => {
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.meta.initial && typeof props.meta.initial === "string") {
      setSelectedImage(`${publicFilesUrl}public/img/${props.meta.initial}`);
    }
  }, [props.meta.initial]);

  useEffect(() => {
    if (!props.input.value || props.input.value instanceof FileList && !props.input.value?.length) {
      setSelectedImage(null);
    }
  }, [props.input.value]);

  useEffect(() => {
    dispatch(change(props.meta.form, props.input.name, selectedImage));

    if (selectedImage && typeof selectedImage !== "string") {
      let reader = new FileReader();
      reader.onload = function () {
        let output = document.getElementById(`${props.input.name}-preview`);
        output.src = reader.result;
      };
      reader.readAsDataURL(selectedImage);
    }
  }, [dispatch, props.meta.form, props.input.name, selectedImage, props.meta.initial]);

  const onChange = (e) => {
    props.input.onChange(e.target.files);
    if (e.target.files.length) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const removeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.imagesList}>

        <div className={styles.inputWrapper}>
          <label
            htmlFor={props.input.name}
            className={styles.imageLabel}
          >
            <button type="button">{props.label}</button>
          </label>
          <input
            id={props.input.name}
            type="file"
            accept=".png, .jpg, .jpeg"
            className={styles.input}
            onChange={(e) => onChange(e)}
          />
        </div>
      </div>
      <div className={styles.previewWrapper}>
        {!!selectedImage && (
          <>
            <img
              id={`${props.input.name}-preview`}
              className={styles.imagePreview}
              src={typeof selectedImage === "string" && selectedImage}
              alt="Preview"
            />
            <span className={styles.removeImage} onClick={removeImage}/>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadImageButton;
