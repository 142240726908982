import React, { useState, useCallback, useEffect } from "react";
import { Field, reduxForm, change } from "redux-form";
import cn from "classnames";

import {
  Input,
  SelectInputKeyValue,
  renderRadioButton,
  Textarea,
} from "../../common/FormsControls/FormControls";
import countries_list from "../../../utils/countries_list";
import { leadSalesTermsOptions, vettedOptions } from "../../../constants";

import styles from "./LeadForm.module.css";

const PRICE_TYPE_PRICE = "price";
const PRICE_TYPE_PERCENTAGE = "percentage";
const PRICE_TYPE_MONEY = "money";

const TYPE_QUALIFIED = "qualified";
const TYPE_UNQUALIFIED = "unqualified";

const QUALIFIED_LEVEL_COLD= "cold";
const QUALIFIED_LEVEL_WARM= "warm";
const QUALIFIED_LEVEL_HOT= "hot";

const WHITE_LABEL= "white label";
const DIRECT_CONTRACT = "direct contract";

const countryList = countries_list.map(item => ({
  key: item.label,
  value: item.label,
}));

let FirstPartForm = (props) => {
  const [priceType, setPriceType] = useState(PRICE_TYPE_PRICE);

  useEffect(() => {
    let priceType;

    if (props.initialValues?.money_percentage) {
      priceType = PRICE_TYPE_MONEY;
    } else if (props.initialValues?.percentage) {
      priceType = PRICE_TYPE_PERCENTAGE;
    } else {
      priceType = PRICE_TYPE_PRICE;
    }

    setPriceType(priceType);
  }, [props.initialValues]);

  const changePriceTypeHandler = (event) => {
    const { value } = event.target;

    const emptyFields = [];

    switch (value) {
      case PRICE_TYPE_PRICE:
        emptyFields.push("percentage", "money_percentage", "money_fixed_price");
        break;

      case PRICE_TYPE_PERCENTAGE:
        emptyFields.push("fixed_price", "money_percentage", "money_fixed_price");
        break;

      default:
        emptyFields.push("fixed_price", "percentage");
    }

    emptyFields.forEach(field => props.dispatch(change("leadFirstPartForm", field, "")));

    setPriceType(value);
  };

  const fixedPriceGeneralValidation = (value) => {
    if (!value) {
      return 'is required';
    }

    if (!isPositiveInteger(value)) {
      return 'must be positive integer';
    }
  };

  const percentageGeneralValidation = (value) => {
    if (!value) {
      return 'is required';
    }

    if (!isPositiveInteger(value)) {
      return 'must be positive integer';
    }

    if (value >= 100) {
      return 'must be less than 100';
    }
  };

  const fixedPriceValidation = useCallback((value) => {
    if (priceType === PRICE_TYPE_PRICE) {
      return fixedPriceGeneralValidation(value);
    }
  }, [priceType]);

  const percentageValidation = useCallback((value) => {
    if (priceType === PRICE_TYPE_PERCENTAGE) {
      return percentageGeneralValidation(value);
    }
  }, [priceType]);

  const moneyFixedPriceValidation = useCallback((value) => {
    if (priceType === PRICE_TYPE_MONEY) {
      return fixedPriceGeneralValidation(value);
    }
  }, [priceType]);

  const moneyPercentageValidation = useCallback((value) => {
    if (priceType === PRICE_TYPE_MONEY) {
      return percentageGeneralValidation(value);
    }
  }, [priceType]);

  function isPositiveInteger(value) {
    if (!value) {
      return false;
    }
    value = value.toString();
    const n = Math.floor(Number(value));
    return n !== Infinity && String(n) === value && n >= 0;
  }

  return (
    <form onSubmit={props.handleSubmit} className={cn(styles.form, styles.aboutLeadForm)}>
      <div className={styles.fields_wrapper}>
        <div className={styles.aboutProject}>About Lead</div>

        <div className={cn(styles.projectTypeWrapper, styles.vettedWrapper)}>
          <div className={cn(styles.fieldTitle, styles.titleMargin)}>
            Only for verified buyers (companies)
          </div>
          <Field
            component={SelectInputKeyValue}
            name="vetted"
            label=""
            options={vettedOptions}
            input_design={styles.projectTypeSelect}
            withoutBlankValue
            withErrorSign
          />
        </div>

        <div className={styles.fieldTitle}>Lead type</div>

        <div className={styles.leadType}>
          <Field
            component={renderRadioButton}
            className={styles.radioButton}
            type="radio"
            name="type"
            value={TYPE_QUALIFIED}
            label="Qualified lead"
          />
          <Field
            component={renderRadioButton}
            className={styles.radioButton}
            type="radio"
            name="type"
            value={TYPE_UNQUALIFIED}
            label="Unqualified lead"
          />
        </div>

        <div className={styles.fieldTitle}>Lead qualification level</div>

        <div className={styles.qualificationLevelType}>
          <Field
            component={renderRadioButton}
            className={styles.radioButton}
            type="radio"
            name="qualification_level"
            value={QUALIFIED_LEVEL_COLD}
            label="Cold"
          />
          <Field
            component={renderRadioButton}
            className={styles.radioButton}
            type="radio"
            name="qualification_level"
            value={QUALIFIED_LEVEL_WARM}
            label="Warm"
          />
          <Field
            component={renderRadioButton}
            className={styles.radioButton}
            type="radio"
            name="qualification_level"
            value={QUALIFIED_LEVEL_HOT}
            label="Hot"
          />
        </div>

        <div className={styles.qualificationLevelHelp}>
          As part of the lead qualification process, each lead is assigned a qualification level ("cold", "warm",
          "hot" for example), describing the potential interest of the business partner (prospect) in terms of the
          probability of converting the lead into an opportunity.
        </div>

        <div className={styles.fieldTitle}>Working under white label</div>

        <div className={styles.whiteLabel}>
          <Field
            component={renderRadioButton}
            className={styles.radioButton}
            type="radio"
            name="white_label"
            value={WHITE_LABEL}
            label="White label"
          />
          <Field
            component={renderRadioButton}
            className={styles.radioButton}
            type="radio"
            name="white_label"
            value={DIRECT_CONTRACT}
            label="Direct contract with client"
          />
        </div>

        <div className={styles.fieldTitle}>Lead price</div>

        <div className={cn(styles.rateBlock, { active: priceType === PRICE_TYPE_PRICE })}>
          <Field
            component={renderRadioButton}
            className={styles.radioButton}
            type="radio"
            name="price_type"
            value={PRICE_TYPE_PRICE}
            onChange={changePriceTypeHandler}
            checked={priceType === PRICE_TYPE_PRICE}
            label="Fixed Price"
            withErrorSign
          />
          <div className={cn(styles.afterRadio, styles.priceWrapper)}>
            <Field
              component={Input}
              type="number"
              name="fixed_price"
              input_design={styles.priceInput}
              validate={fixedPriceValidation}
              disabled={priceType !== PRICE_TYPE_PRICE}
              withErrorSign
            />
            <span className={styles.rateLabel}>USD</span>
          </div>
        </div>

        <div className={cn(styles.rateBlock, { active: priceType === PRICE_TYPE_PERCENTAGE })}>
          <Field
            component={renderRadioButton}
            className={styles.radioButton}
            type="radio"
            name="price_type"
            value={PRICE_TYPE_PERCENTAGE}
            onChange={changePriceTypeHandler}
            checked={priceType === PRICE_TYPE_PERCENTAGE}
            label="Percentage of the project cost"
            withErrorSign
          />
          <div className={cn(styles.afterRadio, styles.percentageWrapper)}>
            <Field
              component={Input}
              type="number"
              name="percentage"
              input_design={styles.percentageInput}
              validate={percentageValidation}
              disabled={priceType !== PRICE_TYPE_PERCENTAGE}
              withErrorSign
            />
            <span className={styles.rateLabel}>%</span>
          </div>
        </div>

        <div className={cn(styles.rateBlock, styles.moneyBlock, { active: priceType === PRICE_TYPE_MONEY })}>
          <Field
            component={renderRadioButton}
            className={styles.radioButton}
            type="radio"
            name="price_type"
            value={PRICE_TYPE_MONEY}
            onChange={changePriceTypeHandler}
            checked={priceType === PRICE_TYPE_MONEY}
            label="Money, $"
            withErrorSign
          />
          <div className={cn(styles.afterRadio, styles.priceWrapper)}>
            <Field
              component={Input}
              type="number"
              name="money_fixed_price"
              input_design={styles.priceInput}
              validate={moneyFixedPriceValidation}
              disabled={priceType !== PRICE_TYPE_MONEY}
              withErrorSign
            />
            <span className={styles.rateLabel}>+</span>
          </div>
          <div className={cn(styles.afterRadio, styles.percentageWrapper)}>
            <Field
              component={Input}
              type="number"
              name="money_percentage"
              input_design={styles.percentageInput}
              validate={moneyPercentageValidation}
              disabled={priceType !== PRICE_TYPE_MONEY}
              withErrorSign
            />
            <span className={styles.rateLabel}>%</span>
          </div>
        </div>

        <div className={styles.projectTypeWrapper}>
          <div className={cn(styles.fieldTitle, styles.titleMargin)}>
            Lead sales terms (payment stages, prepayment)
          </div>
          <Field
            component={SelectInputKeyValue}
            name="lead_sales_terms"
            label=""
            options={leadSalesTermsOptions}
            input_design={styles.projectTypeSelect}
            withoutBlankValue
            withErrorSign
          />
        </div>

        <div className={cn(styles.descriptionWrapper, styles.commentWrapper)}>
          <Field
            component={Textarea}
            type="text"
            name={"lead_sales_comment"}
            placeholder="Comment"
            input_design={styles.projectDescription}
            withErrorSign
          />
        </div>

        <div className={cn(styles.projectTypeWrapper, styles.countryWrapper)}>
          <div className={styles.fieldTitle}>
            Lead country
          </div>
          <Field
            component={SelectInputKeyValue}
            name="country"
            label=""
            options={countryList}
            input_design={styles.countrySelect}
            withErrorSign
          />
        </div>

        <div className={`d_flex ${styles.buttons}`}>
          <button className={`btn ${styles.submit}`}>Next</button>
        </div>
      </div>
    </form>
  );
};

FirstPartForm = reduxForm({
  form: "leadFirstPartForm",
  enableReinitialize: true,
})(FirstPartForm);

export default FirstPartForm;
