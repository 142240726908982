import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Popover } from "antd";
import cn from "classnames";

import { isAfterNow } from "../../../utils/utils";
import { chatListener, socketRegister } from "../../../utils/socket";
import { acceptTerms } from "../../../store/auth";
import { getNewsList, markAsRead } from "../../../store/news";
import { getUnreadChatsCounter } from "../../../store/chat";
import { closeInfoModal } from "../../../store/modal";
import { publicFilesUrl } from "../../../constants";
import Verified from "../../common/Verified/Verified";
import Modal from "../../common/Modal/Modal";
import InfoModal from "../../common/InfoModal/InfoModal";
import TermsModal from "../TermsModal/TermsModal";
import NewsModal from "../NewsModal/NewsModal";
import WalletModal from "../../Payment/WalletModal/WalletModal";
import CompanyVerification from "../../CompanyVerification/CompanyVerification";

import default_profile_photo from "../../../assets/img/boy.svg";
import styles from "./Navigation.module.css";

const publicMenuPages = [
  "/",
  "/teams",
  "/development-teams",
  "/about",
  "/team-search",
  "/newtalents",
  "/trial-search",
  "/privacy-policy",
  "/terms-of-use",
  "/cookie-policy",
];

const Navigation = (props) => {
  const [showVerificationChecks, setShowVerificationChecks] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showNewsModal, setShowNewsModal] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);

  let {
    auth: { companyVerificationChecks, user, company_info },
    closeInfoModal,
    infoModalData,
    getUnreadChatsCounter,
    location: { pathname },
  } = props;

  const connects = +company_info?.connect_points;

  useEffect(() => {
    if (company_info && user) {
      props.getNewsList();

      chatListener(getUnreadChatsCounter);
      getUnreadChatsCounter();
      socketRegister(user.id, user.representative_name, user.type);
    }
  }, [company_info, user]);

  useEffect(() => {
    if (company_info) {
      if (!company_info.terms_accepted) {
        setShowTermsModal(true);
      } else if (props.news?.length) {
        setShowNewsModal(true);
      }
    }
  }, [company_info?.terms_accepted, props.news]);

  // const isActiveSubscription = company_info && (company_info.is_single_developer || isAfterNow(company_info.stripe_subscription_expires));
  const isActiveSubscription = true;

  if (pathname.includes("/trial-search")) {
    pathname = "/trial-search";
  }

  const submitTerms = () => {
    props.acceptTerms(() => setShowTermsModal(false));
  };

  const gotItNews = () => {
    const newsIds = props.news.map(news => news.news_id);
    props.markAsRead(newsIds, () => setShowNewsModal(false));
  };

  const names = [];

  const isCompany = !company_info?.is_single_developer;
  const subscribedCompany = company_info?.legal_documents && company_info.stripe_subscribed;

  if (company_info) {

    if (isCompany && company_info?.type === 2) {
      if (props.auth?.company_info?.representative_name) {
        names.push(props.auth?.company_info?.representative_name);
      }
  
      if (props.auth?.company_info?.company_name) {
        names.push(props.auth?.company_info?.company_name);
      }
    } else {
      names.push(`${props.auth?.company_info?.first_name} ${props.auth?.company_info?.last_name}`);
    }
  }

  const renderMenu = () => {
    if (company_info) {
      return (
        <nav className={cn(styles.companyMenuWrapper, { [styles.talentMenu]: !isCompany })}>
          <ul className={styles.companyMenu}>
            <li className={`${styles.menu_item} ${styles.home}`}>
              <NavLink
                to="/"
                className={styles.home_link}
                disabled={!isActiveSubscription}
              >
                Home
              </NavLink>
            </li>
            {isCompany && company_info.type === 2 && !subscribedCompany && (
              <li className={cn(styles.menu_item, styles.activity_stats)}>
                <div
                  className={cn(styles.wallet, { [styles.disabled]: !isActiveSubscription })}
                  onClick={() => isActiveSubscription && setShowWalletModal(true)}
                >
                  Wallet: {connects} pins
                </div>
              </li>
            )}
            <li className={`${styles.menu_item} ${styles.activity_stats}`}>
              <NavLink
                to="/stats"
                className={styles.activity_stats_link}
                disabled={!isActiveSubscription}
              >
                Daily updates
              </NavLink>
            </li>
            <li className={styles.menu_item}>
              <NavLink
                to="/news"
                className={styles.news}
                disabled={!isActiveSubscription}
              >
                News
              </NavLink>
            </li>
            <li className={styles.menu_item}>
              <a
                href="https://buy.stripe.com/8wMg2t6nl3jd50Y8wJ"
                className={styles.donate}
                target="_blank"
              >
                Donate
              </a>
            </li>
            <li className={styles.menu_item}>
              <NavLink
                to="/profile"
                className={styles.profile}
                disabled={!isActiveSubscription}
              >
                <img
                  src={
                    company_info.representative_photo
                      ? `${publicFilesUrl}public/img/${company_info.representative_photo}`
                      : default_profile_photo
                  }
                  alt={company_info.representative_name}
                  className={styles.representative_photo}
                />
                <div className={styles.name_block}>
                  {names.join(", ")}
                  {!company_info.is_single_developer && (
                    <Popover
                      content={
                        companyVerificationChecks?.length
                          ? "Click to start verification"
                          : "Verification is passed"
                      }
                    >
                      {""}
                      {company_info.type !== 1 && (
                        <Verified
                          className={cn(styles.verified, !!companyVerificationChecks?.length && styles.noVerified)}
                          isDisabled={!!companyVerificationChecks?.length}
                          onClick={() => setShowVerificationChecks(true)}
                        />
                      )}
                    </Popover>
                  )}
                </div>
              </NavLink>
              {company_info.type === 2 && company_info.is_pro_account === 1 && (
                <span className={`pro_icon ${styles.pro_icon}`}>Pro</span>
              )}
            </li>
            <li className={styles.menu_item}>
              <button className={styles.logout} onClick={props.logout}>
                Logout
              </button>
            </li>
          </ul>
        </nav>
      );
    }

    return (
      <>
        {publicMenuPages.includes(pathname) && (
          <nav className={styles.menuWrapper}>
            <ul className={styles.menu}>
              <li>
                <NavLink exact to="/">
                  Hire developers
                </NavLink>
              </li>
              <li>
                <NavLink to="/team-search">
                  Hire team
                </NavLink>
              </li>
              <li>
                <NavLink to="/newtalents">
                  New talents
                </NavLink>
              </li>
              <li>
                {/* It's link for WP (with reload of page) */}
                {/* <a href="https://ibench.net/blog/" rel="nofollow">Blog</a> */}
              </li>
            </ul>
          </nav>
        )}
        <div className={cn(styles.auth_buttons, "mobile_hidden")}>
          <NavLink to="/registration" className={cn(styles.btn, styles.btn_pink, styles.register)}>
            Register
          </NavLink>
          <NavLink to="/login" className={cn(styles.btn, styles.btn_orange, styles.login)}>
            Log in
          </NavLink>
        </div>
      </>
    );
  };

  return (
    <>
      {infoModalData.show && (
        <div className={cn(styles.modal, styles.modalInfo)}>
          <Modal
            showModal={closeInfoModal}
            renderComponent={<InfoModal {...infoModalData.modalData} onClose={closeInfoModal} />}
            noBg
          />
        </div>
      )}

      {showTermsModal && (
        <div className={styles.modal}>
          <Modal
            showModal={setShowTermsModal}
            renderComponent={<TermsModal onSubmit={submitTerms}/>}
            noBg
          />
        </div>
      )}
      {isCompany && showNewsModal && (
        <div className={styles.newsModal}>
          <Modal
            showModal={setShowNewsModal}
            renderComponent={<NewsModal gotItNews={gotItNews} news={props.news}/>}
            noBg
          />
        </div>
      )}
      {isCompany && showWalletModal && (
        <div className={styles.walletModal}>
          <Modal
            showModal={setShowWalletModal}
            renderComponent={<WalletModal closeModal={() => setShowWalletModal(false)}/>}
            noBg
          />
        </div>
      )}

      {isCompany && showVerificationChecks && (
        <CompanyVerification
          companyVerificationChecks={companyVerificationChecks}
          name={user.representative_name}
          company={user.company_name}
          setShowVerificationChecks={setShowVerificationChecks}
          isSingleDeveloper={company_info?.is_single_developer}
        />
      )}

      {renderMenu()}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  chat: state.chat,
  infoModalData: state.modal,
});

const mapDispatchToProps = {
  acceptTerms,
  getNewsList,
  markAsRead,
  closeInfoModal,
  getUnreadChatsCounter,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(Navigation));
