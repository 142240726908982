import { stopSubmit } from "redux-form";

import { authAPI } from "../api/auth";
import { history } from "../utils";
import { apiUrl, talentStepLinks } from "../constants/global-constants";
import { hire } from "./chat";
import { logout } from "./store";
import { setTheme } from "./app";
import { getPositionLevels } from "./developer";
import { getBannersList } from "./banners";

const IS_FETCHING = "ibench/auth/IS_FETCHING";
const IS_FETCHING_AUTH = "ibench/auth/IS_FETCHING_AUTH";
const SHOW_MODAL = "ibench/auth/SHOW_MODAL";
const HIDE_MODAL = "ibench/auth/HIDE_MODAL";
const SET_USER_INFO = "ibench/auth/SET_USER_INFO";
const SET_COMPANY_INFO = "ibench/auth/SET_COMPANY_INFO";
const SET_COMPANY_VERIFICATION_CHECKS = "ibench/auth/SET_COMPANY_VERIFICATION_CHECKS";
const SET_COMPANY_IN_TOP_10_CHECKS = "ibench/auth/SET_COMPANY_IN_TOP_10_CHECKS";

let initialState = {
  is_auth: false,
  is_fetching: false,
  is_fetching_auth: false,
  show_modal: false,
  user: null,
  company_info: null,
  companyVerificationChecks: [],
};

let user = JSON.parse(localStorage.getItem("user"));
if (user) {
  initialState = {
    ...initialState,
    is_auth: true,
    user,
  };
}

let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));
if (companyInfo) {
  initialState = {
    ...initialState,
    is_auth: true,
    company_info: companyInfo,
  };
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, is_fetching: action.payload };
    case IS_FETCHING_AUTH:
      return { ...state, is_fetching_auth: action.payload };
    case SHOW_MODAL:
      return { ...state, show_modal: true };
    case HIDE_MODAL:
      return { ...state, show_modal: false };
    case SET_USER_INFO:
      return { ...state, is_auth: true, user: action.payload };
    case SET_COMPANY_INFO:
      if (action.payload.marketList && action.payload.marketList[0]) {
        return {
          ...state,
          company_info: {
            ...action.payload,
            market_id: action.payload.marketList[0].market_id,
          },
        };
      } else {
        return {
          ...state,
          company_info: {
            ...action.payload,
          },
        };
      }

    case SET_COMPANY_VERIFICATION_CHECKS:
      return { ...state, companyVerificationChecks: action.payload };

    case SET_COMPANY_IN_TOP_10_CHECKS:
      return { ...state, company_info: { ...state.company_info, in_top_10: action.payload } };

    default:
      return state;
  }
};

export const isFetching = (payload) => ({ type: IS_FETCHING, payload });
export const isFetchingAuth = (payload) => ({ type: IS_FETCHING_AUTH, payload });
export const showModal = () => ({ type: SHOW_MODAL });
export const hideModal = () => ({ type: HIDE_MODAL });
export const setUserInfo = (payload) => ({ type: SET_USER_INFO, payload });
export const setCompanyInfo = (payload) => ({ type: SET_COMPANY_INFO, payload });
export const setCompanyVerificationChecks = (payload) => ({ type: SET_COMPANY_VERIFICATION_CHECKS, payload });
export const setCompanyInTop10Checks = (payload) => ({ type: SET_COMPANY_IN_TOP_10_CHECKS, payload });

export const getCompanyData = (token, callback) => {
  return (dispatch) => {
    authAPI.getCompanyData(token).then((response) => {
      if (response.status === 200) {
        const { companyInfo } = response.data;

        localStorage.setItem("companyInfo", JSON.stringify(response.data.companyInfo)); // store companyInfo
        const userPartData = JSON.parse(localStorage.getItem("user"));
        const user = {
          ...userPartData,
          ...companyInfo,
        };
        localStorage.setItem("user", JSON.stringify(user)); // store companyInfo
        dispatch(setUserInfo(user));
        dispatch(setCompanyInfo(companyInfo));
        callback?.();

      } else if (response.status === 401) {
        dispatch(logout());
      }
    });
  };
};

export const getCompanyVerificationChecks = (isSingleDeveloper = 0, callback) => {
  return (dispatch) => {
    authAPI.getCompanyVerificationChecks().then((response) => {
      dispatch(setCompanyVerificationChecks(response.data.failedChecks));
      callback && callback();
    });
  };
};

export const getCompanyInTop10Checks = () => {
  return (dispatch) => {
    authAPI.getCompanyTop10Checks().then((response) => {
      dispatch(setCompanyInTop10Checks(response.data));
    });
  };
};

const loginProceed = (email, password, callback, errorCallback) => {
  return (dispatch) => {

    authAPI.login(email, password).then((response) => {
      if (response.status === 200) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(response.data.user));
        // localStorage.setItem("token", JSON.stringify(response.data.user.token));
        dispatch(setUserInfo(response.data.user));
        dispatch(getPositionLevels());
        dispatch(setTheme("default_theme"));
        dispatch(getBannersList());
        dispatch(getCompanyData(response.data.user.token, () => {
          if (!response.data.user.is_single_developer) {
            // It fix problem with sort on Marketplace
            dispatch(getCompanyVerificationChecks(0));
            callback?.(response);
          } else {
            if (response.data.user.profile_fill_step < talentStepLinks.length) {
              const redirectLink = talentStepLinks[+response.data.user.profile_fill_step];
              history.push(redirectLink);
            }
            callback?.();
          }
        }));
      } else {
        let message = response.data.error.length ? response.data.error : "Some error";
        dispatch(stopSubmit("login", { _error: message }));
        errorCallback && errorCallback();
      }
    });
  }
};

// LOGIN
export const login = (email, password) => {
  return (dispatch) => {
    dispatch(isFetching(true));
    dispatch(loginProceed(email, password, () => {
      dispatch(isFetching(false));

      const redirect = localStorage.getItem("redirect_after_login");
      if (redirect) {
        history.push(redirect);
      }

    }, () => {
      dispatch(isFetching(false));
    }))
  };
};

// REGISTER USER
export const register = (formData, callback) => (dispatch) => {
  // return;
  const registerData = {
    email: formData.email,
    password: formData.password,
    country: formData.country,
    terms_accepted: 1,
    type: 2,
  };

  if (formData.user_type === "1") {
    registerData.is_single_developer = 1;
    registerData.first_name = formData.first_name;
    registerData.last_name = formData.last_name;
  } else if (formData.user_type === "2") {
    registerData.first_name = formData.first_name;
    registerData.last_name = formData.last_name;
    registerData.type = 1;
  } else {
    registerData.company_name = formData.company_name;
  }

  if (formData.referral) {
    registerData.referral = formData.referral;
  }

  dispatch(isFetching(true));

  authAPI.register(registerData)
    .then((response) => {
      if (response.status === 200) {
        callback?.();
        dispatch(isFetching(false));

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(response.data.user));
        dispatch(setUserInfo(response.data.user));
        dispatch(getCompanyData(response.data.user.token));
        dispatch(getCompanyVerificationChecks());

        if (formData.user_type !== "2") {
          localStorage.fireWelcome = 1;
        }
        
        history.push("/thankyou");
      } else {
        dispatch(isFetching(false));
        let message = response.data.error.length ? response.data.error : "Some error";
        dispatch(stopSubmit("registration", { _error: message }));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// ACCEPT TERMS
export const acceptTerms = (callback) => {
  return (dispatch) => {
    authAPI.acceptTerms().then(() => {
      const user = JSON.parse(localStorage.getItem("user"));
      dispatch(getCompanyData(user.token));
      callback && callback();
    });
  };
};

const pickCompanyUploadItemData = (items) => {
  const itemsLogoData = new FormData();
  if (items?.length) {
    items.forEach((itemData, index) => {
      if (!itemData) {
        return;
      }
      itemsLogoData.append(`logos[${index}]`, itemData.logo);
      itemsLogoData.append(`names[${index}]`, itemData.name);

      if (itemData.id) {
        itemsLogoData.append(`ids[${index}]`, itemData.id);
      }
    });
  }

  return itemsLogoData;
};

// EDIT USER INFO
export const editProfile = (formData, callback) => (dispatch) => {
  const editing_data = { ...formData };
  delete editing_data.representative_photo;
  delete editing_data.logo;
  delete editing_data.market_id;
  delete editing_data.url_redirect;
  delete editing_data.awards;

  dispatch(isFetching(true));

  const promises = [];

  if (editing_data?.markets?.length) {
    editing_data.markets = editing_data.markets.filter(market => !!market);
  }

  promises.push(authAPI.editProfile(editing_data));

  if (formData.representative_photo?.length) {
    let photo_data = new FormData();
    photo_data.append("file", formData.representative_photo[0]);
    promises.push(authAPI.uploadPhoto(photo_data));
  }

  if (formData?.logo?.length) {
    let logo_data = new FormData();
    logo_data.append("file", formData.logo[0]);
    logo_data.append("type", "logo"); // for adding company logo
    promises.push(authAPI.uploadPhoto(logo_data));
  }

  if (formData?.awards) {
    const awardLogoData = pickCompanyUploadItemData(formData.awards);
    promises.push(authAPI.uploadAwardLogo(awardLogoData));
  }

  Promise.all(promises)
    .then(() => {
      let user = JSON.parse(localStorage.getItem("user"));
      dispatch(getCompanyData(user.token, () => {
        dispatch(getCompanyVerificationChecks());

        callback?.();

        if (formData.url_redirect) {
          return history.push(formData.url_redirect);
        }
      })); // update user profile data

      dispatch(isFetching(false));
    })
    .catch((error) => console.log(error));
};

export const updateSkills = (data, callback) => (dispatch) => {
  dispatch(isFetching(true));
  const formData = new FormData();

  formData.append('cv', data.cv);
  delete data.cv;

  Object.keys(data).forEach((field) => {
    let fieldData = data[field];

    if (typeof fieldData === 'object') {
      fieldData = JSON.stringify(data[field]);
    }
    formData.append(field, fieldData);
  });

  authAPI.updateSkill(formData).then(() => {
    // update user profile data
    let user = JSON.parse(localStorage.getItem("user"));
    dispatch(getCompanyData(user.token, () => {
      dispatch(isFetching(false));
      callback();
    }));
  });
};

const editCompanyUploadItem = (items, uploadFunction, urlRedirect) => {
  return (dispatch) => {
    dispatch(isFetching(true));

    const itemsLogoData = pickCompanyUploadItemData(items);

    uploadFunction(itemsLogoData).then(() => {
      let user = JSON.parse(localStorage.getItem("user"));
      dispatch(getCompanyData(user.token)); // update user profile data
      dispatch(isFetching(false));

      if (urlRedirect) {
        return history.push(urlRedirect);
      }
    }).catch(console.log);
  }
};

// EDIT COMPANY TECHNOLOGIES
export const editTechnologies = (formData) => {
  return (dispatch) => {
    dispatch(editCompanyUploadItem(formData.technologies, authAPI.uploadTechnologiesLogo, formData.url_redirect));
  };
};

// EDIT COMPANY CLIENTS
export const editClients = (formData) => {
  return (dispatch) => {
    dispatch(editCompanyUploadItem(formData.clients, authAPI.uploadClientsLogo, formData.url_redirect));
  };
};

// EDIT PASSWORD
export const editPassword = (formData) => {
  return (dispatch) => {
    dispatch(isFetching(true));

    authAPI.editProfile(formData).then((response) => {
      if (response.status === 200) {
        dispatch(isFetching(false));
        return history.push("/profile");
      } else {
        dispatch(isFetching(false));
        let message = response.data.error.length ? response.data.error : "Some error";
        dispatch(stopSubmit("passwordEditing", { _error: message }));
      }
    });
  };
};

export const recover = (email) => {
  return (dispatch) => {
    dispatch(isFetching(true));

    authAPI.recover(email).then((response) => {
      if (response.status === 200) {
        dispatch(showModal(true));
      } else {
        let message = response.data.error.length ? response.data.error : "Some error";
        dispatch(stopSubmit("passwordRecovery", { _error: message }));
      }
      dispatch(isFetching(false));
    });
  };
};

const sendHireMessage = (developerId, userId, companyId) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(
        hire(
          `Hi, we would like to chat about your Developer: ${apiUrl}developer/${developerId}`,
          `${userId}:${companyId}`
        )
      );
    }, 2000);
  };
};

// QIUCK REGISTRATION AND HIRE
export const quickRegister = (formData) => {
  let registrationData = {
    email: formData.email,
    password: formData.password,
    country: formData.country,
    type: 2,
  };

  if (formData.referral) {
    registrationData = {
      ...registrationData,
      referral: formData.referral,
    };
  }

  let base_info = {
    representative_name: formData.representative_name,
  };

  return (dispatch) => {
    dispatch(isFetchingAuth(true));

    authAPI
      .register(registrationData)
      .then((response) => {
        if (response.status === 200) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(response.data.user));
          dispatch(editProfile(base_info));

          dispatch(sendHireMessage(
            formData.developer.developer_id,
            response.data.user.id,
            formData.developer.company_id),
          );

          setTimeout(() => {
            dispatch(isFetchingAuth(false));
          }, 2100);
        } else {
          dispatch(isFetchingAuth(false));
          let message = response.data.error.length ? response.data.error : "Some error";
          dispatch(stopSubmit("quick-registration", { _error: message }));
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };
};

// Login and hire
export const loginAndHire = (formData) => {
  return (dispatch) => {
    dispatch(isFetchingAuth(true));
    dispatch(loginProceed(formData.email, formData.password, (response) => {
      if (response.data.user.type === 2) {
        return history.push("/marketplace");
      }

      dispatch(sendHireMessage(
        formData.developer.developer_id,
        response.data.user.id,
        formData.developer.company_id),
      );

      setTimeout(() => {
        dispatch(isFetchingAuth(false));
      }, 2100);
    }, () => {
      dispatch(isFetchingAuth(false));
    }))
  };
};

export const confirmEmail = (otp, callback, errorCallback) => (dispatch) => {
  dispatch(isFetching(true));

  authAPI.confirmEmail(otp).then((response) => {
    console.log('===response', response);
    if (response.status === 200) {
      console.log('===SUCCESS', );
      callback();
    } else {
      console.log('===ERROR', );
      errorCallback();
    }
    console.log('===FETCH FALSE', );
    dispatch(isFetching(false));
  });
};



export default auth;
