import React from "react";

import styles from "./CongratsModal.module.css";

const WalletModal = ({ onGotIt }) => {
  return (
    <div className={styles.congratsModal}>
      <div className={styles.modalHeader}>
        Congrats!
      </div>
      <div className={styles.modalBody}>
        <div className={styles.modalContent}>
          <p>
            Your request has been successfully published and is available to all companies on our platform. We wish you
            to find a buyer.
          </p>

          <p>
            <span className={styles.bold}>
              Please note that <span className={styles.blueUnderline}>verified</span> companies have a special badge.{' '}
            </span>
            We recommend working with such companies only. check it, and if everything is ok, we will charge you
            connects
          </p>

          <p>
            <span className={styles.bold}>
              Yes, all offers for your lead will be sent to you through the chat system.{' '}
            </span>
            You can communicate with
            potential buyers, ask questions, and negotiate terms in the chat. Good luck with finding a buyer!
          </p>
        </div>
        <button className={styles.gotItButton} onClick={onGotIt}>
          Got it!
        </button>
      </div>
    </div>
  );
};

export default WalletModal;
