import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Helmet } from "react-helmet";

import { getCompanyInTop10Checks } from "../../store/auth";
import { getCasesList } from "../../store/cases";
import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import ProfileMenu from "./ProfileMenu";
import TalentDetails from "./TalentDetails";
import CompanyDetails from "./CompanyDetails";
import ClientDetails from "./ClientDetails";
import CopyLink from "../common/CopyLink/CopyLink";

import styles from "./Profile.module.css";

const ProfileContainer = (props) => {
  const {
    company_info,
    cases,
    developers,
    getCasesList,
    getCompanyInTop10Checks
  } = props;
  const isCompany = !company_info.is_single_developer;

  useEffect(() => {
    if (isCompany) {
      getCasesList();
      getCompanyInTop10Checks();
    }
  }, [isCompany]);

  const developersOnBench = useMemo(() => {
    return developers?.filter?.(developer => developer.on_bench) || [];
  }, [developers]);

  let userTypeName;

  if (!isCompany) {
    userTypeName = "Developer";
  } else if (company_info.type === 1) {
    userTypeName = "Client";
  } else {
    userTypeName = "Company";
  }

  const renderProfileDetails = () => {
    if (!isCompany) {
      return <TalentDetails talentData={company_info} />;
    } else if (company_info.type === 1) {
      return <ClientDetails clientData={company_info}/>;
    } else {
      const companyData = {
        ...company_info, 
        cases, 
        developers: developersOnBench,
      };
      return <CompanyDetails companyData={companyData} />;
    }
  }

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>
          {userTypeName} profile | iBench - real-time developers search
        </title>
      </Helmet>

      <h2 className={styles.pageTitle}>
        {userTypeName} profile
        {isCompany && company_info.type === 2 && (
          <div className={styles.copyLinkWrapper}>
            <CopyLink
              link={`/company/${company_info.id}`}
              message="Company link has been copied to clipboard"
            />
          </div>
        )}
      </h2>
      <ProfileMenu/>
      {renderProfileDetails()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  company_info: state.auth.company_info,
  developers: state.developer.developers,
  cases: state.cases.casesList,
});

const mapDispatchToProps = {
  getCasesList,
  getCompanyInTop10Checks,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthRedirect,
)(ProfileContainer);
