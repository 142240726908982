import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker, Table, Pagination } from "antd";
import { Helmet } from "react-helmet";

import {
  siteNameForTitle,
  analyticEvents,
  analyticEventsLabels,
  socialEvents,
} from "../../../constants/global-constants";
import ProfileMenu from "../ProfileMenu";

import styles from "../ProfileEditing/ProfileEditing.module.css";

const dateInputFormat = 'DD MMMM YYYY';
const dateTableFormat = 'D MMM';
const timeTableFormat = 'hh:mm A';
const dateBDFormat = 'YYYY-MM-DD';

const currentDate = new Date();
const defaultStartDate = moment().subtract(1, 'month').format(dateInputFormat);
const defaultEndDate = moment().format(dateInputFormat);
const analyticsPageSize = 10;

const Analytics = (props) => {
  const {
    getCompanyAnalytics,
    analytics: { analytics, total },
  } = props;

  const [analyticsPage, setAnalyticsPage] = useState(1);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [error, setError] = useState();

  useEffect(() => {
    getAnalytics();
  }, [analyticsPage]);

  useEffect(() => {
    if (startDate && endDate && moment(startDate).isAfter(endDate, "day")) {
      return setError("Start date cannot be later than end date");
    }
    setError("");
  }, [startDate, endDate]);

  const getAnalytics = () => {
    const start = (analyticsPage - 1) * analyticsPageSize;
    const searchParams = {
      start,
      limit: analyticsPageSize,
      date_from: moment(startDate).format(dateBDFormat),
      date_to: moment(endDate).format(dateBDFormat),
    };

    getCompanyAnalytics(searchParams);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "date",
      render: createdAt => moment.utc(createdAt).format(dateTableFormat),

    },
    {
      title: "Time",
      dataIndex: "created_at",
      key: "time",
      render: createdAt => moment.utc(createdAt).format(timeTableFormat),
    },
    {
      title: "Service/Industry",
      dataIndex: "service_name",
      key: "service/industry",
      render: (service_name, record) => {
        if (socialEvents[record.event] && !service_name) {
          return 'Company page';
        }

        return service_name || record.market_name;
      },
    },
    {
      title: "Events",
      dataIndex: "event",
      key: "event",
      render: event => analyticEventsLabels[event],
    },
  ];

  const renderDateField = (label, onChange, value) => {
    return (
      <div className={styles.dateFieldWrapper}>
        <label>
          <div className={styles.dateFieldLabel}>{label}</div>
          <DatePicker
            value={value && moment(value)}
            onChange={onChange}
            format={dateInputFormat}
            disabledDate={d => d.isAfter(currentDate, "day") }
          />
        </label>
      </div>
    )
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Analytics | {siteNameForTitle}</title>
      </Helmet>

      <h2 className={styles.title}>Company profile</h2>
      <ProfileMenu />

      <div className={styles.analytics}>
        <div className={styles.analyticsTitle}>
          Analytics
        </div>

        <div className={styles.base_info_form}>
          <div className={styles.fields_wrapper}>
            <div className={styles.analyticsForm}>
              {renderDateField("Date from:", setStartDate, startDate)}
              {renderDateField("Date to:", setEndDate, endDate)}
              <button
                type="button"
                className={styles.analyticsApplyButton}
                onClick={() => !error && getAnalytics()}
              />
            </div>
            {error && <div className={styles.datesError}>{error}</div>}
            <div className={styles.analyticsData}>
              <div className={styles.analyticsTable}>
                <Table
                  dataSource={analytics}
                  columns={columns}
                  pagination={false}
                />
                <Pagination
                  current={analyticsPage}
                  onChange={setAnalyticsPage}
                  pageSize={analyticsPageSize}
                  total={total?.total}
                  hideOnSinglePage
                />
              </div>
              {total && (
                <div className={styles.analyticsTotalBlock}>
                  <div className={styles.analyticsTotalCard}>
                    <div className={styles.analyticsTotalHeader}>
                      Total
                    </div>
                    <div className={styles.analyticsTotalBody}>
                      <div className={styles.totalRow}>
                        <strong>Total clicks to <span className={styles.blueText}>Telegram</span>: </strong>
                        <div className={styles.totalNumber}>
                          {total[analyticEvents.CLICK_SERVICE_COMPANY_TELEGRAM] || 0}
                        </div>
                      </div>
                      <div className={styles.totalRow}>
                        <strong>Total clicks to <span className={styles.greenText}>Whatsapp</span>: </strong>
                        <div className={styles.totalNumber}>
                          {total[analyticEvents.CLICK_SERVICE_COMPANY_WHATSAPP] || 0}
                        </div>
                      </div>
                      <div className={styles.totalRow}>
                        <strong>Total clicks to Email: </strong>
                        <div className={styles.totalNumber}>
                          {total[analyticEvents.CLICK_SERVICE_COMPANY_EMAIL] || 0}
                        </div>
                      </div>
                      <div className={styles.totalRow}>
                        <strong>Total views/clicks clicks to Banner TOP-10: </strong>
                        <div className={styles.totalNumber}>
                          {total[analyticEvents.VIEW_SERVICE_COMPANY] || 0}/
                          {total[analyticEvents.CLICK_SERVICE_COMPANY] || 0}
                        </div>
                      </div>
                      <div className={styles.totalRow}>
                        <strong>Total views/clicks clicks to Banner TOP20: </strong>
                        <div className={styles.totalNumber}>
                          {total[analyticEvents.VIEW_INDUSTRY_COMPANY] || 0}/
                          {total[analyticEvents.CLICK_INDUSTRY_COMPANY] || 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
