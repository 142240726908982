import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { length, numericality, required } from "redux-form-validators";

import {
  Input,
  SelectInput,
  SelectInputKeyValue,
  SelectInputEnglish,
  renderMultiselect,
} from "../../common/FormsControls/FormControls";
import Editor from "../../common/SimpleEditor/SimpleEditor";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import Preloader from "../../common/Preloader/Preloader";
import { siteNameForTitle, vettedOptions } from "../../../constants";
// import { validateHTMLLength } from "../../../utils/validation";

import styles from "./SearchAdding.module.css";

let SearchAddingForm = (props) => {
  const { verified } = props;

  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.search_tip}>
        I’m looking for…Senior <br/>
        Front-end developer, <br/>3 month…
      </div>
      <div className={styles.fields_wrapper}>
        <Field
          input_design={styles.select}
          component={SelectInputKeyValue}
          name="vetted"
          label="Verified companies"
          validate={[required()]}
          options={vettedOptions}
          disabled={!verified}
        />
        <Field
          component={Input}
          label="Slot name (optional)"
          name={"slot_name"}
          validate={[ length({ maximum: 50 }) ]}
        />
        <div className={styles.skillsWrapper}>
          <Field
            name={"location"}
            component={renderMultiselect}
            label="Developer's location"
            // validate={[required()]}
            data={props.countriesList}
            valueField="country_id"
            textField="country_name"
            placeholder="Select the countries from which you need specialists"
          />
        </div>
        <Field
          component={SelectInputKeyValue}
          name="job_title_id"
          label="Job title"
          validate={[required()]}
          help_text="for example: Front-end Developer"
          options={props.jobTitlesList}
        />
        <div className={`d_flex ${styles.rate_wrapper}`}>
          <div className={styles.rate_to}>
            <Field
              component={Input}
              type={"number"}
              label="Maximum hourly rate"
              name={"rate_to"}
              help_text="$/h"
              validate={[required(), numericality({
                int: true,
                '>': 0,
              })]}
            />
          </div>
          <div className={styles.location}>
            <Field
              component={SelectInputKeyValue}
              name="position_level_id"
              label="Position level"
              validate={[required()]}
              help_text="for example: Junior/Middle/Senior/TeamLead"
              options={props.positionLevelsList}
            />
          </div>
        </div>
        <div className={`d_flex`}>
          <div className={styles.experience_wrapper}>
            <Field
              component={SelectInput}
              name={"experience"}
              label="Experience"
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              help_text="for example: 3 years"
            />
          </div>
          <div className={styles.english_wrapper}>
            <Field
              component={SelectInputEnglish}
              name="english_level_id"
              label="English level"
              help_text="for example: B1 (intermediate)"
              options={props.englishLevelsList}
            />
          </div>
        </div>
        <div className={styles.skillsWrapper}>
          <Field
            name={"skills"}
            component={renderMultiselect}
            label="Skills"
            validate={[required()]}
            data={props.skillsList}
            valueField="skill_id"
            textField="skill_name"
            placeholder="Enter the project technologies"
          />
        </div>
        <div className={styles.full_width}>
          <Field
            component={Editor}
            name={"description"}
            label={"Project Description"}
            label_type={"label_above"}
            validate={[required(), length({ min: 100 })]}
            // validate={[required(), input => validateHTMLLength(input, 100)]}
          />
        </div>
      </div>

      <div className={`d_flex ${styles.buttons}`}>
        <button className={`btn ${styles.submit}`}>Activate</button>
        <div className={styles.tip}>To start the search for developers, you need to activate it.</div>
      </div>
    </form>
  );
};

SearchAddingForm = reduxForm({
  form: "searchAdding",
})(SearchAddingForm);

const SearchAdding = (props) => {
  const { verified } = props.companyInfo;
  const onSubmit = (formData) => {
    formData.location = formData.location?.join?.(", ");
    formData.exclude_location = formData.exclude_location?.join?.(", ");
    if (!formData.slot_name) {
      const positionLevelId = +formData.position_level_id;
      const jobTitleId = +formData.job_title_id;
      const positionLevel = props.positionLevelsList.find(position => position.position_level_id === positionLevelId);
      const jobTitle = props.jobTitlesList.find(title => title.job_title_id === jobTitleId);
      formData.slot_name = `${positionLevel.position_level_name}, ${formData.skills[0].skill_name}, ${jobTitle.job_title_name}`;
    }
    props.addSearch(formData);
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Add search slot | {siteNameForTitle}</title>
      </Helmet>

      {props.search.is_fetching && <Preloader/>}

      <DashboardMenuContainer/>

      <div className={styles.slot_number}>Add Search #{props.slot_number}</div>

      <h1 className={styles.title}>
        <span>Find outstaffing developers</span>
      </h1>

      <div className={styles.form_title}>Search info</div>
      <SearchAddingForm
        onSubmit={onSubmit}
        jobTitlesList={props.jobTitlesList}
        positionLevelsList={props.positionLevelsList}
        englishLevelsList={props.englishLevelsList}
        skillsList={props.skillsList}
        countriesList={props.countriesList}
        verified={verified}
        initialValues={{ vetted: verified ? "1" : "0" }}
      />
    </div>
  );
};

export default SearchAdding;
