import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import Subscription from "./Subscription";

const SubscriptionContainer = ({ companyInfo, subscription, subscriptionAgreement }) => {
  return (
    <Subscription
      companyInfo={companyInfo}
      subscription={subscription}
      subscriptionAgreement={subscriptionAgreement}
    />
  );
};

let mapStateToProps = (state) => ({
  companyInfo: state.auth?.company_info,
  subscription: state.subscription,
  subscriptionAgreement: state.form.subscriptionAgreement,
});

export default compose(connect(mapStateToProps, {}), withAuthRedirect)(SubscriptionContainer);