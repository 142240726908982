import React from "react";
import styles from "./Marketplace.module.css";

const ProjectDescriptionModal = (props) => {
  return (
    <div className={styles.descriptionModal}>
      <div className={styles.modalHeader}>
        Project description
      </div>
      <div className={styles.modalBody} dangerouslySetInnerHTML={{ __html: props.description }} />
    </div>
  );
};

export default ProjectDescriptionModal;
