import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import Chat from "./Chat";
import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import {
  getChatList,
  getChatMessages,
  setChatReadMessages,
  setChatOnlineStatuses,
  sendMessage,
  setChatId,
  updateMessageInput,
  setNewChatInfo,
  sendChatSeen,
  deleteChat,
  toggleChatInfo,
} from "../../store/chat";
import {
  chatListener,
  chatReadListener,
  onlineUsersListener,
  getOnlineUsers,
  socketSendMessage,
  socketRead,
  socketTyping,
} from "../../utils/socket";

let ChatContainer = (props) => {
  useEffect(() => {
    chatListener(props.getChatMessages);

    let chat_id = props.match.params.chat_id;

    if (chat_id) {
      props.setChatId(chat_id);
      props.getChatMessages(chat_id);
      props.getChatList(chat_id);
    } else {
      props.setChatId(null); // remove chat_id if URL parameter empty
      props.getChatList();
    }

    chatReadListener((readChatId) => {
      if (chat_id === readChatId) {
        props.setChatReadMessages(chat_id);
      }
    });

    onlineUsersListener((data) => {
      props.setChatOnlineStatuses(data);
    });

    getOnlineUsers();
  }, [props.match.params.chat_id]);

  return (
    <Chat
      {...props.chat}
      user={props.user}
      setChatId={props.setChatId}
      deleteChat={props.deleteChat}
      updateMessageInput={props.updateMessageInput}
      getChatMessages={props.getChatMessages}
      sendMessage={props.sendMessage}
      socketSendMessage={socketSendMessage}
      socketTyping={socketTyping}
      socketRead={socketRead}
      sendChatSeen={props.sendChatSeen}
      toggleChatInfo={props.toggleChatInfo}
    />
  );
};

const mapStateToProps = (state) => ({
  chat: state.chat,
  user: state.auth.company_info,
  search: state.search,
});

const mapDispatchToProps = {
  updateMessageInput,
  getChatList,
  setChatId,
  getChatMessages,
  setChatReadMessages,
  setChatOnlineStatuses,
  sendMessage,
  setNewChatInfo,
  sendChatSeen,
  deleteChat,
  toggleChatInfo,
};

ChatContainer = withRouter(ChatContainer);

export default compose(connect(mapStateToProps, mapDispatchToProps), withAuthRedirect)(ChatContainer);
