import React, { useState } from "react";

import Modal from "../Modal/Modal";
import Video from "../Video/Video";

import styles from "./YoutubeButton.module.css";

const YoutubeButton = (props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={styles.needHelp}>
        <div className={styles.youtubeIcon} onClick={() => setShowModal(true)} />
      </div>

      {showModal && (
        <Modal
          showModal={setShowModal}
          noBg={true}
          renderComponent={<Video src={props.src} />}
        />
      )}
    </>
  );
};

export default YoutubeButton;
