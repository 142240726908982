import cn from "classnames";
import { Helmet } from "react-helmet";
import Linkify from "react-linkify";

import { publicFilesUrl, siteNameForTitle } from "../../../constants";
import Preloader from "../../common/Preloader/Preloader";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import { formatBudget, formatProjectStart, normalizeURL } from "../../../utils/utils";
import defaultCompanyPhoto from "../../../assets/img/rowing.svg";
import linkifyDecorator from "../../../utils/linkifyDecorator";
import styles from "./ProjectPage.module.css";

const ProjectPage = (props) => {
    let { currentProject } = props;

    return (
        <div className={styles.wrapper}>
            <Helmet>
                <title>
                    Project {`${currentProject?.name}`} | {siteNameForTitle}
                </title>
            </Helmet>

            {props.isFetching && <Preloader />}

            <DashboardMenuContainer />

            <div className={styles.company}>
                <div className={styles.logoWrapper}>
                    <img
                        src={currentProject?.logo ? `${publicFilesUrl}public/img/${currentProject.logo}` : defaultCompanyPhoto}
                        alt={currentProject?.company_name}
                        className={styles.logo}
                    />
                </div>

                <div className={styles.companyInfo}>
                    <div className={styles.companyName}>{currentProject?.company_name}</div>
                    <div className={styles.cityAndSite}>
                        <div className={styles.city}>City: {currentProject?.city}</div>
                        <div className={styles.site}>
                            <a href={normalizeURL(currentProject?.site)} target="_blank" rel="nofollow">
                                {currentProject?.site}
                            </a>
                        </div>
                    </div>
                    <div className={styles.country}>Country: {currentProject?.country}</div>
                    <div className={styles.row}>{currentProject?.representative_name}, Project coordinator</div>
                    <div className={styles.companyDescription}>{currentProject?.company_description}</div>
                </div>
            </div>

            <div className={styles.project}>
                <h1 className={styles.projectName}>Project {currentProject?.name}</h1>
                <div className={styles.projectStart}>
                    <span className={styles.label}>Date start:</span>
                    {formatProjectStart(currentProject?.project_start)}
                    <span className={styles.budget}>Budjet: {formatBudget(Number(currentProject?.budget))}</span>
                </div>
                <div className={styles.projectDescription}>
                    <div className={styles.label}>Project details</div> {currentProject?.description}
                </div>
                <div className={styles.marketsList}>
                    {currentProject?.market_names.split(",").map((market_name, i) => (
                        <div key={`project-${currentProject.project_id}-market-${i}`} className={styles.market}>
                            {market_name}
                        </div>
                    ))}
                </div>
                {currentProject?.links && (
                    <div className={cn(styles.row, styles.projectLink)}>
                        <div className={styles.label}>Additional links</div>
                        <Linkify
                            componentDecorator={() => linkifyDecorator(currentProject.links, currentProject.links)}
                        >
                            {currentProject.links}
                        </Linkify>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectPage;
