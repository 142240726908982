import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { length, numericality, required } from "redux-form-validators";
import { PlusCircleFilled } from "@ant-design/icons";
import cn from "classnames";

import { getCurrentSearch } from "store/search";
import {
  Input,
  SelectInput,
  SelectInputEnglish,
  SelectInputKeyValue,
  renderMultiselect,
} from "components/common/FormsControls/FormControls";
import Preloader from "components/common/Preloader/Preloader";
import Modal from "components/common/Modal/Modal";
import Editor from "components/common/SimpleEditor/SimpleEditor";
import { siteNameForTitle, vettedOptions } from "../../../constants";
import styles from "./FreelancerSearchEditing.module.css";

const SearchEditingForm = (props) => {
  return (
    <form
      onSubmit={props.handleSubmit}
      className={`${styles.form} ${props.edit_mode_disabled && styles.edit_mode_disabled_form}`}
    >
      <div className={styles.fields_wrapper}>
        <div className={styles.verified_wrapper}>
          <Field
            component={SelectInputKeyValue}
            name="vetted"
            label="Verified employees"
            validate={[required()]}
            options={vettedOptions}
            disabled={props.edit_mode_disabled}
          />
        </div>
        <div className={cn(styles.locationWrapper, props.edit_mode_disabled && styles.full_width)}>
          {props.edit_mode_disabled ? (
            <div>
              <label className="label active">Developer's location</label>
              <div className={styles.gray_field}>
                {props?.initialValues?.location?.join?.(', ')}
              </div>
            </div>
          ) : (
            <Field
              name={"location"}
              component={renderMultiselect}
              label="Developer's location"
              // validate={[required()]}
              data={props.countries_list}
              valueField="country_id"
              textField="country_name"
              placeholder="Select the countries from which you need specialists"
            />
          )}
        </div>

        <div className={styles.rate_wrapper}>
          <Field
            component={Input}
            type={"number"}
            label="Salary/month"
            name={"rate_month"}
            help_text="$/month"
            disabled={props.edit_mode_disabled}
            validate={[required(), numericality({
              int: true,
              '>': 0,
            })]}
          />
        </div>
        <div className={styles.form_row}>
          <div className={styles.job_title_wrapper}>
            <Field
              component={SelectInputKeyValue}
              name="job_title_id"
              label="Job title"
              validate={[required()]}
              options={props.jobTitlesList}
              disabled={props.edit_mode_disabled}
            />
          </div>
          <div className={styles.position_level_wrapper}>
            <Field
              component={SelectInputKeyValue}
              name="position_level_id"
              label="Position level"
              validate={[required()]}
              options={props.positionLevelsList}
              disabled={props.edit_mode_disabled}
            />
          </div>
        </div>
        <div className={styles.full_width}>
          {props.edit_mode_disabled ? (
            <div>
              <label className="label active">Position Description</label>
              <div
                className={styles.gray_field}
                dangerouslySetInnerHTML={{ __html: props?.initialValues?.description }}
              />
            </div>
          ) : (
            <Field
              component={Editor}
              name={"description"}
              label={"Project Description"}
              label_type={"label_above"}
              validate={[required(), length({ min: 100 })]}
            />
          )}
        </div>
        <div className={styles.full_width}>
          {props.edit_mode_disabled ? (
            <div>
              <label className="label active">Benefits</label>
              <div
                className={styles.gray_field}
                dangerouslySetInnerHTML={{ __html: props?.initialValues?.benefits }}
              />
            </div>
          ) : (
            <Field
              component={Editor}
              name={"benefits"}
              label={"Benefits"}
              label_type={"label_above"}
            />
          )}
        </div>
        <div className={`d_flex ${styles.experience_english_wrapper}`}>
          <div className={styles.experience_wrapper}>
            <Field
              component={SelectInput}
              name={"experience"}
              label="Experience"
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              help_text="from"
              disabled={props.edit_mode_disabled}
            />
          </div>
          <div className={styles.english_level_wrapper}>
            <Field
              component={SelectInputEnglish}
              name="english_level_id"
              label="English level"
              options={props.englishLevelsList}
              disabled={props.edit_mode_disabled}
            />
          </div>
        </div>
        <div className={cn(styles.skillsWrapper, props.edit_mode_disabled && styles.full_width)}>
          {props.edit_mode_disabled ? (
            <div>
              <label className="label active">Skills</label>
              <div
                className={styles.gray_field}
                dangerouslySetInnerHTML={{
                  __html: props?.initialValues?.skills?.map(skill => skill.skill_name)?.join(', ')
                }}
              />
            </div>
          ) : (
            <Field
              name={"skills"}
              component={renderMultiselect}
              label="Skills"
              validate={[required()]}
              data={props.skills_list}
              valueField="skill_id"
              textField="skill_name"
              placeholder="Enter the project technologies"
            />
          )}
        </div>
      </div>
      {!props.edit_mode_disabled && (
        <div className={`d_flex ${styles.buttons}`}>
          <button className={`btn ${styles.submit}`}>Search</button>
        </div>
      )}
    </form>
  );
};

let SearchEditingReduxForm = reduxForm({
  form: "searchEditing",
  enableReinitialize: true,
})(SearchEditingForm);

const selector = formValueSelector("searchEditing");
SearchEditingReduxForm = connect(
  (state) => ({
    initialValues: state.search.current_search, // pull initial values from reducer
    skills: selector(state, "skills"),
  }),
  { load: getCurrentSearch } // bind loading action creator
)(SearchEditingReduxForm);

class FreelancerSearchEditing extends React.Component {
  state = {
    current_search: {
      ...this.props.search.current_search,
    },
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      // set current_search in state
      this.props.search.current_search &&
      !prevProps.search.current_search &&
      this.props.search.current_search.search_id
    ) {
      this.setState({
        current_search: { ...this.props.search.current_search },
      });
    } else if (
      // update job_title_name
      this.props.search.current_search &&
      prevProps.search.current_search &&
      this.props.search.current_search.job_title_name !== prevProps.search.current_search.job_title_name
    ) {
      this.setState({
        current_search: {
          ...this.props.search.current_search,
          job_title_name: this.props.search.current_search.job_title_name,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.switchEditMode(true);
  }

  onSubmit = (formData) => {
    let form_data_changed = false;
    let add_skills = [];
    let delete_skills = [];

    let form_values = {
      job_title_id: formData.job_title_id,
      description: formData.description,
      benefits: formData.benefits,
      position_level_id: formData.position_level_id,
      project_duration: formData.project_duration,
      vetted: formData.vetted,
      rate_month: formData.rate_month,
      experience: formData.experience,
      english_level_id: formData.english_level_id,
      location: formData.location?.join?.(", "),
    };

    let search_data = {
      search_id: formData.search_id,
    };

    let job_title = this.props.jobTitlesList.filter((item) => {
      return item.job_title_id === parseInt(formData.job_title_id, 10);
    });

    for (let field in form_values) {
      let item = form_values[field];

      if (item === "") {
        item = "null"; // set NULL if value empty
      }

      if (item !== this.state.current_search[field]) {
        form_data_changed = true;
        this.setState(
          {
            current_search: {
              ...this.state.current_search,
              job_title_name: job_title[0].job_title_name,
              [field]: item,
            },
          },
          () => {
            console.log(this.state);
          }
        );
        search_data[field] = item;
      }
    }

    // ADD SKILLS
    const containsSkills = (array, value) => {
      return array.some((item) => value === item.skill_id);
    };
    formData.skills.forEach((skill) => {
      let check_result = containsSkills(this.props.search.current_search.skills, skill.skill_id);
      if (!check_result) {
        form_data_changed = true;
        add_skills = [...add_skills, skill.skill_id];
      }
    });
    if (add_skills.length) {
      search_data = {
        ...search_data,
        add_skills,
      };
    }

    // DELETE SKILLS
    const containsOldSkills = (array, value) => {
      return array.some((item) => value === item.skill_id);
    };
    this.props.search.current_search.skills.forEach((skill) => {
      let check_result = containsOldSkills(formData.skills, skill.skill_id);
      if (!check_result) {
        form_data_changed = true;
        delete_skills = [...delete_skills, skill.skill_id];
      }
    });
    if (delete_skills.length) {
      search_data = {
        ...search_data,
        delete_skills,
      };
    }

    if (form_data_changed) {
      this.props.editSearch(search_data);
    }
  };

  switchEditMode = () => {
    this.props.switchEditMode();
  };

  deleteSearch = () => {
    this.props.showModal(false);
    this.props.deleteSearch({
      search_id: this.props.search.current_search.search_id,
    }, true);
  };

  deleteSearchConfirmation = () => {
    this.props.showModal(true);
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <Helmet>
          <title>{`Developers ${this.state.current_search.job_title_name} | ${siteNameForTitle}`}</title>
        </Helmet>

        {this.props.search.is_fetching && <Preloader/>}
        {this.props.search.show_modal && (
          <Modal
            title={"Attention!"}
            message={"You are ready to delete search?"}
            buttons={[
              { btn_text: "Yes, delete", btn_handler: this.deleteSearch },
              {
                btn_text: "No",
                btn_handler: () => this.props.showModal(false),
              },
            ]}
          />
        )}

        <h1 className={styles.title}>
          <span>Find Employee</span> / {this.state.current_search.job_title_name}
        </h1>

        <div className={`${styles.search_actions} d_flex justify_content_center`}>
          <button
            className={`${styles.edit_search} ${!this.props.search.edit_mode_disabled && styles.active}`}
            onClick={this.switchEditMode}
          >
            Edit slot
          </button>
          <button className={styles.yellowBtn} onClick={() => this.props.setShowRequestModal(true)}>
            <PlusCircleFilled className={styles.shareSearch}/>
            Add search to the marketplace
          </button>
          <button className={styles.delete_search} onClick={this.deleteSearchConfirmation}>
            Delete slot
          </button>
        </div>

        <SearchEditingReduxForm
          onSubmit={this.onSubmit}
          edit_mode_disabled={this.props.search.edit_mode_disabled}
          jobTitlesList={this.props.jobTitlesList}
          positionLevelsList={this.props.positionLevelsList}
          englishLevelsList={this.props.englishLevelsList}
          skills_list={this.props.skills_list}
          countries_list={this.props.countries_list}
        />
      </div>
    );
  }
}

export default FreelancerSearchEditing;
