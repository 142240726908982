import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import cn from "classnames";

import { publicFilesUrl, siteNameForTitle } from "../../constants/global-constants";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import Preloader from "../common/Preloader/Preloader";
import Verified from "../common/Verified/Verified";
import defaultCompanyPhoto from "../../assets/img/rowing.svg";
import CaseItem from "../Cases/CaseItem/CaseItem";
import styles from "./CompnyDetails.module.css";

const CompanyDetails = (props) => {
  let { companyInfo } = props;

  const [markets, setMarkets] = useState([]);
  const [activeMarket, setActiveMarket] = useState();
  const [activeCases, setActiveCases] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let markets = props.casesList.reduce((acc, c) => {
      acc.push(...c.markets);
      return acc;
    }, []);
    let uniqueMarkets = [...new Map(markets.map((item) => [item["market_id"], item])).values()];
    setMarkets(uniqueMarkets);
    setActiveMarket(uniqueMarkets[0]);
  }, [props.casesList]);

  useEffect(() => {
    let activeCases = props.casesList.filter((caseItem) =>
      caseItem.markets.some((caseItem) => caseItem.market_id === activeMarket?.market_id)
    );
    setActiveCases(activeCases);
  }, [activeMarket, props.casesList]);

  const locationParts = [];

  if (companyInfo?.city) {
    locationParts.push(companyInfo.city);
  }

  if (companyInfo?.country) {
    locationParts.push(companyInfo.country);
  }

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Cases | {siteNameForTitle}</title>
      </Helmet>

      {props.isFetching && <Preloader/>}

      <DashboardMenuContainer/>

      {companyInfo && (
        <div className={styles.companyInfoWrapper}>
          <div className={styles.companyLogoWrapper}>
            <img
              src={companyInfo.logo ? `${publicFilesUrl}public/img/${companyInfo.logo}` : defaultCompanyPhoto}
              alt={companyInfo.company_name}
              className={styles.companyLogo}
            />
          </div>
          <div className={styles.companyInfo}>
            <div className={styles.companyTitle}>
              <div className={styles.companyName}>{companyInfo.company_name}</div>
              {companyInfo.verified === 1 && <Verified/>}
            </div>
            <div className={styles.companyLocation}>
              Location: {locationParts.join(', ')}
            </div>

            <ul className={styles.socials}>
              {companyInfo.linkedin && (
                <li>
                  <a
                    href={companyInfo.linkedin}
                    className={styles.linkedin}
                    target="_blank"
                    rel="nofollow"
                  >
                    We in LinkedIn
                  </a>
                </li>
              )}
              {companyInfo.twitter && (
                <li>
                  <a
                    href={companyInfo.twitter}
                    className={styles.twitter}
                    target="_blank"
                    rel="nofollow"
                  >
                    We in Twitter
                  </a>
                </li>
              )}
              {companyInfo.facebook && (
                <li>
                  <a
                    href={companyInfo.facebook}
                    className={styles.facebook}
                    target="_blank"
                    rel="nofollow"
                  >
                    We in Facebook
                  </a>
                </li>
              )}
            </ul>
            <p dangerouslySetInnerHTML={{ __html: props.companyInfo.description }}/>
          </div>
        </div>
      )}

      {!!activeCases.length && (
        <>
          <h1 className={styles.pageTitle}>Cases</h1>

          <div className={styles.marketsList}>
            {markets.map((market) => (
              <span
                key={market.market_id}
                className={cn(styles.market, market.market_id === activeMarket.market_id && styles.active)}
                onClick={() => setActiveMarket(market)}
              >
                {market.market_name}
              </span>
            ))}
          </div>

          <h2 className={styles.marketTitle}>{activeMarket?.market_name}</h2>

          {activeCases?.map((caseItem) => (
            <CaseItem
              key={caseItem.case_id}
              isOwnCases={false}
              caseId={caseItem.case_id}
              caseLogo={caseItem.case_logo}
              name={caseItem.name}
              links={caseItem.links}
              budget={caseItem.budget}
              fromDate={caseItem.from_date}
              toDate={caseItem.to_date}
              description={caseItem.description}
              teamSize={caseItem.team_size}
              skills={caseItem.skills}
              country={caseItem.country}
              covers={caseItem.covers}
              files={caseItem.files}
              deleteCase={props.deleteCase}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default CompanyDetails;
