import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { withAuthRedirect } from "../../../hoc/withAuthRedirect";
import LeadAdding from "./LeadAdding";
import { addLead } from "../../../store/lead";

const LeadAddingContainer = (props) => {
  return (
    <LeadAdding
      search={props.search}
      addLead={props.addLead}
      companyType={props.companyType}
    />
  );
};

let mapStateToProps = (state) => ({
  search: state.search,
  companyType: state.auth.company_info.type,
});

export default compose(
  connect(mapStateToProps, { addLead }),
  withAuthRedirect
)(LeadAddingContainer);
