import React from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";

import { trialSearchSkillsLinks, siteNameForTitle } from '../../constants';
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import forClientImage from "../../assets/content_img/for-client.webp";
import forCompanyImage from "../../assets/content_img/for-company.webp";
import styles from "./HireDevelopers.module.css";

const HireDevelopers = ({ stats }) => {
  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>
          {siteNameForTitle} / Looking for a developers, UX/UI designer, QA or DevOps...or development agency?
        </title>
        <meta
          name="description"
          content="IBench is a marketplace filled with highly skilled developers from Eastern Europe. Our developers can tackle web projects, mobile apps or MVPs for start-ups."
        />
        <meta
          name="keywords"
          content="software developers, ux/ui designers, bench, developers on demand, realtime developers search, e-commerce developers, fin-tech developers, healthcare developers"
          data-react-helmet="true"
        />
      </Helmet>

      <div className={styles.promo}>
        <div className={styles.leftPromo}>
          <h1 className={styles.title}>
            Looking for a developers, UX/UI designer, QA or DevOps...or development agency?
          </h1>
          <div className={styles.tip}>
            With us, your search just got easier. Choose from verified companies, communicate without intermediaries,
            and everything is completely free for clients.
          </div>
          <div className={styles.registrationLinks}>
            <div className={styles.btnWrapper}>
              <NavLink to="/registration" className={styles.btn}>
                I want to try iBench
              </NavLink>
              <div className={styles.btnTip}>Our service is free for you</div>
            </div>
          </div>
        </div>
        <img
          src={forClientImage}
          className={styles.clientImage}
          alt="Marketplace"
        />
      </div>
      <div className={styles.section}>
        <div className={styles.subTitle}>
          Try a search without registration
        </div>
        <div className={styles.smallFont}>
          Total: {stats?.developersCount} developers
        </div>
        <div className={styles.skillsLinksContainer}>
          {trialSearchSkillsLinks.map((skillsLinks, columnIndex) => (
            <div className={styles.skillsLinksColumn} key={columnIndex}>
              {skillsLinks.map((linkData, linkIndex) => (
                <Link
                  key={linkIndex}
                  to={linkData.link}
                  className={cn(styles.skillLink, { [styles.purple]: linkData.highlighted })}
                >
                  {linkData.label}
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.purpleField}>
        <div className={styles.whiteTitle}>
          {stats?.companiesCount} companies
        </div>
        <div className={styles.whiteText}>
          USA, Canada, Poland, Ukraine, Armenia, Georgia, Estonia, Lithuania, Turkey, Balkans, India, Pakistan, and
          Nigeria
        </div>
      </div>

      <div className={styles.clientWrapper}>
        <div className={styles.title}>
          For IT companies and professionals
        </div>
        <div className={styles.forCompany}>
          <div>
            <div className={styles.lightFont}>
              If you are an IT company or IT professional, you can register on our platform and get access to projects
              and requests for outstaffing for companies, or access to open vacancies for IT professionals.
            </div>
            <div className={styles.boldFont}>
              Everything is free for IT specialists
            </div>
            <div className={styles.communicateWrapper}>
              <NavLink to="/registration" className={styles.btn}>
                I want to try iBench
              </NavLink>
              <div className={styles.textWithArrow}>
                Communicate without
                intermediaries
              </div>
            </div>
          </div>
          <img
            src={forCompanyImage}
            className={styles.companyImage}
            alt="Marketplace"
          />
        </div>
      </div>
    </div>
  );
};

export default HireDevelopers;
