import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import cn from "classnames";

import { Textarea } from "../../common/FormsControls/FormControls";
import RadioButton from "../../common/RadioButton/RadioButton";
import FormIndicator from "./FormIndicator/FormIndicator";

import styles from "./TeamSearch.module.css";

const TeamSearchForm4 = props => {
  let markets = "";
  props.markets &&
    props.markets.forEach(market => {
      markets += `${market.market_name}, `;
    });
  markets = markets.slice(0, -2); // remove last comma

  let skills = "";
  props.skills &&
    props.skills.forEach(skill => {
      skills += `${skill.skill_name}, `;
    });
  skills = skills.slice(0, -2); // remove last comma

  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.fields_wrapper}>
        <div className={styles.advantages}>
          <div className={styles.budget_question}>
            What is your <br className="mobile_visible" />
            planned monthly budget?
          </div>
          <div className={styles.budget_tip}>
            Specify your monthly budget for this project.
          </div>
        </div>

        <FormIndicator page={props.page} />

        <div className={styles.monthBudget_wrapper}>
          <Field
            component={RadioButton}
            type="radio"
            name="monthBudget"
            value="Less than $5,000"
            text="Less than $5,000"
            help_text="1 developer"
            validate={[required()]}
          />
          <Field
            component={RadioButton}
            type="radio"
            name="monthBudget"
            value="Up to $10,000"
            text="Up to $10,000"
            help_text="2-3 developers"
            validate={[required()]}
          />
          <Field
            component={RadioButton}
            type="radio"
            name="monthBudget"
            value="Up to $20,000"
            text="Up to $20,000"
            help_text="2 developers + UX/UI designer and QA engineer"
            validate={[required()]}
          />
        </div>

        <Field
          component={Textarea}
          name={"monthBudgetInfo"}
          input_wrapper_class="form_control_2_wrapper"
          className="form_control_2"
          rows={5}
          validate={[required()]}
          placeholder="For example: A cross-functional team (developers, designers, project managers)"
        />
      </div>

      <div className={`${styles.submit_wrapper} text_center`}>
        <button className={cn(styles.submit, "btn_2", "btn_purple", "mobile_hidden")}>Next step</button>
        <button className={cn(styles.submit, "btn_2", "btn_purple", "mobile_visible")}>Next</button>
      </div>

      {props.error && (
        <div className={`${styles.form_erros} text_center validation_message`}>
          {props.error}
        </div>
      )}
    </form>
  );
};

let ReduxForm = reduxForm({
  form: "team-search",
  destroyOnUnmount: false
})(TeamSearchForm4);

const formSelector = formValueSelector("team-search");
ReduxForm = connect(state => {
  const markets = formSelector(state, "markets");
  const skills = formSelector(state, "skills");
  return { markets, skills };
})(ReduxForm);

export default ReduxForm;
