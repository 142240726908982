import React from "react";
import { Helmet } from "react-helmet";
import { Field, reduxForm } from "redux-form";
import { length, required, numericality } from "redux-form-validators";

import {
  Input,
  SelectInput,
  SelectInputKeyValue,
  SelectInputEnglish,
  renderMultiselect,
} from "../../common/FormsControls/FormControls";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import Preloader from "../../common/Preloader/Preloader";
 import Editor from "../../common/SimpleEditor/SimpleEditor";
import { siteNameForTitle, vettedOptions } from "../../../constants";
// import { validateHTMLLength } from "../../../utils/validation";

import styles from "./FreelancerSearchAdding.module.css";


let SearchAddingForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className={styles.form}>
      <div className={styles.search_tip}>
        I’m looking for…Senior <br/>
        Front-end developer, <br/>3 month…
      </div>
      <div className={styles.fields_wrapper}>
        <Field
          component={SelectInputKeyValue}
          name="vetted"
          label="Verified employees"
          validate={[required()]}
          options={vettedOptions}
        />
        <div className={styles.skillsWrapper}>
          <Field
            name={"location"}
            component={renderMultiselect}
            label="Developer's location"
            // validate={[required()]}
            data={props.countries_list}
            valueField="country_id"
            textField="country_name"
            placeholder="Select the countries from which you need specialists"
          />
        </div>
        <Field
          component={SelectInputKeyValue}
          name="job_title_id"
          label="Job title"
          validate={[required()]}
          help_text="for example: Front-end Developer"
          options={props.jobTitlesList}
        />
        <Field
          component={Editor}
          name={"description"}
          label={"Position Description"}
          label_type={"label_above"}
          validate={[required(), length({ min: 100 })]}
          // validate={[required(), input => validateHTMLLength(input, 100)]})]}
        />
        <Field
          component={Editor}
          name={"benefits"}
          label={"Benefits"}
          label_type={"label_above"}
          // validate={[required()]}
        />
        <div className={`d_flex ${styles.rate_wrapper}`}>
          <div className={styles.rate_to}>
            <Field
              component={Input}
              type={"number"}
              label="Salary/month"
              name={"rate_month"}
              help_text="$/month"
              validate={[required(), numericality({
                int: true,
                '>': 0,
              })]}
            />
          </div>
          <div className={styles.location}>
            <Field
              component={SelectInputKeyValue}
              name="position_level_id"
              label="Position level"
              validate={[required()]}
              help_text="for example: Junior/Middle/Senior/TeamLead"
              options={props.positionLevelsList}
            />
          </div>
        </div>
        <div className={`d_flex`}>
          <div className={styles.experience_wrapper}>
            <Field
              component={SelectInput}
              name={"experience"}
              label="Experience"
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              help_text="for example: 3 years"
            />
          </div>
          <div className={styles.english_wrapper}>
            <Field
              component={SelectInputEnglish}
              name="english_level_id"
              label="English level"
              help_text="for example: B1 (intermediate)"
              options={props.englishLevelsList}
            />
          </div>
        </div>
        <div className={styles.skillsWrapper}>
          <Field
            name={"skills"}
            component={renderMultiselect}
            label="Skills"
            validate={[required()]}
            data={props.skills_list}
            valueField="skill_id"
            textField="skill_name"
            placeholder="Enter the project technologies"
          />
        </div>
      </div>

      <div className={`d_flex ${styles.buttons}`}>
        <button className={`btn ${styles.submit}`}>Activate</button>
        <div className={styles.tip}>To start the search for developers, you need to activate it.</div>
      </div>
    </form>
  );
};

SearchAddingForm = reduxForm({
  form: "searchAdding",
  initialValues: {
    vetted: "1",
  }
})(SearchAddingForm);

const FreelancerSearchAdding = (props) => {
  const onSubmit = (formData) => {
    formData.location = formData?.location?.join?.(", ");
    props.addSearch({
      ...formData,
      is_single_developer: 1,
    });
  };

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Add search slot | {siteNameForTitle}</title>
      </Helmet>

      {props.search.is_fetching && <Preloader/>}

      <DashboardMenuContainer/>

      <div className={styles.slot_number}>Add Search #{props.slot_number}</div>
      <h1 className={styles.title}>
        <span>Find Employee</span>
      </h1>

      <div className={styles.form_title}>Search info</div>
      <SearchAddingForm
        onSubmit={onSubmit}
        jobTitlesList={props.jobTitlesList}
        positionLevelsList={props.positionLevelsList}
        englishLevelsList={props.englishLevelsList}
        skills_list={props.skills_list}
        countries_list={props.countries_list}
      />
    </div>
  );
};

export default FreelancerSearchAdding;
