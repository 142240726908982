const IS_FETCHING = "ibench/subscription/IS_FETCHING";
const SHOW_MODAL = "ibench/subscription/SHOW_MODAL";
const HIDE_MODAL = "ibench/subscription/HIDE_MODAL";

let initialState = {
    is_fetching: false,
    show_modal: false,
};

const subscription = (state = initialState, action) => {
    switch (action.type) {
        case IS_FETCHING:
            return {
                ...state,
                is_fetching: action.is_fetching,
            };
        case SHOW_MODAL:
            return {
                ...state,
                show_modal: true,
            };
        case HIDE_MODAL:
            return {
                ...state,
                show_modal: false,
            };

        default:
            return state;
    }
};

const isFetching = (is_fetching) => ({ type: IS_FETCHING, is_fetching });

export const showModal = () => ({ type: SHOW_MODAL });
export const hideModal = () => ({ type: HIDE_MODAL });

export default subscription;
