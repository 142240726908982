import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

import { siteNameForTitle } from "../../../constants/global-constants";
import DashboardMenuContainer from "../../DashboardMenu/DashboardMenuContainer";
import { stripeAPI } from "../../../api/stripe";
import { setUserInfo, getCompanyData } from "../../../store/auth";
import styles from "./SubscriptionSuccess.module.css";

const SubscriptionSuccess = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const userToken = JSON.parse(localStorage.getItem("user")).token;
    const token = new URLSearchParams(window.location.search).get("token");

    if (userToken === token) {
      stripeAPI.subscriptionSuccess({ success: true }).then((data) => {
        // update user token
        const user = JSON.parse(localStorage.getItem("user"));
        user.token = data.token;
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(setUserInfo(user));
        dispatch(getCompanyData(user.token));
      });
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Success | {siteNameForTitle}</title>
      </Helmet>

      <DashboardMenuContainer/>

      <div className={styles.content}>
        <h1 className={styles.pageTitle}>Subscription Success!</h1>
        <div className={styles.info}>
          <div className={styles.priceWrapper}>
            started at
            <div className={styles.price}>10 EURO</div>
            /Month
          </div>
        </div>
        <div className={styles.status}>
          Payment was processed successfully
        </div>
        <div className={styles.paymentService}/>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;
