import qs from "qs";

import { axiosFileInstances, axiosInstances } from "./api";
import { chatFilesFolder } from "../constants/global-constants";

const axiosInstance = axiosInstances.chat;
const axiosFileInstance = axiosFileInstances.chat;

export const chatAPI = {
  deleteChat(chat_id) {
    return axiosInstance
      .delete(`api/chat/list`, { data: qs.stringify({ chat_id }) })
      .then((response) => {
        return response.data;
      });
  },

  getChatList() {
    return axiosInstance.get(`api/chat/list`).then((response) => {
      return response.data;
    });
  },

  sendMessage(message, chat_id, for_outstaff, files) {
    const messageData = new FormData();
    messageData.append("message", message);
    messageData.append("chat_id", chat_id);

    if (typeof for_outstaff === "boolean") {
      const fieldName = for_outstaff ? "for_outstaff" : "for_outsource";
      messageData.append(fieldName, "1");
    }

    files?.map((fileData, index) => {
      messageData.append(`files[${index}]`, fileData);
    });

    return axiosFileInstance.post("api/chat", messageData).then((response) => {
      return response.data;
    });
  },

  fileDataProceed(chatData) {
    chatData.forEach(message => {
      if (message.files?.length) {
        message.files.forEach((fileData) => {
          fileData.fileName = fileData.url.split('/').pop();
          fileData.url = `${chatFilesFolder}${fileData.url}`;
          fileData.isDeleted = +fileData.isDeleted;
        })
      }
    });

    return chatData;
  },

  getChatMessages(chat_id, message_id) {
    if (message_id) {
      return axiosInstance.get(`api/chat?chat_id=${chat_id}&message_id=${message_id}`).then((response) => {
        return this.fileDataProceed(response.data.chatData);
      });
    } else {
      return axiosInstance.get(`api/chat?chat_id=${chat_id}`).then((response) => {
        return this.fileDataProceed(response.data.chatData);
      });
    }
  },

  sendChatSeen(chat_id) {
    return axiosInstance.post(`api/chat/seen`, qs.stringify({ chat_id })).then((response) => {
      return response;
    });
  },
};
