import React from "react";
import { Helmet } from "react-helmet";

import { siteNameForTitle } from "../../constants/global-constants";
import Breadcrumbs from "../common/Breadcrumbs/Breadcrumbs";

import styles from "./About.module.css";


const About = () => {
  return (
    <>
      <Breadcrumbs className={styles.breadcrumbs} currentPage="About"/>
      <div className={styles.wrapper}>
        <Helmet>
          <title>
            {siteNameForTitle} / iBench is an easy hiring way to find new highly-skilled Remote developers in 2-3
            business days after your request.
          </title>
          <meta
            name="description"
            content="IBench is a marketplace filled with highly skilled developers from Eastern Europe. Our developers can tackle web projects, mobile apps or MVPs for start-ups."
          ></meta>
          <meta
            name="keywords"
            content="software developers, ux/ui designers, bench, developers on demand, realtime developers search, e-commerce developers, fin-tech developers, healthcare developers"
            data-react-helmet="true"
          />
        </Helmet>

        <h1 className={styles.pageTitle}>
          iBench is an easy hiring way to find new highly-skilled Remote developers in 2-3 business days after
          your request.
        </h1>

        <p>
          A deep understanding of the software development process helps us to select more relevant remote
          developers, with deep experience on similar projects that you need.
        </p>
        <p>Everything's simple, like a morning coffee.</p>
        <br/>

        <h4>
          Our service fees <u>FREE</u> for clients.
        </h4>

        <h3>Benefits</h3>
        <ol>
          <li>
            <strong>Save time for selecting candidates. Reduce your costs.</strong> We select highly skilled
            remote software developers in 2-3 business days. Our service fees FREE for clients.
          </li>
          <li>
            <strong>Pre-screening all candidates</strong> (soft skills and hard skills, English level). Our
            candidates from EU companies.
          </li>
          <li>
            <strong>We do not have freelancers.</strong> All developers work for companies and cannot be found
            on the job market or freelance sites
          </li>
          <li>
            We can select <strong>Remote Team Developers</strong> for our clients
          </li>
          <li>
            <strong>Timezone:</strong> CET, CET+1 (Europe), CET+2
          </li>
          <li>
            All candidates with strong experience on projects with Scrum, Daily meetings, AWS/Azure clouds.
            Development of different projects from simple websites to high-load systems, mobile Apps
            (iOS/Android, Flutter), SaaS, CRM, E-learning, ERP systems.
          </li>
          <li>
            Work with <strong>Jira</strong>, Trello, Basecamp
          </li>
          <li>
            Our companies have repeatedly worked with European and American clients, creating projects from
            scratch, going from zero to hero.
          </li>
        </ol>
        <br/>

        <p>
          <b>iBench</b> consists of <b>350+ companies</b>, which employ more than <b>6,000 remote developers</b>.
          It was not easy to reach the number of 350, as we don’t settle for less. Our teams consist of thoroughly
          selected developers, architects, testers, and business analysts who can proudly call themselves the best
          in the industry.
        </p>
        <br/>
        <p>
          <strong>
            Our clients are respectable companies from Germany, Sweden, Poland, Belgium, Estonia, Israel, The
            United States of America, Canada.
          </strong>
        </p>
        <br/>

        <h3>Technologies</h3>
        <p>We cover the entire technology stack that is currently on the edge of popularity:</p>
        <ul>
          <li>
            <strong>Front-end</strong> - React.js, Vue.js, Angular, Gatsby.js
          </li>
          <li>
            <strong>Back-end</strong> - Node.js/microservices, Python, Java, C#/.NET, Golang, PHP, Ruby
          </li>
          <li>
            <strong>Mobile app development</strong> - Swift, Kotlin, Dart, Flutter, React Native
          </li>
          <li>
            <strong>Design UX/UI</strong> - Sketch, Zeplin, Adobe Photoshop, Axure RP, Balsamiq (Design,
            Prototyping, A/B testing)
          </li>
          <li>Blockchain, crypto-wallets, crypto exchange, creation of cryptocurrencies</li>
          <li>
            <strong>E-commerce solutions:</strong> Magento2, Shopify, Woocommerce
          </li>
          <li>
            <strong>Cloud Security</strong> - AWS, Azure, Kubernetes, RocketSpace. Analysis of malware and
            malicious code. Penetration testing, etc.
          </li>
          <li>
            <strong>Game development</strong> - Unity, Unreal engine, 3D modeling
          </li>
          <li>AR/VR Development</li>
          <li>ML/AI development and Data Science</li>
        </ul>
      </div>
    </>
  );
};

export default About;
