import { useEffect } from "react";
import { connect } from "react-redux";
import {
  addBookmark,
  setAddingToBookmark,
  getBookmarksList,
  addDeveloperToBookmark,
  deleteDeveloperFromBookmark,
} from "../../../store/bookmarks";
import BookmarksForm from "./BookmarksForm";

const BookmarksFormContainer = (props) => {
  useEffect(() => {
    props.getBookmarksList();
  }, []);

  return <BookmarksForm {...props} />;
};

let mapStateToProps = (state) => {
  return {
    isFetching: state.bookmarks.isFetching,
    bookmarksList: state.bookmarks.bookmarksList,
    currentDeveloperId: state.bookmarks.currentDeveloperId,
    currentDeveloperBookmarks: state.bookmarks.currentDeveloperBookmarks,
  };
};

export default connect(mapStateToProps, {
  getBookmarksList,
  addBookmark,
  setAddingToBookmark,
  addDeveloperToBookmark,
  deleteDeveloperFromBookmark,
})(BookmarksFormContainer);
