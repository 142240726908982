import styles from "./Video.module.css";

const Video = (props) => {
  return (
    <div className={styles.wrapper}>
      <div className="embed-container">
        <iframe
          title={props.title}
          src={props.src}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Video;
