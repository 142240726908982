import qs from "qs";
import { axiosInstances } from "./api";

const axiosAuthInstance = axiosInstances.auth;
const axiosAccountInstance = axiosInstances.account;

export const skillsAPI = {
  getPublicSkillsList() {
    return axiosAuthInstance.get(`api/public/skills/list`).then((response) => {
      return response;
    });
  },

  getSkillSponsor(skill_id) {
    return axiosAuthInstance.get(`api/public/skills/top/company?skill_id=${skill_id}`).then((response) => {
      return response;
    });
  },

  getSkillsList() {
    return axiosAccountInstance.get(`api/users/developers/skills/list`).then((response) => {
      return response.data.result;
    });
  },

  addSkill(data) {
    return axiosAccountInstance.post(`api/users/developers/skills`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  editSkill(data) {
    return axiosAccountInstance.put(`api/users/developers/skills`, qs.stringify(data)).then((response) => {
      return response.data;
    });
  },

  deleteSkill(data) {
    return axiosAccountInstance.delete(`api/users/developers/skills`, { data: qs.stringify(data) }).then((response) => {
      return response.data;
    });
  },
};
