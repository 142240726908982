import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import cn from "classnames";

import { siteNameForTitle } from "../../constants/global-constants";
import DashboardMenuContainer from "../DashboardMenu/DashboardMenuContainer";
import Preloader from "../common/Preloader/Preloader";
import CaseItem from "./CaseItem/CaseItem";
import ProfileMenu from "../Profile/ProfileMenu";
import CompanyMenu from "../Profile/CompanyMenu";

import styles from "./Cases.module.css";
import React from "react";

const Cases = (props) => {

  const [markets, setMarkets] = useState([]);
  const [activeMarket, setActiveMarket] = useState();
  const [activeCases, setActiveCases] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let markets = props.casesList.reduce((acc, c) => {
      acc.push(...c.markets);
      return acc;
    }, []);
    let uniqueMarkets = [...new Map(markets.map((item) => [item["market_id"], item])).values()];
    setMarkets(uniqueMarkets);
    setActiveMarket(uniqueMarkets[0]);
  }, [props.casesList]);

  useEffect(() => {
    let activeCases = props.casesList.filter((caseItem) =>
      caseItem.markets.some((caseItem) => caseItem.market_id === activeMarket?.market_id)
    );
    setActiveCases(activeCases);
  }, [activeMarket, props.casesList]);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>Cases | {siteNameForTitle}</title>
      </Helmet>

      {props.isFetching && <Preloader/>}

      <h2 className={`${styles.title} text_center`}>
        Company profile
      </h2>

      <ProfileMenu />

      <CompanyMenu companyInfo={props.auth.company_info} />

      <h1 className={styles.pageTitle}>Cases</h1>

      <div className={styles.marketsList}>
        {markets.map((market) => (
          <span
            key={market.market_id}
            className={cn(styles.market, market.market_id === activeMarket.market_id && styles.active)}
            onClick={() => setActiveMarket(market)}
          >
            {market.market_name}
          </span>
        ))}
      </div>

      <h2 className={styles.marketTitle}>{activeMarket?.market_name}</h2>
      <div className={styles.addCaseBtnWrapper}>
        <NavLink to="add-case" className={cn("btn", styles.addCaseBtn)}>
          Add new case
        </NavLink>
      </div>

      {activeCases?.map((caseItem) => (
        <CaseItem
          key={caseItem.case_id}
          caseId={caseItem.case_id}
          caseLogo={caseItem.case_logo}
          name={caseItem.name}
          links={caseItem.links}
          budget={caseItem.budget}
          fromDate={caseItem.from_date}
          toDate={caseItem.to_date}
          description={caseItem.description}
          teamSize={caseItem.team_size}
          skills={caseItem.skills}
          country={caseItem.country}
          covers={caseItem.covers}
          files={caseItem.files}
          deleteCase={props.deleteCase}
          isOwnCases
        />
      ))}
    </div>
  );
};

export default Cases;
