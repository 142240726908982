import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withAuthRedirect } from "../../hoc/withAuthRedirect";
import {
  getMarketplaceDevelopers,
  getMarketplaceFreelancers,
  getMarketplaceProjects,
  getMarketplaceLeads,
  deleteLead,
} from "../../store/marketplace";
import MarketplaceLeads from "./MarketplaceLeads";

const MarketplaceLeadsContainer = (props) => {
  useEffect(() => {
    if (props.companyType === 1) {
      props.getMarketplaceLeads();
      props.getMarketplaceFreelancers();
    }

    if (props.isSingleDeveloper) {
      props.getMarketplaceFreelancers()
    } else {
      props.getMarketplaceDevelopers();
      props.getMarketplaceProjects();
    }
  }, []);

  return (
    <MarketplaceLeads
      companyId={props.companyId}
      companyType={props.companyType}
      isSingleDeveloper={props.isSingleDeveloper}
      marketplace={props.marketplace}
      deleteLead={props.deleteLead}
    />
  );
};

let mapStateToProps = (state) => ({
  companyId: state.auth.company_info.id,
  companyType: state.auth.company_info.type,
  isSingleDeveloper: state.auth.company_info.is_single_developer,
  marketplace: state.marketplace,
});

export default compose(
  connect(mapStateToProps, {
    getMarketplaceDevelopers,
    getMarketplaceFreelancers,
    getMarketplaceProjects,
    getMarketplaceLeads,
    deleteLead,
  }),
  withAuthRedirect,
)(MarketplaceLeadsContainer);
