import React from "react";
import { connect } from "react-redux";

import { login } from "../../store/auth";
import Login from "./Login";

const LoginContainer = (props) => {
  return <Login is_auth={props.is_auth} is_fetching={props.is_fetching} login={props.login} />;
};

let mapStateToProps = (state) => ({
  is_auth: state.auth.is_auth,
  is_fetching: state.auth.is_fetching,
});

export default connect(mapStateToProps, { login })(LoginContainer);
